import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons/Button";
import Select from "../../../components/Inputs/Select";
import * as projectStatusAPI from "../../../api/ownFields/projectStatusApi";
function CustomProgressBarView({
  showModal,
  hideModal,
  steps,
  setSteps,
  handleSaveSteps,
}) {
  const [statusOptions, setStatusOptions] = useState();

  useEffect(() => {
    projectStatusAPI.getStatusList().then((list) => setStatusOptions(list));
  }, []);

  const selectStep1 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step1: value.value });
  };
  const selectStep2 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step2: value.value });
  };
  const selectStep3 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step3: value.value });
  };
  const selectStep4 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step4: value.value });
  };
  const selectStep5 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step5: value.value });
  };
  const selectStep6 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step6: value.value });
  };
  const selectStep7 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step7: value.value });
  };
  const selectStep8 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step8: value.value });
  };
  const selectStep9 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step9: value.value });
  };
  const selectStep10 = (e, value) => {
    setSteps({ ...steps, progress_step_bar_step10: value.value });
  };

  useEffect(() => {
    let deleteOption = {
      value: null,
      label: "Ta bort",
    };
    if (statusOptions) statusOptions.push(deleteOption);
  }, [statusOptions]);

  if (typeof statusOptions === "object")
    return (
      <>
        <Modal show={showModal} onHide={hideModal} size="sm" centered>
          <Modal.Header closeButton>Editera progressbar</Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    name={"step1"}
                    label={"Steg 1"}
                    options={statusOptions}
                    onChange={selectStep1}
                    value={
                      steps?.progress_step_bar_step1
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step1
                          )
                        : ""
                    }
                  />{" "}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 2"}
                    options={statusOptions}
                    onChange={selectStep2}
                    value={
                      steps?.progress_step_bar_step2
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step2
                          )
                        : ""
                    }
                  />{" "}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 3"}
                    options={statusOptions}
                    onChange={selectStep3}
                    value={
                      steps?.progress_step_bar_step3
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step3
                          )
                        : ""
                    }
                  />{" "}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 4"}
                    options={statusOptions}
                    onChange={selectStep4}
                    value={
                      steps?.progress_step_bar_step4
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step4
                          )
                        : ""
                    }
                  />{" "}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 5"}
                    options={statusOptions}
                    onChange={selectStep5}
                    value={
                      steps?.progress_step_bar_step5
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step5
                          )
                        : ""
                    }
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 6"}
                    options={statusOptions}
                    onChange={selectStep6}
                    value={
                      steps?.progress_step_bar_step6
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step6
                          )
                        : ""
                    }
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 7"}
                    options={statusOptions}
                    onChange={selectStep7}
                    value={
                      steps?.progress_step_bar_step7
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step7
                          )
                        : ""
                    }
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 8"}
                    options={statusOptions}
                    onChange={selectStep8}
                    value={
                      steps?.progress_step_bar_step8
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step8
                          )
                        : ""
                    }
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Select
                    label={"Steg 9"}
                    options={statusOptions}
                    onChange={selectStep9}
                    value={
                      steps?.progress_step_bar_step9
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step9
                          )
                        : ""
                    }
                  />
                </div>
                <div>
                  <Select
                    label={"Steg 10"}
                    options={statusOptions}
                    onChange={selectStep10}
                    value={
                      steps?.progress_step_bar_step10
                        ? statusOptions.find(
                            (item) =>
                              item.value === steps?.progress_step_bar_step10
                          )
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleSaveSteps()}>Spara</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default CustomProgressBarView;
