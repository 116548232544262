import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import ColumnFilter from "../dataTableFilters/ColumnFilter";

export const PricelistColumns = [
  {
    Header: "Leverantör",
    table: "pricelist",
    accessor: "supplier_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Artikel",
    table: "pricelist",
    accessor: "article_article_name",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Artikel nr",
    table: "pricelist",
    accessor: "article_article_number",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Hemtagningskostnad i kr",
    accessor: "retrieval_cost",
    disableFilters: true,
  },
  {
    Header: "Inköpspris i kr",
    accessor: "purchase_price",
    disableFilters: true,
  },
  {
    Header: "Totalt inköpspris",
    Cell: (props) => {
      return (
        <>
          {(
            props?.row?.original?.retrieval_cost +
            props?.row?.original?.purchase_price
          ).toFixed(2)}{" "}
          kr
        </>
      );
    },
    disableFilters: true,
  },
];
