import React from "react";
import { EconomyColumns } from "../../../../components/dataTable/columns/EconomyColumns";
import DataTable from "../../../../components/newDataTable/DataTable";

function EconomyTable({ economyRowDTO }) {
  return (
    <>
      <DataTable
        columns={EconomyColumns}
        data={economyRowDTO?.rows.length > 0 ? economyRowDTO.rows : []}
        typeOfTable="economy"
      />
    </>
  );
}

export default EconomyTable;
