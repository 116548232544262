import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getAta = (id) => api.get(`/ata/${id}`).then((res) => res.data);
export const getAllForProject = (id) =>
  api.get(`/ataforproject/${id}`).then((res) => res.data);

export const updateAta = ({ id, ...updatedAta }) => {
  api.put(`/ata/${id}`, updatedAta).then((res) => res.data);
};

export const newAta = (ata) => api.post(`/ata`, ata);

export const deleteAta = (id) => api.delete(`/ata/` + id);
