import { createContext, useState } from "react";

export const AuthContext = createContext({});

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      console.log(action.payload, "PAYLOAD");
      return { user: action.payload };
    }
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  // useEffect(() => {
  //   const refreshToken = getCookie("refresh-token");
  //   const user = getCookie("user");
  //   const userid = getCookie("userid");
  //   console.log(refreshToken, user, userid, "COOOOOKIE");
  //   if (refreshToken) {
  //     console.log("TRIEGGERED IN CONTEXT");
  //     dispatch({ type: "LOGIN", payload: JSON.parse(user) });
  //   }
  // }, []);

  // console.log("AuthContext state: ", state);
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {" "}
      {children}{" "}
    </AuthContext.Provider>
  );
};
