import React, { useState } from "react";
import AccumulatedTimeView from "./components/AccumulatedTimeView";
import TimeRegView from "./components/TimeRegView";
import EditTimeRegView from "./components/EditTimeRegView";
function ProductionForm({
  timeRegTypes,
  timeRegistredUnderProject,
  createTimeReg,
  projectId,
  setWasMutaded,
  objectWithTimeProperties,
}) {
  const [showEditTimeRegView, setShowEditTimeRegView] = useState();
  return (
    <>
      <TimeRegView
        setWasMutaded={setWasMutaded}
        projectId={projectId}
        createTimeReg={createTimeReg}
        timeRegTypes={timeRegTypes}
        timeRegistredUnderProject={timeRegistredUnderProject}
        setShowEditTimeRegView={setShowEditTimeRegView}
        objectWithTimeProperties={objectWithTimeProperties}
      />
      <hr></hr>
      <AccumulatedTimeView
        objectWithTimeProperties={objectWithTimeProperties}
      />
      <EditTimeRegView
        setWasMutaded={setWasMutaded}
        timeRegistredUnderProject={timeRegistredUnderProject}
        showModal={showEditTimeRegView}
        hideModal={() => setShowEditTimeRegView(false)}
      />
    </>
  );
}

export default ProductionForm;
