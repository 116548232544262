import React from "react";

import "./checkbox.css";

function CheckBoxAnimation() {
  return (
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        zIndex: "999999",
      }}
    >
      <div className="card-body">
        <div>
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <h5>Sparat</h5>
      </div>
    </div>
  );
}

export const startSavingAnimation = (setSavingSavingAnimation) => {
  setSavingSavingAnimation(1);
  let timer = setInterval(() => {
    setSavingSavingAnimation(0);
    clearInterval(timer);
  }, 1500);
};

export default CheckBoxAnimation;
