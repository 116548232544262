import React from "react";
import { Typography } from "@mui/material";

function ArticleHeader({ article }) {
  return (
    <div>
      <Typography variant="h4" component="h1">
        Artikel : {article.article_name}
      </Typography>
      <Typography variant="h5" component="h2">
        Artikel Nr : {article.article_number}
      </Typography>
    </div>
  );
}

export default ArticleHeader;
