import React, { useState } from "react";
import { useQuery } from "react-query";
import EconomyTable from "./EconomyTable";
import * as economyAPI from "../../../../api/project/economyAPI";
import Button from "../../../../components/buttons/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleEconomyExport } from "../../utils/ExportFunctions";

function EconomyOverview({ projectId, projectNumber }) {
  const { data: economyRowDTO } = useQuery(["economyRows", projectId], () =>
    economyAPI.getProjectEconomy(projectId)
  );
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FileDownloadIcon />}
        onClick={() => handleEconomyExport(economyRowDTO.rows, projectNumber)}
      >
        Exportera tabell
      </Button>
      <EconomyTable economyRowDTO={economyRowDTO} />
    </>
  );
}

export default EconomyOverview;
