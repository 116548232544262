import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import * as contactPersonAPI from "../../../api/register/contactPersonApi";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
const AddSupplierContactPersonView = ({ showModal, hideModal, supplierId }) => {
  const [contactPerson, setContactPerson] = useState({
    name: "",
    role: "",
    email: "",
    phone: "",
    supplier: supplierId,
  });

  const createContactPerson = useMutation(
    contactPersonAPI.createContactPerson,
    {
      onSuccess: (response) => {
        console.log("ON success", response);
        setTimeout(function () {
          hideModal();
        }, 1000);
        toastSuccess("Kontaktperson lades till");
      },
      onError: (e) => {
        console.log(e, "contactperson");
        toastError("Gick inte att lägga till kontaktperson");
      },
    }
  );
  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
        // queryClient.invalidateQueries();
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>Lägg till kontaktperson</Modal.Header>

      <Modal.Body>
        <div>
          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Namn"}
              name="3;name"
              value={contactPerson.name}
              onChange={(e) =>
                setContactPerson({ ...contactPerson, name: e.target.value })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Roll"}
              name="3;role"
              value={contactPerson.role}
              onChange={(e) =>
                setContactPerson({ ...contactPerson, role: e.target.value })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <InputField
              label={"Telefonnummer"}
              name="3;phone"
              value={contactPerson.phone}
              onChange={(e) =>
                setContactPerson({
                  ...contactPerson,
                  phone: e.target.value,
                })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Mail"}
              name="3;email"
              value={contactPerson.email}
              onChange={(e) => {
                setContactPerson({
                  ...contactPerson,
                  email: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => {
            createContactPerson.mutate(contactPerson);
          }}
        >
          Lägg till
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSupplierContactPersonView;
