import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getComment = (id) =>
  api.get(`/errand/${id}`).then((res) => res.data);

export const getAll = () => api.get(`/errand/`).then((res) => res.data);

export const getErrandsForProject = (id) =>
  api.get(`/errandforproject/${id}`).then((res) => res.data);

export const updateComment = ({ id, ...updatedComment }) => {
  api.put(`/errand/${id}`, updatedComment).then((res) => res.data);
};

export const newComment = (comment) => api.post(`/errand`, comment);

export const deleteComment = (id) => api.delete(`/errand/` + id);

export const newErrandLog = (log) => api.post("/errandlog", log);

export const getErrandLogs = (id) =>
  api.get(`/errandlog/${id}`).then((res) => res.data);

export const sendErrandUpdateToclient = ({ id, ...commentToSend }) =>
  api
    .put(`/senderrendupdatetoclient/${id}`, commentToSend)
    .then((res) => res.data);
