import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import InputField from "../../Inputs/InputField";
import Button from "../../buttons/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { resetStoredFilters } from "./HandleFilterLocalStorage";
function GlobalFilter({
  filter,
  setFilter,
  setShowFilters,
  showFilters,
  setAllFilters,
  storedFilters,
  typeOfTable,
}) {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 300);

  const handleResetFilters = () => {
    resetStoredFilters(typeOfTable);
    setAllFilters([]);
    setShowFilters(false);
  };

  return (
    <div
      style={{
        width: "35em",
        marginBottom: "10px",
        float: "right",
        display: "flex",
        gap: "0.5em",
      }}
    >
      <div style={{ display: "flex" }}>
        {showFilters || storedFilters ? (
          <Button
            sx={{ width: "10em", marginRight: "1em" }}
            onClick={() => handleResetFilters()}
          >
            Reset
          </Button>
        ) : (
          <></>
        )}
        <Button
          sx={{ width: "10em" }}
          onClick={() => setShowFilters(!showFilters)}
          endIcon={<FilterListIcon />}
        >
          Filter
        </Button>
      </div>

      <InputField
        endAdornment={<SearchIcon />}
        label={"Sök"}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
}

export default GlobalFilter;
