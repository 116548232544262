import React, { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as accountAPI from "../../../api/register/accountApi";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import CloseConfirmation from "../../../components/modals/CloseConfirmation";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function AddAccountView({ hideModal, showModal }) {
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const queryClient = useQueryClient();

  const [fields, setFields] = useState({
    account: undefined,
    Description: undefined,
    lead_time: undefined,
  });

  const { mutate } = useMutation(accountAPI.newAccount, {
    onSuccess: () => {
      toastSuccess("Konto skapades");
      hideModal();
      setFields({
        account: undefined,
        Description: undefined,
        lead_time: undefined,
      });
      console.log("ON SETTLED");
      queryClient.invalidateQueries();
    },
    onError: (e) => {
      if (e.response.status === 409)
        toastError("Finns redan en konto med det namnet!");
      if (e.response.status === 500) toastError("Gick inte att skapa konto!");
      console.log("error fron mutate");
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);

    hideModal();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till konto:</Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                gap: "1em",
                marginBottom: "1em",
              }}
            >
              <InputField
                required
                label="Konto"
                name="account"
                value={fields.account}
                onChange={handleChange}
              />

              <InputField
                required
                label="    Beskrivning"
                name="Description"
                value={fields.Description}
                onChange={handleChange}
              />
              <InputField
                type="number"
                label="  Ledtid"
                name="lead_time"
                value={fields.lead_time}
                onChange={handleChange}
              />
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
              <Button type="submit" color="success">
                Skapa
              </Button>
              <Button type="button" onClick={() => hideModal()}>
                Stäng
              </Button>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <CloseConfirmation
        showModal={displayCloseConfirmationModal}
        confirmModal={confirmClose}
        hideModal={() => setDisplayCloseConfirmationModal(false)}
      />
    </>
  );
}

export default AddAccountView;
