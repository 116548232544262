import React from "react";
import { Tooltip } from "@mui/material";
import { formatNumberWithSpaces } from "../../../helpers/generalFunctions";

export const EconomyColumns = [
  {
    Header: "Konto",
    accessor: "account",
    disableFilters: true,
  },
  {
    Header: "Beskrivning",
    accessor: "accountDescription",
    disableFilters: true,
  },
  {
    // Header: "Kalkyl från system",
    Header: () => (
      <Tooltip title={<strong>{"kalkyl som ligger på IO i systemet"}</strong>}>
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kalkyl från system
        </div>
      </Tooltip>
    ),
    accessor: "system_calculatedValue",
    Cell: (props) => {
      return (
        <div style={{ textAlign: "right" }}>
          {formatNumberWithSpaces(props.row.original.system_calculatedValue)}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.original.system_calculatedValue + sum,
            0
          ),
        [info.rows]
      );

      return (
        <div style={{ textAlign: "right", marginRight: "16px" }}>
          {formatNumberWithSpaces(total)}{" "}
        </div>
      );
    },
    disableFilters: true,
  },
  {
    Header: () => (
      <Tooltip title={<strong>{"Kostnad som ligger på IO i systemet"}</strong>}>
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kostnad från system
        </div>
      </Tooltip>
    ),
    accessor: "system_cost",
    Cell: (props) => {
      return (
        <div style={{ textAlign: "right" }}>
          {formatNumberWithSpaces(props.row.original.system_cost)}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.original.system_cost + sum, 0),
        [info.rows]
      );

      return (
        <div style={{ textAlign: "right", marginRight: "16px" }}>
          {formatNumberWithSpaces(total)}{" "}
        </div>
      );
    },
    disableFilters: true,
  },
  {
    Header: () => (
      <Tooltip
        title={
          <strong>{"Fakturerade beloppet som ligger på IO i systemet"}</strong>
        }
      >
        <div
          style={{
            textAlign: "right",
          }}
        >
          Faktura från system
        </div>
      </Tooltip>
    ),
    accessor: "system_invoicedAmount",
    Cell: (props) => {
      return (
        <div style={{ textAlign: "right" }}>
          {formatNumberWithSpaces(props.row.original.system_invoicedAmount)}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => row.original.system_invoicedAmount + sum,
            0
          ),
        [info.rows]
      );

      return (
        <div style={{ textAlign: "right", marginRight: "16px" }}>
          {formatNumberWithSpaces(total)}{" "}
        </div>
      );
    },
    disableFilters: true,
  },
  {
    Header: () => (
      <Tooltip
        title={
          <strong>
            {"Prognosvärde baserat på senaste riktiga värdet från systemet"}
          </strong>
        }
      >
        <div
          style={{
            textAlign: "right",
          }}
        >
          Prognos från system
        </div>
      </Tooltip>
    ),
    accessor: "prognos",
    Cell: (props) => {
      return (
        <div style={{ textAlign: "right" }}>
          {formatNumberWithSpaces(props.row.original.prognos)}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => row.original.prognos + sum, 0),
        [info.rows]
      );

      return (
        <div style={{ textAlign: "right", marginRight: "16px" }}>
          {formatNumberWithSpaces(total)}{" "}
        </div>
      );
    },

    disableFilters: true,
  },
  {
    Header: () => (
      <Tooltip
        title={<strong>{"Avvikelse från kalkyl som importerades"}</strong>}
      >
        <div
          style={{
            textAlign: "right",
          }}
        >
          Avvikelse
        </div>
      </Tooltip>
    ),
    accessor: "difference_against_forecast",
    Cell: (props) => {
      return (
        <div
          style={{
            textAlign: "right",
            color:
              props.row.original.difference_against_forecast <= 0
                ? "green"
                : "red",
            marginRight: "16px",
          }}
        >
          {formatNumberWithSpaces(
            props.row.original.difference_against_forecast
          )}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            (sum, row) => calculateSumForDifference(row) + sum,
            0
          ),
        [info.rows]
      );

      return (
        <div
          style={{
            textAlign: "right",
            color: total <= 0 ? "green" : "red",
            marginRight: "16px",
          }}
        >
          {formatNumberWithSpaces(total)}
        </div>
      );
    },
    disableFilters: true,
  },
  // {
  //   Header: () => (
  //     <div
  //       style={{
  //         textAlign: "right",
  //       }}
  //     >
  //       Leverantör debit
  //     </div>
  //   ),
  //   accessor: "fortnox_supplier_debit_amount",
  //   Cell: (props) => {
  //     return (
  //       <div style={{ textAlign: "right" }}>
  //         {props.row.original.fortnox_supplier_debit_amount}
  //       </div>
  //     );
  //   },
  //   Footer: (info) => {
  //     // Only calculate total visits if rows change
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.original.fortnox_supplier_debit_amount + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <div style={{ textAlign: "right" }}>{total.toFixed(1)} </div>;
  //   },
  //   disableFilters: true,
  // },

  // {
  //   Header: () => (
  //     <div
  //       style={{
  //         textAlign: "right",
  //       }}
  //     >
  //       Leverantör kredit
  //     </div>
  //   ),
  //   accessor: "fortnox_supplier_credit_amount",
  //   Cell: (props) => {
  //     return (
  //       <div style={{ textAlign: "right" }}>
  //         {props.row.original.fortnox_supplier_credit_amount}
  //       </div>
  //     );
  //   },
  //   Footer: (info) => {
  //     // Only calculate total visits if rows change
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.original.fortnox_supplier_credit_amount + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <div style={{ textAlign: "right" }}>{total.toFixed(1)} </div>;
  //   },
  //   disableFilters: true,
  // },
  // {
  //   Header: () => (
  //     <div
  //       style={{
  //         textAlign: "right",
  //       }}
  //     >
  //       Kund debit
  //     </div>
  //   ),
  //   accessor: "fortnox_client_debit_amount",
  //   Cell: (props) => {
  //     return (
  //       <div style={{ textAlign: "right" }}>
  //         {props.row.original.fortnox_client_debit_amount}
  //       </div>
  //     );
  //   },
  //   Footer: (info) => {
  //     // Only calculate total visits if rows change
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.original.fortnox_client_debit_amount + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <div style={{ textAlign: "right" }}>{total.toFixed(1)} </div>;
  //   },
  //   disableFilters: true,
  // },
  // {
  //   Header: () => (
  //     <div
  //       style={{
  //         textAlign: "right",
  //       }}
  //     >
  //       Kund kredit
  //     </div>
  //   ),
  //   accessor: "fortnox_client_credit_amount",

  //   Cell: (props) => {
  //     return (
  //       <div style={{ textAlign: "right" }}>
  //         {props?.row?.original?.fortnox_client_credit_amount}
  //       </div>
  //     );
  //   },
  //   Footer: (info) => {
  //     // Only calculate total visits if rows change
  //     const total = React.useMemo(
  //       () =>
  //         info.rows.reduce(
  //           (sum, row) => row.original.fortnox_client_credit_amount + sum,
  //           0
  //         ),
  //       [info.rows]
  //     );

  //     return <div style={{ textAlign: "right" }}>{total.toFixed(1)} </div>;
  //   },
  //   disableFilters: true,
  // },
  {
    Header: () => (
      <Tooltip title={<strong>{"Leverantörskostnader från fortnox"}</strong>}>
        <div
          style={{
            textAlign: "right",
          }}
        >
          Leverantör
        </div>
      </Tooltip>
    ),
    accessor: "result",

    disableFilters: true,
    Cell: (props) => {
      return (
        <div
          style={{
            textAlign: "right",
          }}
        >
          {formatNumberWithSpaces(props.row.original.result)}
        </div>
      );
    },
    Footer: (info) => {
      // Only calculate total visits if rows change
      const total = React.useMemo(
        () => info.rows.reduce((sum, row) => calculateSumForRow(row) + sum, 0),
        [info.rows]
      );

      return (
        <div style={{ textAlign: "right", marginRight: "16px" }}>
          {formatNumberWithSpaces(total)}
        </div>
      );
    },
  },
  {
    Header: "Senast inläst",
    Cell: (props) => {
      return (
        <>
          {props.row.original.updated <
          new Date().toLocaleDateString("sv-SE") ? (
            <div style={{ color: "red" }}>{props.row.original.updated}</div>
          ) : (
            <div>{props.row.original.updated}</div>
          )}
        </>
      );
    },

    accessor: "updated",
    disableFilters: true,
  },
];

const calculateSumForRow = (row) => {
  return (
    // Number(row.original.fortnox_client_credit_amount) +
    Number(row.original.fortnox_supplier_credit_amount) -
    Number(row.original.fortnox_supplier_debit_amount)
    //  -
    // Number(row.original.fortnox_client_debit_amount)
  );
};

const calculateSumForDifference = (row) => {
  return (
    Number(row.original.prognos) - Number(row.original.system_calculatedValue)
  );
};
