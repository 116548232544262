import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import Select from "../../../../../components/Inputs/SelectV2";
import Spinner from "../../../../../components/progress/Spinner";

const map = [];
const handleChangeColumnMapp = (e, value) => {
  map.push(value.value);
};

const ImportOrderRowsView = ({
  showModal,
  hideModal,
  confirmModal,
  orderHeads,
  statusImportedRows,
  isImported,
  handleOnExport,
  failedRowsToImport,
  setDisplayImportOrderRowsModal,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  if (orderHeads)
    return (
      <Modal show={showModal} onHide={hideModal} size="lg" centered>
        <Modal.Header closeButton>
          {!isImported ? (
            <Modal.Title id="contained-modal-title-vcenter">
              Importera orderrader
            </Modal.Title>
          ) : (
            <Modal.Title id="contained-modal-title-vcenter">
              Status importering
            </Modal.Title>
          )}
        </Modal.Header>
        {!isImported && !showLoader ? (
          <Modal.Body>
            <h4 style={{ marginBottom: "1rem" }}>
              Välj vilken kolumn till vilken del av orderraden den ska mappas
              mot
            </h4>
            <div className="form-grid-auto">
              {orderHeads.map((item, i) => (
                <Select
                  label={item}
                  name={item}
                  onChange={handleChangeColumnMapp}
                  options={[
                    { value: ["articlenumber", i], label: "Artikelnummer" },
                    { value: ["quantity", i], label: "Antal" },
                    { value: ["module", i], label: "Modul" },
                    { value: ["appendix", i], label: "Kommentar" },
                    { value: ["length", i], label: "Längd" },
                    { value: ["width", i], label: "Bredd" },
                    { value: ["calculation", i], label: "Kalkyl" },
                    { value: ["account", i], label: "Konto" },
                  ]}
                />
              ))}
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
              }}
            >
              {isImported ? (
                <h4>
                  Importerade {statusImportedRows.success} av{" "}
                  {statusImportedRows.error - 1} rader
                </h4>
              ) : (
                <>
                  <h4> Importerar...</h4>
                  <Spinner />
                </>
              )}
            </div>
          </Modal.Body>
        )}

        {!isImported ? (
          <Modal.Footer>
            <Button
              onClick={() => {
                confirmModal(map, setShowLoader);
                setShowLoader(true);
              }}
            >
              Importera
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            {" "}
            <Button
              onClick={() =>
                handleOnExport(
                  failedRowsToImport,
                  setDisplayImportOrderRowsModal
                )
              }
            >
              Ladda ner misslyckade rader
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
};

export default ImportOrderRowsView;
