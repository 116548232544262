import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import CloseConfirmation from "../../../components/modals/CloseConfirmation";
import { Modal } from "react-bootstrap";
import Select from "../../../components/Inputs/Select";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function EditUserView({ userAPI, staffRoles, hideModal, showModal }) {
  const [user, setUser] = useState();
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (showModal) userAPI.getUser(showModal).then((user) => setUser(user));
  }, [showModal, userAPI]);

  useEffect(() => {
    setUser();
  }, [showModal]);

  const { mutate } = useMutation(userAPI.updateUser, {
    onSuccess: () => {
      toastSuccess("Användare uppdaterades");
      setTimeout(function () {
        hideModal();
      }, 1000);
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
    onError: (e) => {
      if (e.response.status === 500)
        toastError("Gick inte att uppdatera användare!");
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(user);
  };

  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);

    hideModal();
  };
  if (user)
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            hideModal();
            queryClient.invalidateQueries();
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>Hantera användare:</Modal.Header>

          <Modal.Body>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div
                style={{
                  display: "grid",
                  gap: "1em",
                  marginBottom: "1em",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gap: "1em",
                    margin: "1em 0",
                    marginTop: "25px",
                  }}
                >
                  <InputField
                    value={user.first_name}
                    required
                    name="first_name"
                    label="Förnamn"
                    onChange={(e) =>
                      setUser({ ...user, first_name: e.target.value })
                    }
                  />
                  <InputField
                    value={user.last_name}
                    required
                    name="last_name"
                    label="Efternamn"
                    onChange={(e) =>
                      setUser({ ...user, last_name: e.target.value })
                    }
                  />

                  <Select
                    required
                    label={"Roll"}
                    value={staffRoles.find(
                      (item) => item.value === user.staff_role_id
                    )}
                    options={staffRoles}
                    onChange={(event, item) => {
                      setUser({ ...user, staff_role_id: item.value });
                    }}
                  />

                  <InputField
                    disabled
                    required
                    value={user.email}
                    type="email"
                    name="email"
                    label="Email"
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />

                  <InputField
                    value={user.phonenumber}
                    required
                    name="phone"
                    label="Telefon"
                    defaultValue={"+46"}
                    onChange={(e) =>
                      setUser({ ...user, phonenumber: e.target.value })
                    }
                  />
                </div>
                {/* <Button>Skicka ut nytt lösenord</Button> */}
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
                <Button type="submit" color="success">
                  Spara
                </Button>
                <Button type="button" onClick={() => hideModal()}>
                  Stäng
                </Button>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
        <CloseConfirmation
          showModal={displayCloseConfirmationModal}
          confirmModal={confirmClose}
          hideModal={() => setDisplayCloseConfirmationModal(false)}
        />
      </>
    );
}

export default EditUserView;
