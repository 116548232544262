import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as materialAPI from "../../../api/register/materialApi";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import CloseConfirmation from "../../../components/modals/CloseConfirmation";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function AddMaterialView({ hideModal, showModal }) {
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const material = {
    material: undefined,
  };

  const queryClient = useQueryClient();

  const [fields, setFields] = useState(material);

  useEffect(() => {
    setFields(material);
  }, [showModal]);

  const { mutate } = useMutation(materialAPI.newMaterial, {
    onSuccess: () => {
      toastSuccess("Material skapades");
      hideModal();
      queryClient.invalidateQueries();
    },
    onError: (e) => {
      if (e.response.status === 409)
        toastError("Finns redan en Material med det namnet!");
      if (e.response.status === 500)
        toastError("Gick inte att skapa Material!");
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);

    hideModal();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till material:</Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                gap: "1em",
                marginBottom: "1em",
              }}
            >
              <InputField
                required
                label="Material"
                name="material"
                value={fields.material}
                onChange={handleChange}
              />
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
              <Button type="submit" color="success">
                Skapa
              </Button>
              <Button type="button" onClick={() => hideModal()}>
                Stäng
              </Button>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <CloseConfirmation
        showModal={displayCloseConfirmationModal}
        confirmModal={confirmClose}
        hideModal={() => setDisplayCloseConfirmationModal(false)}
      />
    </>
  );
}

export default AddMaterialView;
