import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import { AccountColumns } from "../../../components/dataTable/columns/AccountColumns";
import * as accountAPI from "../../../api/register/accountApi";
import AccountView from "./AccountView";
import AddAccountView from "./AddAccountView";
import { useQuery } from "react-query";
function AccountOverview() {
  const [displayAddAccountView, setDisplayAddAccountView] = useState(false);
  const [displayAccountView, setDisplayAccountView] = useState(false);
  const { data: accountResponse } = useQuery(["account"], () =>
    accountAPI.getAllAccount()
  );
  return (
    <>
      <Button onClick={() => setDisplayAddAccountView(true)}>
        Lägg till konto
      </Button>
      <DataTable
        columns={AccountColumns}
        data={accountResponse?.length > 0 ? accountResponse : []}
        typeOfTable="account"
        handleShowObject={setDisplayAccountView}
      />

      <AccountView
        showModal={displayAccountView}
        hideModal={() => setDisplayAccountView(false)}
        accountId={displayAccountView}
      />
      <AddAccountView
        showModal={displayAddAccountView}
        hideModal={() => setDisplayAddAccountView(false)}
      />
    </>
  );
}

export default AccountOverview;
