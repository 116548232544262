import * as XLSX from "xlsx";
import * as exportAPI from "../../../api/project/exportAPI";

export const handleOnExport = (
  failedRowsToImport,
  setDisplayImportOrderRowsModal
) => {
  var wb = XLSX.utils.book_new();
  console.log(failedRowsToImport, "THESE ROWS YOUR LOOKING FOR");
  var ws = XLSX.utils.json_to_sheet(failedRowsToImport, {
    skipHeader: true,
  });
  XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
  XLSX.writeFile(wb, "misslyckade.xlsx");
  setDisplayImportOrderRowsModal(false);
};

export const handleOnProductionExport = (rows, projectNumber) => {
  let dateForExportFile = new Date().toLocaleDateString("sv-SE");
  var columnOrder = [
    "project_project_number",
    "module_module_number",
    "module_wall_designation",
    "controlled",
    "antal",
    "ledTid",
    "workingHours",
  ];
  var rowHeader = {
    project_project_number: "PROJEKT",
    module_module_number: "MODULNUMMER",
    module_wall_designation: "VÄGGBET",
    controlled: "KONTROLLERAD",
    antal: "ANTAL DELAR",
    ledTid: "LEDTID",
    workingHours: "TIMMAR",
    spikman: "SPIKMÅN",
    verklig_tot_tid: "VERKLIG TOTAL TID",
    material_uttaget: "MATERIAL UTTAGET",
    vagga: "VAGGA",
  };
  const rowData = [rowHeader, ...rows];
  // rows[0] = []
  var ws = XLSX.utils.json_to_sheet(rowData, {
    header: columnOrder,
    skipHeader: true,
  });
  ws["!cols"] = [];
  ws["!cols"][11] = { hidden: true };
  ws["!cols"][12] = { hidden: true };
  ws["!cols"][13] = { hidden: true };
  ws["!cols"][14] = { hidden: true };
  ws["!cols"][15] = { hidden: true };
  ws["!cols"][16] = { hidden: true };
  ws["!cols"][17] = { hidden: true };
  ws["!cols"][18] = { hidden: true };
  var wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
  XLSX.writeFile(wb, "produktionsrapport.xlsx");
};

// To be fixed

export const handleEconomyExport = (rows, projectNumber) => {
  console.log("EconomyFunc", rows, projectNumber);
  var columnOrder = [
    "account",
    "accountDescription",
    "system_calculatedValue",
    "system_cost",
    "system_invoicedAmount",
    "prognos",
    "difference_against_forecast",
    "result",
    "updated",
  ];
  var rowHeader = {
    account: "Konto",

    accountDescription: "Beskrvning",

    system_calculatedValue: "Kalkyl från system",

    system_cost: "Kostnad från system",

    system_invoicedAmount: "Faktura från system",
    prognos: "Prognos från system",

    difference_against_forecast: "Avvikelse",

    result: "Leverantör",

    updated: "Senast inläst",
  };
  const rowData = [rowHeader, ...rows];
  // rows[0] = []
  var ws = XLSX.utils.json_to_sheet(rowData, {
    header: columnOrder,
    skipHeader: true,
  });
  ws["!cols"] = [];
  ws["!cols"][9] = { hidden: true };
  ws["!cols"][10] = { hidden: true };
  ws["!cols"][11] = { hidden: true };
  ws["!cols"][12] = { hidden: true };
  ws["!cols"][13] = { hidden: true };
  ws["!cols"][14] = { hidden: true };
  ws["!cols"][15] = { hidden: true };
  ws["!cols"][16] = { hidden: true };
  ws["!cols"][17] = { hidden: true };
  ws["!cols"][18] = { hidden: true };
  var wb = XLSX.utils.book_new();

  let dateForFile = new Date().toLocaleDateString("sv-SE");
  XLSX.utils.book_append_sheet(wb, ws, "Ekonomi");
  XLSX.writeFile(wb, `${projectNumber}-ekonomi-${dateForFile}.xlsx`);
};

export const downloadContacts = async (projectContactPersonData, project) => {
  let supplierContactPersons = projectContactPersonData.map((person) => {
    console.log(person);
    return {
      id: person.supplier_contact_person_id,
      kommentar: person.project_supplier_contact_person_comment,
      bolag: person.supplier_name,
    };
  });
  const contactObject = {
    supplierContacts: supplierContactPersons,
    projectContacts: [
      {
        id: project?.project_a_rit,
        roll: "A-RIT",
      },
      {
        id: project?.project_k_rit,
        roll: "K-RIT",
      },
      {
        id: project?.project_project_leader,
        roll: "Projektledare",
      },
      {
        id: project?.project_purchaser,
        roll: "Inköpare",
      },
      {
        id: project?.project_salesperson,
        roll: "Säljare",
      },
    ],
    projnumber: project.project_project_number,
  };
  console.log("step 1 downloadonctacts");
  await exportAPI.downloadContacts(contactObject).then((response) => {
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  });
};
