import React from "react";
import Select from "../../../../components/Inputs/Select";
import InputField from "../../../../components/Inputs/InputField";
import { ReqOptions } from "../../../../components/customSelect/SelectOptions";
function RequirementInformationView({ fields, handleSelect, handleChange }) {
  return (
    <div className="wrapper" style={{ flex: "1" }}>
      <div className="wrapper-title">Krav</div>
      <div className="form-grid-auto">
        {" "}
        <Select
          required
          label="Längd krävs"
          options={ReqOptions}
          value={ReqOptions.find(
            (item) => item.value === fields.length_required
          )}
          onChange={(e, data) => {
            handleSelect(data, "length_required");
          }}
        />
        <Select
          required
          label="Bredd krävs"
          options={ReqOptions}
          value={ReqOptions.find(
            (item) => item.value === fields.width_required
          )}
          onChange={(e, data) => {
            handleSelect(data, "width_required");
          }}
        />
        <Select
          required
          label="Bilaga krävs"
          options={ReqOptions}
          value={ReqOptions.find(
            (item) => item.value === fields.appendix_required
          )}
          onChange={(e, data) => {
            handleSelect(data, "appendix_required");
          }}
        />
        <InputField
          type="number"
          label="Spill i %"
          name="waste"
          step={"0.01"}
          value={fields.waste}
          onChange={handleChange}
        />
        <InputField
          mulitline
          label=" DU"
          name="DU"
          value={fields.DU}
          onChange={handleChange}
        />{" "}
      </div>
    </div>
  );
}

export default RequirementInformationView;
