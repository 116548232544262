import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import { ArticleListColumns } from "../../../components/dataTable/columns/ArticleListColumns";
import DataTable from "../../../components/newDataTable/DataTable";
import * as articleAPI from "../../../api/register/articleApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
function ArticleOverview({ activeArticles }) {
  const navigate = useNavigate();

  const [articleList, setArticleList] = useState();

  const { data: articleResponse } = useQuery(["article"], () =>
    articleAPI.getAllArticles()
  );
  const { data: inactiveArticleResponse } = useQuery(["inactivearticle"], () =>
    articleAPI.getAllInactiveArticles()
  );

  useEffect(() => {
    if (activeArticles) {
      setArticleList(articleResponse);
    } else {
      setArticleList(inactiveArticleResponse);
    }
  }, [articleResponse, inactiveArticleResponse]);

  return (
    <div>
      <Button onClick={() => navigate("/register/skapa/artikel/")}>
        Lägg till Artikel
      </Button>

      <DataTable
        columns={ArticleListColumns}
        data={articleList?.length ? articleList : []}
        typeOfTable="article"
      />
    </div>
  );
}

export default ArticleOverview;
