import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
function Movablerow({ item, i }) {
  return (
    <>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>{item.articlenumber}</TableCell>
        <TableCell>{item.articlename}</TableCell>
        <TableCell>
          {item.quantity}
          {item.order_unit}
        </TableCell>
        <TableCell>{item.length}</TableCell>
        <TableCell>{item.width}</TableCell>
        <TableCell>
          {item.total_amount} {"  "} {item.price_unit}
        </TableCell>
        <TableCell>{item.purchase_price}</TableCell>

        <TableCell>
          {(item.total_amount * item.purchase_price).toFixed(2)}
        </TableCell>
        <TableCell>{item.calculation ? item.calculation : ""}</TableCell>
        <TableCell>{item.appendix}</TableCell>
      </TableRow>
    </>
  );
}

export default Movablerow;
