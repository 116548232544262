import React from "react";
import Button from "../../../components/buttons/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import * as ataAPI from "../../../api/ata/ataApi";
import DataTable from "../../../components/newDataTable/DataTable";
import SelectColumnFilter from "../../../components/dataTable/dataTableFilters/SelectColumnFilter";

function ProjectAtaOverview({ projectId }) {
  const navigate = useNavigate();

  const { data: ataData } = useQuery(["ata", projectId], () =>
    ataAPI.getAllForProject(projectId)
  );

  const AtaColumns = [
    {
      Header: "ÄTA Nr",
      accessor: "ata_ata_nr",
      disableFilters: true,
    },
    {
      Header: "Beställt",
      accessor: "ata_order_date",
      disableFilters: true,
    },
    {
      Header: "Skapad av",
      accessor: "registered_by",
      disableFilters: true,
    },
    {
      Header: "Aviserad av av",
      accessor: "supplier_name",
      disableFilters: true,
    },
    {
      Header: "Godkänd av",
      accessor: "confirmed_by",
      disableFilters: true,
    },
    {
      Header: "Godkänd datum",
      accessor: "ata_confirmed_date",
      disableFilters: true,
    },

    {
      Header: "Märke",
      accessor: "ata_label",
      disableFilters: true,
    },
    {
      Header: "Status",
      table: "ATA",
      accessor: "ata_status_name",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Enhet",
      accessor: "ata_unit",
      disableFilters: true,
    },
    {
      Header: "Antal",
      accessor: "ata_quantity",
      disableFilters: true,
    },
    {
      Header: "Pris",
      accessor: "ata_price",
      disableFilters: true,
    },
    {
      Header: "Summa",
      accessor: "ata_sum",
      disableFilters: true,
    },
    {
      Header: "Summa med ev påslag",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.ata_sum_with_client_charge &&
            props?.row?.original?.ata_sum_with_client_charge > 0 ? (
              <>{props?.row?.original?.ata_sum_with_client_charge}</>
            ) : (
              <>
                {props.row.original.ata_sum_with_supplier_carge &&
                props.row.original.ata_sum_with_supplier_carge > 0
                  ? props.row.original.ata_sum_with_supplier_carge
                  : props.row.original.ata_sum}
              </>
            )}
          </>
        );
      },
      disableFilters: true,
    },
  ];
  return (
    <>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => navigate(`/projektoversikt/skapa/ata/${projectId}`)}
      >
        Lägg till ny ÄTA
      </Button>
      <DataTable
        columns={AtaColumns}
        data={ataData?.length > 0 ? ataData : []}
        typeOfTable="ata"
      />
    </>
  );
}

export default ProjectAtaOverview;
