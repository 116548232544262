import * as holidayAPI from "../../../../../api/holiday/holidayAPI";
export const dateRuleVerifier = async (
  row,
  setRuleColors,
  clientDeliveryDate,
  factoryDeliveryDate,
  dateClicked,
  setDateClicked
) => {
  let rules = {
    rule1: "",
    rule2: "",
  };
  let workDaysLeftFromFactoryDelivery = await holidayAPI.getNumberOfWorkdays({
    ruleDate: factoryDeliveryDate,
  });
  let workDaysLeftFromClientDelivery = await holidayAPI.getNumberOfWorkdays({
    ruleDate: clientDeliveryDate,
  });

  if (!row.date_1)
    if (
      row.lead_time_1_yellow >
      (row.lead_time_1_towards === "Leverans fabrik"
        ? workDaysLeftFromFactoryDelivery.daysleft
        : workDaysLeftFromClientDelivery.daysleft)
    ) {
      if (
        row.lead_time_1_red >
        (row.lead_time_1_towards === "Leverans fabrik"
          ? workDaysLeftFromFactoryDelivery.daysleft
          : workDaysLeftFromClientDelivery.daysleft)
      ) {
        rules.rule1 = "error";
      } else {
        rules.rule1 = "warning";
      }
    }
  if (!row.date_2)
    if (
      row.lead_time_2_yellow >
      (row.lead_time_2_towards === "Leverans fabrik"
        ? workDaysLeftFromFactoryDelivery.daysleft
        : workDaysLeftFromClientDelivery.daysleft)
    ) {
      if (
        row.lead_time_2_red >
        (row.lead_time_2_towards === "Leverans fabrik"
          ? workDaysLeftFromFactoryDelivery.daysleft
          : workDaysLeftFromClientDelivery.daysleft)
      ) {
        rules.rule2 = "error";
      } else {
        rules.rule2 = "warning";
      }
    }

  setRuleColors(rules);
};
