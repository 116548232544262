import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const ConstructionpartColumns = [
  {
    Header: "Byggdel kod",
    table: "constructionparts",
    accessor: "constructionpart",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Byggdel",
    table: "constructionparts",
    accessor: "constructiondescription",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Övergripande",
    accessor: "overall",
    disableFilters: true,
  },
];
