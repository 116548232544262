import React from "react";
import CustomSelect from "../../customSelect/CustomSelect";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

function RestedEditRow({
  item,
  i,
  handleSelectModuleOnOrderRow,
  moduleList,
  handleChangeRestOrderRow,
}) {
  return (
    <TableRow>
      <TableCell scope="row" style={{ textAlign: "center" }}>
        {i + 1}
      </TableCell>
      <TableCell>{item.articlenumber}</TableCell>
      <TableCell>{item.articlename}</TableCell>
      <TableCell>
        <input
          type="text"
          name="quantity"
          onChange={(e) => handleChangeRestOrderRow(e, i)}
          placeholder={item.quantity}
          value={item.quantity}
        ></input>
      </TableCell>
      <TableCell>
        <input
          onChange={handleChangeRestOrderRow}
          type="text"
          name="appendix"
          placeholder={item.appendix}
          value={item.appendix}
          disabled
        ></input>{" "}
      </TableCell>
      <TableCell colSpan={3}>
        <CustomSelect
          handleChange={(ev, meta) => handleSelectModuleOnOrderRow(ev, meta)}
          optionList={moduleList}
          placeholder="Välj modul"
          defaultValue={item.module}
          name={i}
          isDisabled={true}
        />
      </TableCell>

      <TableCell></TableCell>
    </TableRow>
  );
}

export default RestedEditRow;
