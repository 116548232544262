import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const ClimateColumns = [
  {
    Header: "Resurs ID",
    table: "climate",
    accessor: "resurs_id",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Produktnamn",
    table: "climate",
    accessor: "produktnamn",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Version",
    accessor: "version",
    disableFilters: true,
  },
  {
    Header: "Uppdaterad",
    accessor: "Uppdaterad",
    disableFilters: true,
  },
  {
    Header: "A1-A3 resursens klimatpåverkan, konservativt värde",
    accessor: "a1a3_resource_climateeffect",
    disableFilters: true,
  },
  {
    Header: "A4 transporters klimatpåverkan GWP-GHG",
    accessor: "a4_transport_climateeffect",
    disableFilters: true,
  },
  {
    Header: "A5 byggspills klimatpåverkan GWP-GHG, konservativt värde",
    accessor: "a5_constructionwaste_climateeffect",
    disableFilters: true,
  },
  {
    Header: "Enhet för klimatpåverkan",
    accessor: "climateeffect_unit",
    disableFilters: true,
  },
  {
    Header: "A1-A3 byggproduktens klimatpåverkan GWP-GHG, typiskt värde",
    accessor: "a1a3_constructionproduct_GWP_GHG_climateeffect",
    disableFilters: true,
  },
  {
    Header: "A1-A3 faktor för konservativa värden",
    accessor: "a1a3_factor",
    disableFilters: true,
  },

  {
    Header: "A5 faktor för byggspill",
    accessor: "a5_factor",
    disableFilters: true,
  },
  {
    Header: "Teknisk livslängd",
    accessor: "technical_lifespan",
    disableFilters: true,
  },

  {
    Header: "Omräkningsfaktor",
    accessor: "recount_factor",
    disableFilters: true,
  },
  {
    Header: "Enhet för omräkningsfaktor",
    accessor: "recount_unit",
    disableFilters: true,
  },
  {
    Header: "Produktstandard",
    accessor: "productstandard",
    disableFilters: true,
  },
];
