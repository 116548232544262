import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500
    console.log(status, 'ERROR api status')
    if (status === 401) {
      console.log('401 error från api')
      // window.location.reload();
      window.location =
        window.location.protocol + '//' + window.location.host + '/login'
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, 'APIERROR')
      console.log('APIERROR')

      return Promise.reject(error) // Delegate error to calling side
    }
  },
)

export const userList = () => api.get(`/usersforlist/`).then((res) => res.data)

export const getAllUsers = () =>
  api.get('/usersandroles/').then((res) => res.data)

export const newUser = (user) => api.post(`/userregister/`, user)

export const getUserInfo = (id) =>
  api.get(`/userinfo/${id}`).then((res) => res.data)

export const getUser = (id) => api.get(`/user/${id}`).then((res) => res.data)

export const updateUser = ({ id, ...user }) => {
  api.put(`/user/${id}`, user).then((res) => res.data)
}
