import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as constructionAPI from "../../../api/register/constructionpartApi";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import CloseConfirmation from "../../../components/modals/CloseConfirmation";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function ConstructionPartview({ hideModal, showModal, constructionPartId }) {
  const [fields, setFields] = useState();
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  useEffect(() => {
    if (constructionPartId)
      constructionAPI
        .getConstructionpart(constructionPartId)
        .then((part) => setFields(part));
  }, [constructionPartId]);

  const queryClient = useQueryClient();

  useEffect(() => {
    setFields();
  }, [showModal]);

  const { mutate } = useMutation(constructionAPI.updateConstructionpart, {
    onSuccess: () => {
      toastSuccess("Byggdel uppdaterades");
      hideModal();
      queryClient.invalidateQueries();
    },
    onError: (e) => {
      if (e.response.status === 409)
        toastError("Finns redan en del med det namnet!");
      if (e.response.status === 500) toastError("Gick inte att uppdatera!");
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);

    hideModal();
  };
  if (fields)
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            hideModal();
            queryClient.invalidateQueries();
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>Byggdel:</Modal.Header>

          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "grid",
                  gap: "1em",
                  marginBottom: "1em",
                }}
              >
                <InputField
                  required
                  label="Byggdel kod"
                  name="constructionpart"
                  value={fields.constructionpart}
                  onChange={handleChange}
                />

                <InputField
                  label="    Byggdel"
                  name="constructiondescription"
                  value={fields.constructiondescription}
                  onChange={handleChange}
                />
                <InputField
                  type="number"
                  label="  Övergripande"
                  name="overall"
                  value={fields.overall}
                  onChange={handleChange}
                />
              </div>

              <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
                <Button type="submit" color="success">
                  Spara
                </Button>
                <Button type="button" onClick={() => hideModal()}>
                  Stäng
                </Button>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
        <CloseConfirmation
          showModal={displayCloseConfirmationModal}
          confirmModal={confirmClose}
          hideModal={() => setDisplayCloseConfirmationModal(false)}
        />
      </>
    );
}

export default ConstructionPartview;
