import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import * as supplierApi from "./../../../api/register/supplierApi";
import * as articleApi from "./../../../api/register/articleApi";
import * as accountApi from "./../../../api/register/accountApi";
import * as constructionpartAPI from "../../../api/register/constructionpartApi";
import * as materialAPI from "../../../api/register/materialApi";
import * as storagespaceAPI from "../../../api/register/storagespaceApi";
import * as climateAPI from "../../../api/register/climateApi";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import BasicInformationView from "./components/BasicInformationView";
import UnitInformationView from "./components/UnitInformationView";
import RequirementInformationView from "./components/RequirementInformationView";
import OtherInformationView from "./components/OtherInformationView";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";

function AddArticleView() {
  let navigate = useNavigate();
  const [newArticleNumber, setNewArticleNumber] = useState();
  const [supplierList, setSupplierList] = useState();
  const [accountList, setAccountList] = useState();
  const [partList, setPartList] = useState();
  const [materialList, setMaterialList] = useState();
  const [storagespaceList, setStoragespaceList] = useState();
  const [climateList, setClimateList] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  useEffect(() => {
    supplierApi
      .getAllSupplierNames()
      .then((suppliers) => setSupplierList(suppliers));
    accountApi
      .getAllAccountNames()
      .then((accounts) => setAccountList(accounts));
    constructionpartAPI
      .getConstructionpartNames()
      .then((parts) => setPartList(parts));

    materialAPI
      .getAllMaterial()
      .then((materials) => setMaterialList(materials));

    storagespaceAPI
      .getAllStoragespace()
      .then((storages) => setStoragespaceList(storages));

    climateAPI
      .getAllClimatenames()
      .then((climates) => setClimateList(climates));
    articleApi.GetLastArticleNumber().then((number) => {
      if (number) setNewArticleNumber(parseInt(number.article_number) + 1);
      else setNewArticleNumber(1);
    });
  }, []);

  const article = {
    DU: undefined,
    account: undefined,
    appendix_required: undefined,
    article_name: undefined,
    article_number: newArticleNumber,
    climateid: undefined,
    comment: undefined,
    construction_part: undefined,
    length_required: undefined,
    mainSupplierId: undefined,
    material: undefined,
    order_unit: undefined,
    orderpoint_karlskoga: undefined,
    orderpoint_surahammar: undefined,
    price_unit: undefined,
    recount_unit: 1,
    storage_space: undefined,
    unit: undefined,
    volym: undefined,
    waste: undefined,
    weight: undefined,
    width_required: undefined,
    isActive: undefined,
  };

  const [fields, setFields] = useState(article);

  const { mutate } = useMutation(articleApi.newArticle, {
    onSuccess: (e) => {
      toastSuccess("Artikel skapades");
      navigate(`/register/artikel/${e.data.raw.insertId}`);
    },
    onError: (err) => {
      toastError("Gick inte att skapa artikel");
    },
  });

  const handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
  };

  const handleSelect = (e, key) => {
    setFields({ ...fields, [key]: e.value });
    setHasInputChanged(true);
  };

  const handleSubmit = (event) => {
    fields.article_number = newArticleNumber.toString();
    event.preventDefault();
    mutate(fields);
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };

  const confirmClose = () => {
    navigate(`/register/`);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  if (
    supplierList &&
    accountList &&
    partList &&
    materialList &&
    storagespaceList &&
    climateList
  )
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <h4 style={{ marginBottom: "1em" }}>Lägg till ny artikel</h4>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "grid",
            gap: "1em",
            width: "100%",
          }}
        >
          <BasicInformationView
            fields={fields}
            handleChange={handleChange}
            handleSelect={handleSelect}
            newArticleNumber={newArticleNumber}
            supplierList={supplierList}
          />
          <UnitInformationView
            fields={fields}
            handleSelect={handleSelect}
            handleChange={handleChange}
          />
          <RequirementInformationView
            fields={fields}
            handleChange={handleChange}
            handleSelect={handleSelect}
          />
          <OtherInformationView
            fields={fields}
            handleChange={handleChange}
            handleSelect={handleSelect}
            accountList={accountList}
            partList={partList}
            materialList={materialList}
            climateList={climateList}
            storagespaceList={storagespaceList}
          />

          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
            <Button type="submit" color="success">
              Lägg till
            </Button>
            <Button onClick={(e) => handleClose(e)}>Stäng</Button>
          </div>
        </form>

        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={confirmClose}
          hideModal={hideConfirmationModal}
        />
      </div>
    );
}

export default AddArticleView;
