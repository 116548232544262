import React, { useState } from "react";
import TableInstance from "../../../../components/dataTable/TableInstance";

function ErrandTable({ errandsData, setErrandLogId, errandColumns }) {
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <TableInstance
      typeOfTable={"errand"}
      tableData={errandsData}
      setPageNumber={setPageNumber}
      columnsRecieved={errandColumns}
      pageNumber={pageNumber}
      handleShowObject={setErrandLogId}
    />
  );
}

export default ErrandTable;
