import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
import { useNavigate } from "react-router-dom";
function StaffForms({ staff, staffRoles, setStaff, mutate }) {
  let navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();

  const handleClose = () => {
    navigate("/personal");
  };
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          data.staff_id = staff.id;

          mutate(data);
        })}
      >
        <div
          style={{
            display: "grid",
            gap: "1em",
            maxWidth: "350px",
            margin: "1em 0",
          }}
        >
          <InputField
            name="fullname"
            label="Fullständigt namn"
            defaultValue={staff.staff_fullname}
            register={register("fullname")}
          />

          <Controller
            control={control}
            name={"role"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("role")}
                options={staffRoles}
                label={"Roll"}
                defaultValue={""}
                value={staffRoles.find(
                  (item) => item.value === staff.staff_role
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setStaff({
                    ...staff,
                    staff_role: item.value,
                  });
                }}
              />
            )}
          />

          <InputField
            type="email"
            name={"staff_email"}
            register={register("staff_email")}
            label="Email"
            defaultValue={staff.staff_email}
          />

          <InputField
            name={"staff_phone"}
            register={register("staff_phone")}
            label="Telefon"
            defaultValue={staff.staff_phone}
          />
        </div>
        <hr></hr>
        <div style={{ display: "flex", gap: "1em" }}>
          <Button variant="outlined" onClick={handleClose}>
            Stäng
          </Button>
          <Button type="submit">Spara</Button>
        </div>
      </form>
    </>
  );
}

export default StaffForms;
