export const handleFilterLocalStorage = (table, id, value) => {
  console.log(table, id, value, "filter");
  let filtersStored = JSON.parse(localStorage.getItem("filtersStored"));
  if (filtersStored) {
    if (filtersStored[table]) {
      let letIdIndex = filtersStored[table].findIndex(
        (element) => (element.id = id)
      );

      if (letIdIndex !== -1) {
        filtersStored[table][letIdIndex].value = value;
      } else {
        filtersStored[table].push({ id: id, value: value });
      }
    } else {
      filtersStored[table] = [{ id: id, value: value }];
    }
    localStorage.setItem("filtersStored", JSON.stringify(filtersStored));
  } else {
    let filtersToStore = {};
    filtersToStore[table] = [{ id: id, value: value }];
    console.log(filtersToStore, "filter to store");

    localStorage.setItem("filtersStored", JSON.stringify(filtersToStore));
  }
};

export const resetStoredFilters = (table) => {
  let filtersStored = JSON.parse(localStorage.getItem("filtersStored"));
  filtersStored[table] = [];
  localStorage.setItem("filtersStored", JSON.stringify(filtersStored));
};
