import React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";

export default function InputField({ register, ...props }) {
  return (
    <FormControl fullWidth>
      <TextField
        {...register}
        sx={props.sx}
        color={props.color}
        type={props.type}
        required={props.required}
        disabled={props.disabled}
        multiline={props.multiline}
        minRows={props.minRows}
        maxRows={props.maxRows ? props.maxRows : 10}
        size="small"
        name={props.name}
        id="outlined-basic"
        label={props.label}
        variant={props.variant ? props.variant : "outlined"}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        InputLabelProps={{
          shrink: true,
          style: {
            color: "#000000db",
            textTransform: "capitalize",
          },
        }}
        InputProps={{
          // disableUnderline: props.disableunderline,
          inputProps: {
            step: props.step,
            maxLength: props.maxLength,
            min: props.minNumbValue,
            max: props.maxNumbValue,
          },

          startAdornment: (
            <InputAdornment position="start">
              {props.startAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{props.endAdornment}</InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
}
