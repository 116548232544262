import React, { useEffect, useState } from "react";
import DateSelect from "../../../../../components/Inputs/DateSelect";
import InputField from "../../../../../components/Inputs/InputField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function SalesDataView({ setFields, fields, register, setPreventPageChange }) {
  const [result, setResult] = useState(
    fields.project_contractsum - fields.project_cost
  );
  const [tg, setTg] = useState();
  useEffect(() => {
    setResult(fields.project_contractsum - fields.project_cost);
  }, [fields.project_contractsum, fields.project_cost]);

  useEffect(() => {
    if (result) {
      var tg = (result / Number(fields.project_contractsum)) * 100;
      setTg(tg.toFixed(2) + "%");
    } else {
      setTg(0);
    }
  }, [result, fields.project_contractsum]);

  return (
    <div style={{ flex: "0.25" }}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" style={{ fontWeight: "500" }}>
            Säljfakta
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="form-grid-auto">
          <DateSelect
            label={"Offert"}
            value={fields.project_tender_offer}
            onChange={(value) => {
              setFields({
                ...fields,
                project_tender_offer: value,
              });
              setPreventPageChange(true);
            }}
          />
          <DateSelect
            label={"Avtal"}
            value={fields.project_contract}
            onChange={(value) => {
              setFields({ ...fields, project_contract: value });
              setPreventPageChange(true);
            }}
          />
          <DateSelect
            label={"Avrop"}
            value={fields.project_avrop}
            onChange={(value) => {
              setFields({ ...fields, project_avrop: value });
              setPreventPageChange(true);
            }}
          />

          <InputField
            type="number"
            name="project_contractsum"
            label={"Avtalssumma"}
            value={fields.project_contractsum ? fields.project_contractsum : 0}
            onChange={(value) => {
              setFields({
                ...fields,
                project_contractsum: value.target.value,
              });
              setPreventPageChange(true);
            }}
            register={register("project_contractsum")}
          />
          <InputField
            type="number"
            name="project_cost"
            label={"Kostnad"}
            value={fields.project_cost ? fields.project_cost : 0}
            onChange={(value) => {
              setFields({ ...fields, project_cost: value.target.value });
              setPreventPageChange(true);
            }}
            register={register("project_cost")}
          />
          <InputField
            type="number"
            name="project_result"
            label={"Resultat"}
            value={result}
            onChange={(value) => {
              setPreventPageChange(true);
            }}
            register={register("project_result")}
          />
          <InputField
            name="project_tg"
            label={"TG"}
            value={tg}
            onChange={(value) => {
              setPreventPageChange(true);
            }}
            register={register("project_tg")}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" style={{ fontWeight: "500" }}>
            Levfakta
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="form-grid-auto">
          <DateSelect
            label={"Lev fabrik"}
            value={fields.project_factory_delivery}
            onChange={(value) => {
              setFields({
                ...fields,
                project_factory_delivery: value,
              });
              setPreventPageChange(true);
            }}
          />
          <DateSelect
            label={"Färdigställande"}
            value={fields.project_client_delivery}
            onChange={(value) => {
              setFields({ ...fields, project_client_delivery: value });
              setPreventPageChange(true);
            }}
          />
        </AccordionDetails>
        <div style={{ marginBottom: "35px" }}></div>
      </Accordion>
    </div>
  );
}

export default SalesDataView;
