import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../../components/buttons/Button";
import UpdateConfirmation from "../../../../components/modals/UpdateConfirmation";
import ProjectDataView from "./components/ProjectDataView";
import SalesDataView from "./components/SalesDataView";
import PropertyDetailsView from "./components/PropertyDetailsView";
import ClientDetailsView from "./components/ClientDetailsView";
import AddLogView from "./components/AddLogView";
import ProjectLogView from "./components/ProjectLogView";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

function AdressAndContactsForm({
  mutate,
  fields,
  setFields,
  handleChange,
  projectStatusList,
  projectTypeList,
  projectPhaseList,
  staffList,
  leadChannelList,
  municipalityList,
  displayUpdateConfirmationmodal,
  setDisplayUpdateConfirmationModal,
  newStatus,
  setNewStatus,
  setNewLog,
  steps,
  projectDeliveryTypeList,
  projectIndexTypeList,
  setPreventPageChange,
  preventPageChange,

  //
  register,
  handleSubmit,
  control,
}) {
  const [dataFromHookForm, setDataFromHookForm] = useState();
  const [currentStatus, setCurrentStatus] = useState([]);
  const [showAddLogView, setShowAddLogview] = useState(false);
  const [progressProcentage, setProgressProcentage] = useState();
  const [displayErrandLogView, setDisplayErrandLogView] = useState();

  const [stepsToShow, setStepsToShow] = useState([]);
  const [currentStep, setCurrentStep] = useState();

  const { auth } = useAuthContext();
  useEffect(() => {
    setCurrentStatus(
      projectStatusList.find((item) => item.value === fields.project_status)
    );
  }, []);

  useEffect(() => {
    let stage = 0;
    for (const key in steps) {
      if (key !== "progress_step_bar_id" && key !== "id") {
        if (steps[key]) {
          stage++;
          let statusType = projectStatusList.find(
            (item) => item.value === fields.project_status
          );

          if (statusType) {
            setProgressProcentage(stage);
          }
        }
      }
    }
  }, [fields.project_status]);

  useEffect(() => {
    if (newStatus) setShowAddLogview(true);
  }, [newStatus]);

  useEffect(() => {
    let list = [];
    let numberOfSteps = 0;
    for (const key in steps) {
      if (key !== "progress_step_bar_id" && key !== "id") {
        numberOfSteps++;
        let statusType = projectStatusList.find(
          (item) => item.value === steps[key]
        );
        if (statusType) {
          if (statusType.value === fields.project_status) {
            setCurrentStep(numberOfSteps);
          }
          list.push(statusType);
        }
        setStepsToShow(list);
      }
    }
  }, [steps, fields]);
  if (currentStatus && stepsToShow.length > 0)
    return (
      <>
        <form
          onSubmit={handleSubmit((data) => {
            data.tender_offer = fields.project_tender_offer;
            data.contract = fields.project_contract;
            data.avrop = fields.project_avrop;
            data.factory_delivery = fields.project_factory_delivery;
            data.client_delivery = fields.project_client_delivery;
            data.id = fields.id;
            data.clientId = fields.client_id;
            setDataFromHookForm(data);
            setDisplayUpdateConfirmationModal(true);
          })}
        >
          <div style={{ display: "grid", gap: "1em" }}>
            <div>
              <Stepper activeStep={currentStep} alternativeLabel>
                {stepsToShow.map((step, i) => (
                  <Step key={i}>
                    <StepLabel
                      onClick={() => setDisplayErrandLogView(step.value)}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
              <>
                <div style={{ flex: "0.75", display: "grid", gap: "1em" }}>
                  <ProjectDataView
                    control={control}
                    fields={fields}
                    register={register}
                    staffList={staffList}
                    setFields={setFields}
                    projectPhaseList={projectPhaseList}
                    projectStatusList={projectStatusList}
                    projectTypeList={projectTypeList}
                    leadChannelList={leadChannelList}
                    municipalityList={municipalityList}
                    projectIndexTypeList={projectIndexTypeList}
                    projectDeliveryTypeList={projectDeliveryTypeList}
                    handleChange={handleChange}
                    setNewStatus={setNewStatus}
                    setShowAddLogview={setShowAddLogview}
                    progressProcentage={progressProcentage}
                    setDisplayErrandLogView={setDisplayErrandLogView}
                    steps={steps}
                    setPreventPageChange={setPreventPageChange}
                  />{" "}
                  <PropertyDetailsView
                    fields={fields}
                    handleChange={handleChange}
                    register={register}
                  />
                  <ClientDetailsView
                    fields={fields}
                    handleChange={handleChange}
                    register={register}
                  />
                </div>
                <div style={{ flex: "0.25" }}>
                  <SalesDataView
                    fields={fields}
                    setFields={setFields}
                    register={register}
                    setPreventPageChange={setPreventPageChange}
                  />
                </div>
              </>
            </div>

            <div className="flex-content-divider"></div>
          </div>
          <Button disabled={!preventPageChange} type="submit" color="success">
            Spara{" "}
          </Button>
          <hr></hr>
        </form>

        <AddLogView
          userId={auth.user.id}
          fields={fields}
          setFields={setFields}
          currentStatus={currentStatus}
          setNewStatus={setNewStatus}
          newStatus={newStatus}
          setNewLog={setNewLog}
          showModal={showAddLogView}
          projectId={fields.project_id}
          confirmModal={() => mutate(dataFromHookForm)}
          hideModal={setShowAddLogview}
        />
        <ProjectLogView
          showModal={displayErrandLogView}
          confirmModal={() => console.log("first")}
          hideModal={() => setDisplayErrandLogView(false)}
        />

        <UpdateConfirmation
          showModal={displayUpdateConfirmationmodal}
          confirmModal={() => mutate(dataFromHookForm)}
          hideModal={() => setDisplayUpdateConfirmationModal(false)}
        />
      </>
    );
}

export default AdressAndContactsForm;
