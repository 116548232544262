import React, { useEffect, useState } from "react";
import { TableContainer } from "@mui/material";
import Button from "../../../components/buttons/Button";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { BsTrash } from "react-icons/bs";
import { IconButton } from "@mui/material";
import { FiEdit } from "react-icons/fi";

import InputField from "../../../components/Inputs/InputField";
function CurrentListView({
  whatListToGetFormBackEnd,
  handleDeleteField,
  handleSubmit,
  setWasEdited,
}) {
  const [editRowIndex, setEditRowIndex] = useState();
  const [nameToEdit, setNameToEdit] = useState();
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useEffect(() => {
    forceUpdate();
  }, [whatListToGetFormBackEnd]);
  const handleChange = (e, i) => {
    setNameToEdit(e.target.value);
  };
  if (typeof whatListToGetFormBackEnd === "object")
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Namn</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {whatListToGetFormBackEnd.map((row, i) => (
                <TableRow
                  key={row.label}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {editRowIndex === i ? (
                      <InputField
                        variant="standard"
                        name="name"
                        onChange={(e) => {
                          handleChange(e, i);
                          setWasEdited(true);
                        }}
                        value={nameToEdit}
                      />
                    ) : (
                      <>{row.label}</>
                    )}
                  </TableCell>
                  {editRowIndex === i ? (
                    <>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            style={{ marginLeft: "1em" }}
                            color="success"
                            onClick={(e) => {
                              handleSubmit(nameToEdit, row.value);
                              setEditRowIndex(null);
                            }}
                          >
                            Spara
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => {
                              setEditRowIndex(null);
                            }}
                          >
                            Avbryt
                          </Button>
                        </div>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <IconButton
                            onClick={(e) => {
                              setEditRowIndex(i);
                              setNameToEdit(row.label);
                            }}
                          >
                            <FiEdit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteField(row.value)}
                          >
                            <BsTrash />
                          </IconButton>
                        </div>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
}

export default CurrentListView;
