import React from "react";
import { Modal, Button } from "react-bootstrap";

const CloseFonfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
}) => {
  return (
    <Modal show={showModal} onHide={hideModal} style={{ top: "10%" }}>
      <Modal.Header closeButton>
        <Modal.Title>Stäng</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">
          Är du säker på att du vill avbryta? {message}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Avbryt
        </Button>
        <Button variant="danger" onClick={() => confirmModal(0)}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseFonfirmation;
