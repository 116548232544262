import DataTable from "../../components/dataTable/DataTable";
import { useEffect } from "react";

// import "bootstrap/dist/css/bootstrap.css";

import Content from "../../components/Content";
import { userColumns } from "../../components/dataTable/columns/userColumns";
import { Paper } from "@mui/material";
function StaffOverView() {
  useEffect(() => {
    localStorage.clear();
  });

  const tabs = [
    { label: "Användare", id: 1 },
    { label: "Användare", id: 2 },
  ];

  return (
    <>
      <Content tabs={true} tabList={tabs} title={"Projektöversikt"}>
        <div value={1}>
          <DataTable
            columns={userColumns}
            queryName={"staff"}
            searchString={process.env.REACT_APP_API_DBBASE + "staffandroles/"}
            typeOfTable="staff"
          />{" "}
        </div>
        <div value={2}>
          <Paper>
            <DataTable
              columns={userColumns}
              queryName={"overhead"}
              searchString={process.env.REACT_APP_API_DBBASE + "staffandroles/"}
              typeOfTable="project"
            />
          </Paper>
        </div>
      </Content>
    </>
  );
}

export default StaffOverView;
