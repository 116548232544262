// "id": 64252,
// "order": 1036,
// "module": 16,
// "article": 3,
// "appendix": "BATTEN",
// "length": "1155.80",
// "width": null,
// "unit": "st",
// "quantity": "1.00",
// "retrieval_cost": "149.00",
// "purchase_cost": "149.00",
// "purchase_price": "298.00",
// "isRested": 1,
// "price_unit": "m",
// "total_amount": "1.33",
// "rested_order": 1039,
// "articlename": "Gles 28x70 mm 5.4längd  fallande. 270 st/paket",
// "articlenumber": "202012"
export const orderRowColumns = [
  {
    Header: "Artikelnummer",
    accessor: "articlenumber",
    disableFilters: true,
  },
  {
    Header: "Artikel",
    accessor: "articlename",
    disableFilters: true,
  },

  {
    Header: "Kommentar",
    accessor: "appendix",
    disableFilters: true,
  },
  {
    Header: "Längd",
    accessor: "length",
    disableFilters: true,
  },
  {
    Header: "Bredd",
    accessor: "width",
    disableFilters: true,
  },
  {
    Header: "Enhet",
    accessor: "unit",
    disableFilters: true,
  },
  {
    Header: "Antal",
    accessor: "quantity",
    disableFilters: true,
  },
  {
    Header: "Inköpspris",
    accessor: "purchase_price",
    disableFilters: true,
  },
  {
    Header: "Prisenhet",
    accessor: "price_unit",
    disableFilters: true,
  },
  {
    Header: "Totalmängd ink spill",
    accessor: "total_amount",
    disableFilters: true,
  },
];
