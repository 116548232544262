export const ProjectContactPersonColumns = [
  {
    Header: "Leverantör",
    accessor: "supplier_name",
    disableFilters: true,
  },
  {
    Header: "Kontaktperson",
    accessor: "supplier_contact_person_name",
    disableFilters: true,
  },
  {
    Header: "Roll",
    accessor: "supplier_contact_person_role",
    disableFilters: true,
  },
  {
    Header: "Telefon",
    accessor: "supplier_contact_person_phone",
    disableFilters: true,
  },
  {
    Header: "Email",
    accessor: "supplier_contact_person_email",
    disableFilters: true,
  },
  {
    Header: "Kommentar",
    accessor: "project_supplier_contact_person_comment",
    disableFilters: true,
  },
];
