import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // Anything except 2XX goes to here
//     const status = error.response?.status || 500;
//     console.log(status, "ERROR api status");
//     if (status === 401) {
//       console.log("401 error från api");
//       // window.location.reload();
//       window.location =
//         window.location.protocol + "//" + window.location.host + "/login";
//     } else {
//       // window.location =
//       //   window.location.protocol + "//" + window.location.host + "/login";
//       console.log(error, "APIERROR");
//       console.log("APIERROR");

//       return Promise.reject(error); // Delegate error to calling side
//     }
//   }
// );

export const getStatusGroups = () =>
  api.get(`/statusgroup/`).then((res) => res.data);

export const getStatusGroupsForProject = (projectId) =>
  api.get(`/statusgroupsforproject/${projectId}`).then((res) => res.data);

export const getStatusGroup = (id) =>
  api.get(`/statusgroup/${id}`).then((res) => res.data);

export const updateStatusGroup = ({ id, ...updateStatusGroup }) => {
  api.put(`/statusgroup/${id}`, updateStatusGroup).then((res) => res.data);
};

export const updatePositions = (statusGroups) => {
  api.put(`/statusgrouppositions/`, statusGroups).then((res) => res.data);
};

export const newStatusGroup = (statusgroup) =>
  api.post(`/statusgroup`, statusgroup);

export const newStatusRow = (statusrow) => api.post(`/statusrow`, statusrow);

export const updateStatusRow = ({ id, ...statusRow }) => {
  api.put(`/statusrow/${id}`, statusRow).then((res) => res.data);
};

export const updateStatusGroupsForProject = (statusGroups) =>
  api.put(`/statusgroupsforproject/`, statusGroups).then((res) => res.data);

export const deleteStatusGroup = (id) => api.delete(`/statusgroup/` + id);
