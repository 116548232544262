import React, { useState, useEffect } from "react";
import ColumnFilter from "../../../components/dataTable/dataTableFilters/ColumnFilter";
import CreateUserForm from "./CreateUserForm";
import SelectColumnFilter from "../../../components/dataTable/dataTableFilters/SelectColumnFilter";
import * as staffAPI from "../../../api/staff/staffApi";
import * as userAPI from "../../../api/user/userAPI";
import { useMutation, useQueryClient, useQuery } from "react-query";
import DataTable from "../../../components/newDataTable/DataTable";
import Button from "../../../components/buttons/Button";
import { useForm } from "react-hook-form";
import EditUserView from "./EditUserView";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function UserView() {
  const [staffRoles, setStaffRoles] = useState();
  const [userExists, setUserExists] = useState(false);
  const [showEditUserView, setShowEditUserView] = useState(false);

  useEffect(() => {
    staffAPI.getRoles().then((roles) => setStaffRoles(roles));
    localStorage.clear();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({});

  const { data: userData } = useQuery(["user", showEditUserView], () =>
    userAPI.getAllUsers()
  );

  const createStaff = useMutation((staff) => userAPI.newUser(staff));

  let queryclient = useQueryClient();
  const createNewStaff = (data) => {
    try {
      createStaff.mutate(data, {
        onSuccess: (e) => {
          queryclient.invalidateQueries();
          reset();
          toastSuccess("Användare skapades");
        },
        onError: (e) => {
          if (e.response.status === 409) {
            setUserExists(true);
            toastError("Finns redan användare med den emailen!");
          }
          if (e.response.status === 500) {
            toastError("Gick ej att skapa anvädare!");
          }
        },
      });
    } catch {
      console.log("error från click on send");
    }
  };

  const userColumns = [
    {
      Header: "Förnamn",
      accessor: "user_first_name",
      Filter: ColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Efternamn ",
      accessor: "user_last_name",
      Filter: ColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Roll",
      accessor: "staff_role_name",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Behörighet",
      accessor: "user_role_role",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Email",
      accessor: "user_email",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Telefon",
      accessor: "user_phonenumber",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Registrades ",
      accessor: "user_register_date",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "",
      accessor: "user_register_date",
      id: "id",
      Cell: (props) => {
        return props.value === "Importerad" ? (
          <Button
            color="error"
            onClick={(e) => {
              console.log(props.row.original.id);
            }}
          >
            Begär nytt lösenord
          </Button>
        ) : (
          <></>
        );
      },
      disableFilters: true,
    },
  ];

  if (typeof staffRoles === "object" && typeof userData === "object") {
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: "2em" }}>
        <div style={{ flex: "1", minWidth: "300px" }}>
          {userExists ? (
            <p style={{ color: "#FF0000" }}>
              Email existerar redan i databasen
            </p>
          ) : (
            <></>
          )}
          <CreateUserForm
            staffRoles={staffRoles}
            createNewStaff={createNewStaff}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
          />
        </div>
        <div style={{ flex: "3" }}>
          <DataTable
            columns={userColumns}
            data={userData}
            typeOfTable="user"
            handleShowObject={setShowEditUserView}
          />
        </div>

        <EditUserView
          userAPI={userAPI}
          showModal={showEditUserView}
          hideModal={() => setShowEditUserView(false)}
          staffRoles={staffRoles}
        />
      </div>
    );
  }
}

export default UserView;
