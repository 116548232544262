import React, { useState } from "react";
import { Controller } from "react-hook-form";
import InputField from "../../../../components/Inputs/InputField";
import Select from "../../../../components/Inputs/Select";
import Typography from "@mui/material/Typography";

function ProjectDetailsForm({
  register,
  control,
  projectTypeList,
  projectPhaseList,
  leadChannelList,
  projectStatusList,
  municipalityList,
  projectIndexTypeList,
  projectDeliveryTypeList,
}) {
  const [selects, setSelects] = useState({
    municipality: undefined,
    indexType: undefined,
    deliveryType: undefined,
    projectType: undefined,
    leadChannel: undefined,
    projectStatus: undefined,
    phase: undefined,
  });
  return (
    <div>
      <div style={{ marginBottom: "1em" }}>
        <Typography variant="h6">Projekt</Typography>
      </div>

      <div className="form-grid-auto wrapper">
        <InputField
          required
          name="name"
          label="Projekt namn"
          register={register("name")}
          maxLength={255}
        />
        <Controller
          control={control}
          name={"project_municipality"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("project_municipality")}
              options={municipalityList}
              value={municipalityList.find(
                (item) => item.value === selects.municipality
              )}
              label={"Kommun"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  municipality: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"index_type"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("index_type")}
              options={projectIndexTypeList}
              value={projectIndexTypeList.find(
                (item) => item.value === selects.indexType
              )}
              label={"Indextyp"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  indexType: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"delivery_type"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("delivery_type")}
              options={projectDeliveryTypeList}
              value={projectDeliveryTypeList.find(
                (item) => item.value === selects.deliveryType
              )}
              label={"Leveranstyp"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  deliveryType: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"type"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("type")}
              options={projectTypeList}
              value={projectTypeList.find(
                (item) => item.value === selects.projectType
              )}
              label={"Projekttyp"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  projectType: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"lead_channel"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("lead_channel")}
              options={leadChannelList}
              value={leadChannelList.find(
                (item) => item.value === selects.leadChannel
              )}
              label={"Kanal in"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  leadChannel: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"status"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("lead_channel")}
              options={projectStatusList}
              value={projectStatusList.find(
                (item) => item.value === selects.projectStatus
              )}
              label={"Projektstatus"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  projectStatus: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"phase"}
          render={({ field: { onChange, value } }) => (
            <Select
              required
              control={control}
              register={register("phase")}
              options={projectPhaseList}
              value={projectPhaseList.find(
                (item) => item.value === selects.phase
              )}
              label={"FAS"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  phase: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <InputField
          name="property_designation"
          label="Fastighetsbeteckning"
          register={register("property_designation")}
          maxLength={255}
        />
        <InputField
          name="property_adress"
          label="Fastighetsadress"
          register={register("property_adress")}
          maxLength={255}
        />
        <InputField
          name="property_post_adress"
          label="Postadress"
          register={register("property_post_adress")}
          maxLength={255}
        />{" "}
        <InputField
          required
          name="description"
          label="ProjektBeskrivning"
          register={register("description")}
          maxLength={1000}
          multiline
          minRows={5}
        />
      </div>
    </div>
  );
}

export default ProjectDetailsForm;
