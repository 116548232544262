import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

// export const errandColumns = [
//   {
//     Header: "Skapades",
//     accessor: "errand_date",
//     disableFilters: true,
//   },
//   {
//     Header: "Uppdaterad senast",
//     accessor: "errand_last_updated",
//     disableFilters: true,
//   },
//   {
//     Header: "Kommentar",
//     accessor: "errand_comment",
//     disableFilters: true,
//   },
//   {
//     Header: "Ärende",
//     accessor: "errand_type_name",
//     Filter: SelectColumnFilter,
//     filter: "includesString",
//   },
//   {
//     Header: "Ansvarig",
//     accessor: "responsible_person",
//     Filter: SelectColumnFilter,
//     filter: "includesString",
//   },
//   {
//     Header: "Skapad av ",
//     accessor: "registered_by",
//     Filter: SelectColumnFilter,
//     filter: "includesString",
//   },
//   {
//     Header: "Status",
//     accessor: "errand_status_name",
//     Filter: SelectColumnFilter,
//     filter: "includesString",
//   },
// ];

export const allErrandColumns = [
  {
    Header: "Projekt",
    table: "errand",
    accessor: "project_project_number",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Skapades",
    accessor: "errand_date",
    disableFilters: true,
  },
  {
    Header: "Uppdaterad senast",
    accessor: "errand_last_updated",
    disableFilters: true,
  },
  {
    Header: "Kommentar",
    accessor: "errand_comment",
    disableFilters: true,
  },
  {
    Header: "Ärende",
    table: "errand",
    accessor: "errand_type_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Ansvarig",
    table: "errand",
    accessor: "responsible_person",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Skapad av ",
    table: "errand",
    accessor: "registered_by",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Status",
    accessor: "errand_status_name",
    table: "errand",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Egenkontroll till kund",
    table: "errand",
    accessor: "control_to_client",
    id: "id",
    Cell: (props) => {
      return props.row.original.errand_control_to_client === 1 ? (
        <>Ja</>
      ) : (
        <>Nej</>
      );
    },
    disableFilters: true,
  },
];

export const errandColumns = [
  {
    Header: "Ärende",
    accessor: "errand_id",
    disableFilters: true,
  },
  {
    Header: "Skapades",
    accessor: "errand_date",
    disableFilters: true,
  },
  {
    Header: "Uppdaterad senast",
    accessor: "errand_last_updated",
    disableFilters: true,
  },
  {
    Header: "Kommentar",
    accessor: "errand_comment",
    disableFilters: true,
  },
  {
    Header: "Ärende",
    accessor: "errand_type_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Skapades av",
    accessor: "registered_by",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Ansvarig",
    accessor: "responsible_person",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Status",
    accessor: "errand_status_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Egenkontroll",
    table: "errand",
    accessor: "control_to_client",
    id: "id",
    Cell: (props) => {
      return props.row.original.errand_control_to_client === 1 ? (
        <>Ja</>
      ) : (
        <>Nej</>
      );
    },
    disableFilters: true,
  },
  {
    Header: "Skickat",
    table: "errand",
    accessor: "sent_to_client",
    id: "sent_to_client",
    Cell: (props) => {
      return props.row.original.errand_sent_to_client === 1 ? (
        <>Ja</>
      ) : (
        <>Nej</>
      );
    },
    disableFilters: true,
  },
];
