import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as api from "../../../api/register/articleApi";
import ArticleForm from "./ArticleForm";
import ArticleHeader from "./ArticleHeader";
import { useQueryClient, useMutation } from "react-query";

import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function ArticleView() {
  let navigate = useNavigate();
  let artId = useParams();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayUpdateConfirmationModal, setDisplayUpdateConfirmationModal] =
    useState(false);

  useEffect(() => {
    if (artId.id)
      api.getArticle(artId.id).then((article) => setFields(article));
  }, [artId.id]);

  const [fields, setFields] = useState();

  const queryClient = useQueryClient();

  const { mutate } = useMutation(api.updateArticle, {
    onSuccess: () => {
      toastSuccess("Artikel uppdaterades");
      queryClient.invalidateQueries();
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
      console.log("error fron mutate");
    },
  });
  const handleChange = (event) => {
    setHasInputChanged(true);

    let { name, value } = event.target;

    setFields({ ...fields, [name]: value });
  };

  const handleSelect = (e, key) => {
    setFields({ ...fields, [key]: e.value });
    setHasInputChanged(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      setDisplayUpdateConfirmationModal(true);
    }
  };

  const confirmChange = () => {
    if (hasInputChanged) {
      mutate(fields);
      setDisplayUpdateConfirmationModal(false);
      setHasInputChanged(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };
  const confirmClose = () => {
    navigate(`/register/`);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const hideUpdateConfirmationModal = () => {
    setDisplayUpdateConfirmationModal(false);
  };

  if (fields) {
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <div style={{ marginBottom: "1.5em" }}>
          <ArticleHeader article={fields} />
        </div>
        <ArticleForm
          fields={fields}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleClose={handleClose}
          handleSelect={handleSelect}
          confirmClose={confirmClose}
          hideConfirmationModal={hideConfirmationModal}
          hideUpdateConfirmationModal={hideUpdateConfirmationModal}
          displayConfirmationModal={displayConfirmationModal}
          displayUpdateConfirmationModal={displayUpdateConfirmationModal}
          confirmChange={confirmChange}
        />
      </div>
    );
  }
  return <div>Loading</div>;
}

export default ArticleView;
