export const ReqOptions = [
  {
    value: 1,
    label: "Ja",
  },
  {
    value: 0,
    label: "Nej",
  },
];

export const UnitOptions = [
  {
    value: "st",
    label: "st",
  },
  {
    value: "m",
    label: "m",
  },
  {
    value: "mm",
    label: "mm",
  },
  {
    value: "m2",
    label: "m2",
  },
  {
    value: "rulle",
    label: "rulle",
  },
  {
    value: "buntar",
    label: "buntar",
  },
  {
    value: "m3",
    label: "m3",
  },
  {
    value: "RLE",
    label: "RLE",
  },
  {
    value: "skivor",
    label: "Skivor",
  },
  {
    value: "tim",
    label: "tim",
  },
  {
    value: "kr",
    label: "kr",
  },
];

export const OrderStatusOptions = [
  {
    value: "Mängdat",
    label: "Mängdat",
  },
  {
    value: "Mottagningskontrollerat",
    label: "Mottagningskontrollerat",
  },
  {
    value: "Ska mängdas",
    label: "Ska mängdas",
  },
  {
    value: "Orderstatus",
    label: "Orderstatus",
  },
  {
    value: "Internfakturerad",
    label: "Internfakturerad",
  },
  {
    value: "Fakturerat",
    label: "Fakturerat",
  },
  {
    value: "Beställning",
    label: "Beställning",
  },
  {
    value: "Restnoterad",
    label: "Restnoterad",
  },
  {
    value: "Utgår",
    label: "Utgår",
  },
  {
    value: "Bekräftat",
    label: "Bekräftat",
  },
  {
    value: "Levererad",
    label: "Levererad",
  },
  {
    value: "Importerad",
    label: "Importerad",
  },
  {
    value: "Reklamation",
    label: "Reklamation",
  },
  {
    value: "Monterat",
    label: "Monterat",
  },
  {
    value: "Plockad",
    label: "Plockad",
  },
];
