import React, { useState, useEffect } from "react";
import Content from "../../components/Content";
import DataTable from "../../components/newDataTable/DataTable";
import { Paper } from "@mui/material";
import { AllModuleColumns } from "../../components/dataTable/columns/ModuleColumns";
import * as purchaseAPI from "../../api/project/newPurchaseApi";
import { useQuery } from "react-query";
import { formatNumberWithSpaces } from "../../helpers/generalFunctions";
import ColumnFilter from "../../components/dataTable/dataTableFilters/ColumnFilter";
import SelectColumnFilter from "../../components/dataTable/dataTableFilters/SelectColumnFilter";
function PurchaseOrderOverview() {
  const [lastTab, setLastTab] = useState(1);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    localStorage.clear();
  });

  const tabs = [{ label: "Ordrar", id: 1 }];

  const { data: orderData } = useQuery(["orders"], () =>
    purchaseAPI.getAllOrders()
  );

  useEffect(() => {
    if (typeof orderData === "object") {
      setOrders(orderData);
    }
  }, [orderData]);

  const PurchaseOrderColumns = [
    {
      Header: "Order",
      table: "purchase",
      accessor: "order_number",
      Filter: ColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Projekt",
      table: "purchase",
      accessor: "project_project_number",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Lev",
      table: "purchase",
      accessor: "supplier_name",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    // {
    //   Header: "Kontrakt",
    //   table: "purchase",
    //   accessor: "suppliercontract_contract_name",
    //   Filter: SelectColumnFilter,
    //   filter: "includesString",
    // },
    {
      Header: "Konto",
      table: "purchase",
      accessor: "account_description",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    // {
    //   Header: "Skapad",
    //   accessor: "created_date",
    //   disableFilters: true,
    // },
    {
      Header: "Orderdatum",
      accessor: "order_date",
      disableFilters: true,
    },
    {
      Header: "Status",
      table: "purchase",
      accessor: "status",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Önskad leverans",
      accessor: "requested_delivery_date",
      disableFilters: true,
    },

    {
      Header: "Bekräftad leverans",
      accessor: "confirmed_delivery_date",
      disableFilters: true,
    },
    {
      Header: "Verklig leverans",
      accessor: "real_delivery",
      disableFilters: true,
    },
    {
      Header: "Monterad",
      accessor: "montage_date",
      disableFilters: true,
    },
    {
      // Header: "Kalkyl",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kalkyl
        </div>
      ),
      accessor: "calculation",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.calculation ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.calculation)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      disableFilters: true,
    },

    {
      // Header: "Kost",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kost
        </div>
      ),
      accessor: "total_cost",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.total_cost ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.total_cost)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      disableFilters: true,
    },
    {
      // Header: "Fakturerat",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Fakturerat
        </div>
      ),
      accessor: "invoiced_amount",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.invoiced_amount ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.invoiced_amount)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      disableFilters: true,
    },
    {
      // Header: "Prognos",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Prognos
        </div>
      ),
      id: "prognos",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.invoiced_amount ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.invoiced_amount)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}>
                {props.row.original.total_cost > 0
                  ? formatNumberWithSpaces(props.row.original.total_cost)
                  : formatNumberWithSpaces(props.row.original.calculation)}
              </div>
            )}
          </>
        );
      },
      disableFilters: true,
    },
  ];

  console.log(orders);
  return (
    <>
      <Content tabs={true} tabList={tabs} lastTab={lastTab}>
        <div value={1}>
          <DataTable
            columns={PurchaseOrderColumns}
            data={orders}
            typeOfTable="purchaseoverview"
          />
        </div>
        <div value={2}>
          <Paper></Paper>
        </div>
      </Content>
    </>
  );
}

export default PurchaseOrderOverview;
