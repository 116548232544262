import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import InputField from "../../../../../components/Inputs/InputField";
import * as timeRegAPI from "../../../../../api/project/timeRegApi";
import { useMutation } from "react-query";
import {
  toastError,
  toastSuccess,
} from "../../../../../components/toast/toastFunctions";
function EditTimeRegView({
  showModal,
  hideModal,
  timeRegistredUnderProject,
  setWasMutaded,
}) {
  const [times, setTimes] = useState(timeRegistredUnderProject);
  const handleChange = (e) => {
    let list = [...times];

    e.preventDefault();
    const { name, value } = e.target;
    list[name].time_reg_time = value;
    setTimes(list);
  };

  const { mutate } = useMutation(timeRegAPI.updateTimeRegs, {
    onSettled: (e) => {
      toastSuccess("Uppdatering lyckades");
      setWasMutaded(true);
      setTimeout(function () {
        hideModal();
      }, 500);
    },
    onError: () => {
      toastError("Uppdatering misslyckades");
      console.log("error fron mutate");
    },
  });

  const handleSave = () => {
    mutate(times);
  };
  if (times)
    return (
      <Modal show={showModal} onHide={hideModal} centered>
        <Modal.Header closeButton> Ändra beräknad tid</Modal.Header>

        <Modal.Body>
          {timeRegistredUnderProject.length > 0 && (
            <div className="form-grid-auto">
              {times.map((item, i) => (
                <InputField
                  key={i}
                  onChange={handleChange}
                  multiline
                  name={i}
                  label={item.time_reg_type_name}
                  value={item.time_reg_time}
                />
              ))}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleSave}>Spara</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default EditTimeRegView;
