import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500
    console.log(status, 'ERROR api status')
    if (status === 401) {
      console.log('401 error från api')
      // window.location.reload();
      window.location =
        window.location.protocol + '//' + window.location.host + '/login'
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, 'APIERROR')
      console.log('APIERROR')

      return Promise.reject(error) // Delegate error to calling side
    }
  },
)

export const getStaff = (id) => api.get(`/staff/${id}`).then((res) => res.data)

export const getAllStaff = () => api.get('/staff/').then((res) => res.data)

export const updateStaff = ({ id, ...updatedStaff }) => {
  return api.put(`/staff/${id}`, updatedStaff)
}

export const getStaffList = () => api.get(`/stafflist/`).then((res) => res.data)

export const getAritList = () =>
  api.get(`/stafflistarit/`).then((res) => res.data)

export const getKritList = () =>
  api.get(`/stafflistkrit/`).then((res) => res.data)

export const getProjectLeaderList = () =>
  api.get(`/stafflistprojectleader/`).then((res) => res.data)

export const getRoles = () => api.get('/staffroles/').then((res) => res.data)

export const deleteStaff = (id) => api.delete(`/staff/` + id)
