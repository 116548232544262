import React from "react";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import { Modal } from "react-bootstrap";
function SupplierCategoryForm({
  handleClose,
  handleChange,
  handleSubmit,
  fields,
  showModal,
  hideModal,
}) {
  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton>Leverantörskategori:</Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "grid",
              gap: "1em",
              marginBottom: "1em",
            }}
          >
            <InputField
              required
              label="Kategori"
              name="category_name"
              value={fields.category_name}
              onChange={handleChange}
            />
            <InputField
              label="    Prioritet"
              name="priority"
              value={fields.priority}
              onChange={handleChange}
            />
            <InputField
              label="  Mål med kategorin"
              name="category_goal"
              value={fields.category_goal}
              onChange={handleChange}
            />
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
            <Button type="submit" color="success">
              Spara
            </Button>
            <Button type="button" onClick={() => handleClose(0)}>
              Stäng
            </Button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default SupplierCategoryForm;
