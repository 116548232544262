import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const SendConfirmation = ({
  orderid,
  showModal,
  hideModal,
  confirmModal,
  sendingEmail,
  id,
  type,
  message,
}) => {
  const [additionalPdfs, setAdditionalPdfs] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const newFiles = additionalPdfs
      ? [...additionalPdfs, ...e.target.files]
      : [...e.target.files];
    setAdditionalPdfs(newFiles);

    const fileNamesArray = [...fileNames];
    for (let i = 0; i < e.target.files.length; i++) {
      fileNamesArray.push(e.target.files[i].name);
    }
    setFileNames(fileNamesArray);
  };

  const clearFiles = () => {
    setAdditionalPdfs(null);
    setFileNames([]);
    // Set the value of the input element to an empty string
    fileInputRef.current.value = "";
  };

  return (
    <Modal show={showModal} onHide={hideModal} style={{ top: "10%" }}>
      <Modal.Header closeButton>
        <Modal.Title>
          {sendingEmail ? "Skickar.." : "Slutför beställningen."}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {sendingEmail ? (
          <LinearProgress sx={{ height: "10px" }} />
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Button variant="contained" component="label">
              Ladda upp fil
              <input
                type="file"
                id="additionalPdfs"
                name="additionalPdfs[]"
                multiple
                accept=".pdf"
                onChange={handleFileChange}
                ref={fileInputRef} // Add a ref to the input element
                hidden
              />
            </Button>
            {fileNames.length > 0 ? (
              <div>
                <h6>Bifogade filer</h6>
                <List>
                  {fileNames.map((name, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Avbryt
        </Button>
        <Button variant="default" onClick={clearFiles}>
          Rensa filer
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            confirmModal(orderid, additionalPdfs);
          }}
        >
          Skicka
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendConfirmation;
