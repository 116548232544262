import Button from "../../../../../components/buttons/Button";
import React, { useState } from "react";
import InputField from "../../../../../components/Inputs/InputField";
import Select from "../../../../../components/Inputs/Select";
import { useForm, Controller } from "react-hook-form";
import {
  toastError,
  toastSuccess,
} from "../../../../../components/toast/toastFunctions";
function TimeRegView({
  timeRegTypes,
  timeRegistredUnderProject,
  createTimeReg,
  projectId,
  setWasMutaded,
  setShowEditTimeRegView,
  objectWithTimeProperties,
}) {
  const { register, handleSubmit, setValue, control, reset } = useForm({});
  const [selects, setSelects] = useState({ timeRegType: undefined });
  if (timeRegTypes && timeRegistredUnderProject && objectWithTimeProperties)
    return (
      <form
        onSubmit={handleSubmit((data) => {
          data.project = projectId;
          reset({ time_reg_type: "" });
          try {
            createTimeReg.mutate(data, {
              onSettled: (e) => {
                setValue("time_reg_type", "");
                setWasMutaded(true);
                let timer = setInterval(() => {
                  clearInterval(timer);
                }, 3500);
              },
              onSuccess: () => {
                toastSuccess("Sparades");
              },
              onError: () => {
                toastError("Error");
                console.log("error fron mutate");
              },
            });
          } catch {
            console.log("error från click on send");
          }
        })}
      >
        {timeRegistredUnderProject.length > 0 && (
          <div className="form-grid-auto">
            {timeRegistredUnderProject.map((item, i) => (
              <InputField
                key={i}
                multiline
                name="time_reg_time"
                label={item.time_reg_type_name}
                value={item.time_reg_time}
              />
            ))}
          </div>
        )}

        {/* Om det inte finns reggad tid på någon modul så ska denna knappen synas
        och tillåta ändring av "prognosstid" */}

        {objectWithTimeProperties.allowEditOfTime === true &&
        timeRegistredUnderProject.length > 0 ? (
          <div style={{ marginTop: "1.5em" }}>
            <Button onClick={() => setShowEditTimeRegView(true)}>
              ändra tid{" "}
            </Button>
          </div>
        ) : (
          <></>
        )}

        <hr></hr>
        <div className="form-grid-auto">
          <Controller
            control={control}
            name={"time_reg_type"}
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                control={control}
                register={register("time_reg_type")}
                options={timeRegTypes.filter(
                  (option) =>
                    -1 ===
                    timeRegistredUnderProject.findIndex(
                      (element) => element.time_reg_type_name === option.label
                    )
                )}
                value={timeRegTypes.find(
                  (item) => item.value === selects.timeRegType
                )}
                label={"Tidstyp"}
                onChange={(event, item) => {
                  setSelects({
                    ...selects,
                    timeRegType: item.value,
                  });
                  onChange(item.value);
                }}
              />
            )}
          />

          <InputField
            required
            name="time"
            label="Tid"
            register={register("time")}
            type="number"
            step={"0.01"}
          />
        </div>
        <div style={{ marginTop: "1.5em" }}>
          <Button type="submit">Lägg till tid</Button>
        </div>
      </form>
    );
}

export default TimeRegView;
