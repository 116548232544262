import * as React from "react";
import DraggableListItem from "./DraggableListItem";
import Divider from "@mui/material/Divider";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const DraggableList = React.memo(({ items, onDragEnd, onClick }) => {
  if (items)
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <>
                  {" "}
                  <DraggableListItem
                    item={item}
                    index={index}
                    key={item.id}
                    onClick={onClick}
                  />{" "}
                  <Divider />
                </>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
});

export default DraggableList;
