import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useQuery } from "react-query";
import ColumnFilter from "../../../components/dataTable/dataTableFilters/ColumnFilter";
import SelectColumnFilter from "../../../components/dataTable/dataTableFilters/SelectColumnFilter";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import * as XLSX from "xlsx";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as purchaseAPI from "../../../api/project/newPurchaseApi";
import { orderRowsMapping } from "../ProjectDetailTabViews/Purchases/utils/ImportRows";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { handleOnExport } from "../utils/ExportFunctions";
import ImportOrderRowsView from "../ProjectDetailTabViews/Purchases/components/ImportOrderRowsView";
import DeleteConfirmation from "../../../components/modals/DeleteConfirmation";
import { formatNumberWithSpaces } from "../../../helpers/generalFunctions";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function ProjectPurchaseOverview({ projectId }) {
  const [isImported, setIsImported] = useState();
  const [importOrderRowHeads, setImportOrderRowHeads] = useState();
  const [dataToMapToOrderRows, setDataToMapToOrderRows] = useState();
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);
  const [orderToDeleteId, setOrderToDeleteId] = useState();
  const [displayImportOrderRowsModal, setDisplayImportOrderRowsModal] =
    useState(false);

  const [statusImportedRows, setStatusImportedRows] = useState({
    success: 0,
    error: 0,
  });

  const [failedRowsToImport, setFailedRowsToImport] = useState();
  const queryClient = useQueryClient();
  const { auth } = useAuthContext();
  let navigate = useNavigate();

  const { data: purchaseData } = useQuery(["purchase", projectId], () =>
    purchaseAPI.getAll(projectId)
  );

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [displayImportOrderRowsModal, queryClient]);

  const hiddenFileInput = React.useRef(null);
  const handleClickOnUpload = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = async (event) => {
    console.log("wasTriggered", "fileUploaded");

    setIsImported(false);
    const fileUploaded = event.target.files[0];
    const data = await fileUploaded.arrayBuffer();
    const workbook = XLSX.read(data);

    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(workSheet, {
      header: 1,
      defval: "",
    });

    setImportOrderRowHeads(jsonData[0]);
    setDataToMapToOrderRows(jsonData);
    console.log(fileUploaded, "fileUploaded");
    if (fileUploaded) {
      setDisplayImportOrderRowsModal(true);
    }
  };
  const ImportPurchases = async (rowHeadMaps, setShowLoader) => {
    let rows = await orderRowsMapping(
      dataToMapToOrderRows,
      rowHeadMaps,
      undefined,
      true,
      projectId,
      auth.user.id
    );
    if (rows) {
      queryClient.invalidateQueries();
      setShowLoader(false);
      setIsImported(true);
      setStatusImportedRows({
        success: rows.successFullRowsImported,
        error: rows.failedRows.length + rows.successFullRowsImported,
      });

      setFailedRowsToImport(rows.failedRows);
    }
  };
  const PurchaseOrderColumns = [
    {
      Header: "Order",
      table: "purchase",
      accessor: "order_number",
      Filter: ColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Lev",
      table: "purchase",
      accessor: "supplier_name",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    // {
    //   Header: "Kontrakt",
    //   table: "purchase",
    //   accessor: "suppliercontract_contract_name",
    //   Filter: SelectColumnFilter,
    //   filter: "includesString",
    // },
    {
      Header: "Konto",
      table: "purchase",
      accessor: "account_description",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    // {
    //   Header: "Skapad",
    //   accessor: "created_date",
    //   disableFilters: true,
    // },
    {
      Header: "Orderdatum",
      accessor: "order_date",
      disableFilters: true,
    },
    {
      Header: "Status",
      table: "purchase",
      accessor: "status",
      Filter: SelectColumnFilter,
      filter: "includesString",
    },
    {
      Header: "Önskad leverans",
      accessor: "requested_delivery_date",
      disableFilters: true,
    },

    {
      Header: "Bekräftad leverans",
      accessor: "confirmed_delivery_date",
      disableFilters: true,
    },
    {
      Header: "Verklig leverans",
      accessor: "real_delivery",
      disableFilters: true,
    },
    {
      Header: "Monterad",
      accessor: "montage_date",
      disableFilters: true,
    },
    {
      // Header: "Kalkyl",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kalkyl
        </div>
      ),
      accessor: "calculation",

      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.calculation ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.calculation)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => Number(row.original.calculation) + sum,
              0
            ),
          [info.rows]
        );

        return (
          <div style={{ textAlign: "right", marginRight: "16px" }}>
            {formatNumberWithSpaces(total)}{" "}
          </div>
        );
      },
      disableFilters: true,
    },

    {
      // Header: "Kost",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Kost
        </div>
      ),
      accessor: "total_cost",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.total_cost ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.total_cost)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => Number(row.original.total_cost) + sum,
              0
            ),
          [info.rows]
        );

        return (
          <div style={{ textAlign: "right", marginRight: "16px" }}>
            {formatNumberWithSpaces(total)}{" "}
          </div>
        );
      },
      disableFilters: true,
    },
    {
      // Header: "Fakturerat",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Fakturerat
        </div>
      ),
      accessor: "invoiced_amount",

      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.invoiced_amount ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.invoiced_amount)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}></div>
            )}
          </>
        );
      },
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) => Number(row.original.invoiced_amount) + sum,
              0
            ),
          [info.rows]
        );

        return (
          <div style={{ textAlign: "right", marginRight: "16px" }}>
            {formatNumberWithSpaces(total)}{" "}
          </div>
        );
      },
      disableFilters: true,
    },
    {
      // Header: "Prognos",
      Header: () => (
        <div
          style={{
            textAlign: "right",
          }}
        >
          Prognos
        </div>
      ),
      id: "prognos",
      Cell: (props) => {
        return (
          <>
            {props?.row?.original?.invoiced_amount ? (
              <div style={{ textAlign: "right" }}>
                {formatNumberWithSpaces(props?.row?.original?.invoiced_amount)}
              </div>
            ) : (
              <div style={{ textAlign: "right" }}>
                {props.row.original.total_cost > 0
                  ? formatNumberWithSpaces(props.row.original.total_cost)
                  : formatNumberWithSpaces(props.row.original.calculation)}
              </div>
            )}
          </>
        );
      },
      Footer: (info) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(
          () =>
            info.rows.reduce(
              (sum, row) =>
                Number(
                  row.original.invoiced_amount
                    ? row.original.invoiced_amount
                    : row.original.total_cost > 0
                    ? row.original.total_cost
                    : row.original.calculation
                ) + sum,
              0
            ),
          [info.rows]
        );

        return (
          <div style={{ textAlign: "right", marginRight: "16px" }}>
            {formatNumberWithSpaces(total)}{" "}
          </div>
        );
      },
      disableFilters: true,
    },
    {
      Header: "",
      accessor: "status",
      id: "id",
      Cell: (props) => {
        return props.value === "Importerad" ? (
          <Button
            color="error"
            onClick={(e) => {
              setDisplayDeleteConfirmation(true);
              setOrderToDeleteId(props.row.original.id);
            }}
          >
            <DeleteForeverIcon />
          </Button>
        ) : (
          <></>
        );
      },
      disableFilters: true,
    },
  ];

  const deleteImportedOrder = useMutation(purchaseAPI.deleteImportedOrder, {
    onSettled: () => {
      queryClient.invalidateQueries();

      setOrderToDeleteId(null);
      toastSuccess("Order borttagen");
      queryClient.invalidateQueries();
      setDisplayDeleteConfirmation(false);
    },
    onError: () => {
      toastError("Gick inte att ta bort order");
    },
  });
  const confirmDeleteOfImportedOrder = () => {
    if (orderToDeleteId) deleteImportedOrder.mutate(orderToDeleteId);
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<UploadFileIcon />}
        onClick={handleClickOnUpload}
      >
        Importera inköp
      </Button>{" "}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => navigate(`/projektoversikt/skapa/inkop/${projectId}`)}
      >
        Lägg till nytt inköp
      </Button>{" "}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <DataTable
        purchaseTable={true}
        columns={PurchaseOrderColumns}
        data={purchaseData?.length > 0 ? purchaseData : []}
        typeOfTable="purchase"
      />
      <ImportOrderRowsView
        showModal={displayImportOrderRowsModal}
        confirmModal={ImportPurchases}
        hideModal={() => setDisplayImportOrderRowsModal(false)}
        orderHeads={importOrderRowHeads}
        statusImportedRows={statusImportedRows}
        isImported={isImported}
        failedRowsToImport={failedRowsToImport}
        setDisplayImportOrderRowsModal={setDisplayImportOrderRowsModal}
        handleOnExport={handleOnExport}
      />
      <DeleteConfirmation
        showModal={displayDeleteConfirmation}
        confirmModal={confirmDeleteOfImportedOrder}
        hideModal={() => setDisplayDeleteConfirmation(false)}
        centered={"centered"}
      />
    </>
  );
}

export default ProjectPurchaseOverview;
