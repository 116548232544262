import React from "react";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import Button from "../../../../../components/buttons/Button";
function ContactReferenceView({
  setFields,
  fields,
  handleChange,
  handleSelectContactPerson,
  supplierToDisplay,
  contactPersons,
  blockPurchaseEdit,
  users,
  setHasInputChanged,
  userAPI,
  setDisplayAddSupplierContactPersonView,
}) {
  const handleChangeOurReference = async (e, value) => {
    await userAPI.getUserInfo(value.value).then((user) => {
      setFields({
        ...fields,
        our_reference: user.name,
        our_reference_mail: user.user_email,
        our_reference_phone: user.user_phonenumber,
      });
    });
    setHasInputChanged(true);
  };

  if (contactPersons && users)
    return (
      <div
        className="wrapper"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1em",
          margin: "1.5em 0",
        }}
      >
        <div style={{ flex: "1" }}>
          <Select
            label={"Välj kontaktperson för denna order"}
            onChange={handleSelectContactPerson}
            options={contactPersons}
            value={
              fields?.contactperson
                ? contactPersons.find(
                    (item) => item.id === fields.contactperson
                  )
                : ""
            }
            disabled={blockPurchaseEdit}
          />

          {supplierToDisplay ? (
            <div
              style={{
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.596)",
                margin: "1em 0",
                padding: "8.5px 14px",
                border: "1px solid rgba(0, 0, 0, 0.175)",
                borderRadius: "4px",
              }}
            >
              <p> Leverantör : {supplierToDisplay.name} </p>
              <p> Ordermail : {supplierToDisplay.order_mail}</p>
              <p> Telefon : {supplierToDisplay.phonenumber}</p>
              <p>Avtal : {supplierToDisplay.suppliercontract_contract_name}</p>
              <p></p>
              <Button
                sx={{ marginTop: "15px" }}
                onClick={() => setDisplayAddSupplierContactPersonView(true)}
              >
                Lägg till kontaktperson
              </Button>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div style={{ display: "grid", gap: "5px", flex: "1" }}>
          <Select
            label={"Vår referens"}
            onChange={handleChangeOurReference}
            options={users}
            value={
              fields?.our_reference
                ? users.find((item) => item.label === fields.our_reference)
                : ""
            }
            disabled={blockPurchaseEdit}
          />

          <InputField
            label={"Mail"}
            name={"our_reference_mail"}
            value={fields.our_reference_mail}
            onChange={handleChange}
            disabled={blockPurchaseEdit}
          />
          <InputField
            label={"Nummer"}
            name={"our_reference_phone"}
            value={fields.our_reference_phone}
            onChange={handleChange}
            disabled={blockPurchaseEdit}
          />
          <InputField
            name="purchase_adress"
            label={"Leveransadress"}
            value={fields.purchase_adress}
            onChange={handleChange}
            disabled={blockPurchaseEdit}
          />
        </div>
      </div>
    );
}

export default ContactReferenceView;
