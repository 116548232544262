import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const AccountColumns = [
  {
    Header: "Konto",
    table: "account",
    accessor: "account",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Beskrivning",
    table: "account",
    accessor: "Description",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Ledtid",
    table: "account",
    accessor: "lead_time",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];
