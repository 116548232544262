import React from "react";
import InputField from "../../../../../components/Inputs/InputField";
import { Controller } from "react-hook-form";
import Select from "../../../../../components/Inputs/Select";
import "react-step-progress-bar/styles.css";
// import { ProgressBar, Step } from "react-step-progress-bar";

function ProjectDataView({
  control,
  fields,
  register,
  staffList,
  setFields,
  projectPhaseList,
  projectStatusList,
  projectTypeList,
  leadChannelList,
  municipalityList,
  projectIndexTypeList,
  projectDeliveryTypeList,
  setNewStatus,
  setPreventPageChange,
}) {
  // const steps = [
  //   "Projekt skapat",
  //   "Offert",
  //   "Avtalet skrivet",
  //   "Bygg start",
  //   "Klart",
  // ];

  return (
    <div style={{ flex: "1", display: "grid", gap: "1em" }}>
      {/* <InputField disabled label={"name"} value={fields.project_name} /> */}
      <div className="wrapper">
        <div className="wrapper-title"> Organisation</div>
        <div className="form-grid-auto ">
          <Controller
            control={control}
            name={"project_project_leader"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_project_leader")}
                options={staffList}
                label={"Projektledare"}
                defaultValue={""}
                value={staffList.find(
                  (item) => item.value === fields.project_project_leader
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_project_leader: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_a_rit"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_a_rit")}
                options={staffList}
                label={"A-rit"}
                value={staffList.find(
                  (item) => item.value === fields.project_a_rit
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_a_rit: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_k_rit"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_k_rit")}
                options={staffList}
                label={"K-rit"}
                value={staffList.find(
                  (item) => item.value === fields.project_k_rit
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_k_rit: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_salesperson"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_salesperson")}
                options={staffList}
                label={"Säljare"}
                value={staffList.find(
                  (item) => item.value === fields.project_salesperson
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_salesperson: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_purchaser"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_purchaser")}
                options={staffList}
                label={"Inköpare"}
                value={staffList.find(
                  (item) => item.value === fields.project_purchaser
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_purchaser: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="wrapper">
        <div className="wrapper-title"> Projektdata</div>
        <div className="form-grid-auto ">
          <Controller
            control={control}
            name={"project_status"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_status")}
                options={projectStatusList}
                label={"Projektstatus"}
                value={projectStatusList.find(
                  (item) => item.value === fields.project_status
                )}
                onChange={(event, item) => {
                  setFields({
                    ...fields,
                    project_status: item.value,
                  });
                  onChange(item.value);
                  setNewStatus(item);

                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_type"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_type")}
                options={projectTypeList}
                label={"Projekttyp"}
                value={projectTypeList.find(
                  (item) => item.value === fields.project_type
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_type: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_index_type"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_index_type")}
                options={projectIndexTypeList}
                label={"Indextyp"}
                value={projectIndexTypeList.find(
                  (item) => item.value === fields.project_index_type
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_index_type: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_delivery_type"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_delivery_type")}
                options={projectDeliveryTypeList}
                label={"Leveranstyp"}
                value={projectDeliveryTypeList.find(
                  (item) => item.value === fields.project_delivery_type
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_delivery_type: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_phase"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_phase")}
                options={projectPhaseList}
                label={"FAS"}
                value={projectPhaseList.find(
                  (item) => item.value === fields.project_phase
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_phase: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_lead_channel"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_lead_channel")}
                options={leadChannelList}
                label={"Kanal in"}
                value={leadChannelList.find(
                  (item) => item.value === fields.project_lead_channel
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_lead_channel: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={"project_municipality"}
            render={({ field: { onChange, value } }) => (
              <Select
                control={control}
                register={register("project_municipality")}
                options={municipalityList}
                label={"Kommun"}
                value={municipalityList.find(
                  (item) => item.value === fields.project_municipality
                )}
                onChange={(event, item) => {
                  onChange(item.value);
                  setFields({
                    ...fields,
                    project_municipality: item.value,
                  });
                  setPreventPageChange(true);
                }}
              />
            )}
          />

          {/* <DateSelect
            disabled
            label={"Datum"}
            value={fields.project_created}
            onChange={handleChange} //! fungerar inte för den inte kan ta in name={"Datum"}, måste ha separat onChange
          /> */}
        </div>
      </div>
      <div className="wrapper">
        <div className="wrapper-title"> Beskrivning</div>
        <InputField
          multiline={true}
          name="project_description"
          label={"Beskrivning"}
          value={fields.project_description}
          onChange={(e) => {
            setFields({
              ...fields,
              project_description: e.value,
            });
            setPreventPageChange(true);
          }}
          register={register("project_description")}
          maxLength={1000}
          minRows={1}
        />
      </div>
    </div>
  );
}

export default ProjectDataView;
