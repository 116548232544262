import React from "react";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import Button from "../../../../../components/buttons/Button";
import { Tooltip } from "@mui/material";

function AddingArticleView({
  articleList,
  handleSelectArticle,
  articleToDisplay,
  priceForChosenArticle,
  formErrors,
  orderRow,
  handleChangeOrderRow,
  blockPurchaseEdit,
  moduleList,
  handleSelectModule,
  handleAddOrderRow,
  setShowAddStandAloneArticleView,
  orderStatus,
}) {
  return (
    <>
      <div
        className="wrapper"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1em",
          margin: "1.5em 0",
        }}
      >
        <div style={{ flex: "1" }}>
          <Select
            label={"Artikel"}
            options={articleList?.length > 0 ? articleList : []}
            value={articleList?.find((item) => item.value === orderRow.article)}
            onChange={handleSelectArticle}
            disabled={blockPurchaseEdit}
          />

          {articleToDisplay ? (
            <div
              style={{
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.596)",
                margin: "1em 0",
                padding: "8.5px 14px",
                border: "1px solid rgba(0, 0, 0, 0.175)",
                borderRadius: "4px",
              }}
            >
              <p>
                Huvudleverantör för denna artikel : {articleToDisplay.name}{" "}
              </p>
              <p> Artikel : {articleToDisplay.article_name}</p>
              <p> Artikelnr : {articleToDisplay.article_number}</p>
              <p> Enhet : {articleToDisplay.unit}</p>
              <p> Beställningsenhet : {articleToDisplay.order_unit}</p>
              <p>
                Pris per Beställningsenhet :{" "}
                {priceForChosenArticle.pricelist_purchase_price +
                  priceForChosenArticle.pricelist_retrieval_cost}{" "}
                kr
              </p>
              <p> Prisenhet : {articleToDisplay.price_unit}</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ display: "grid", gap: "1em", flex: "1" }}>
          <div>
            <InputField
              required={articleToDisplay?.length_required ? true : false}
              type="number"
              name="length"
              label={"Längd"}
              minNumbValue="0"
              step={"0.01"}
              value={orderRow.length}
              onChange={handleChangeOrderRow}
              disabled={blockPurchaseEdit}
            />{" "}
            <p style={{ color: "#FF0000" }}>{formErrors.length}</p>
          </div>
          <div>
            <InputField
              required={articleToDisplay?.width_required ? true : false}
              type="number"
              name="width"
              label={"Bredd"}
              minNumbValue="0"
              step={"0.01"}
              value={orderRow.width}
              onChange={handleChangeOrderRow}
              disabled={blockPurchaseEdit}
            />{" "}
            <p style={{ color: "#FF0000" }}>{formErrors.width}</p>
          </div>
          <div>
            <InputField
              required={articleToDisplay?.appendix_required ? true : false}
              multiline
              minRows={4}
              name="appendix"
              label={"Bilaga/kommentar"}
              value={orderRow.appendix}
              onChange={handleChangeOrderRow}
              disabled={blockPurchaseEdit}
              maxLength={255}
            />
            <p style={{ color: "#FF0000" }}>{formErrors.appendix}</p>
          </div>
          <div>
            <Select
              required={articleToDisplay ? true : false}
              label={"Modul"}
              options={moduleList}
              value={moduleList?.find((item) => item.value === orderRow.module)}
              onChange={handleSelectModule}
            />
            <p style={{ color: "#FF0000" }}>{formErrors.module}</p>
          </div>
          <div>
            <InputField
              required={articleToDisplay ? true : false}
              type="number"
              name="quantity"
              label={"Antal"}
              minNumbValue="0"
              step={"0.01"}
              value={orderRow.quantity}
              onChange={handleChangeOrderRow}
              disabled={blockPurchaseEdit}
            />
            <p style={{ color: "#FF0000" }}>{formErrors.quantity}</p>
            <p style={{ color: "#FF0000" }}>{formErrors.quantitynotanumber}</p>
          </div>
          <div>
            <InputField
              type="number"
              name="special_price"
              label={"Specialpris"}
              minNumbValue="0"
              step={"0.01"}
              value={orderRow.special_price}
              onChange={handleChangeOrderRow}
              disabled={blockPurchaseEdit}
            />{" "}
            <p style={{ color: "#FF0000" }}>{formErrors.special_price}</p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          title={
            <div style={{ fontSize: "0.85rem" }}>
              {orderStatus === "Beställning"
                ? "Får ej lägga till rader på beställning"
                : "  Används för att lägga till artiklar som idag inte har något pris kopplat till leverantören"}
            </div>
          }
        >
          <div>
            <Button
              sx={{ marginRight: "1em" }}
              disabled={blockPurchaseEdit || orderStatus === "Beställning"}
              onClick={() => setShowAddStandAloneArticleView(true)}
            >
              Lägg till fristående artikel
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          title={
            <div style={{ fontSize: "0.85rem" }}>
              {orderStatus === "Beställning"
                ? "Får ej lägga till rader på beställning"
                : ""}
            </div>
          }
        >
          <div>
            <Button
              disabled={
                orderStatus === "Beställning" ||
                blockPurchaseEdit ||
                !articleToDisplay ||
                !orderRow.quantity ||
                (!orderRow.length && articleToDisplay.length_required) ||
                (!orderRow.width && articleToDisplay.width_required) ||
                (!orderRow.appendix && articleToDisplay.appendix_required) ||
                !orderRow.module
              }
              onClick={(e) => handleAddOrderRow(e)}
            >
              Lägg till orderrad
            </Button>
          </div>
        </Tooltip>
      </div>
    </>
  );
}

export default AddingArticleView;
