import React from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function Select({ register, ...props }) {
  // return (
  //   <>
  //     <FormControl fullWidth>
  //       <Autocomplete
  //         disabled={props.disabled}
  //         name={props.name}
  //         options={props.options}
  //         value={props.value}
  //         onChange={props.onChange}
  //         getOptionLabel={(item) => (item.label ? item.label : "")}
  //         getOptionSelected={(option, value) =>
  //           value === undefined || value === "" || option.id === value.id
  //         }
  //         isOptionEqualToValue={(option, value) => option.value === value.value}
  //         renderInput={(params) => (
  //           <TextField
  //             {...params}
  //             label={props.label}
  //             required={props.required}
  //             // {...register}
  //           />
  //         )}
  //       />
  //     </FormControl>
  //   </>
  // );
  return (
    <>
      <FormControl fullWidth required>
        <Autocomplete
          name={props.name}
          options={props.options}
          value={props.value ? props.value : null}
          onChange={props.onChange}
          getOptionLabel={(item) => (item.label ? item.label : "")}
          // getOptionSelected={(option, value) =>
          //   value === undefined || value === "" || option.id === value.id
          // }
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={props.label}
              disabled={props.disabled}
              required={props.required}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "black",
                  textTransform: "capitalize",
                },
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}
