import React, { useState, useEffect } from "react";
import StaffForms from "./StaffForms";
import Content from "../../../components/Content";
import { Paper } from "@mui/material";
import { newProjColumns } from "../../../components/dataTable/columns/Columns";
import DataTable from "../../../components/dataTable/DataTable";
import { useParams } from "react-router-dom";
import * as staffAPI from "../../../api/staff/staffApi";
import { useMutation } from "react-query";
import { startSavingAnimation } from "../../../assets/animations/CheckBoxAnimation";
import CheckBoxAnimation from "../../../assets/animations/CheckBoxAnimation";
function StaffView() {
  const [staff, setStaff] = useState();
  const [staffRoles, setStaffRoles] = useState();
  const staffid = useParams();
  const [savingAnimation, setSavingSavingAnimation] = useState();

  useEffect(() => {
    staffAPI
      .getStaff(staffid.id)
      .then((fetchedStaff) => setStaff(fetchedStaff));
  }, [staffid.id]);

  useEffect(() => {
    staffAPI.getRoles().then((roles) => setStaffRoles(roles));
    localStorage.clear();
  }, []);

  const tabs = [
    { label: "Info", id: 1 },
    { label: "Aktiva projekt", id: 2 },
    { label: "historiska projekt", id: 3 },
  ];
  const { mutate } = useMutation(staffAPI.updateStaff, {
    onSuccess: () => {
      startSavingAnimation(setSavingSavingAnimation);
      console.log("ON SETTLED");
    },
    onError: () => {
      console.log("error fron mutate");
    },
  });
  if (staff && staffRoles) {
    return (
      <>
        {savingAnimation ? <CheckBoxAnimation /> : <div></div>}

        <Content tabs={true} tabList={tabs} title={"Kontaktinformation"}>
          <div value={1}>
            <StaffForms
              staff={staff}
              staffRoles={staffRoles}
              setStaff={setStaff}
              mutate={mutate}
            />
          </div>
          <div value={2}>
            <Paper>
              <div value={1}>
                <DataTable
                  columns={newProjColumns}
                  queryName={"projects"}
                  searchString={process.env.REACT_APP_API_DBBASE + "project?"}
                  typeOfTable="project"
                />{" "}
              </div>
            </Paper>
          </div>
          <div value={3}>
            <Paper>
              <div value={1}>
                <DataTable
                  columns={newProjColumns}
                  queryName={"projects"}
                  searchString={process.env.REACT_APP_API_DBBASE + "project?"}
                  typeOfTable="project"
                />{" "}
              </div>
            </Paper>
          </div>
        </Content>
      </>
    );
  }
}
export default StaffView;
