import React from "react";
import "../../styles/navbar/Topbar.css";
import Logo from "../../assets/Images/Logo-Grey.png";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as authAPI from "../../api/auth/authAPI";

import UserMenu from "./UserMenu";

export default function Topbar({ user }) {
  const { setAuth, auth } = useAuthContext();
  const navigate = useNavigate();
  const handleClickOnLogout = () => {
    setAuth();
    authAPI.logout();
    navigate("/login");
  };
  if (auth.auth)
    return (
      <div className="Topbar">
        <div className="Topbar-content">
          <div className="Topbar-logo-container">
            <img
              src={Logo}
              alt={""}
              style={{ height: "100%", width: "100%" }}
            ></img>
          </div>

          <div>
            {user?.user ? (
              <>
                <UserMenu
                  handleClickOnLogout={handleClickOnLogout}
                  user={user.user}
                />
              </>
            ) : (
              <> </>
            )}
          </div>
        </div>
      </div>
    );
}
