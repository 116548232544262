import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getAllForProject = (id) =>
  api.get(`/ordersforproject/${id}`).then((res) => res.data);

export const getAll = (id) =>
  api.get(`/purchaseorderforproject/${id}`).then((res) => res.data);

export const getAllOrders = () =>
  api.get("/purchaseorder/").then((res) => res.data);

export const GetOrder = (id) =>
  api.get(`/purchaseorder/${id}`).then((res) => res.data);

export const updateOrder = ({ id, ...updatedOrder }) => {
  return api.put(`/purchaseorder/${id}`, updatedOrder);
};

export const createPDF = (order) =>
  api.post(`/createorderpdf/`, order, {
    responseType: "blob",
  });

export const getTotalCost = (id) =>
  api.get(`/totalcostsforproject/${id}`).then((res) => res.data);

export const GetOrderRows = (id, pageNumber) =>
  api.get(`/orderrowpagination/${id}/${pageNumber}`).then((res) => res.data);

export const getPageNumber = (id) =>
  api.get(`/getorderrowtotalpagecount/${id}`).then((res) => res.data);
export const deleteOrderRow = (id) => api.delete(`/orderrow/` + id);

export const newOrder = (order) => api.post(`/purchaseorder`, order);
export const newRestOrder = (order) => api.post(`/purchaserestorder`, order);

export const sendOrderPDF = async ({ id, additionalPdfs }) => {
  const formData = new FormData();
  formData.append("id", id);

  if (additionalPdfs) {
    for (let i = 0; i < additionalPdfs.length; i++) {
      console.log(additionalPdfs[i], "FILE");
      const file = new File([additionalPdfs[i]], `${additionalPdfs[i].name}`);
      formData.append(`additionalPdfs`, file);
    }
  }

  const res = await api.post(`/sendorder/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};
export const deleteOrder = (id) => api.delete(`/order/` + id);

export const handleImportRows = (object) =>
  api.post(`/handleimportofrows/`, object).then((res) => res.data);

export const handleImportPurchase = (object) =>
  api.post(`/handleimportofpurchases/`, object).then((res) => res.data);
// Används vid skapande av restorder, rader som ska vara kvar i "gamla" order behöver uppdateras

export const updateMultipleRows = ({ id, ...updatedOrder }) => {
  return api.put(`/purchaseorder/${id}`, updatedOrder);
};

export const deleteImportedOrder = (id) => api.delete(`/purchaseorder/` + id);

export const getAllOrdersForSelect = (id) =>
  api.get(`/purchaseorder/labels/${id}`).then((res) => res.data);

export const moveRows = (object) =>
  api.post(`/purchaseorder/moverows`, object).then((res) => res.data);
