import React, { useState, useEffect } from "react";
import TableInstance from "../../components/dataTable/TableInstance";

function DataTable({
  typeOfTable,
  data,
  handleShowObject,
  columns,
  initialState,
  purchaseTable,
}) {
  const [pageNumber, setPageNumber] = useState(1);

  const [storedFilters, setStoredFilters] = useState();

  useEffect(() => {
    let filters = initialState?.filters ? initialState.filters : [];
    let filtersStored = JSON.parse(localStorage.getItem("filtersStored"));
    if (filtersStored) {
      console.log("step 2");
      if (filtersStored[typeOfTable]) {
        console.log("step 3");
        filtersStored[typeOfTable].map((filterStored) => {
          filters.push({ id: filterStored.id, value: filterStored.value });
        });
      }
    }
    setStoredFilters(filters);
  }, [typeOfTable]);

  if (data && storedFilters)
    return (
      <TableInstance
        typeOfTable={typeOfTable}
        tableData={data}
        setPageNumber={setPageNumber}
        columnsRecieved={columns}
        pageNumber={pageNumber}
        handleShowObject={handleShowObject}
        initialState={storedFilters}
        purchaseTable={purchaseTable}
      />
    );
}

export default DataTable;
