import React, { Fragment, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Button from "../../../../../components/buttons/Button";
import RestedEditRow from "../../../../../components/dataTable/purchaseRows/RestedEditRow";

const RestOrderRowsView = ({
  showModal,
  hideModal,
  confirmModal,
  restedRows,
  moduleList,
}) => {
  const [rows, setRows] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  // DEEP COPY NYTT MINNE ALOKERAS
  useEffect(() => {
    setRows(JSON.parse(JSON.stringify(restedRows)));
    setOriginalRows(JSON.parse(JSON.stringify(restedRows)));
  }, [restedRows]);

  const handleChangeRestOrderRow = (event, index) => {
    let newRestedRows = [...rows];

    event.preventDefault();
    let { value } = event.target;

    newRestedRows[index].quantity = value;
    setRows(newRestedRows);
  };

  // Behöver hitta ett sätt att ta bort från totalt_mängd, calculutation
  // Behöver även hitta ett sätt lägga till totalt_mängd,calculutation på raderna sen i backend controllern

  const handleOriginalRowsToBeAltered = () => {
    let sumToRemove = 0;
    let ogRows = [...originalRows];
    ogRows.map((row, index) => {
      if (row.quantity === rows[index].quantity) {
        row.isRested = 1;

        sumToRemove += Number(row.quantity) * Number(row.purchase_price);
      } else {
        row.quantity = Number(row.quantity) - Number(rows[index].quantity);
        sumToRemove +=
          Number(rows[index].quantity) * Number(row.purchase_price);

        let amountToRemove =
          Number(row.total_amount) /
          (Number(row.quantity) + Number(rows[index].quantity));
        row.total_amount =
          row.total_amount - amountToRemove * Number(rows[index].quantity);
        rows[index].total_amount =
          amountToRemove * Number(rows[index].quantity);
        let calculation =
          Number(row.calculation) /
          (Number(row.quantity) + Number(rows[index].quantity));
        row.calculation =
          row.calculation - calculation * Number(rows[index].quantity);
        row.isRested = 1;

        rows[index].calculation = calculation * Number(rows[index].quantity);
      }
    });
    setOriginalRows(ogRows);
    confirmModal(rows, ogRows, sumToRemove);
  };
  if (rows) {
    return (
      <Modal show={showModal} onHide={hideModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Hantera rader som ska restnoteras
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ margin: "1em 0", maxHeight: "50em", overflow: "auto" }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead
                  style={{
                    position: "sticky",
                    top: "0px",
                    margin: "0 0 0 0",
                    background: "white",
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell scope="col">#</TableCell>
                    <TableCell scope="col">Artikelnr</TableCell>
                    <TableCell scope="col">Artikel</TableCell>
                    <TableCell scope="col">Antal som restas</TableCell>

                    <TableCell scope="col">Kommentar</TableCell>
                    <TableCell scope="col">Modul</TableCell>
                  </TableRow>
                </TableHead>
                {rows.length > 0 ? (
                  <TableBody>
                    {rows.map((item, i) => (
                      <Fragment key={i}>
                        <RestedEditRow
                          item={item}
                          i={i}
                          //   handleSelectModuleOnOrderRow={handleSelectModuleOnOrderRow}
                          //   indexInRows={i}
                          moduleList={moduleList}
                          editRowData={item}
                          handleChangeRestOrderRow={handleChangeRestOrderRow}
                          //   handleEditRowChange={handleEditRowChange}
                          //   handleRowEditSubmit={handleRowEditSubmit}
                          //   setEditRowIndex={setEditRowIndex}
                        />
                      </Fragment>
                    ))}
                  </TableBody>
                ) : (
                  <div></div>
                )}
              </Table>
            </TableContainer>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleOriginalRowsToBeAltered}>
            Skapa restorder
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
export default RestOrderRowsView;
