import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useQueryClient, useMutation } from "react-query";
import * as api from "../../../../api/ata/ataApi";
import InputField from "../../../../components/Inputs/InputField";
import DateSelect from "../../../../components/Inputs/DateSelect";
import Button from "../../../../components/buttons/Button";
import "../../../../styles/ata/Ata.css";
import { useNavigate, useParams } from "react-router-dom";
import * as supplierAPI from "../../../../api/register/supplierApi";
import * as userAPI from "../../../../api/user/userAPI";
import * as ataStatusAPI from "../../../../api/ata/ataStatusAPI";
import Select from "../../../../components/Inputs/Select";
import { UnitOptions } from "../../../../components/customSelect/SelectOptions";
import Content from "../../../../components/Content";
import Checkbox from "@mui/material/Checkbox";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function AddAtaView() {
  const [users, setUsers] = useState();
  const [suppliers, setSuppliers] = useState();
  const [ataStatusList, setAtaStatusList] = useState();
  const [sumWithSupCharge, setSumWithSupCharge] = useState();

  const project = useParams();
  const { auth } = useAuthContext();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const ata = {
    registered_by: auth.user.id,
    project: project.id,
    ata_nr: "",
    order_date: "",
    confirmed_date: "",
    // registered_by: "",
    label: "",
    unit: undefined,
    price: undefined,
    sum: undefined,
    notes: "",
    quantity: undefined,
    bill_towards_supplier: 0,
    bill_towards_client: 0,
    client_surcharge: undefined,
    supplier_surcharge: undefined,
    sum_with_client_charge: undefined,
    sum_with_supplier_carge: undefined,
    notified_by_supplier: undefined,
    confirmed_by: undefined,
    status: undefined,
  };

  const [fields, setFields] = useState(ata);

  useEffect(() => {
    if (fields.supplier_surcharge > 0) {
      setFields({
        ...fields,
        sum_with_supplier_carge:
          (fields.supplier_surcharge / 100) * fields.price * fields.quantity +
          fields.price * fields.quantity,
      });

      setSumWithSupCharge(
        (fields.supplier_surcharge / 100) * fields.price * fields.quantity +
          fields.price * fields.quantity
      );
    } else if (sumWithSupCharge !== 0) setSumWithSupCharge(0);
  }, [fields.supplier_surcharge, fields, sumWithSupCharge]);

  useEffect(() => {
    if (fields.client_surcharge > 0) {
      if (sumWithSupCharge > 0) {
        setFields({
          ...fields,
          sum_with_client_charge:
            (fields.client_surcharge / 100) * sumWithSupCharge +
            sumWithSupCharge,
        });
      } else {
        setFields({
          ...fields,
          sum_with_client_charge:
            (fields.client_surcharge / 100) * fields.price * fields.quantity +
            fields.price * fields.quantity,
        });
      }
    } else if (fields.ata_sum_with_client_charge !== 0)
      setFields({
        ...fields,
        ata_sum_with_client_charge: 0,
      });
  }, [fields.client_surcharge, fields, sumWithSupCharge]);

  const { mutate } = useMutation(api.newAta, {
    onSuccess: (e) => {
      toastSuccess("ÄTA skapades");

      console.log("ON SETTLED");
      queryClient.invalidateQueries(["ata"]);
      navigate(`/projektoversikt/ata/${e.data.raw.insertId}/`);
    },
    onError: (e) => {
      toastError("Gick inte att skapa ÄTA");

      console.log("error fron mutate");
    },
  });

  const handleSelectUnit = (e, value) => {
    setFields({ ...fields, unit: value.value });
  };
  const handleSelectNotfiedSupplier = (e, value) => {
    setFields({ ...fields, notified_by_supplier: value.value });
  };
  const handleSelectConfirmedBy = (e, value) => {
    setFields({ ...fields, confirmed_by: value.value });
  };
  const handleSelectSupplier = (e, value) => {
    setFields({ ...fields, supplier: value.value });
  };
  const handleSelectStatus = (e, value) => {
    setFields({ ...fields, status: value.value });
  };

  useEffect(() => {
    userAPI.userList().then((users) => setUsers(users));
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSuppliers(suppliers));
    ataStatusAPI
      .getStatusList()
      .then((statusList) => setAtaStatusList(statusList));
  }, []);

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      if (name === "bill_towards_supplier") {
        if (fields.bill_towards_supplier === 0) {
          setFields({ ...fields, bill_towards_supplier: 1 });
        } else {
          setFields({ ...fields, bill_towards_supplier: 0 });
        }
      } else {
        if (fields.bill_towards_client === 0) {
          setFields({ ...fields, bill_towards_client: 1 });
        } else {
          setFields({ ...fields, bill_towards_client: 0 });
        }
      }
    } else setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  function handleClose() {
    navigate(`/projektoversikt/${project.id}/`);
  }
  if (users && suppliers && ataStatusList)
    return (
      <Content>
        <div className="wrapper">
          <div>
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                <div style={{ flex: "2" }}>
                  <div
                    style={{ marginBottom: "1em", display: "flex", gap: "1em" }}
                  >
                    <InputField
                      required
                      label={"Märke"}
                      name={"label"}
                      value={fields.label}
                      onChange={handleChange}
                      maxLength={45}
                    />
                    <Select
                      required
                      label="status på ÄTA"
                      options={ataStatusList}
                      value={ataStatusList.find(
                        (item) => item.value === fields.status
                      )}
                      onChange={handleSelectStatus}
                    />
                  </div>
                  <div className="form-grid-auto">
                    <DateSelect
                      required
                      label={"Beställt"}
                      value={fields.order_date}
                      onChange={(value) => {
                        setFields({ ...fields, order_date: value });
                      }}
                    />
                    <Select
                      required
                      label="ÄTA aviserad av"
                      options={suppliers}
                      value={suppliers.find(
                        (item) => item.value === fields.notified_by_supplier
                      )}
                      onChange={handleSelectNotfiedSupplier}
                    />
                    <Select
                      label="Godkänd av"
                      options={users}
                      value={users.find(
                        (item) => item.value === fields.confirmed_by
                      )}
                      onChange={handleSelectConfirmedBy}
                    />{" "}
                    <DateSelect
                      label={"Godkänd datum"}
                      value={fields.confirmed_date}
                      onChange={(value) => {
                        setFields({ ...fields, confirmed_date: value });
                      }}
                    />
                    <InputField
                      required
                      type="number"
                      label={"Antal"}
                      name={"quantity"}
                      onChange={handleChange}
                      value={fields.quantity}
                    />
                    <Select
                      required={fields.quantity ? true : false}
                      label="Enhet"
                      options={UnitOptions}
                      value={UnitOptions.find(
                        (item) => item.value === fields.unit
                      )}
                      onChange={handleSelectUnit}
                    />
                    <InputField
                      required
                      type="number"
                      label={"Pris"}
                      name={"price"}
                      onChange={handleChange}
                      value={fields.price}
                    />
                    <InputField
                      disabled
                      type="number"
                      label={"Summa"}
                      value={
                        fields.price && fields.quantity
                          ? fields.price * fields.quantity
                          : 0
                      }
                    />
                  </div>

                  <div
                    style={{ display: "flex", marginTop: "1em", gap: "1em" }}
                  >
                    <div style={{ display: "grid", flex: "1" }}>
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          name="bill_towards_supplier"
                          label={"Klarmarkerad"}
                          type="checkbox"
                          onChange={handleChange}
                          checked={fields.bill_towards_supplier ? true : false}
                          // value={fields}
                        ></Checkbox>
                        <div style={{ marginTop: "10px", marginRight: "99px" }}>
                          <p>Faktureras av leverantör</p>
                        </div>
                        <div style={{ width: "323px" }}>
                          <Select
                            required={
                              fields.bill_towards_supplier ? true : false
                            }
                            label="Leverantör"
                            options={suppliers}
                            value={suppliers.find(
                              (item) => item.value === fields.supplier
                            )}
                            onChange={handleSelectSupplier}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Checkbox
                          name="bill_towards_client"
                          label={"Klarmarkerad"}
                          type="checkbox"
                          onChange={handleChange}
                          checked={fields.bill_towards_client === 1}
                          // value={fields}
                        ></Checkbox>
                        <div style={{ marginTop: "10px" }}>
                          <p>Faktuera kund</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "grid", gap: "1em", flex: "1" }}>
                      <InputField
                        required={fields.bill_towards_supplier ? true : false}
                        type="number"
                        label={"Påslag"}
                        name={"supplier_surcharge"}
                        onChange={handleChange}
                        value={fields.supplier_surcharge}
                      />
                      <InputField
                        required={fields.bill_towards_client ? true : false}
                        type="number"
                        label={"Påslag"}
                        name={"client_surcharge"}
                        onChange={handleChange}
                        value={fields.client_surcharge}
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <InputField readOnly={true} value={sumWithSupCharge} />
                      <div style={{ marginTop: "1em" }}>
                        <InputField
                          readOnly={true}
                          value={fields.sum_with_client_charge}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ flex: "1" }}>
                  <InputField
                    required
                    label={"Notering"}
                    name={"notes"}
                    onChange={handleChange}
                    value={fields.notes}
                    multiline
                    minRows={15}
                    maxLength={1000}
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: "1em", marginTop: "1em" }}>
                <Button color="success" type="submit">
                  Lägg till
                </Button>
                <Button type="button" onClick={() => handleClose(0)}>
                  Stäng
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Content>
    );
}

export default AddAtaView;
