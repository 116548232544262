import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import * as articleAPI from "../../../../../api/register/articleApi";
function AddStandAloneArticleView({
  showModal,
  hideModal,
  moduleList,
  articleToDisplay,
  handleSelectArticle,
  orderRow,
  handleChangeOrderRow,
  formErrors,
  handleSelectModule,
  confirmModal,
}) {
  const [articelList, setArticleList] = useState();

  useEffect(() => {
    articleAPI
      .GetAllArticlesNames()
      .then((articles) => setArticleList(articles));
  }, []);

  if ("rows") {
    return (
      <Modal show={showModal} onHide={hideModal} size="lg" centered>
        <form onSubmit={(e) => confirmModal(e)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Lägg till orderrad
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              style={{
                margin: "1em 0",
                maxHeight: "50em",
                overflow: "auto",
                marginTop: "1em",
              }}
            >
              <div style={{ marginTop: "1em" }}>
                <Select
                  required
                  label={"Artikel"}
                  options={articelList}
                  onChange={handleSelectArticle}
                  value={articelList?.find(
                    (item) => item.value === orderRow.article
                  )}

                  //   disabled={blockPurchaseEdit}
                />
              </div>
            </div>
            {articleToDisplay ? (
              <div
                style={{
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.596)",
                  margin: "1em 0",
                  padding: "8.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.175)",
                  borderRadius: "4px",
                }}
              >
                <p>
                  Huvudleverantör för denna artikel : {articleToDisplay.name}{" "}
                </p>
                <p> Artikel : {articleToDisplay.article_name}</p>
                <p> Artikelnr : {articleToDisplay.article_number}</p>
                <p> Enhet : {articleToDisplay.unit}</p>
                <p> Beställningsenhet : {articleToDisplay.order_unit}</p>
                <p> Prisenhet : {articleToDisplay.price_unit}</p>
              </div>
            ) : (
              <div></div>
            )}

            <div style={{ display: "grid", gap: "1em", flex: "1" }}>
              <div>
                <InputField
                  required={articleToDisplay?.length_required === 1}
                  type="number"
                  name="length"
                  minNumbValue="0"
                  step={"0.01"}
                  label={"Längd"}
                  value={orderRow.length}
                  onChange={handleChangeOrderRow}
                />{" "}
                <p style={{ color: "#FF0000" }}>{formErrors.length}</p>
              </div>
              <div>
                <InputField
                  required={articleToDisplay?.width_required === 1}
                  type="number"
                  name="width"
                  minNumbValue="0"
                  step={"0.01"}
                  label={"Bredd"}
                  value={orderRow.width}
                  onChange={handleChangeOrderRow}
                />{" "}
                <p style={{ color: "#FF0000" }}>{formErrors.width}</p>
              </div>
              <div>
                <InputField
                  required={articleToDisplay?.appendix_required === 1}
                  multiline
                  minRows={4}
                  name="appendix"
                  label={"Bilaga/kommentar"}
                  value={orderRow.appendix}
                  onChange={handleChangeOrderRow}
                />
                <p style={{ color: "#FF0000" }}>{formErrors.appendix}</p>
              </div>
              <div>
                <Select
                  required
                  label={"Modul"}
                  options={moduleList}
                  value={moduleList?.find(
                    (item) => item.value === orderRow.module
                  )}
                  onChange={handleSelectModule}
                />
                <p style={{ color: "#FF0000" }}>{formErrors.module}</p>
              </div>
              <div>
                <InputField
                  required
                  type="number"
                  name="quantity"
                  minNumbValue="0"
                  step={"0.01"}
                  label={"Antal"}
                  value={orderRow.quantity}
                  onChange={handleChangeOrderRow}
                />
                <p style={{ color: "#FF0000" }}>{formErrors.quantity}</p>
                <p style={{ color: "#FF0000" }}>
                  {formErrors.quantitynotanumber}
                </p>
              </div>
              <div>
                <InputField
                  required
                  type="number"
                  name="special_price"
                  minNumbValue="0"
                  step={"0.01"}
                  label={"Specialpris"}
                  value={orderRow.special_price}
                  onChange={handleChangeOrderRow}
                />{" "}
                <p style={{ color: "#FF0000" }}>{formErrors.special_price}</p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="submit">Lägg till orderrad</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default AddStandAloneArticleView;
