import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { sv } from "date-fns/locale";
import { useState } from "react";
export default function DateSelect(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {props.MobileDatePicker ? (
        <FormControl>
          <LocalizationProvider adapterLocale={sv} dateAdapter={AdapterDateFns}>
            {props.controlled ? (
              <MobileDatePicker
                style={{ width: "100%" }}
                inputFormat="yyyy/MM/dd"
                toolbarTitle={"Välj datum"}
                disabled={props.disabled}
                readOnly={props.readOnly}
                label={props.label}
                value={props.value}
                emptyLabel={props.emptyLabel}
                onChange={props.onChange}
                onClose={props.onClose}
                open={props.open}
                displayWeekNumber={true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    color={props.color}
                    sx={props.sx}
                    {...params}
                    size="small"
                    placeholder={props.placeholder}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "black",
                        textTransform: "capitalize",
                      },
                    }}
                    error={props.required && !props.value}
                    required={props.required}
                  />
                )}
              />
            ) : (
              <MobileDatePicker
                style={{
                  width: "100%",
                }}
                inputFormat="yyyy/MM/dd"
                toolbarTitle={"Välj datum"}
                disabled={props.disabled}
                readOnly={props.readOnly}
                label={props.label}
                value={props.value}
                emptyLabel={props.emptyLabel}
                onChange={props.onChange}
                displayWeekNumber={true}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    color={props.color}
                    sx={props.sx}
                    {...params}
                    placeholder={props.placeholder}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={props.error}
                    required={props.required}
                  />
                )}
              />
            )}
          </LocalizationProvider>
        </FormControl>
      ) : (
        <FormControl>
          <LocalizationProvider adapterLocale={sv} dateAdapter={AdapterDateFns}>
            <DatePicker
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              style={{ width: "100%" }}
              inputFormat="yyyy/MM/dd"
              disabled={props.disabled}
              readOnly={props.readOnly}
              label={props.label}
              value={props.value}
              emptyLabel={props.emptyLabel}
              onChange={props.onChange}
              InputAdornmentProps={{ style: { display: "none" } }}
              displayWeekNumber={true}
              renderInput={(params) => (
                <TextField
                  onClick={(e) => setOpen(true)}
                  sx={props.sx}
                  {...params}
                  color={props.color}
                  placeholder={props.placeholder}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "black", textTransform: "capitalize" },
                  }}
                  error={props.required && !props.value}
                  required={props.required}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      )}
    </>
  );
}
