import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { handleOnProductionExport } from "../utils/ExportFunctions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DataTable from "../../../components/newDataTable/DataTable";
import { ModuleColumns } from "../../../components/dataTable/columns/ModuleColumns";
import * as moduleAPI from "../../../api/project/moduleApi";
import { useQueryClient, useQuery } from "react-query";
import AddModuleView from "../ProjectDetailTabViews/module/AddModuleViewModal";
function ProjectModuleOverview({ projectId, projectNumber }) {
  const [showAddModuleView, setShowAddModuleView] = useState(false);
  const queryClient = useQueryClient();
  const { data: moduleData } = useQuery(["module", projectId], () =>
    moduleAPI.getAll(projectId)
  );
  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FileDownloadIcon />}
        onClick={() => handleOnProductionExport(moduleData)}
      >
        Exporta Produktionsrapport
      </Button>{" "}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setShowAddModuleView(true)}
      >
        Lägg till ny Modul
      </Button>
      <DataTable
        columns={ModuleColumns}
        data={moduleData?.length > 0 ? moduleData : []}
        typeOfTable="module"
      />
      <AddModuleView
        showModal={showAddModuleView}
        hideModal={() => setShowAddModuleView(false)}
        projectId={projectId}
        queryClient={queryClient}
        projektnr={projectNumber}
      />
    </>
  );
}

export default ProjectModuleOverview;
