import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const Supplierlistcolumns = [
  {
    Header: "Leverantör",
    table: "supplier",
    accessor: "supplier_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Telefonnr",
    table: "supplier",
    accessor: "supplier_phonenumber",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kategori",
    table: "supplier",
    accessor: "supplier_category_category_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Referens",
    accessor: "supplier_reference",
    disableFilters: true,
  },
  {
    Header: "Adress",
    accessor: "supplier_adress",
    disableFilters: true,
  },
  {
    Header: "Postnr",
    accessor: "supplier_postnumber",
    disableFilters: true,
  },
  {
    Header: "Postadress",
    accessor: "supplier_postadress",
    disableFilters: true,
  },
];
