import React from "react";
import DataTable from "../../../components/newDataTable/DataTable";
import { ProjectLogColumns } from "../../../components/dataTable/columns/ProjectLogColumns";
import * as projectAPI from "../../../api/project/projectApi";
import { useQuery } from "react-query";
function ProjectLogOverview({ projectId }) {
  const { data: projectLogData } = useQuery(["projectlog", projectId], () =>
    projectAPI.getLogs(projectId)
  );
  return (
    <div className="card-body">
      <div className="head-button-container"></div>
      <DataTable
        columns={ProjectLogColumns}
        data={projectLogData?.length > 0 ? projectLogData : []}
        typeOfTable="projectlog"
      />
    </div>
  );
}

export default ProjectLogOverview;
