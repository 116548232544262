import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import DoneIcon from "@mui/icons-material/Done";
export const ModuleColumns = [
  {
    Header: "Väggbet",
    table: "module",
    accessor: "module_wall_designation",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Vagga",
    table: "module",
    accessor: "module_module_stage",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Faktisk spiktid",
    table: "module",
    accessor: "module_work_time",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Antal delar",
    table: "module",
    accessor: "antal",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },

  {
    Header: "Ledtid",
    accessor: "ledTid",
    // Cell: (props) => {
    //   return props.value ? <>{props.value}</> : <>Inga rader att räkna på</>;
    // },

    disableFilters: true,
  },
  {
    Header: "Produktionstid",
    accessor: "workingHours",
    // Cell: (props) => {
    //   return props.value ? <>{props.value}</> : <>Inga rader att räkna på</>;
    // },

    disableFilters: true,
  },

  {
    Header: "Material uttag",
    table: "module",
    accessor: "module_material_withdrawal",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Datum spikad",
    accessor: "module_date_nailed",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Egentillverkad",
    table: "module",
    accessor: "module_self_produced",
    id: "module_self_produced",
    Cell: (props) => {
      return props.value === 1 ? (
        <>
          <DoneIcon />
        </>
      ) : (
        <></>
      );
    },
    disableFilters: true,
  },
  {
    Header: "Egenkontroll",
    table: "module",
    accessor: "module_is_checked",
    id: "id",
    Cell: (props) => {
      return props.value === 1 ? (
        <>
          <DoneIcon />
        </>
      ) : (
        <>Ej klar</>
      );
    },
    disableFilters: true,
  },
];

export const AllModuleColumns = [
  {
    Header: "Projekt",
    accessor: "project_project_number",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Väggbet",
    accessor: "module_wall_designation",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Vagga",
    accessor: "module_module_stage",
    Filter: SelectColumnFilter,
    filter: "equals",
  },

  {
    Header: "Antal delar",
    accessor: "antal",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Material uttag",
    accessor: "module_material_withdrawal",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Datum spikad",
    accessor: "module_date_nailed",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
];
