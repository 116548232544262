import React from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// This component is only used in ImportOrderRowsView. Changes made to v1 component and requires values to be stored in state to render selected choice.
export default function Select({ register, ...props }) {
  return (
    <>
      <FormControl fullWidth required>
        <Autocomplete
          name={props.name}
          options={props.options}
          value={props.value}
          onChange={props.onChange}
          getOptionLabel={(item) => (item.label ? item.label : "")}
          // getOptionSelected={(option, value) =>
          //   value === undefined || value === "" || option.id === value.id
          // }
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={props.label}
              disabled={props.disabled}
              required={props.required}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "black",
                  textTransform: "capitalize",
                },
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}
