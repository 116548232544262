import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import { PricelistColumns } from "../../../components/dataTable/columns/PricelistColumns";
import DataTable from "../../../components/newDataTable/DataTable";
import PriceListItemView from "./PriceListItemView";
import AddPriceListItemView from "./AddPriceListItemView";
import * as pricelistAPI from "../../../api/register/priceListApi";
import { useQuery } from "react-query";
function PriceListOverview() {
  const [displayAddPriceListItemView, setDisplayAddPriceListItemView] =
    useState(false);
  const [displayPriceListItemView, setDisplayPriceListItemView] =
    useState(false);

  const { data: priceList } = useQuery(["pricelist"], () =>
    pricelistAPI.getAllPriceListItems()
  );
  return (
    <>
      <div>
        <Button onClick={() => setDisplayAddPriceListItemView(true)}>
          Lägg till Pris
        </Button>

        <DataTable
          columns={PricelistColumns}
          data={priceList?.length > 0 ? priceList : []}
          typeOfTable="pricelist"
          handleShowObject={setDisplayPriceListItemView}
        />
      </div>
      <PriceListItemView
        showModal={displayPriceListItemView}
        hideModal={() => setDisplayPriceListItemView(false)}
        priceListItemToShow={displayPriceListItemView}
      />
      <AddPriceListItemView
        showModal={displayAddPriceListItemView}
        hideModal={() => setDisplayAddPriceListItemView(false)}
      />
    </>
  );
}

export default PriceListOverview;
