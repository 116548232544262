import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import * as supplierAPI from "../../../../../api/register/supplierApi";
import * as contactPersonAPI from "../../../../../api/register/contactPersonApi";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DeleteConfirmation from "../../../../../components/modals/DeleteConfirmation";
import {
  toastError,
  toastSuccess,
} from "../../../../../components/toast/toastFunctions";
const ContactPersonView = ({
  showModal,
  hideModal,
  projectId,
  queryClient,
}) => {
  const [suppliers, setSuppliers] = useState();
  const [chosenSupplier, setChosenSuppliers] = useState();
  const [chosenContactPerson, setChosenContactPerson] = useState();
  const [contactPersons, setContactPersons] = useState();
  const [contactPersonFromDb, setContactPersonsFromDb] = useState();
  const [comment, setComment] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const handleSelectSupplier = (e, value) => {
    setChosenSuppliers(value.value);
  };
  const handleSelectContactPerson = (e, value) => {
    setChosenContactPerson(value.id);
  };

  useEffect(() => {
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSuppliers(suppliers));
    if (showModal)
      contactPersonAPI
        .getProjectContactPerson(showModal)
        .then((contactPerson) => {
          setChosenContactPerson(
            contactPerson.project_supplier_contact_person_supplier_contact_person
          );
          setChosenSuppliers(contactPerson.supplier_id);
          setComment(contactPerson.project_supplier_contact_person_comment);
        });
  }, [showModal]);

  useEffect(() => {
    if (chosenSupplier)
      contactPersonAPI
        .getAllContactPersonForSupplier(chosenSupplier)
        .then((contactPersons) => setContactPersonsFromDb(contactPersons));
  }, [chosenSupplier]);

  useEffect(() => {
    let listofContacts = [];
    if (contactPersonFromDb) {
      contactPersonFromDb.map((person) => {
        let object = {
          value:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          label:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          id: person?.id,
          name: person?.name,
          email: person?.email,
          role: person?.role,
          phone: person?.phone,
        };
        listofContacts.push(object);
      });
      setContactPersons(listofContacts);
    }
  }, [contactPersonFromDb]);

  const { mutate } = useMutation(contactPersonAPI.updateProjectContactPerson, {
    onSettled: () => {
      queryClient.invalidateQueries();
      setTimeout(function () {
        queryClient.invalidateQueries();
        hideModal();
      }, 1000);
      toastSuccess("Kontaktperson uppdaterades");
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });

  const updateContactPerson = () => {
    let contactPerson = {
      id: showModal,
      project: projectId,
      supplier_contact_person: chosenContactPerson,
      comment: comment,
    };
    mutate(contactPerson);
  };

  const deleteProjectContactPerson = useMutation(
    contactPersonAPI.deleteProjectContactPerson,
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        setShowDelete(false);

        setTimeout(function () {
          queryClient.invalidateQueries();
          hideModal();
        }, 500);
        toastSuccess("Kontaktperson togs bort");
      },
      onError: () => {
        toastError("Gick inte att ta bort kontaktperson");
      },
    }
  );

  const confirmDelete = () => {
    deleteProjectContactPerson.mutate(showModal);
  };
  if (suppliers && contactPersons)
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            hideModal();
            queryClient.invalidateQueries();
          }}
          size="lg"
          centered
        >
          <Modal.Header closeButton>Kontaktperson</Modal.Header>

          <Modal.Body>
            <div>
              <div style={{ marginBottom: "1em" }}>
                {" "}
                <Select
                  required={true}
                  onChange={handleSelectSupplier}
                  options={suppliers}
                  label={"Leverantör"}
                  value={
                    chosenSupplier
                      ? suppliers.find((item) => item.value === chosenSupplier)
                      : ""
                  }
                />
              </div>
              <div style={{ marginBottom: "1em" }}>
                {" "}
                <Select
                  required={true}
                  onChange={handleSelectContactPerson}
                  options={contactPersons}
                  label={"Kontaktperson"}
                  value={
                    chosenContactPerson
                      ? contactPersons.find(
                          (item) => item.id === chosenContactPerson
                        )
                      : ""
                  }
                />
              </div>
              <div style={{ marginBottom: "1em" }}>
                {" "}
                <InputField
                  multiline={true}
                  minRows={4}
                  label={"Kommentar"}
                  name="comment"
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  maxLength={255}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              color="error"
              onClick={(e) => {
                setShowDelete(true);
              }}
            >
              <DeleteForeverIcon />
            </Button>
            <div style={{ marginLeft: "39em", marginRight: "1em" }}>
              <Button
                onClick={() => {
                  hideModal();
                }}
              >
                Stäng
              </Button>
            </div>
            <Button
              onClick={() => {
                updateContactPerson();
              }}
              color="success"
            >
              Spara
            </Button>
          </Modal.Footer>
        </Modal>
        <DeleteConfirmation
          centered={"centered"}
          showModal={showDelete}
          confirmModal={confirmDelete}
          hideModal={() => setShowDelete(false)}
        />
      </>
    );
};

export default ContactPersonView;
