import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import * as errandAPI from "../../../../api/project/errandApi";
import * as errandStatusAPI from "../../../../api/ownFields/errandStatusApi";
import * as errandTypeAPI from "../../../../api/ownFields/errandTypeApi";
import * as userAPI from "../../../../api/user/userAPI";
import ErrandForm from "./components/ErrandForm";
import { Modal } from "react-bootstrap";
import Button from "../../../../components/buttons/Button.js";
import { useForm, Controller } from "react-hook-form";
import AddErrandLogView from "./AddErrandLogView";
import ErrandLogModal from "./ErrandLogModal";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import ClientControlView from "./components/ClientControlView";
import SelfControlPreview from "./components/SelfControlPreview";
import { Tooltip } from "@mui/material";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function ErrandView({
  errandId,
  showModal,
  hideModal,
  queryClient,
  client_mail,
}) {
  const [errand, setErrand] = useState();
  const [errandStatusList, setErrandStatusList] = useState();
  const [errandTypeList, setErrandTypeList] = useState();
  const [staffList, setStaffList] = useState();
  const [showAddErrandLogView, setShowAddErrandLogView] = useState(false);
  const [showErrandLogModal, setShowErrandLogModal] = useState(false);
  const [logComment, setLogComment] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [responsiblePersonWasChanged, setResponsiblePersonWasChanged] =
    useState(false);
  const [statusWasChanged, setStatusWasChanged] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [errandLogToShow, setErrandLogToShow] = useState();
  const [buttonTimeOut, setButtonTimeout] = useState(false);
  const [sendButtonTimeout, setSendButtonTimeout] = useState();
  const { register, handleSubmit, control } = useForm({});
  const { auth } = useAuthContext();

  useEffect(() => {
    if (errandId) {
      errandAPI.getComment(errandId).then((comment) => setErrand(comment));
    }
  }, [errandId]);

  useEffect(() => {
    if (errandId)
      errandAPI
        .getErrandLogs(errandId)
        .then((logs) => setErrandLogToShow(logs));
  }, [showAddErrandLogView, errand, buttonTimeOut, sendButtonTimeout]);

  useEffect(() => {
    errandStatusAPI.getStatusList().then((list) => setErrandStatusList(list));
    errandTypeAPI.getTypeList().then((list) => setErrandTypeList(list));
    userAPI.userList().then((list) => setStaffList(list));
  }, []);
  const [fields, setFields] = useState();

  useEffect(() => {
    (async () => {
      setFields({ ...errand });
      setResponsiblePersonWasChanged(false);
      setStatusWasChanged(false);
    })();
  }, [errand]);

  const { mutate } = useMutation(errandAPI.updateComment, {
    onMutate: () => {
      setButtonTimeout(true);
    },
    onSettled: (e) => {
      setTimeout(function () {
        // queryClient.invalidateQueries();
        setButtonTimeout(false);
        // queryClient.invalidateQueries(["errand"]);
      }, 1500);

      // console.log("ON SETTLED", e.data.raw.insertId);
      // hideModal();
    },
    onSuccess: () => {
      setHasInputChanged(false);
      toastSuccess("Ärende uppdaterades!");
    },
    onError: (e) => {
      toastError("Gick inte att uppdatera ärende!");
      queryClient.invalidateQueries(["kommentar", 1]);
    },
  });

  const sendErrandUpdate = useMutation(errandAPI.sendErrandUpdateToclient, {
    onSettled: (e) => {
      queryClient.invalidateQueries(["errand"]);
      setTimeout(function () {
        queryClient.invalidateQueries();
        setSendButtonTimeout(false);
        queryClient.invalidateQueries(["errand"]);
      }, 1000);

      // console.log("ON SETTLED", e.data.raw.insertId);
      // hideModal();
    },
    onSuccess: () => {
      toastSuccess("Ärende skickades till kund!");
    },
    onError: (e) => {
      toastError("Gick inte att skicka ärende!");
      queryClient.invalidateQueries(["kommentar", 1]);
    },
  });

  // function handleClose() {
  //   handleShowObject(0);
  // }

  /////////////////////////

  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(0);

  if (
    fields &&
    errandStatusList &&
    errandTypeList &&
    staffList &&
    errandLogToShow
  ) {
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            hideModal();
            setFields();
            setHasInputChanged(false);
            setErrandLogToShow([]);
            queryClient.invalidateQueries();
          }}
          centered
          size="xl"
          backdrop={hasInputChanged ? "static" : undefined}
        >
          <Modal.Header closeButton>
            <h5>
              Ärende: {fields.errand_id} Projekt:{" "}
              {fields.project_project_number}
            </h5>
          </Modal.Header>

          <Modal.Body>
            <form
              style={{ display: "grid", flexWrap: "wrap" }}
              // className="form-grid-auto"
              onSubmit={handleSubmit((data) => {
                let errand = {
                  id: fields.errand_id,
                  project: fields.errand_project,
                  responsible_person: fields.errand_responsible_person,
                  status: fields.errand_status,
                  type: fields.errand_type,
                  comment: fields.errand_comment,
                  logComments: logComment,
                  wasUpdatedBy: auth.user.id,
                  responsiblePersonWasChanged: responsiblePersonWasChanged,
                  statusWasChanged: statusWasChanged,
                  client_comment: fields.errand_client_comment,
                  time_delivery_plan: fields.errand_time_delivery_plan,
                  self_control_ue: fields.errand_self_control_ue,
                  general_status: fields.errand_general_status,
                  open_errands: fields.errand_open_errands,
                  client_mail: client_mail,
                };
                queryClient.invalidateQueries(["errands"]);
                setButtonTimeout(true);
                data.date = new Date().toLocaleDateString("sv-SE");
                mutate(errand);
              })}
            >
              <ErrandForm
                Controller={Controller}
                control={control}
                register={register}
                errandStatusList={errandStatusList}
                fields={fields}
                setFields={setFields}
                setHasInputChanged={setHasInputChanged}
                setStatusWasChanged={setStatusWasChanged}
                errandTypeList={errandTypeList}
                staffList={staffList}
                setResponsiblePersonWasChanged={setResponsiblePersonWasChanged}
                errandLogToShow={errandLogToShow}
                selectedID={selectedID}
                setOpen={setOpen}
                open={open}
                setSelectedID={setSelectedID}
              />
              <hr></hr>
              {fields.errand_control_to_client ? (
                <ClientControlView
                  fields={fields}
                  register={register}
                  setFields={setFields}
                  setHasInputChanged={setHasInputChanged}
                  setShowPreview={setShowPreview}
                  client_mail={client_mail}
                />
              ) : (
                <></>
              )}

              <div style={{ display: "flex" }}>
                <div style={{ flex: "1", display: "flex" }}>
                  {" "}
                  {fields.errand_control_to_client ? (
                    <Tooltip
                      title={
                        hasInputChanged ? (
                          <strong style={{ fontSize: "0.85rem" }}>
                            Du behöver spara innan du kan skicka till kund
                          </strong>
                        ) : client_mail ? undefined : (
                          <strong>Finns ingen email inlagd</strong>
                        )
                      }
                    >
                      <div style={{ marginRight: "1em" }}>
                        <Button
                          disabled={
                            hasInputChanged || sendButtonTimeout || !client_mail
                          }
                          onClick={() => {
                            sendErrandUpdate.mutate({
                              id: errandId,
                              client_mail: client_mail,
                            });
                            setSendButtonTimeout(true);
                          }}
                        >
                          Skicka till kund
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                  <div style={{ marginRight: "1em" }}>
                    {" "}
                    <Button onClick={() => setShowAddErrandLogView(true)}>
                      Lägg till logg
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={!hasInputChanged}
                      // onClick={() => setButtonTimeout(true)}
                      type="submit"
                      color="success"
                    >
                      Spara
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div style={{ maxHeight: "300px" }}></div>
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
        <ErrandLogModal
          errandLogToShow={errandLogToShow}
          errandId={errandId}
          showModal={showErrandLogModal}
          hideModal={() => setShowErrandLogModal(false)}
        />
        <AddErrandLogView
          userId={auth.user.id}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          queryClient={queryClient}
          errandId={errandId}
          showModal={showAddErrandLogView}
          hideModal={() => setShowAddErrandLogView(false)}
        />
        <SelfControlPreview
          errandToSend={fields}
          showModal={showPreview}
          hideModal={() => setShowPreview(false)}
          sender={auth.user}
        />
      </>
    );
  }
  return <></>;
}

export default ErrandView;
