import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import { ConstructionpartColumns } from "../../../components/dataTable/columns/ConstructionColumns";
import * as constructionpartAPI from "../../../api/register/constructionpartApi";
import ConstructionPartview from "./ConstructionPartView";
import AddConstructionPartview from "./AddConstructionPartView";
import { useQuery } from "react-query";
function ConstructionPartOverview() {
  const [displayAddConstructionPartView, setAddDisplayConstructionPartView] =
    useState(false);
  const [displayConstructionPartView, setDisplayConstructionPartView] =
    useState(false);

  const { data: constructionpartResponse } = useQuery(
    ["constructionparts"],
    () => constructionpartAPI.getAllConstructionpart()
  );
  return (
    <>
      <div>
        <Button onClick={() => setAddDisplayConstructionPartView(true)}>
          Lägg till byggdel
        </Button>
        <DataTable
          columns={ConstructionpartColumns}
          data={
            constructionpartResponse?.length > 0 ? constructionpartResponse : []
          }
          typeOfTable="constructionparts"
          handleShowObject={setDisplayConstructionPartView}
        />
      </div>
      <ConstructionPartview
        showModal={displayConstructionPartView}
        hideModal={() => setDisplayConstructionPartView(false)}
        constructionPartId={displayConstructionPartView}
      />
      <AddConstructionPartview
        showModal={displayAddConstructionPartView}
        hideModal={() => setAddDisplayConstructionPartView(false)}
      />
    </>
  );
}

export default ConstructionPartOverview;
