import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as api from "../../../api/register/supplierApi";
import * as contractApi from "../../../api/register/supplierContractApi";
import * as contactPersonAPI from "../../../api/register/contactPersonApi";
import SupplierForm from "./SupplierForm";
import { useQueryClient, useMutation } from "react-query";
import { ReqOptions } from "../../../components/customSelect/SelectOptions";
import Content from "../../../components/Content";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import AddSupplierContactPersonView from "./AddSupplierContactPerson";
import SupplierContactPersonView from "./SupplierContactPersonView";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function SupplierView() {
  let navigate = useNavigate();
  let supId = useParams();
  const [supplier, setSupplier] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [contractList, setContractList] = useState();
  const [contactPersonList, setContactPersonList] = useState();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayUpdateConfirmationModal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [
    displayAddSupplierContactPersonView,
    setDisplayAddSupplierContactPersonView,
  ] = useState(false);
  const [
    displaySupplierContactPersonView,
    setDisplaySupplierContactPersonView,
  ] = useState(false);

  useEffect(() => {
    contractApi.GetAll().then((contracts) => setContractList(contracts));
    if (supId.id) {
      api.getSupplier(supId.id).then((supplier) => setSupplier(supplier));
      contactPersonAPI
        .getAllContactPersonForSupplier(supId.id)
        .then((contacts) => setContactPersonList(contacts));
    }
  }, [supId.id, displayAddSupplierContactPersonView]);

  const [fields, setFields] = useState({ ...supplier });

  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      setFields({ ...supplier });
    })();
  }, [supplier]);

  const { mutate } = useMutation(api.updateSupplier, {
    onSettled: () => {
      queryClient.invalidateQueries();
      toastSuccess("Leverantör uppdaterades");
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "isActive") {
      value = parseInt(value);
    }
    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
  };

  const handleSelectCategory = (e, data) => {
    setFields({
      ...fields,
      supplierandcategory_supplier_category: data.value,
    });
    setHasInputChanged(true);
  };

  const confirmChange = () => {
    if (hasInputChanged) {
      mutate(fields);
      setDisplayUpdateConfirmationModal(false);
      setHasInputChanged(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      handleClose(0);
    }
  };

  const confirmClose = () => {
    navigate(`/register/`);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleSelectContract = (e, data) => {
    setFields({ ...fields, contract: data.value });
    setHasInputChanged(true);
  };

  const hideUpdateConfirmationModal = () => {
    setDisplayUpdateConfirmationModal(false);
  };

  const handleSelectActive = (e, data) => {
    setFields({ ...fields, isActive: data.value });
    setHasInputChanged(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      setDisplayUpdateConfirmationModal(true);
    }
  };

  const tabs = [
    { label: "Kontaktinformation", id: 1 },
    { label: "Kontaktpersoner", id: 2 },
    // { label: "Artiklar", id: 3 },
  ];
  const contactPersonColumns = [
    {
      Header: "Namn",
      accessor: "name",
      disableFilters: true,
    },
    {
      Header: "Roll",
      accessor: "role",
      disableFilters: true,
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: true,
    },
    {
      Header: "Telefon",
      accessor: "phone",
      disableFilters: true,
    },
  ];

  if (supplier && contactPersonList) {
    return (
      <div>
        <Content
          tabs={true}
          tabList={tabs}
          onClickTab={"onClickTab"}
          keepDataInForm={"keepDataInForm"}
          lastTab={1}
          view="Leverantör"
          title={"Leverantör: " + supplier.name}
        >
          <div value={1}>
            <SupplierForm
              fields={fields}
              setFields={setFields}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleClose={handleClose}
              handleSelectCategory={handleSelectCategory}
              handleSelectContract={handleSelectContract}
              contractList={contractList}
              contactPersonList={contactPersonList}
              ReqOptions={ReqOptions}
              handleSelectActive={handleSelectActive}
              confirmClose={confirmClose}
              hideConfirmationModal={hideConfirmationModal}
              hideUpdateConfirmationModal={hideUpdateConfirmationModal}
              confirmChange={confirmChange}
              displayConfirmationModal={displayConfirmationModal}
              displayUpdateConfirmationModal={displayUpdateConfirmationModal}
            />
          </div>
          <div value={2}>
            <div>
              <div style={{ marginBottom: "15px" }}>
                {" "}
                <Button
                  onClick={() => setDisplayAddSupplierContactPersonView(true)}
                >
                  Lägg till
                </Button>
              </div>

              <DataTable
                data={contactPersonList}
                columns={contactPersonColumns}
                typeOfTable={"suppliercontactperson"}
                handleShowObject={setDisplaySupplierContactPersonView}
              />
            </div>
          </div>

          <div value={3}></div>
        </Content>
        <AddSupplierContactPersonView
          showModal={displayAddSupplierContactPersonView}
          confirmModal={confirmChange}
          hideModal={() => setDisplayAddSupplierContactPersonView(false)}
          queryClient={queryClient}
          supplierId={supId.id}
        />
        <SupplierContactPersonView
          showModal={displaySupplierContactPersonView}
          confirmModal={confirmChange}
          hideModal={() => setDisplaySupplierContactPersonView(false)}
          queryClient={queryClient}
          contactPersonId={displaySupplierContactPersonView}
        />
      </div>
    );
  }
  return <div>Loading</div>;
}

export default SupplierView;
