import React, { useEffect, useState } from "react";
import ColumnFilter from "../../../components/dataTable/dataTableFilters/ColumnFilter";
import CreateMForm from "./CreateMForm";
import { useMutation, useQueryClient } from "react-query";
import * as municipalityAPI from "../../../api/project/municipalityApi";
import DeleteConfirmation from "../../../components/modals/DeleteConfirmation";
import UpdateConfirm from "../../../components/modals/UpdateConfirmation";
import DataTable from "../../../components/newDataTable/DataTable";
import { IconButton } from "@mui/material";
import { BsTrash } from "react-icons/bs";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function CreateMunicipalityView() {
  const [whatListToGetFormBackEnd, setWhatListToGetFromBackend] = useState([]);
  const [wasMutaded, setWasMutated] = useState(false);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);
  const [displayUpdateConfirm, setDisplayUpdateConfirm] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [objToUpdate, setObjectToUpdate] = useState();
  const [wasEdited, setWasEdited] = useState();

  const createMunicipality = useMutation((municipality) =>
    municipalityAPI.newMunicipality(municipality)
  );
  const deleteMunicipality = useMutation((id) =>
    municipalityAPI.deletedMunicipality(id)
  );
  useEffect(() => {
    municipalityAPI.getMunicipalityList().then((list) => {
      setWhatListToGetFromBackend(list);
    });
    setWasMutated(false);
    // alert("was triggered");
  }, [wasMutaded]);

  const queryClient = useQueryClient();
  const confirmDelete = () => {
    setWasMutated(true);
    deleteMunicipality.mutate(idToBeDeleted, {
      onSuccess: (e) => {
        setWasMutated(true);
        toastSuccess("Kommun raderades");
      },
      onSettled: () => {
        queryClient.invalidateQueries();
      },
      onError: (e) => {
        if (e.response.status === 500) {
          toastError("Gick ej att radera kommun!");
        }
      },
    });
    setWhatListToGetFromBackend([]);
    setDisplayDeleteConfirmation(false);
  };

  const confirmedUpdate = () => {
    municipalityAPI.updatedMunicipality(objToUpdate);
    setWasMutated(true);
    setDisplayUpdateConfirm(false);
    setWasEdited(false);
  };

  const fieldColumns = [
    {
      Header: "Namn",
      accessor: "label",
      Filter: ColumnFilter,
      filter: "includesString",
    },
    {
      Header: "",
      accessor: "value",
      id: "id",
      Cell: (props) => {
        return (
          <IconButton
            onClick={() => {
              setDisplayDeleteConfirmation(true);
              setIdToBeDeleted(props.row.original.value);
            }}
          >
            <BsTrash />
          </IconButton>
        );
      },
      disableFilters: true,
    },
  ];

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "2em" }}>
      <div style={{ flex: "1", minWidth: "300px" }}>
        <CreateMForm
          createMunicipality={createMunicipality}
          setWasMutated={setWasMutated}
        />
      </div>

      <div style={{ flex: "2", minWidth: "600px" }}>
        {whatListToGetFormBackEnd ? (
          <>
            <div style={{ gap: "1em" }}>
              <h5>Kommuner som finns i systemet</h5>
              <DataTable
                columns={fieldColumns}
                data={whatListToGetFormBackEnd}
                typeOfTable="municipality"
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <UpdateConfirm
        showModal={displayUpdateConfirm}
        confirmModal={confirmedUpdate}
        hideModal={() => setDisplayUpdateConfirm(false)}
      />
      <DeleteConfirmation
        showModal={displayDeleteConfirmation}
        confirmModal={confirmDelete}
        hideModal={() => setDisplayDeleteConfirmation(false)}
        message="Kommun?"
      />
    </div>
  );
}

export default CreateMunicipalityView;
