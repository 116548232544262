import React, { useEffect, useState } from "react";
import Button from "../../../components/buttons/Button";
import { Supplierlistcolumns } from "../../../components/dataTable/columns/SupplierlistColumns";
import DataTable from "../../../components/newDataTable/DataTable";
import * as supplierAPI from "../../../api/register/supplierApi";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
function SupplierOverview({ activeSuppliers }) {
  const navigate = useNavigate();

  const [supplierList, setSupplierList] = useState();

  const { data: supplierResponse } = useQuery(["supplier"], () =>
    supplierAPI.getAllSuppliers()
  );
  const { data: inactiveSupplierResponse } = useQuery(
    ["inactivesupplier"],
    () => supplierAPI.getAllInactiveSuppliers()
  );

  useEffect(() => {
    if (activeSuppliers) {
      setSupplierList(supplierResponse);
    } else {
      setSupplierList(inactiveSupplierResponse);
    }
  }, [supplierResponse, inactiveSupplierResponse, activeSuppliers]);

  return (
    <div>
      <Button onClick={() => navigate("/register/skapa/leverantor/")}>
        Lägg till leverantör
      </Button>

      <DataTable
        columns={Supplierlistcolumns}
        data={supplierList?.length > 0 ? supplierList : []}
        typeOfTable="supplier"
      />
    </div>
  );
}

export default SupplierOverview;
