import React, { useState, useEffect } from "react";
import * as categoryApi from "./../../../api/register/supplierCategoryApi";
import * as supplierApi from "./../../../api/register/supplierApi";
import * as contractApi from "./../../../api/register/supplierContractApi";
import { useMutation } from "react-query";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import { ReqOptions } from "../../../components/customSelect/SelectOptions";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
import Button from "../../../components/buttons/Button";
import "../../../styles/supplier/Supplier.css";
import { useNavigate } from "react-router-dom";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function AddSupplierview() {
  const [categoryList, setCategoryList] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [contactPersonList, setContactPersonList] = useState([]);
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    categoryApi
      .getAllCategoriesNames()
      .then((categories) => setCategoryList(categories));
    contractApi.GetAll().then((contracts) => setContractList(contracts));
  }, []);

  const supplier = {
    account: undefined,
    active: undefined,
    adress: undefined,
    bg: undefined,
    credit_time: undefined,
    contract: undefined,
    lead_time: undefined,
    name: undefined,
    order_mail: undefined,
    order_phonenumber: undefined,
    order_template: undefined,
    org_number: undefined,
    phonenumber: undefined,
    postadress: undefined,
    postnumber: undefined,
    reference: undefined,
    suppliercategory: undefined,
    contactpersons: [],
  };

  const [fields, setFields] = useState(supplier);

  const { mutate } = useMutation(supplierApi.newSupplier, {
    onSuccess: (e) => {
      toastSuccess("Leverantör skapades");
      navigate(`/register/leverantor/${e.data.raw.insertId}`);
    },
    onError: (e) => {
      console.log(e.response.status, "SUPPERE");
      if (e.response.status === 409)
        toastError("Finns redan leverantör med det namnet!");
      if (e.response.status === 500)
        toastError("Gick inte att skapa leverantör!");
    },
  });

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "suppliercategory") {
      value = parseInt(value);
    }
    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
  };

  // TODO
  const handleSelectActive = (e, data) => {
    setFields({ ...fields, active: data.value });
    setHasInputChanged(true);
  };
  const handleSelectContract = (e, data) => {
    setFields({ ...fields, contract: data.value });
    setHasInputChanged(true);
  };

  const handleSelectCategory = (e, data) => {
    setFields({ ...fields, suppliercategory: data.value });
    setHasInputChanged(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      fields.contactpersons = contactPersonList;
    }
    mutate(fields);
  };

  const IsObjectEmpty = (obj) => {
    for (let key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  };

  const handleClose = (e) => {
    e.preventDefault();
    const isMyObjectEmpty = IsObjectEmpty(fields);
    if (!isMyObjectEmpty) {
      if (hasInputChanged) {
        setDisplayConfirmationModal(true);
      } else {
        confirmClose();
      }
    } else if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };

  const confirmClose = () => {
    navigate(`/register/`);
  };

  // TODO
  const handleChangeContactPersonObject = (event) => {
    let { name, value } = event.target;
    let listToupdate = [...contactPersonList];
    const [number, contactheader] = name.split(";");
    if (!listToupdate[number]) {
      var emptyPerson = {
        name: "",
        role: "",
        email: "",
        phone: "",
      };
      listToupdate.push(emptyPerson);
    }
    setHasInputChanged(true);

    switch (contactheader) {
      case "name": {
        listToupdate[number].name = value;
        setContactPersonList(listToupdate);
        break;
      }
      case "email": {
        listToupdate[number].email = value;
        setContactPersonList(listToupdate);
        break;
      }
      case "role": {
        listToupdate[number].role = value;
        setContactPersonList(listToupdate);
        break;
      }
      case "phone": {
        listToupdate[number].phone = value;
        setContactPersonList(listToupdate);
        break;
      }
      default: {
        break;
      }
    }

    // setFields({
    //   ...fields,
    //   [fields.contactpersons[number].contactheader]: value,
    // });
    // fields.contactpersons[number].contactheader = value;
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  if (categoryList)
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              gap: "1.5em",
              flexWrap: "wrap",
              marginBottom: "1em",
            }}
          >
            <div
              style={{
                flex: "1",
              }}
            >
              <p id="section-title">Lägg till ny leverantör</p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1em",
                  marginTop: "1em",
                }}
              >
                <div className="newSupplier-form-left">
                  <div className="newSupplier-form-left--div1">
                    <InputField
                      required
                      label="Leverantör"
                      name="name"
                      value={fields.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div2">
                    <InputField
                      type="email"
                      required
                      label="Order Mail"
                      name="order_mail"
                      value={fields.order_mail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div3">
                    <InputField
                      label="Referens"
                      name="reference"
                      value={fields.reference}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div4">
                    {" "}
                    <InputField
                      type="email"
                      label="Mail"
                      name="email"
                      value={fields.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div5">
                    {" "}
                    <InputField
                      required
                      label="Telefon"
                      name="phonenumber"
                      value={fields.phonenumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div6">
                    {" "}
                    <InputField
                      label="Org. nummer"
                      name="org_number"
                      value={fields.org_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div7">
                    <Select
                      required
                      label="Kontrakt"
                      options={contractList}
                      value={contractList.find(
                        (item) => item.value === fields.contract
                      )}
                      onChange={handleSelectContract}
                    />
                  </div>
                  <div className="newSupplier-form-left--div8">
                    <Select
                      required
                      label="Aktiv"
                      value={ReqOptions.find(
                        (item) => item.value === fields.active
                      )}
                      options={ReqOptions}
                      onChange={handleSelectActive}
                    />
                  </div>
                  <div className="newSupplier-form-left--div9">
                    <Select
                      required
                      label="Huvudkategori"
                      options={categoryList}
                      value={categoryList.find(
                        (item) => item.value === fields.suppliercategory
                      )}
                      onChange={handleSelectCategory}
                    />
                  </div>
                </div>

                <div className="newSupplier-form-right">
                  <div className="newSupplier-form-right--div1">
                    <InputField
                      label="Adress"
                      name="adress"
                      value={fields.adress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div2">
                    <InputField
                      label="Postnummer"
                      name="postnumber"
                      value={fields.postnumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div3">
                    <InputField
                      label="Postadresss"
                      name="postadress"
                      value={fields.postadress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div4">
                    <InputField
                      label="Ledtid"
                      name="lead_time"
                      value={fields.lead_time}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div5">
                    <InputField
                      label="Kredit tid"
                      name="credit_time"
                      value={fields.credit_time}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div6">
                    <InputField
                      label="BG"
                      name="bg"
                      value={fields.bg}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div7">
                    <InputField
                      multiline
                      minRows={4}
                      label="Beställningsmallar/rutiner"
                      name="order_template"
                      value={fields.order_template}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ flex: "1", marginTop: "1em" }}>
              <p id="section-title">Lägg till en kontaktperson:</p>
              <div style={{ display: "grid", gap: "2em", minWidth: "370px" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1em",
                  }}
                >
                  <InputField
                    required
                    label={"Namn"}
                    name="0;name"
                    value={contactPersonList[0]?.name}
                    onChange={handleChangeContactPersonObject}
                  />
                  <InputField
                    label={"Roll"}
                    name="0;role"
                    value={contactPersonList[0]?.role}
                    onChange={handleChangeContactPersonObject}
                  />
                  <InputField
                    label={"Telefonnummer"}
                    name="0;phone"
                    value={contactPersonList[0]?.phone}
                    onChange={handleChangeContactPersonObject}
                  />
                  <InputField
                    required
                    label={"Mail"}
                    name="0;email"
                    value={contactPersonList[0]?.email}
                    onChange={handleChangeContactPersonObject}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "1em" }}>
            <Button type="submit" color="success">
              Lägg till
            </Button>
            <Button onClick={(e) => handleClose(e)}>Stäng</Button>
          </div>
        </form>
        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={confirmClose}
          hideModal={hideConfirmationModal}
        />
      </div>
    );
}

export default AddSupplierview;
