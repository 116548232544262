import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import UpdateConfirmation from "../../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../../components/modals/CloseConfirmation";
import InputField from "../../../../components/Inputs/InputField";
import DateSelect from "../../../../components/Inputs/DateSelect";
import Button from "../../../../components/buttons/Button";
import Select from "../../../../components/Inputs/Select";
import "../../../../styles/ata/Ata.css";
import { UnitOptions } from "../../../../components/customSelect/SelectOptions";
import Checkbox from "@mui/material/Checkbox";

function AtaForm({
  setFields,
  fields,
  setHasInputChanged,
  handleSubmit,
  handleChange,
  handleClose,
  confirmChange,
  hideUpdateConfirmationModal,
  setDisplayConfirmationModal,
  displayConfirmationModal,
  displayUpdateConfirmationModal,
  confirmClose,
  suppliers,
  users,
  hasInputChanged,
  ataStatusList,
}) {
  const [sumWithSupCharge, setSumWithSupCharge] = useState();

  useEffect(() => {
    if (fields.ata_supplier_surcharge > 0) {
      setFields({
        ...fields,
        ata_sum_with_supplier_carge:
          (fields.ata_supplier_surcharge / 100) *
            fields.ata_price *
            fields.ata_quantity +
          fields.ata_price * fields.ata_quantity,
      });

      setSumWithSupCharge(
        (fields.ata_supplier_surcharge / 100) *
          fields.ata_price *
          fields.ata_quantity +
          fields.ata_price * fields.ata_quantity
      );
    } else
      setFields({
        ...fields,
        ata_sum_with_supplier_carge: 0,
      });
  }, [fields.ata_supplier_surcharge, fields, setFields]);

  useEffect(() => {
    if (fields.ata_client_surcharge > 0) {
      if (sumWithSupCharge > 0) {
        setFields({
          ...fields,
          ata_sum_with_client_charge:
            (fields.ata_client_surcharge / 100) * sumWithSupCharge +
            sumWithSupCharge,
        });
      }
    } else
      setFields({
        ...fields,
        ata_sum_with_client_charge: 0,
      });
  }, [fields.ata_client_surcharge, fields, setFields, sumWithSupCharge]);

  const handleSelectUnit = (e, value) => {
    setHasInputChanged(true);
    setFields({ ...fields, ata_unit: value.value });
  };
  const handleSelectNotfiedSupplier = (e, value) => {
    setHasInputChanged(true);

    setFields({ ...fields, ata_notified_by_supplier: value.value });
  };
  const handleSelectConfirmedBy = (e, value) => {
    setHasInputChanged(true);

    setFields({ ...fields, ata_confirmed_by: value.value });
  };
  const handleSelectSupplier = (e, value) => {
    setHasInputChanged(true);

    setFields({ ...fields, ata_supplier: value.value });
  };

  const handleSelectStatus = (e, value) => {
    setHasInputChanged(true);

    setFields({ ...fields, ata_status: value.value });
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  if (fields)
    return (
      <div className="wrapper">
        <div>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
              <div style={{ flex: "2" }}>
                <div
                  style={{ display: "flex", gap: "1em", marginBottom: "1em" }}
                >
                  <InputField
                    required={true}
                    label={"Märke"}
                    name={"ata_label"}
                    value={fields.ata_label}
                    onChange={handleChange}
                    maxLength={45}
                  />
                  <Select
                    required
                    label="status på ÄTA"
                    options={ataStatusList}
                    value={ataStatusList.find(
                      (item) => item.value === fields.ata_status
                    )}
                    onChange={handleSelectStatus}
                  />
                </div>
                <div className="form-grid-auto">
                  <DateSelect
                    required={true}
                    label={"Beställt"}
                    value={fields.ata_order_date}
                    onChange={(value) => {
                      setFields({ ...fields, ata_order_date: value });
                    }}
                  />
                  <Select
                    required={true}
                    label="ÄTA aviserad av"
                    options={suppliers}
                    onChange={handleSelectNotfiedSupplier}
                    value={
                      fields?.ata_notified_by_supplier !== ""
                        ? suppliers.find(
                            (item) =>
                              item.value === fields.ata_notified_by_supplier
                          )
                        : ""
                    }
                  />
                  <Select
                    label="Godkänd av"
                    options={users}
                    onChange={handleSelectConfirmedBy}
                    value={
                      fields?.ata_confirmed_by !== ""
                        ? users.find(
                            (item) => item.value === fields.ata_confirmed_by
                          )
                        : ""
                    }
                  />
                  <DateSelect
                    label={"Godkänd datum"}
                    value={fields.ata_confirmed_date}
                    onChange={(value) => {
                      setFields({ ...fields, ata_confirmed_date: value });
                    }}
                  />
                  <InputField
                    required={true}
                    type="number"
                    label={"Antal"}
                    name={"ata_quantity"}
                    onChange={handleChange}
                    value={fields.ata_quantity}
                  />
                  <Select
                    required={fields.quantity ? true : false}
                    label="Enhet"
                    options={UnitOptions}
                    onChange={handleSelectUnit}
                    value={
                      fields?.ata_unit !== ""
                        ? UnitOptions.find(
                            (item) => item.value === fields.ata_unit
                          )
                        : ""
                    }
                  />
                  <InputField
                    required={true}
                    type="number"
                    label={"Pris"}
                    name={"ata_price"}
                    onChange={handleChange}
                    value={fields.ata_price}
                  />
                  <InputField
                    readOnly={true}
                    label={"Summa"}
                    value={
                      fields.ata_price && fields.ata_quantity
                        ? fields.ata_price * fields.ata_quantity
                        : 0
                    }
                  />
                </div>

                <div style={{ display: "flex", marginTop: "1em", gap: "1em" }}>
                  <div style={{ display: "grid", flex: "1" }}>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        name="ata_bill_towards_supplier"
                        label={"Klarmarkerad"}
                        type="checkbox"
                        onChange={handleChange}
                        checked={fields.ata_bill_towards_supplier === 1}
                        // value={fields}
                      ></Checkbox>

                      <div style={{ marginTop: "10px", marginRight: "103px" }}>
                        <p>Faktureras av leverantör</p>
                      </div>
                      <div style={{ width: "330px" }}>
                        <Select
                          required={fields.ata_bill_towards_supplier === 1}
                          label="Leverantör"
                          options={suppliers}
                          onChange={handleSelectSupplier}
                          value={
                            fields?.ata_supplier !== ""
                              ? suppliers.find(
                                  (item) => item.value === fields.ata_supplier
                                )
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        name="ata_bill_towards_client"
                        label={"Klarmarkerad"}
                        type="checkbox"
                        onChange={handleChange}
                        checked={fields.ata_bill_towards_client === 1}
                        // value={fields}
                      ></Checkbox>
                      <div style={{ marginTop: "10px" }}>
                        <p>Fakturera kund</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "grid", gap: "1em", flex: "1" }}>
                    <InputField
                      required={fields.ata_bill_towards_supplier === 1}
                      type="number"
                      label={"Påslag"}
                      name={"ata_supplier_surcharge"}
                      onChange={handleChange}
                      value={fields.ata_supplier_surcharge}
                    />
                    <InputField
                      required={fields.ata_bill_towards_client === 1}
                      type="number"
                      label={"Påslag"}
                      name={"ata_client_surcharge"}
                      onChange={handleChange}
                      value={fields.ata_client_surcharge}
                    />
                  </div>
                  <div style={{ flex: "1" }}>
                    <InputField readOnly={true} value={sumWithSupCharge} />
                    <div style={{ marginTop: "1em" }}>
                      <InputField
                        readOnly={true}
                        value={fields.ata_sum_with_client_charge}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ flex: "1" }}>
                <InputField
                  required
                  label={"Notering"}
                  name={"notes"}
                  onChange={handleChange}
                  value={fields.notes}
                  multiline
                  minRows={11}
                  maxLength={1000}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "1em", marginTop: "1em" }}>
              <Button disabled={!hasInputChanged} color="success" type="submit">
                Spara
              </Button>
              <Button type="button" onClick={() => handleClose(0)}>
                Stäng
              </Button>
            </div>
          </form>
        </div>

        <UpdateConfirmation
          showModal={displayUpdateConfirmationModal}
          confirmModal={confirmChange}
          hideModal={hideUpdateConfirmationModal}
        />
        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={confirmClose}
          hideModal={hideConfirmationModal}
        />
      </div>
    );
}

export default AtaForm;
