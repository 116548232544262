import React, { useState, useEffect } from "react";
import Content from "../../components/Content";
import DataTable from "../../components/newDataTable/DataTable";
import { Paper } from "@mui/material";
import { allErrandColumns } from "../../components/dataTable/columns/ErrandColumns";
import { useQueryClient } from "react-query";
import ErrandView from "../project/ProjectDetailTabViews/errand/ErrandView";
import * as errandAPI from "../../api/project/errandApi";
import { useQuery } from "react-query";
import { useAuthContext } from "../../hooks/useAuthContext";
function ErrandOverview() {
  const { auth } = useAuthContext();
  const [lastTab, setLastTab] = useState(1);
  const [errandLogId, setErrandLogId] = useState();
  const [errands, setErrands] = useState([]);

  let queryClient = useQueryClient();
  useEffect(() => {
    localStorage.clear();
  });

  const { data: errandData } = useQuery(["errand"], () => errandAPI.getAll());

  useEffect(() => {
    if (typeof errandData === "object") {
      setErrands(errandData);
    }
  }, [errandData]);

  const initialState = {
    filters: [
      {
        id: "responsible_person",
        value: auth.user.firstName + " " + auth.user.lastName,
      },
    ],
  };

  const tabs = [{ label: "Ärenden", id: 1 }];
  return (
    <>
      <Content tabs={true} tabList={tabs} lastTab={lastTab}>
        <div value={1}>
          <DataTable
            columns={allErrandColumns}
            data={errands}
            typeOfTable="errand"
            handleShowObject={setErrandLogId}
            initialState={initialState}
          />
        </div>
        <div value={2}>
          <Paper></Paper>
        </div>
      </Content>
      <ErrandView
        queryClient={queryClient}
        errandId={errandLogId}
        showModal={errandLogId}
        hideModal={() => {
          setErrandLogId(null);
        }}
      />
    </>
  );
}

export default ErrandOverview;
