import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const ProjectLogColumns = [
  {
    Header: "Datum",
    table: "projectlog",
    accessor: "project_log_timestamp",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Loggades av",
    table: "projectlog",
    accessor: "registered_by",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Statushändelse",
    table: "projectlog",
    accessor: "project_log_previous_status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kommentar",
    table: "projectlog",
    accessor: "project_log_comment",
    disableFilters: true,
  },
];
