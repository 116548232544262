import React, { useState } from "react";
import CustomSelect from "../../customSelect/CustomSelect";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { Tooltip } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
function ReadOnlyRow({
  item,
  i,
  handleEditClick,
  moduleList,
  isRestAble,
  handleAddToRestOrderCheckBox,
  handleDeleteClick,
  blockPurchaseEdit,
  indexInRows,
}) {
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);

  const handleOnClickRestIcon = (id) => {
    window.location = `/projektoversikt/inkop/${id}`;
  };

  return (
    <>
      <TableRow style={item.isRested === 1 ? { background: "#eb630d9e" } : {}}>
        <TableCell scope="row" style={{ textAlign: "center" }}>
          {i + 1}
          {item.rested_order ? (
            <Tooltip
              title={
                <h5>
                  {`Orderraden har blivit restad mot order med id ${item.rested_order}`}
                </h5>
              }
            >
              <IconButton
                onClick={() => handleOnClickRestIcon(item.rested_order)}
              >
                <ReportProblemIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>{item.articlenumber}</TableCell>
        <TableCell>{item.articlename}</TableCell>
        <TableCell>
          {item.quantity}
          {""} {item.order_unit}
        </TableCell>
        <TableCell>{item.length}</TableCell>
        <TableCell>{item.width}</TableCell>
        <TableCell>
          {item.total_amount} {item.price_unit}
        </TableCell>
        <TableCell>{item.purchase_price}</TableCell>
        <TableCell>
          {(item.total_amount * item.purchase_price).toFixed(2)}
        </TableCell>
        <TableCell>{item.calculation ? item.calculation : ""}</TableCell>
        <TableCell>{item.appendix}</TableCell>
        <TableCell>
          <CustomSelect
            optionList={moduleList}
            placeholder="Välj modul"
            defaultValue={item.module}
            isDisabled={true}
            name={i}
          />
        </TableCell>
        {isRestAble ? (
          <TableCell style={{ textAlign: "right" }}>
            <input
              type="checkbox"
              onChange={(e) => handleAddToRestOrderCheckBox(e)}
              checked={item.isRested === 1}
              value={i}
            ></input>
          </TableCell>
        ) : null}
        <TableCell style={{ textAlign: "center" }}>
          {!blockPurchaseEdit ? (
            <IconButton onClick={(e) => handleEditClick(e, indexInRows)}>
              <FiEdit />
            </IconButton>
          ) : null}
        </TableCell>
        {/* <TableCell style={{ textAlign: "center" }}>
          <IconButton onClick={(e) => setDisplayDeleteConfirmation(true)}>
            <BsTrash />
          </IconButton>
        </TableCell> */}
      </TableRow>
      <DeleteConfirmation
        showModal={displayDeleteConfirmation}
        confirmModal={() =>
          handleDeleteClick(indexInRows, item.id, setDisplayDeleteConfirmation)
        }
        hideModal={() => setDisplayDeleteConfirmation(false)}
        centered={"centered"}
      />
    </>
  );
}

export default ReadOnlyRow;
