import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmMoveRows = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
  fromOrder,
  ToOrder,
  NumberOfRows,
}) => {
  if (ToOrder)
    return (
      <Modal
        size={"lg"}
        show={showModal}
        onHide={hideModal}
        style={{ top: "40%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bekräfta flytt av rader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "3px" }}
          >
            <div> Är du säker på att du vill flytta </div>
            <div>
              {" "}
              antal <strong>{NumberOfRows}</strong> rader från{" "}
              <strong>Order: {fromOrder}</strong> till{" "}
              <strong>{ToOrder.label}</strong>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={() => confirmModal()}>
            Bekräfta
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default ConfirmMoveRows;
