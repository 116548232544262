export const validateOrder = (fields, editRowIsOpen, isNewOrder) => {
  const errors = {};
  if (!fields.supplierId) {
    errors.supplier = "Måste välja en leverantör";
  }
  // if (!isNewOrder) {
  //   if (!fields.order_mail) {
  //     errors.order_mail = "Måste registrera en giltig mail under leverantören";
  //   }
  // }

  // if (!fields.requested_delivery_date) {
  //   errors.requested_delivery_date = "Måste ange ett önskat leveransdatum";
  // }

  if (!fields.confirmed_delivery_date && fields.status === "Levererad") {
    errors.confirmed_delivery_date = "Måste ange ett bekräftat leveransdatum";
  }

  if (!fields.status) {
    errors.status = "Måste välja status";
  }

  if (!fields.account) {
    errors.account = "Måste välja account";
  }
  // if (!fields.referens) {
  //   errors.referens = "Måste välja referens";
  // }
  if (editRowIsOpen) {
    errors.editRow =
      "Du editerar en rad, vänligen stäng den innan försök att spara";
  }

  return errors;
};

export const validateOrderRow = (orderRowToAdd, articleToDisplay) => {
  const errors = {};
  if (
    (articleToDisplay.length_required === 1 &&
      orderRowToAdd.length === undefined) ||
    (articleToDisplay.length_required === 1 && orderRowToAdd.length === "")
  ) {
    errors.length = "Artikeln kräven inmatning för längd";
  }
  if (
    (articleToDisplay.width_required === 1 &&
      orderRowToAdd.width === undefined) ||
    (articleToDisplay.width_required === 1 && orderRowToAdd.width === "")
  ) {
    errors.width = "Artikeln kräven inmatning för bredd";
  }
  if (
    (articleToDisplay.appendix_required === 1 &&
      orderRowToAdd.appendix === undefined) ||
    (articleToDisplay.appendix_required === 1 && orderRowToAdd.appendix === "")
  ) {
    errors.appendix = "Artikeln kräven inmatning för bilaga";
  }
  if (orderRowToAdd.quantity === undefined || orderRowToAdd.quantity === "") {
    errors.quantity = "Måste ange antal";
  }
  if (isNaN(orderRowToAdd.quantity)) {
    errors.quantitynotanumber = "Måste vara ett nummer";
  }

  if (orderRowToAdd.module === undefined || orderRowToAdd.module === "") {
    errors.module = "Måste välja modul";
  }

  return errors;
};

export const IsObjectEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] !== undefined) {
      return false;
    }
  }
  return true;
};
