import React from "react";
import StatusRowView from "../StatusRowView";
function StatusGroupView({
  group,
  groupIndex,
  implementRules,
  editView,
  handleChangeStatusRow,
  clientDeliveryDate,
  factoryDeliveryDate,
}) {
  if (group)
    return (
      <div
        className="project_overview_vertical_table_container"
        style={{
          padding: "0.5em",
          backgroundColor: "white",
          borderRadius: "5px",
          height: "fit-content",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
      >
        <div
          style={{
            display: "flex",
            fontWeight: "500",
            fontSize: "1.2rem",
            padding: "0.5em",
            borderBottom: "thin solid lightgrey",
            marginBottom: ".5em",
            backgroundColor: "#1976d2",
            borderRadius: "5px",
            color: "white",
          }}
        >
          {group.name}
        </div>
        <div>
          <table
            style={{
              gap: "0.5em",
              padding: "0.05em",
              fontSize: "12px",
            }}
          >
            <thead>
              <tr style={{ opacity: "0.8" }}>
                <th></th>
                <th style={{ textAlign: "right", fontWeight: "500" }}>
                  {group.dateTitle1}
                </th>
                <th style={{ textAlign: "right", fontWeight: "500" }}>
                  {group.dateTitle2}
                </th>
              </tr>
            </thead>
            <tbody>
              {group.statusRows.map((row, index) => {
                return row.is_shown ? (
                  <StatusRowView
                    key={index}
                    groupIndex={groupIndex}
                    rowIndex={index}
                    row={row}
                    implementRules={implementRules}
                    editView={editView}
                    handleChangeStatusRow={handleChangeStatusRow}
                    clientDeliveryDate={clientDeliveryDate}
                    factoryDeliveryDate={factoryDeliveryDate}
                  />
                ) : (
                  <>
                    {editView ? (
                      <StatusRowView
                        key={index}
                        groupIndex={groupIndex}
                        rowIndex={index}
                        row={row}
                        implementRules={implementRules}
                        editView={editView}
                        handleChangeStatusRow={handleChangeStatusRow}
                        clientDeliveryDate={clientDeliveryDate}
                        factoryDeliveryDate={factoryDeliveryDate}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default StatusGroupView;
