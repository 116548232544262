import React, { useState } from "react";
import { useMutation } from "react-query";
import * as api from "../../../../api/project/moduleApi";
import InputField from "../../../../components/Inputs/InputField";
import Button from "../../../../components/buttons/Button";
import CloseFonfirmation from "../../../../components/modals/CloseConfirmation";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";

function AddmoduleView({ setAddModuleView, projektnr, projectId }) {
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const navigate = useNavigate();
  const [fields, setFields] = useState({
    wall_designation: "",
    module_stage: "",
    material_withdrawal: null,
    module_number: projektnr,
    project: Number(projectId),
    self_produced: 0,
  });

  const { mutate } = useMutation(api.newModule, {
    onSuccess: (e) => {
      console.log("ON SETTLED");
      toastSuccess("Modul skapades!");

      navigate(`/projektoversikt/module/${e.data.raw.insertId}/${0}`);
    },
    onError: (e) => {
      if (e.response.status === 409) {
        toastError("Finns redan modul med den beteckningen!");
      }
      if (e.response.status === 500) {
        toastError("Gick ej att skapa modul!");
      }
    },
  });

  const handleChange = (event) => {
    event.preventDefault();
    setHasInputChanged(true);
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      if (name === "self_produced") {
        if (fields.self_produced === 0) {
          setFields({ ...fields, self_produced: 1 });
        } else {
          setFields({ ...fields, self_produced: 0 });
        }
      }
    } else setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  const handeleClose = () => {
    if (hasInputChanged) {
      setDisplayCloseConfirmationModal(true);
    } else {
      setAddModuleView(0);
    }
  };
  const confirmClose = () => {
    setAddModuleView(0);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "grid",
            gap: "1em",
            marginBottom: "1em",
            maxWidth: "500px",
          }}
        >
          <InputField
            required
            label={"Väggbet"}
            name="wall_designation"
            onChange={handleChange}
            value={fields.wall_designation}
            maxLength={255}
          />
          <div>
            <InputField
              required
              label={"Vagga"}
              name="module_stage"
              onChange={handleChange}
              value={fields.module_stage}
              maxLength={45}
            />
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px", marginTop: "8px" }}>
              <p>Egentillverkad</p>
            </div>
            <Checkbox
              name="self_produced"
              label={"self_produced"}
              type="checkbox"
              onChange={handleChange}
              checked={fields.self_produced === 1}
              // value={fields}
            >
              Egentillverkad
            </Checkbox>
          </div>
        </div>

        <div style={{ display: "flex", gap: "1em" }}>
          <Button color="success" type="submit">
            Skapa
          </Button>
          <Button onClick={handeleClose}>Stäng</Button>
        </div>
      </form>
      <CloseFonfirmation
        showModal={displayCloseConfirmationModal}
        confirmModal={confirmClose}
        hideModal={() => setDisplayCloseConfirmationModal(false)}
      />
    </>
  );
}

export default AddmoduleView;

// <div>
// <div>
//   <div className="card-body">
//     {savingAnimation ? <CheckBoxAnimation /> : <div></div>}
//     <div className="card-body">
//       <form onSubmit={handleSubmit}>
//         <div className="input-group mb-3 "></div>
//         <div className="input-group-prepend">
//           <div className="col-md-1 mb-3">
//             <label
//               className="input-group-text"
//               style={{ width: "15em" }}
//             >
//               Väggbet
//             </label>
//             <input
//               style={{ width: "15em" }}
//               className="form-control"
//               name="wall_designation"
//               type="text"
//               placeholder="Ange vagga för modul"
//               onChange={handleChange}
//               value={fields.wall_designation}
//             />
//           </div>
//         </div>{" "}
//         <div style={{ width: "15em" }}>
//           <input
//             className="form-control"
//             name="category_name"
//             type="text"
//             onChange={handleChange}
//             value={
//               fields.wall_designation +
//               "," +
//               fields.module_number +
//               "-" +
//               fields.module_stage
//             }
//             disabled
//           />
//         </div>
//         <p style={{ color: "#FF0000" }}>{formErrors.category_name}</p>
//         <div className="input-group-prepend">
//           <div className="col-md-1 mb-3">
//             <label
//               className="input-group-text"
//               style={{ width: "15em" }}
//             >
//               Vagga
//             </label>
//             <input
//               style={{ width: "15em" }}
//               className="form-control"
//               name="module_stage"
//               type="text"
//               placeholder="ange väggbet för modul"
//               onChange={handleChange}
//               value={fields.module_stage}
//             />
//           </div>
//         </div>
//         <div className="input-group-prepend">
//           <div className="col-md-1">
//             <label
//               className="input-group-text"
//               style={{ width: "15em" }}
//             >
//               Material uttag
//             </label>
//             <div style={{ width: "15em" }}>
//               <DatePickerComponent
//                 fields={fields.material_withdrawal}
//                 handleChange={handleChange}
//                 name={"material_withdrawal"}
//                 customInput={
//                   <CustomInput value={fields.material_withdrawal} />
//                 }
//               />
//             </div>
//           </div>
//         </div>
//         <button
//           className="btn btn-primary"
//           type="submit"
//           style={{ marginTop: "1em" }}
//         >
//           Spara ändringar
//         </button>
//         <div className="col-md-2 mt-3">
//           <button
//             type="button"
//             className="btn btn-primary btn-sm"
//             onClick={() => setAddModuleView(0)}
//           >
//             Stäng
//           </button>
//         </div>
//       </form>
//     </div>
//   </div>
// </div>
// </div>
