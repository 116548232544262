import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as api from "../../../../api/project/projectApi";
import "./AdressAndContactsInfo.css";
import AdressAndContactsForm from "./AdressAndContactsForm";
import * as projectStatusAPI from "../../../../api/ownFields/projectStatusApi";
import * as projectTypeAPI from "../../../../api/ownFields/projectTypeApi";
import * as projectPhaseAPi from "../../../../api/ownFields/projectPhaseApi";
import * as leadChannelAPI from "../../../../api/ownFields/leadChannelApi";
import * as staffAPI from "../../../../api/staff/staffApi";
import * as municipalityAPI from "../../../../api/project/municipalityApi";
import * as projectDeliveryTypeAPI from "../../../../api/ownFields/projectDeliveryTypeAPI";
import * as projectIndexTypeAPI from "../../../../api/ownFields/projectIndexTypeAPI";
import * as progressBarAPI from "../../../../api/project/progressBarApi";
import * as userAPI from "../../../../api/user/userAPI";
import logo from "../../../../assets/gifs/loading.gif";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
import { useForm } from "react-hook-form";

function AdressAndContactsInfo({
  project,
  setPreventPageChange,
  preventPageChange,
}) {
  const [fields, setFields] = useState();
  const [projectStatusList, setProjectStatusList] = useState();
  const [projectTypeList, setProjectTypeList] = useState();
  const [projectPhaseList, setProjectPhaseList] = useState();
  const [projectIndexTypeList, setProjectIndexTypeList] = useState();
  const [projectDeliveryTypeList, setProjectDeliveryTypeList] = useState();
  const [leadChannelList, setLeadChannelList] = useState();
  const [projectLeaderList, setProjectLeaderList] = useState();
  const [userList, setUserList] = useState();
  const [municipalityList, setMunicipalityList] = useState();
  const [displayUpdateConfirmationmodal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [steps, setSteps] = useState();
  const { register, handleSubmit, control, reset } = useForm();

  const [newStatus, setNewStatus] = useState();
  const [newLog, setNewLog] = useState();

  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      setFields({ ...project });
    })();
  }, [project]);

  useEffect(() => {
    reset({ ...project });
  }, [project, reset]);

  useEffect(() => {
    projectStatusAPI.getStatusList().then((list) => setProjectStatusList(list));
    projectPhaseAPi.getPhaseList().then((list) => setProjectPhaseList(list));
    projectTypeAPI
      .getProjectTypeList()
      .then((list) => setProjectTypeList(list));
    leadChannelAPI
      .getLeadChannelList()
      .then((list) => setLeadChannelList(list));

    staffAPI.getProjectLeaderList().then((list) => setProjectLeaderList(list));
    userAPI.userList().then((list) => {
      setUserList(list);
    });
    municipalityAPI
      .getMunicipalityList()
      .then((list) => setMunicipalityList(list));
    projectDeliveryTypeAPI
      .getDeliveryTypeList()
      .then((list) => setProjectDeliveryTypeList(list));
    projectIndexTypeAPI
      .getIndexTypeList()
      .then((list) => setProjectIndexTypeList(list));
  }, []);

  const createNewProjectLog = useMutation(api.newProjectLog, {
    onSettled: () => {
      console.log("ON SETTLED");
      queryClient.invalidateQueries(["projectlog"]);
    },
    onError: () => {
      console.log("error fron mutate");
    },
  });

  const { mutate } = useMutation(api.updateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("projectstatusgroups");
      console.log("ON SETTLED");
      setPreventPageChange(false);
      setDisplayUpdateConfirmationModal(false);
      if (newLog) {
        createNewProjectLog.mutate(newLog);
        setNewStatus();
        setNewLog();
      }
      toastSuccess("Projektet uppdaterades");
    },
    onSettled: () => {
      setPreventPageChange(false);
      queryClient.invalidateQueries();
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFields({ ...fields, [name]: value });
    setPreventPageChange(true);
  };

  useEffect(() => {
    progressBarAPI.getProgressStep().then((list) => setSteps(list));
  }, []);

  if (
    !projectStatusList ||
    !userList ||
    !projectTypeList ||
    !projectPhaseList ||
    !projectLeaderList ||
    !municipalityList ||
    !projectIndexTypeList ||
    !projectDeliveryTypeList ||
    !fields
  )
    return (
      <div style={{ position: "absolute", left: "40%", top: "400%" }}>
        <img
          src={logo}
          alt="loading..."
          style={{ height: "200px", width: "200px" }}
        />
      </div>
    );

  if (
    projectStatusList &&
    userList &&
    projectTypeList &&
    projectPhaseList &&
    projectLeaderList &&
    municipalityList &&
    projectIndexTypeList &&
    projectDeliveryTypeList &&
    fields
  ) {
    return (
      <>
        <AdressAndContactsForm
          fields={fields}
          setFields={setFields}
          mutate={mutate}
          handleChange={handleChange}
          projectStatusList={projectStatusList}
          projectTypeList={projectTypeList}
          projectPhaseList={projectPhaseList}
          staffList={userList}
          leadChannelList={leadChannelList}
          municipalityList={municipalityList}
          projectIndexTypeList={projectIndexTypeList}
          projectDeliveryTypeList={projectDeliveryTypeList}
          displayUpdateConfirmationmodal={displayUpdateConfirmationmodal}
          setDisplayUpdateConfirmationModal={setDisplayUpdateConfirmationModal}
          newStatus={newStatus}
          setNewStatus={setNewStatus}
          setNewLog={setNewLog}
          steps={steps}
          setPreventPageChange={setPreventPageChange}
          preventPageChange={preventPageChange}
          register={register}
          handleSubmit={handleSubmit}
          control={control}
        />
      </>
    );
  }
  // return <div>Loading...</div>
}

export default AdressAndContactsInfo;
