import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import * as supplierAPI from "../../../../../api/register/supplierApi";
import * as contactPersonAPI from "../../../../../api/register/contactPersonApi";
import {
  toastError,
  toastSuccess,
} from "../../../../../components/toast/toastFunctions";
const AddContactPersonView = ({
  showModal,
  hideModal,
  projectId,
  queryClient,
}) => {
  const [suppliers, setSuppliers] = useState();
  const [chosenSupplier, setChosenSuppliers] = useState();
  const [chosenContactPerson, setChosenContactPerson] = useState();
  const [contactPersons, setContactPersons] = useState([]);
  const [contactPersonFromDb, setContactPersonsFromDb] = useState();
  const [comment, setComment] = useState("");
  const handleSelectSupplier = (e, value) => {
    setChosenSuppliers(value.value);
  };
  const handleSelectContactPerson = (e, value) => {
    setChosenContactPerson(value.id);
  };

  useEffect(() => {
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSuppliers(suppliers));
  }, []);

  useEffect(() => {
    if (chosenSupplier)
      contactPersonAPI
        .getAllContactPersonForSupplier(chosenSupplier)
        .then((contactPersons) => setContactPersonsFromDb(contactPersons));
  }, [chosenSupplier]);

  useEffect(() => {
    let listofContacts = [];
    if (contactPersonFromDb) {
      contactPersonFromDb.map((person) => {
        let object = {
          value:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          label:
            "Namn: " +
            person.name +
            ". Email: " +
            person.email +
            ". Telefon: " +
            person.phone +
            ". Roll: " +
            person.role,
          id: person?.id,
          name: person?.name,
          email: person?.email,
          role: person?.role,
          phone: person?.phone,
        };
        listofContacts.push(object);
      });
      setContactPersons(listofContacts);
    }
  }, [contactPersonFromDb]);

  const { mutate } = useMutation(contactPersonAPI.addContactPersonToproject, {
    onSettled: () => {
      queryClient.invalidateQueries();
      setTimeout(function () {
        queryClient.invalidateQueries();
        hideModal();
      }, 1000);
      toastSuccess("Kontaktperson lades till");
    },
    onError: () => {
      toastError("Gick inte att lägga till kontaktperson");
    },
  });
  const addContactPerson = () => {
    let contactPerson = {
      project: projectId,
      supplier_contact_person: chosenContactPerson,
      comment: comment,
    };
    mutate(contactPerson);
  };
  if (suppliers)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till kontaktperson</Modal.Header>

        <Modal.Body>
          <div>
            <div style={{ marginBottom: "1em" }}>
              {" "}
              <Select
                label={"Välj leverantör"}
                required={true}
                onChange={handleSelectSupplier}
                options={suppliers}
                value={suppliers.find((item) => item.value === chosenSupplier)}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              {" "}
              <Select
                label={"Välj kontaktperson"}
                required={true}
                onChange={handleSelectContactPerson}
                value={contactPersons.find(
                  (item) => item.value === chosenContactPerson
                )}
                options={contactPersons}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              {" "}
              <InputField
                label={"Kommentar"}
                multiline={true}
                minRows={4}
                name="comment"
                onChange={(e) => setComment(e.target.value)}
                maxLength={255}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              addContactPerson();
            }}
          >
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AddContactPersonView;
