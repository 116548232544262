import React, { useEffect, useState } from "react";
import DateSelect from "../../../../components/Inputs/DateSelect";
import { dateRuleVerifier } from "./utils/RuleVerification";
import { Switch } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
function StatusRowView({
  groupIndex,
  rowIndex,
  row,
  implementRules,
  editView,
  handleChangeStatusRow,
  clientDeliveryDate,
  factoryDeliveryDate,
}) {
  const [ruleColors, setRuleColors] = useState({
    rule1: null,
    rule2: null,
  });
  const [dateClicked, setDateClicked] = useState({
    date1: false,
    date2: false,
  });
  useEffect(() => {
    if (implementRules) {
      if (
        (row.lead_time_1_yellow && !row.date_1) ||
        (row.lead_time_1_red && !row.date_1) ||
        (row.lead_time_2_yellow && !row.date_2) ||
        (row.lead_time_1_red && !row.date_2)
      )
        dateRuleVerifier(
          row,
          setRuleColors,
          clientDeliveryDate,
          factoryDeliveryDate,
          dateClicked,
          setDateClicked
        );
    } else {
      setRuleColors({
        rule1: null,
        rule2: null,
      });
    }
  }, [
    row,
    implementRules,
    clientDeliveryDate,
    dateClicked,
    factoryDeliveryDate,
  ]);
  return (
    <tr
      style={{
        borderRadius: "5px",
      }}
    >
      <Tooltip
        title={
          <div style={{ display: "grid", gap: "0.5em", fontSize: "1rem" }}>
            <strong>{row.name}</strong>
            <div>
              {row.tooltip_text ? row.tooltip_text : "Här saknas det text"}
            </div>
          </div>
        }
      >
        <td
          style={{
            textAlign: "start",
            borderRadius: "5px, 0, 0, 5px",
            fontWeight: "500",
            color: (
              (!row.date_1 && ruleColors.rule1) ||
              (!row.date_2 && ruleColors.rule2)
                ? ruleColors.rule1 || ruleColors.rule2
                : undefined
            )
              ? "red"
              : undefined,
            maxWidth: "10ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.name}
        </td>
      </Tooltip>
      <td className="mobile-datepicker" style={{ maxWidth: "100px" }}>
        <DateSelect
          sx={{
            background:
              ruleColors.rule1 && !row.date_1
                ? ruleColors.rule1 === "warning"
                  ? "#fad000"
                  : "rgb(241 43 33)"
                : undefined,
            borderRadius: "5px",
            color: "white",
          }}
          placeholder={ruleColors.rule1 ? "Passerad!" : undefined}
          value={row.date_1}
          onChange={(e) =>
            handleChangeStatusRow(groupIndex, rowIndex, "date_1", e)
          }
        />
      </td>
      <td className="mobile-datepicker" style={{ maxWidth: "100px" }}>
        <DateSelect
          sx={{
            background:
              ruleColors.rule2 && !row.date_2
                ? ruleColors.rule2 === "warning"
                  ? "#fad000"
                  : "rgb(241 43 33)"
                : undefined,
            borderRadius: "5px",
            color: "white",
          }}
          placeholder={ruleColors.rule2 ? "Passerad!" : undefined}
          value={row.date_2}
          onChange={(e) =>
            handleChangeStatusRow(groupIndex, rowIndex, "date_2", e)
          }
        />
      </td>
      {editView ? (
        <>
          {" "}
          <Switch
            checked={row.is_shown}
            onChange={(e) =>
              handleChangeStatusRow(
                groupIndex,
                rowIndex,
                "is_shown",
                !row.is_shown
              )
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </>
      ) : (
        <></>
      )}
    </tr>
  );
}

export default StatusRowView;
