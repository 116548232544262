import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputField from "../../../../../components/Inputs/InputField";
import * as projectAPI from "../../../../../api/project/projectApi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ProjectLogView = ({ showModal, hideModal }) => {
  const [projectLog, setProjectLog] = useState();
  useEffect(() => {
    setProjectLog();
    if (showModal)
      projectAPI.getProjectLogs(showModal).then((log) => setProjectLog(log));
  }, [showModal]);
  if (projectLog)
    return (
      <Modal show={showModal} onHide={hideModal} size="sm" centered>
        <Modal.Header closeButton>
          Projektlog senaste uppdateringen :{" "}
          {projectLog[projectLog.length - 1]?.previous_status}
        </Modal.Header>

        <Modal.Body>
          {projectLog.length > 0 && projectLog.length < 2 ? (
            <div>
              <InputField
                required
                name="comment"
                label={"Statushändelse"}
                value={
                  projectLog
                    ? projectLog[0]?.previous_status
                    : "Finns ingen log"
                }
                onChange={"handleChange"}
              />
              <div style={{ marginTop: "1em" }}>
                <InputField
                  required
                  name="comment"
                  label={"Datum"}
                  value={
                    projectLog ? projectLog[0]?.timestamp : "Finns ingen log"
                  }
                  onChange={"handleChange"}
                />
              </div>
              <div style={{ marginTop: "1em" }}>
                <InputField
                  multiline={true}
                  name="project_description"
                  label={"beskrivning"}
                  value={
                    projectLog ? projectLog[0]?.comment : "Finns ingen log"
                  }
                  minRows={4}
                />
              </div>
            </div>
          ) : (
            <>
              {projectLog?.map((item, i) => (
                <div style={{ marginBottom: "1em" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Loggades: {item.timestamp}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ marginBottom: "1em" }}>
                        <Typography>{item.previous_status}</Typography>
                      </div>

                      <Typography>{item.comment}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </>
          )}
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
};

export default ProjectLogView;
