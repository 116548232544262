import React, { useState, useEffect } from "react";
import ProductionForm from "./ProductionForm";
import * as timeRegAPI from "../../../../api/project/timeRegApi";
import { useMutation, useQueryClient } from "react-query";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";

function ProductionView({ projectId }) {
  const [timeRegTypes, setTimeRegTypes] = useState();
  const [timeRegistredUnderProject, setTimeRegistredUnderProject] = useState();
  const [wasMutaded, setWasMutaded] = useState(false);
  const [objectWithTimeProperties, setObjectWithTimeProperties] = useState();
  const queryClient = useQueryClient();

  useEffect(() => {
    setWasMutaded(false);
    timeRegAPI.getTimeRegTypes().then((list) => setTimeRegTypes(list));
    timeRegAPI
      .getTimeReg(projectId)
      .then((timeForProject) => setTimeRegistredUnderProject(timeForProject));
    timeRegAPI
      .getModuleAccumlatedtime(projectId)
      .then((object) => setObjectWithTimeProperties(object));
  }, [projectId, wasMutaded]);

  const createTimeReg = useMutation((data) => timeRegAPI.newTimeReg(data), {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      toastSuccess("Tid registrerades");
    },
    onError: (e) => {
      console.table(e);
      toastError("Gick inte att registrera tid");
    },
  });

  if (timeRegTypes && objectWithTimeProperties)
    return (
      <div style={{ marginTop: "1em", padding: "1em" }}>
        <ProductionForm
          objectWithTimeProperties={objectWithTimeProperties}
          setWasMutaded={setWasMutaded}
          projectId={projectId}
          createTimeReg={createTimeReg}
          timeRegTypes={timeRegTypes}
          timeRegistredUnderProject={timeRegistredUnderProject}
        />
      </div>
    );
}

export default ProductionView;
