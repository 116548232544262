import React, { useState } from "react";
import Select from "../../../../components/Inputs/Select";
import { Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";

function ChooseStaffForm({ register, control, staffList }) {
  const [selects, setSelects] = useState({
    project_leader: undefined,
    a_rit: undefined,
    k_rit: undefined,
    salesperson: undefined,
    purchaser: undefined,
  });
  return (
    <div>
      <div style={{ marginBottom: "1em" }}>
        <Typography variant="h6">Ansvariga</Typography>
      </div>

      <div className="form-grid-auto wrapper">
        <Controller
          control={control}
          name={"project_leader"}
          render={({ field: { onChange, value } }) => (
            <Select
              control={control}
              register={register("project_leader")}
              options={staffList}
              value={staffList.find(
                (item) => item.value === selects.project_leader
              )}
              label={"Projektledare"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  project_leader: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={"a_rit"}
          render={({ field: { onChange, value } }) => (
            <Select
              control={control}
              register={register("a_rit")}
              options={staffList}
              value={staffList.find((item) => item.value === selects.a_rit)}
              label={"A-rit"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  a_rit: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={"k_rit"}
          render={({ field: { onChange, value } }) => (
            <Select
              control={control}
              register={register("k_rit")}
              options={staffList}
              value={staffList.find((item) => item.value === selects.k_rit)}
              label={"K-rit"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  k_rit: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />

        <Controller
          control={control}
          name={"salesperson"}
          render={({ field: { onChange, value } }) => (
            <Select
              control={control}
              register={register("salesperson")}
              options={staffList}
              value={staffList.find(
                (item) => item.value === selects.salesperson
              )}
              label={"Säljare"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  salesperson: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={"purchaser"}
          render={({ field: { onChange, value } }) => (
            <Select
              control={control}
              register={register("purchaser")}
              options={staffList}
              value={staffList.find((item) => item.value === selects.purchaser)}
              label={"Inköpare"}
              onChange={(event, item) => {
                setSelects({
                  ...selects,
                  purchaser: item.value,
                });
                onChange(item.value);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}

export default ChooseStaffForm;
