import React, { useState, useEffect } from "react";
import SupplierCategoryForm from "./SupplierCategoryForm";
import * as api from "./../../../api/register/supplierCategoryApi";
import { useMutation, useQueryClient } from "react-query";
import UpdateConfirmation from "../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function SupplierCategoryView({
  hideModal,
  showModal,

  supplierCategoryId,
}) {
  const [category, setCategory] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayUpdateConfirmationmodal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (supplierCategoryId)
      api
        .getCategory(supplierCategoryId)
        .then((category) => setCategory(category));
  }, [supplierCategoryId]);

  const [fields, setFields] = useState(category);

  useEffect(() => {
    (async () => {
      setFields({ ...category });
    })();
  }, [category]);

  const { mutate } = useMutation(api.updateCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      setHasInputChanged(false);
      setDisplayUpdateConfirmationModal(false);
      toastSuccess("Kategori uppdaterades");
      setTimeout(function () {
        hideModal();
      }, 1000);
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });

  const handleChange = (event) => {
    setHasInputChanged(true);
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisplayUpdateConfirmationModal(true);
  };

  const confirmSubmit = () => {
    mutate(fields);
  };

  const handleClose = () => {
    if (hasInputChanged) {
      setDisplayCloseConfirmationModal(true);
    } else {
      hideModal();
    }
  };
  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);
    hideModal();
  };
  if (category) {
    return (
      <div>
        <SupplierCategoryForm
          handleClose={handleClose}
          fields={fields}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          hideModal={hideModal}
          showModal={showModal}
        />
        <UpdateConfirmation
          showModal={displayUpdateConfirmationmodal}
          confirmModal={confirmSubmit}
          hideModal={() => setDisplayUpdateConfirmationModal(false)}
        />
        <CloseFonfirmation
          showModal={displayCloseConfirmationModal}
          confirmModal={confirmClose}
          hideModal={() => setDisplayCloseConfirmationModal(false)}
        />
      </div>
    );
  }
}

export default SupplierCategoryView;
