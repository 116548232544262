import React, { useEffect, useState } from "react";
import DraggableList from "../../../../components/dragableList/DraggableList";
import { reorder } from "../../../../components/dragableList/utils/helpers";
function StatusGroupStatusRowView({ rows, setRowToShow }) {
  const [items, setItems] = useState(rows);
  useEffect(() => {
    if (rows) setItems(rows);
  }, [rows]);
  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const onItemClick = (item) => {
    setRowToShow(item);
    console.log("Item with id: " + item.id + " was clicked", item);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "0.5em",
      }}
    >
      <DraggableList
        items={items}
        onDragEnd={onDragEnd}
        onClick={onItemClick}
      />
    </div>
  );
}

export default StatusGroupStatusRowView;
