import {
  handleImportRows,
  handleImportPurchase,
} from "../../../../../api/project/newPurchaseApi";
export const orderRowsMapping = async (
  dataToMapToOrderRows,
  rowHeadMaps,
  supplierId,
  importPurchases,
  projectId,
  userId
) => {
  let placements = {
    articlenumber: undefined,
    quantity: undefined,
    module: undefined,
    appendix: undefined,
    length: undefined,
    width: undefined,
    account: undefined,
  };
  for (let index = 0; index < rowHeadMaps.length; index++) {
    console.log(rowHeadMaps[index], "INDEXD");
    switch (rowHeadMaps[index][0]) {
      case "articlenumber": {
        placements.articlenumber = rowHeadMaps[index][1];
        break;
      }
      case "quantity": {
        placements.quantity = rowHeadMaps[index][1];
        break;
      }
      case "module": {
        placements.module = rowHeadMaps[index][1];
        break;
      }
      case "appendix": {
        placements.appendix = rowHeadMaps[index][1];
        break;
      }
      case "length": {
        placements.length = rowHeadMaps[index][1];
        break;
      }
      case "calculation": {
        placements.calculation = rowHeadMaps[index][1];
        break;
      }
      case "width": {
        placements.width = rowHeadMaps[index][1];
        break;
      }
      case "account": {
        placements.account = rowHeadMaps[index][1];
        break;
      }
      default: {
        break;
      }
    }
  }

  for (let index = 1; index < dataToMapToOrderRows.length; index++) {
    // console.log("yehaaa");
    // articles.push(dataToMapToOrderRows[index][placements.articlenumber]);
    // article = await getArticleByArtNumber(
    //   dataToMapToOrderRows[index][placements.articlenumber]
    // );
    // console.log(article, "thisisarticle");
    //     if (!article) {
    //       //   console.log(article, "article");
    //       console.log("finns ej");
    //       faultyOrderRows.push(dataToMapToOrderRows[index]);
    //       console.log("ansikte", dataToMapToOrderRows[index]);
    //     } else {
    //       articles.push(article);
    //     }
    //     console.log(articles, "article", faultyOrderRows);
    //     const element = dataToMapToOrderRows[index];
  }
  console.log(supplierId, "supid");
  let objectToSend = {
    created_by: userId,
    dataToMapToOrderRows: dataToMapToOrderRows,
    placements: placements,
    supplierId: supplierId,
    projectId: projectId,
  };
  console.log(objectToSend, "OBJECTTOSEND");
  let responsefromback;
  if (importPurchases) {
    responsefromback = await handleImportPurchase(objectToSend);
  } else {
    responsefromback = await handleImportRows(objectToSend);
    console.table(responsefromback, "DAFAK");
  }

  // let responsefromback;
  // responsefromback = await handleImportRows(objectToSend);
  // console.table(responsefromback, "DAFAK");

  // for (let index = 0; index < articles.length; index++) {
  //   const artnumber = articles[index];
  // }

  // var objectToSendToBackEnd = {
  //   orignalData: dataToMapToOrderRows,
  //   articleNumbers: articles,
  // };

  // function onlyUnique(value, index, self) {
  //   return self.indexOf(value) === index;
  // }
  // var unique = articles.filter(onlyUnique);
  // console.log(unique, "rowheads");
  //   dataToMapToOrderRows.map((row) => {
  //     // Kolla om modulfinns i db,
  //     // hitta artikelnummer
  //     // getArticle();
  //     console.log(row, "RAD", rowHeadMaps);

  //     return row;
  console.log(responsefromback, "ISTHISANYGOOD");
  return responsefromback;
};
