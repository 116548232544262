import React from "react";
import "../datatable.css";

function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;

  const columnToFilter = column.id;

  switch (columnToFilter) {
  }

  return (
    <span>
      <input
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      ></input>
    </span>
  );
}

export default ColumnFilter;
