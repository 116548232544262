import React from "react";
import CustomSelect from "../../customSelect/CustomSelect";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../buttons/Button";
import { useAuthContext } from "../../../hooks/useAuthContext";
function EditableRow({
  item,
  i,
  handleSelectModuleOnOrderRow,
  moduleList,
  editRowData,
  handleEditRowChange,
  handleRowEditSubmit,
  indexInRows,
  setEditRowIndex,
}) {
  const { auth } = useAuthContext();
  return (
    <TableRow>
      <TableCell scope="row" style={{ textAlign: "center" }}>
        {i + 1}
      </TableCell>
      <TableCell>{item.articlenumber}</TableCell>
      <TableCell>{item.articlename}</TableCell>
      <TableCell>
        <input
          type="text"
          name="quantity"
          onChange={handleEditRowChange}
          placeholder={item.quantity}
          value={editRowData.quantity}
        ></input>
      </TableCell>
      <TableCell>
        {/* {item.length} */}
        <input
          type="text"
          name="length"
          onChange={handleEditRowChange}
          placeholder={item.length}
          value={editRowData.length}
        ></input>
      </TableCell>
      <TableCell>
        {/* {item.width} */}
        <input
          type="text"
          name="width"
          onChange={handleEditRowChange}
          placeholder={item.width}
          value={editRowData.width}
        ></input>
      </TableCell>
      <TableCell>{item.total_amount}</TableCell>

      <TableCell>
        <input
          onChange={handleEditRowChange}
          type="text"
          name="purchase_price"
          placeholder={item.purchase_price}
          value={editRowData.purchase_price}
        ></input>{" "}
      </TableCell>

      <TableCell>{item.purchase_price * item.total_amount}</TableCell>
      <TableCell>
        {auth.user.role === 2 ? (
          <input
            onChange={handleEditRowChange}
            type="text"
            name="calculation"
            placeholder={item.calculation}
            value={editRowData.calculation}
          ></input>
        ) : (
          <>{item.calculation}</>
        )}
      </TableCell>
      <TableCell>
        <input
          onChange={handleEditRowChange}
          type="text"
          name="appendix"
          placeholder={item.appendix}
          value={editRowData.appendix}
          maxLength={255}
        ></input>{" "}
      </TableCell>
      <TableCell colSpan={3}>
        <CustomSelect
          handleChange={(ev, meta) => handleSelectModuleOnOrderRow(ev, meta)}
          optionList={moduleList}
          placeholder="Välj modul"
          defaultValue={item.module}
          name={i}
        />
      </TableCell>

      <TableCell>
        <div style={{ display: "flex", gap: "0.5em" }}>
          <Button color="primary" onClick={(e) => setEditRowIndex(null)}>
            Stäng
          </Button>
          <Button
            color="success"
            onClick={(e) => handleRowEditSubmit(e, indexInRows)}
          >
            Spara
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default EditableRow;
