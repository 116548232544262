import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as api from "../../../api/register/supplierCategoryApi";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import CloseConfirmation from "../../../components/modals/CloseConfirmation";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function AddCategoryView({ hideModal, showModal }) {
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const category = {
    category_name: undefined,
    priority: undefined,
    category_goal: undefined,
  };

  const queryClient = useQueryClient();

  const [fields, setFields] = useState(category);

  useEffect(() => {
    setFields(category);
    setHasInputChanged(false);
  }, [showModal]);

  const { mutate } = useMutation(api.newCategory, {
    onSuccess: () => {
      toastSuccess("Kategori skapades");
      setTimeout(function () {
        hideModal();
      }, 1000);
      queryClient.invalidateQueries();
    },
    onError: (e) => {
      if (e.response.status === 409)
        toastError("Finns redan en kategori med det namnet!");
      if (e.response.status === 500)
        toastError("Gick inte att skapa kategori!");
    },
  });

  const handleChange = (event) => {
    setHasInputChanged(true);
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    mutate(fields);
  };

  const handleClose = () => {
    console.log(hasInputChanged, "hasInputChanged");
    if (hasInputChanged) {
      setDisplayCloseConfirmationModal(true);
    } else {
      hideModal();
    }
  };
  const confirmClose = () => {
    setDisplayCloseConfirmationModal(false);

    hideModal();
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          Lägg till ny leverantörskategori:
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                gap: "1em",
                marginBottom: "1em",
              }}
            >
              <InputField
                required
                label="Kategori"
                name="category_name"
                value={fields.category_name}
                onChange={handleChange}
              />

              <InputField
                label="    Prioritet"
                name="priority"
                value={fields.priority}
                onChange={handleChange}
              />
              <InputField
                label="  Mål med kategorin"
                name="category_goal"
                value={fields.category_goal}
                onChange={handleChange}
              />
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: "1em " }}>
              <Button type="submit" color="success">
                Skapa
              </Button>
              <Button type="button" onClick={() => handleClose()}>
                Stäng
              </Button>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
      <CloseConfirmation
        showModal={displayCloseConfirmationModal}
        confirmModal={confirmClose}
        hideModal={() => setDisplayCloseConfirmationModal(false)}
      />
    </>
  );
}

export default AddCategoryView;
