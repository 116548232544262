import { useContext, useState, useEffect } from "react";
import { newProjColumns } from "../../components/dataTable/columns/Columns";
import { useQuery } from "react-query";
import * as projectAPi from "../../api/project/projectApi";
import { ProjectContext } from "./../../context/ProjectContext";
import { useNavigate } from "react-router-dom";
import Content from "../../components/Content";
import Paper from "@mui/material/Paper";
import Button from "../../components/buttons/Button";
import DataTable from "../../components/newDataTable/DataTable";
import { Tooltip } from "@mui/material";
function ProjectOverview() {
  const [lastTab, setLastTab] = useState(1);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    localStorage.removeItem("LocalProjectStrings");
    localStorage.removeItem("projectdetails");
  }, []);

  const { data: apiResponse } = useQuery(["projects"], () =>
    projectAPi.getAll()
  );

  const navigate = useNavigate();
  const { handleClickViewChosenProjects } = useContext(ProjectContext);

  const tabs = [
    { label: "Projektledare", id: 1 },
    { label: "Sälj", id: 2 },
    { label: "Bygg", id: 3 },
  ];

  function renderNavigateButton() {
    return (
      <div style={{ margin: "1em 0" }}>
        <Button
          // disabled
          variant="outlined"
          onClick={() => handleClickViewChosenProjects(navigate)}
        >
          Se valda projekt
        </Button>
      </div>
    );
  }

  function renderCreateProjectButton() {
    return (
      <div style={{ margin: "1em 0" }}>
        <Button onClick={() => navigate("/skapa/projekt")}>
          Skapa nytt projekt
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (typeof apiResponse === "object") setProjects(apiResponse);
  }, [apiResponse]);

  return (
    <>
      <Content tabs={true} tabList={tabs} lastTab={lastTab}>
        <div value={1}>
          <DataTable
            columns={newProjColumns}
            data={projects}
            typeOfTable="project"
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {renderNavigateButton()}
            {renderCreateProjectButton()}
          </div>
        </div>
        <div value={2}>
          <Paper></Paper>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {renderNavigateButton()}
            {renderCreateProjectButton()}
          </div>
        </div>
        <div value={3}>
          <Paper></Paper>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {renderNavigateButton()}
            {renderCreateProjectButton()}
          </div>
        </div>
      </Content>
    </>
  );
}

export default ProjectOverview;
