import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getTimeReg = (projectId) =>
  api.get(`/timereg/${projectId}`).then((res) => res.data);

export const updateTimeRegs = (updatedTimeRegs) => {
  api.put(`/updatetimeregs/`, updatedTimeRegs).then((res) => res.data);
};

export const newTimeReg = (comment) => api.post(`/timereg`, comment);

export const deleteTimeReg = (id) => api.delete(`/timereg/` + id);

export const getTimeRegType = (id) =>
  api.get(`/timeregtype/${id}`).then((res) => res.data);

export const updateTimeRegType = ({ id, ...updatedTimeRegType }) => {
  return api.put(`/timeregtype/${id}`, updatedTimeRegType);
};

export const getTimeRegTypes = () =>
  api.get(`/timeregtypes/`).then((res) => res.data);

export const getModuleAccumlatedtime = (projectId) =>
  api.get(`/moduleaccumlatedtime/${projectId}`).then((res) => res.data);

export const newTimeRegType = (status) => api.post(`/timeregtype`, status);

export const deleteTimeRegType = (id) => api.delete(`/timeregtype/` + id);
