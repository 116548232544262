import React from "react";
import InputField from "../../../../../components/Inputs/InputField";
function PropertyDetailsView({ fields, handleChange, register }) {
  return (
    <div style={{ flex: "1" }} className="wrapper">
      <div className="wrapper-title"> Fastighet</div>

      <div className="form-grid-auto ">
        <InputField
          name="project_property_designation"
          label={"Fastighetsbeteckning"}
          value={fields.project_property_designation}
          onChange={handleChange}
          register={register("project_property_designation")}
        />
        <InputField
          name="project_property_adress"
          label={"Fastighetsadress"}
          value={fields.project_property_adress}
          onChange={handleChange}
          register={register("project_property_adress")}
        />
        <InputField
          name="project_property_post_adress"
          label={"Postadress"}
          value={fields.project_property_post_adress}
          onChange={handleChange}
          register={register("project_property_post_adress")}
        />
      </div>
    </div>
  );
}

export default PropertyDetailsView;
