import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import "react-datepicker/dist/react-datepicker.css";
import * as supplierAPI from "./../../../../api/register/supplierApi";
import * as accountAPI from "./../../../../api/register/accountApi";
import * as purchaseAPI from "./../../../../api/project/newPurchaseApi";
import * as projectAPI from "./../../../../api/project/projectApi";
import CloseFonfirmation from "../../../../components/modals/CloseConfirmation";
import Button from "../../../../components/buttons/Button";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import OrderHead from "./components/OrderHeadView";
import { validateOrder } from "./utils/Validate";
import { IsObjectEmpty } from "./utils/Validate";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import React from "react";
import { useCallbackPrompt } from "../../../../hooks/useCallBackPrompt";
import DialogBox from "../../../../components/modals/DialogBox";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function AddPurchaseForm() {
  const { projectid } = useParams();
  const [project, setProject] = useState();
  const [projectadress, setProjectAdress] = useState();
  const { auth } = useAuthContext();

  useEffect(() => {
    projectAPI.getProject(projectid).then((proj) => {
      setProject(proj);
      setProjectAdress(
        proj.project_property_designation +
          "," +
          proj.project_property_adress +
          "," +
          proj.project_property_post_adress
      );
    });
  }, [projectid]);
  const [supplierList, setSupplierList] = useState();
  const [accountList, setAccountList] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [isOrderSubmit, setOrderIsSubmit] = useState(false);
  const [accountForOrderNumber, setAccountForOrderNumber] = useState();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(hasInputChanged);
  let navigate = useNavigate();

  const purchase = {
    created_by: auth.user.id,
    order_number: undefined,
    purchase_adress: projectadress,
    project: projectid,
    created_date: new Date().toLocaleDateString("sv-SE"),
    order_date: "",
    requested_delivery_date: null,
    confirmed_delivery_date: null,
    status: "",
    total_cost: "",
    supplierId: "",
    comment: "",
    account: "",
    our_reference: auth.user.firstName + " " + auth.user.lastName,
    our_reference_mail: auth.user.email,
    our_reference_phone: auth.user.phone,
    referens: { value: "" },
    order_comment: "",
  };

  const [fields, setFields] = useState(purchase);

  // USEEFFECT ARREA
  // USEEFFECT ARREA
  // USEEFFECT ARREA ↓↓↓

  useEffect(() => {
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSupplierList(suppliers));
    accountAPI
      .getAllAccountNames()
      .then((accounts) => setAccountList(accounts));
  }, []);

  useEffect(() => {
    if (fields.order_number) purchaseAPI.createPDF(fields);
  }, [fields]);

  useEffect(() => {
    if (isOrderSubmit) {
      mutate(fields);
      setHasInputChanged(false);
      // setKeepDataInForm(false);
    }
  }, [isOrderSubmit, fields]);

  // USEEFFECT ARREA ↑↑↑
  // USEEFFECT ARREA
  // USEEFFECT ARREA

  // handleSelect AREA
  // handleSelect AREA
  // handleSelect AREA ↓↓↓

  const handleSelectSupplier = (e, value) => {
    console.log(value);

    setFields({ ...fields, supplierId: value.value });

    setHasInputChanged(true);
    // setKeepDataInForm(true);
  };

  const handleSelectStatus = (e, value) => {
    setFields({ ...fields, status: value.value });
    setHasInputChanged(true);
    // setKeepDataInForm(true);
  };

  const handleSelectAccount = (e, value) => {
    setFields({ ...fields, account: value.value });
    accountAPI.getAccount(value.value).then((account) => {
      setAccountForOrderNumber(account.account);
    });
    setHasInputChanged(true);
    // setKeepDataInForm(true);
  };

  // handleSelect AREA ↑↑↑
  // handleSelect AREA
  // handleSelect AREA

  // Handle Changes AREA
  // Handle Changes AREA
  // Handle Changes AREA ↓↓↓
  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "supplier" || name === "articleId") {
      value = parseInt(value);
    }
    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
    // setKeepDataInForm(true);
  };

  //Function för att deleta orderrader. hard delete. Prevent om purchaseBlocked = true

  // Handle Changes AREA ↑↑↑↑
  // Handle Changes AREA
  // Handle Changes AREA

  // SUBMIT AREA ↓↓↓
  // SUBMIT AREA ↓↓↓
  // SUBMIT AREA ↓↓↓
  const { mutate } = useMutation(purchaseAPI.newOrder, {
    onSuccess: (e) => {
      console.table(e);
      toastSuccess("Order skapades");
      setOrderIsSubmit(false);
      setHasInputChanged(false);
      setFields({
        ...fields,
        order_number:
          e.data.raw.insertId +
          "-" +
          accountForOrderNumber +
          "-" +
          project.project_project_number,
      });
      navigate(`/projektoversikt/inkop/${e.data.raw.insertId}`);
    },
    onError: (e) => {
      console.table(e);
      toastError("Gick inte att skapa Order");
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("validateOrder triggered", validateOrder(fields));
    // console.log(fields);
    setOrderIsSubmit(true);
    // mutate(fields);
  };

  // SUBMIT AREA ↑↑↑
  // SUBMIT AREA ↑↑↑
  // SUBMIT AREA ↑↑↑

  const handleClose = (e) => {
    e.preventDefault();
    const isMyObjectEmpty = IsObjectEmpty(fields);
    if (!isMyObjectEmpty) {
      if (hasInputChanged) {
        setDisplayConfirmationModal(true);
      } else {
        confirmClose();
      }
    } else if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const confirmClose = () => {
    navigate(`/projektoversikt/${projectid}`);
  };
  if (supplierList && accountList) {
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <form onSubmit={handleSubmit}>
          <DialogBox
            message={`Du har osparad data på inköpsordern`}
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <OrderHead
            fields={fields}
            setFields={setFields}
            handleChange={handleChange}
            handleSelectSupplier={handleSelectSupplier}
            handleSelectStatus={handleSelectStatus}
            supplierList={supplierList}
            handleSelectAccount={handleSelectAccount}
            accountList={accountList}
          />

          <hr></hr>

          <hr></hr>

          <div style={{ display: "flex", gap: "1em", marginTop: "1.5em" }}>
            <Button onClick={(e) => handleClose(e)}>Stäng</Button>
            <Button type="submit" color="success">
              {" "}
              Skapa order
            </Button>
          </div>
          <CloseFonfirmation
            showModal={displayConfirmationModal}
            confirmModal={confirmClose}
            hideModal={hideConfirmationModal}
          />
        </form>
      </div>
    );
  }
}

export default AddPurchaseForm;
