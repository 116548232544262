import React from "react";
import { Checkbox, Switch } from "@mui/material";
import Button from "../../../../../components/buttons/Button";
import InputField from "../../../../../components/Inputs/InputField";
function ClientControlView({
  fields,
  register,
  setFields,
  setHasInputChanged,
  setShowPreview,
  client_mail,
}) {
  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "3em" }}>
            {" "}
            Tid- och leveransplan (Inköp){" "}
            <Switch
              checked={fields.errand_time_delivery_plan ? true : false}
              onChange={() => {
                setFields({
                  ...fields,
                  errand_time_delivery_plan: !fields.errand_time_delivery_plan,
                });
                setHasInputChanged(true);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div style={{ marginRight: "3em" }}>
            {" "}
            Egenkontroller UE{" "}
            <Switch
              checked={fields.errand_self_control_ue ? true : false}
              onChange={() => {
                setFields({
                  ...fields,
                  errand_self_control_ue: !fields.errand_self_control_ue,
                });
                setHasInputChanged(true);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div style={{ marginRight: "3em" }}>
            {" "}
            Allmänt status{" "}
            <Switch
              checked={fields.errand_general_status ? true : false}
              onChange={() => {
                setFields({
                  ...fields,
                  errand_general_status: !fields.errand_general_status,
                });
                setHasInputChanged(true);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div style={{ marginRight: "1em" }}>
            {" "}
            Öppna ärenden{" "}
            <Switch
              checked={fields.errand_open_errands ? true : false}
              onChange={() => {
                setFields({
                  ...fields,
                  errand_open_errands: !fields.errand_open_errands,
                });
                setHasInputChanged(true);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div>
            <Button onClick={() => setShowPreview(true)}>
              Förhandsgrandska
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "1em", marginBottom: "1em" }}>
          <InputField
            register={register("client_comment")}
            label={"Meddelande till kund"}
            name={"client_comment"}
            value={fields.errand_client_comment}
            multiline={true}
            minRows={4}
            onChange={(e) => {
              setFields({
                ...fields,
                errand_client_comment: e.target.value,
              });
              setHasInputChanged(true);
            }}
          />
        </div>
        <div style={{ marginBottom: "1em" }}>
          <div>
            Har skickats till kund{" "}
            <Checkbox checked={fields.errand_sent_to_client ? true : false} />
          </div>
          <div>
            Skickas till email : {client_mail ? client_mail : "Mail saknas"}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientControlView;
