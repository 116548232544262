import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import ColumnFilter from "../dataTableFilters/ColumnFilter";

export const userColumns = [
  {
    Header: "Förnamn",
    accessor: "user_first_name",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Efternamn ",
    accessor: "user_last_name",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Roll",
    accessor: "staff_role_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Behörighet",
    accessor: "user_role_role",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Email",
    accessor: "user_email",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Telefon",
    accessor: "user_phonenumber",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Registrades ",
    accessor: "user_register_date",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];
