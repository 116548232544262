import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import ColumnFilter from "../dataTableFilters/ColumnFilter";

export const SupplierCategoryColumns = [
  {
    Header: "Kategori",
    table: "suppliercategory",
    accessor: "category_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Prioritet",
    table: "suppliercategory",
    accessor: "priority",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Mål för kategori",
    table: "suppliercategory",
    accessor: "category_goal",
    Filter: ColumnFilter,
    filter: "includesString",
  },
];
