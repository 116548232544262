import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
import Paper from "@mui/material/Paper";
import Button from "../../../../../components/buttons/Button";
import Movablerow from "../../../../../components/dataTable/purchaseRows/MovableRow";
import Select from "../../../../../components/Inputs/Select";
const MoveOrderRowsView = ({
  currentOrder,
  orders,
  orderForTransfer,
  setOrderForTransfer,
  showModal,
  hideModal,
  confirmModal,
  selectedRows,
}) => {
  if (selectedRows && orders) {
    return (
      <Modal show={showModal} onHide={hideModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{ width: "100%" }}
            id="contained-modal-title-vcenter"
          >
            <div
              style={{
                display: "flex",
                gap: "1em",
                // justifyContent: "center",
                // width: "fit-content",
              }}
            >
              <div style={{ flex: 1 }}>Valda rader för flytt till order : </div>
              <div style={{ flex: 1 }}>
                <Select
                  label={"Order"}
                  options={orders.filter((item) => item.value !== currentOrder)}
                  value={orders.find((item) => item.value === orderForTransfer)}
                  onChange={(e, data) => {
                    setOrderForTransfer(data.value);
                  }}
                />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1em 0", maxHeight: "50em", overflow: "auto" }}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="simple table">
                <TableHead
                  style={{
                    position: "sticky",
                    top: "0px",
                    margin: "0 0 0 0",
                    background: "white",
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell scope="col"></TableCell>
                    <TableCell scope="col">Artikelnr</TableCell>
                    <TableCell scope="col">Artikel</TableCell>
                    <TableCell scope="col">Antal</TableCell>
                    <TableCell scope="col">Längd</TableCell>
                    <TableCell scope="col">Bredd</TableCell>
                    <TableCell scope="col">Mängd ink spill</TableCell>
                    <TableCell scope="col">
                      Pris per beställningsenhet
                    </TableCell>
                    <TableCell scope="col">Summa</TableCell>
                    <TableCell scope="col">Kalkyl</TableCell>
                    <TableCell scope="col">Kommentar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedRows.length > 0 ? (
                    <>
                      {selectedRows.map((item, i) => (
                        <Fragment key={i}>
                          <Movablerow item={item} i={i} />
                        </Fragment>
                      ))}
                    </>
                  ) : (
                    <div></div>
                  )}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={2}>
                      <strong>Kalkylvärde som kommer flyttas</strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        {selectedRows
                          .reduce(
                            (acc, row) => acc + Number(row.calculation),
                            0
                          )
                          .toFixed(2)}
                        kr
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={2}>
                      <strong>Summa som kommer flyttas</strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        {selectedRows
                          .reduce(
                            (acc, row) =>
                              acc +
                              Number(row.total_amount) *
                                Number(row.purchase_price),
                            0
                          )
                          .toFixed(2)}
                        kr
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!orderForTransfer}
            onClick={() => confirmModal(true)}
          >
            Flytta rader
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
export default MoveOrderRowsView;
