import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
const AddStatusGroupView = ({
  showModal,
  hideModal,
  statusAPI,
  queryClient,
}) => {
  const [statusGroup, setStatusGroup] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setStatusGroup({
      title: "",
      datetitle_1: "",
      datetitle_2: "",
    });
  }, [showModal]);

  const { mutate } = useMutation(statusAPI.newStatusGroup, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      setTimeout(function () {
        hideModal();
        queryClient.invalidateQueries("statusgroup");
      }, 1000);
      toastSuccess("Statusgrupp lades till");
      setTimeout(function () {
        navigate(`/admin/statusgroup/${e.data.raw.insertId}`);
      }, 1000);
    },
    onError: (e) => {
      toastError("Gick inte att lägga till Statusgrupp");
    },
  });
  if (statusGroup)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          // queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till statusgrupp</Modal.Header>

        <Modal.Body>
          <div>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Namn"}
                value={statusGroup.title}
                onChange={(e) =>
                  setStatusGroup({ ...statusGroup, title: e.target.value })
                }
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Datum title 1"}
                value={statusGroup.datetitle_1}
                onChange={(e) =>
                  setStatusGroup({
                    ...statusGroup,
                    datetitle_1: e.target.value,
                  })
                }
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <InputField
                label={"Datum title 2"}
                value={statusGroup.datetitle_2}
                onChange={(e) =>
                  setStatusGroup({
                    ...statusGroup,
                    datetitle_2: e.target.value,
                  })
                }
              />
            </div>{" "}
            {/* <div style={{ display: "flex" }}>
              <Checkbox
                name="bill_towards_client"
                label={"Klarmarkerad"}
                type="checkbox"
                onChange={(e) => {
                  setStatusGroup({
                    ...statusGroup,
                    ["standard"]: !statusGroup.standard,
                  });
                }}
                checked={statusGroup.standard}
                // value={fields}
              ></Checkbox>
              <div style={{ marginTop: "10px" }}>
                <p>Standard</p>
              </div>
            </div> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              mutate(statusGroup);
            }}
          >
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AddStatusGroupView;
