import React from "react";
import MUiButton from "@mui/material/Button";

export default function Button(props) {
  return (
    <MUiButton
      fullWidth={props.fullWidth}
      size={props.size}
      sx={props.sx}
      color={props.color}
      variant={props.variant ? props.variant : "contained"}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      endIcon={props.endIcon}
      startIcon={props.startIcon}
    >
      {props.children}
    </MUiButton>
  );
}
