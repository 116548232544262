import React from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";

function CreateMForm({ createMunicipality, setWasMutated }) {
  const { register, handleSubmit } = useForm({});
  const queryClient = useQueryClient();
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          try {
            createMunicipality.mutate(data, {
              onSuccess: (e) => {
                setWasMutated(true);
                toastSuccess("Kommun skapades");
              },
              onSettled: () => {
                queryClient.invalidateQueries();
              },
              onError: (e) => {
                if (e.response.status === 409) {
                  toastError("Kommun redan registrerad!");
                }
                if (e.response.status === 500) {
                  toastError("Gick ej att skapa kommun!");
                }
              },
            });
          } catch {
            console.log("error från click on send");
          }
        })}
      >
        <h5>Lägg till kommun</h5>
        <div
          style={{
            display: "grid",
            gap: "1em",
            margin: "1em 0",
            marginTop: "25px",
          }}
        >
          <div className="form-grid-auto">
            <InputField
              required
              name="name"
              label="kommun"
              register={register("name")}
            />
          </div>
        </div>

        <hr></hr>
        <Button type="submit">Skapa</Button>
      </form>
    </>
  );
}

export default CreateMForm;
