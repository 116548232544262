import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ProjectOverview from ".././views/project/ProjectOverview";
import ProjectDetails from ".././views/project/ProjectDetails";
import { useState } from "react";
import { PricelistColumns } from "./../components/dataTable/columns/PricelistColumns";
import SupplierView from ".././views/register/supplier/SupplierView";
import ArticleView from ".././views/register/article/ArticleView";
import SupplierCategoryView from ".././views/register/supplierCategory/SupplierCategoryView";
import Navbar from ".././components/navbar/Navbar";
import Topbar from ".././components/navbar/Topbar";
import PurchaseView from ".././views/project/ProjectDetailTabViews/Purchases/PurchaseView";
import AddPurchaseForm from ".././views/project/ProjectDetailTabViews/Purchases/AddPurchaseForm";
import PriceListItemView from ".././views/register/pricelist/PriceListItemView";
import AddPriceListItemView from ".././views/register/pricelist/AddPriceListItemView";
import RegisterOverView from ".././views/register/RegisterOverView";
import AddSupplierview from ".././views/register/supplier/AddSupplierview";
import AddArticleView from ".././views/register/article/AddArticleView";
import StaffOverView from "../views/staff/StaffOverview";
import StaffView from "../views/staff/staff/StaffView";
import AdminView from "../views/admin/AdminView";
import CreateProjectView from "../views/project/createProject/CreateProjectView";
import AddAtaView from "../views/project/ProjectDetailTabViews/Ata/AddAtaView";
import AtaView from "../views/project/ProjectDetailTabViews/Ata/AtaView";
import ErrandOverview from "../views/errands/ErrandOverview";
import ModuleOverView from "../views/modules/ModuleOverView";
import Moduleview from "../views/project/ProjectDetailTabViews/module/Moduleview";
import AddmoduleView from "../views/project/ProjectDetailTabViews/module/AddmoduleView";
import Login from "../views/login/Login";
import { useAuthContext } from "../hooks/useAuthContext";
import RequireAuth from "./RequireAuth";
import StatusGroupView from "../views/admin/status/StatusGroup/StatusGroupView";
import PurchaseOrderOverview from "../views/purchaseOrders/PurchaseOrderOverView";

function AppRoutes() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { auth } = useAuthContext();

  return (
    <Router>
      <div className={"main-container"}>
        {window.location.pathname !== "/login" && (
          <>
            <Navbar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
            <Topbar user={auth} />
          </>
        )}

        <div
          className={
            !sidebarOpen
              ? "main-content main-content_large"
              : window.location.pathname !== "/login"
              ? "main-content"
              : ""
          }
        >
          <Routes>
            <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
              <Route exact path="/" element={<ProjectOverview />} />

              <Route
                exact
                path="/register"
                element={
                  <RegisterOverView
                    columns={PricelistColumns}
                    queryName={"pricelist"}
                  />
                }
              />

              <Route exact path="/personal" element={<StaffOverView />} />

              <Route exact path="/errand" element={<ErrandOverview />} />

              <Route
                exact
                path="/moduleoverview"
                element={<ModuleOverView />}
              />
              <Route
                exact
                path="/purchaseoverview"
                element={<PurchaseOrderOverview />}
              />

              <Route
                exact
                path="/projektoversikt/module/:id/:id2"
                element={<Moduleview />}
              />

              <Route
                exact
                path="/projektoversikt/skapa/module/:id"
                element={<AddmoduleView />}
              />

              <Route
                path="/projektoversikt/:projectId"
                element={<ProjectDetails />}
              />

              <Route
                path="/register/prislista/:id"
                element={<PriceListItemView />}
              />

              <Route
                path="/register/skapa/prislista/"
                element={<AddPriceListItemView />}
              />

              <Route
                path="/register/leverantor/:id"
                element={<SupplierView />}
              />

              <Route
                path="/register/inaktivaleverantor/:id"
                element={<SupplierView />}
              />

              <Route
                path="/register/skapa/leverantor/"
                element={<AddSupplierview />}
              />

              <Route path="/register/artikel/:id" element={<ArticleView />} />

              <Route
                path="/register/skapa/artikel/"
                element={<AddArticleView />}
              />

              <Route
                path="/register/inaktivartikel/:id"
                element={<ArticleView />}
              />

              <Route
                path="/projektoversikt/inkop/:id"
                element={<PurchaseView />}
              />

              <Route
                path="/projektoversikt/skapa/inkop/:projectid"
                element={<AddPurchaseForm />}
              />

              <Route path="/projektoversikt/ata/:id" element={<AtaView />} />

              <Route
                path="/projektoversikt/skapa/ata/:id"
                element={<AddAtaView />}
              />

              <Route
                path="/addSupplierCategory"
                element={<SupplierCategoryView />}
              />

              <Route path="/skapa/projekt" element={<CreateProjectView />} />

              <Route path="/personal/:id" element={<StaffView />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth allowedRoles={[2]} />}>
              <Route path="/admin" element={<AdminView />} />
              <Route
                path="/admin/statusgroup/:id"
                element={<StatusGroupView />}
              />
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default AppRoutes;
