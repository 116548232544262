export const calculateTotalAmount = (orderRow, article) => {
  //   if (orderRow.unit !== "st") {
  // if (typeof orderRow.quantity === "string")
  //   orderRow.quantity = orderRow.quantity;
  if (article.length_required === 0 && article.width_required === 0) {
    orderRow.total_amount = orderRow.quantity / article.recount_unit;
  } else if (article.length_required === 1 && article.width_required === 1) {
    orderRow.total_amount =
      (orderRow.quantity * (orderRow.length * orderRow.width)) /
      article.recount_unit;
  } else if (article.length_required === 1 && article.width_required === 0) {
    orderRow.total_amount =
      (orderRow.quantity * orderRow.length) / article.recount_unit;
  }
  //   } else {
  //     orderRow.total_amount = orderRow.quantity;
  //   }
  return Number(orderRow.total_amount).toFixed(2);
};

// Om artikel inte har krav på varken längd eller bredd gäller
//  total mängd ink spill = antal * (1 + spillprocent) / omräkningsenhet

// Om artikeln HAR krav på både längd och bredd gäller
//  total mängd ink spill = ((antal * (längd * bredd)) / omräkningsenhet) * (1 + spillprocent)

// Om artikeln har krav på längd men inte bredd gäller
//  total mängd ink spill = ((antal *  bredd) / omräkningsenhet) * (1 + spillprocent)
