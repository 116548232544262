import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import GlobalFilter from "../dataTableFilters/ColumnFilter";
import TableInstance from "../TableInstance";

const fetchData = (pageNumber, searchString) => {
  return axios.get(searchString);
  // return axios.get(searchString + `_limit=20&_page=${pageNumber}`);
};
export const TableQuery = ({
  columns,
  queryName,
  searchString,
  typeOfTable,
  handleShowObject,
  setProjectList,
}) => {
  // const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: apiResponse, isLoading } = useQuery(
    [queryName, pageNumber],
    () => fetchData(pageNumber, searchString)
  );

  useEffect(() => {
    setTableData(apiResponse?.data);
  }, [apiResponse]);

  if (isLoading || !tableData) {
    return <div>Loading...</div>;
  }
  return (
    <TableInstance
      typeOfTable={typeOfTable}
      tableData={tableData}
      setPageNumber={setPageNumber}
      columnsRecieved={columns}
      GlobalFilter={GlobalFilter}
      pageNumber={pageNumber}
      handleShowObject={handleShowObject}
      setProjectList={setProjectList}
    />
  );
};
