import { TableQuery } from "../dataTable/dataTableHooks/GetInitialListForAllObjects";

const DataTable = ({
  columns,
  queryName,
  searchString,
  typeOfTable,
  handleShowObject,
}) => {
  return (
    <TableQuery
      columns={columns}
      queryName={queryName}
      searchString={searchString}
      typeOfTable={typeOfTable}
      handleShowObject={handleShowObject}
    />
  );
};

export default DataTable;
