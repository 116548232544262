import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import Content from "../../../components/Content";
import ChooseStaffForm from "./components/ChooseStaffForm";
import ClientDetailsForm from "./components/ClientDetailsForm";
import ProjectDetailsForm from "./components/ProjectDetailsForm";
function ProjectForm({
  staffList,
  projectTypeList,
  projectPhaseList,
  leadChannelList,
  projectStatusList,
  newProjectNumber,
  handleCreateProject,
  municipalityList,
  projectIndexTypeList,
  projectDeliveryTypeList,
}) {
  const { register, handleSubmit, control } = useForm({});
  return (
    <Content title="Skapa nytt projekt">
      <form
        onSubmit={handleSubmit((data) => {
          data.project_number = newProjectNumber;
          handleCreateProject(data);
        })}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
          <div
            style={{
              flex: "1",
            }}
          >
            <ProjectDetailsForm
              register={register}
              control={control}
              newProjectNumber={newProjectNumber}
              projectTypeList={projectTypeList}
              projectPhaseList={projectPhaseList}
              leadChannelList={leadChannelList}
              projectStatusList={projectStatusList}
              municipalityList={municipalityList}
              projectIndexTypeList={projectIndexTypeList}
              projectDeliveryTypeList={projectDeliveryTypeList}
            />
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              gap: "2em",
            }}
          >
            <ClientDetailsForm register={register} />

            <ChooseStaffForm
              register={register}
              control={control}
              staffList={staffList}
            />
          </div>
        </div>
        <hr></hr>
        <Button type="submit">Skapa</Button>
      </form>
    </Content>
  );
}

export default ProjectForm;
