import React, { useEffect, useState } from "react";
import InputField from "../../../../components/Inputs/InputField";
import Select from "../../../../components/Inputs/Select";

function RuleView({
  rule_required,
  statusRow,
  setStatusRow,
  ruleTowards,
  ruleTitle,
}) {
  const [lead_time_towards, setLead_time_towards] = useState();

  const options = [
    { label: "Leverans fabrik", value: "Leverans fabrik" },
    { label: "Färdigställande", value: "Färdigställande" },
  ];
  useEffect(() => {
    if (ruleTowards === 1) {
      setLead_time_towards({
        lead_time_towards: "lead_time_1_towards",
        lead_time_yellow: "lead_time_1_yellow",
        lead_time_red: "lead_time_1_red",
      });
    } else {
      setLead_time_towards({
        lead_time_towards: "lead_time_2_towards",
        lead_time_yellow: "lead_time_2_yellow",
        lead_time_red: "lead_time_2_red",
      });
    }
  }, [ruleTowards]);

  if (lead_time_towards)
    return (
      <div>
        <div style={{ marginBottom: "1em" }}>
          <Select
            required={rule_required}
            options={options}
            value={options.find(
              (item) =>
                item.value === statusRow[lead_time_towards.lead_time_towards]
            )}
            onChange={(e) =>
              setStatusRow({
                ...statusRow,
                [lead_time_towards.lead_time_towards]: e.target.innerText,
              })
            }
          />
        </div>
        <div>
          <InputField
            type={"number"}
            required
            label={"Ledtid för gultläge "}
            value={statusRow[lead_time_towards.lead_time_yellow]}
            onChange={(e) =>
              setStatusRow({
                ...statusRow,
                [lead_time_towards.lead_time_yellow]: Number(e.target.value),
              })
            }
          />
          <InputField
            sx={{ marginTop: "1em" }}
            type={"number"}
            required
            label={"Ledtid för röttläge "}
            value={statusRow[lead_time_towards.lead_time_red]}
            onChange={(e) =>
              setStatusRow({
                ...statusRow,
                [lead_time_towards.lead_time_red]: Number(e.target.value),
              })
            }
          />
        </div>
      </div>
    );
}

export default RuleView;
