import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import * as supplierCategoryAPI from "../../../api/register/supplierCategoryApi";
import { SupplierCategoryColumns } from "../../../components/dataTable/columns/SupplierCategoryColumns";
import { useQuery } from "react-query";
import SupplierCategoryView from "./SupplierCategoryView";
import AddCategoryView from "./AddCategoryView";
function SupplierCategoryOverview() {
  const [displaySupplierCategoryView, setDisplaySupplierCategoryView] =
    useState(false);
  const [displayAddCategoryView, setDisplayAddCategoryView] = useState(false);

  const { data: supplierCategoryResponse } = useQuery(
    ["suppliercategory"],
    () => supplierCategoryAPI.getAllCategories()
  );
  return (
    <>
      {" "}
      <div>
        <Button onClick={() => setDisplayAddCategoryView(true)}>
          Lägg till leverantörskategori
        </Button>

        <DataTable
          columns={SupplierCategoryColumns}
          data={
            supplierCategoryResponse?.length > 0 ? supplierCategoryResponse : []
          }
          typeOfTable="suppliercategory"
          handleShowObject={setDisplaySupplierCategoryView}
        />
      </div>
      <SupplierCategoryView
        showModal={displaySupplierCategoryView}
        hideModal={() => setDisplaySupplierCategoryView(false)}
        supplierCategoryId={displaySupplierCategoryView}
      />
      <AddCategoryView
        showModal={displayAddCategoryView}
        hideModal={() => setDisplayAddCategoryView(false)}
      />
    </>
  );
}

export default SupplierCategoryOverview;
