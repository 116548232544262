import React from "react";
import InputField from "../../../../components/Inputs/InputField";
import Typography from "@mui/material/Typography";

function ClientDetailsForm({ register }) {
  return (
    <div>
      <div style={{ marginBottom: "1em" }}>
        <Typography variant="h6">Kund</Typography>
      </div>

      <div className="form-grid-auto wrapper">
        <InputField
          required
          name="client_name"
          label="Fullständigt namn"
          register={register("client_name")}
        />

        <InputField
          required
          name="client_phone"
          label="Telefon"
          register={register("client_phone")}
        />

        <InputField
          type="email"
          required
          name="client_email"
          label="Email"
          register={register("client_email")}
        />

        <InputField
          required
          name="client_adress"
          label="Adress"
          register={register("client_adress")}
        />
        <InputField
          required
          name="client_postnumber"
          label="Postnummer"
          register={register("client_postnumber")}
        />
        <InputField
          required
          name="client_postadress"
          label="Postadress"
          register={register("client_postadress")}
        />

        <InputField
          name="client_clientname"
          label="Kundnamn"
          register={register("client_clientname")}
        />
      </div>
    </div>
  );
}

export default ClientDetailsForm;
