import React from "react";
import InputField from "../../../../../components/Inputs/InputField";
function ClientDetailsView({ fields, handleChange, register }) {
  return (
    <div style={{ flex: "1" }} className="wrapper">
      <div className="wrapper-title">Kund</div>

      <div className="form-grid-auto ">
        <InputField
          name="client_clientname"
          label={"Kundnamn"}
          value={fields.client_clientname}
          onChange={handleChange}
          register={register("client_clientname")}
          maxLength={255}
        />
        <InputField
          name="client_name"
          label={"Namn"}
          value={fields.client_name}
          onChange={handleChange}
          register={register("client_name")}
          maxLength={500}
        />
        <InputField
          name="client_phone"
          label={"Mobil"}
          value={fields.client_phone}
          onChange={handleChange}
          register={register("client_phone")}
          maxLength={255}
        />
        <InputField
          type="email"
          name="client_email"
          label={"E-post"}
          value={fields.client_email}
          onChange={handleChange}
          register={register("client_email")}
          maxLength={255}
        />
        <InputField
          name="client_adress"
          label={"Adress"}
          value={fields.client_adress}
          onChange={handleChange}
          register={register("client_adress")}
          maxLength={255}
        />{" "}
        <InputField
          name="client_postnumber"
          label={"Postnr"}
          value={fields.client_postnumber}
          onChange={handleChange}
          register={register("client_postnumber")}
          maxLength={255}
        />
        <InputField
          name="client_postadress"
          label={"Postort"}
          value={fields.client_postadress}
          onChange={handleChange}
          register={register("client_postadress")}
          maxLength={255}
        />
      </div>
    </div>
  );
}

export default ClientDetailsView;
