import React from "react";
import InputField from "../../../../components/Inputs/InputField";
import Select from "../../../../components/Inputs/Select";
import { ReqOptions } from "../../../../components/customSelect/SelectOptions";
function BasicInformationView({
  fields,
  handleChange,
  handleSelect,
  newArticleNumber,
  supplierList,
}) {
  return (
    <div className="wrapper" style={{ flex: "1" }}>
      <div className="wrapper-title">Grunduppgifter</div>
      <div className="form-grid-auto">
        <InputField
          required
          label="Artikel"
          name="article_name"
          value={fields.article_name}
          onChange={handleChange}
        />
        <InputField
          required
          label="Artikel nummer "
          name="article_number"
          value={newArticleNumber}
          onChange={handleChange}
          disabled
        />{" "}
        <Select
          required
          label="Huvudleverantör"
          options={supplierList}
          value={supplierList.find(
            (item) => item.value === fields.mainSupplierId
          )}
          onChange={(e, data) => {
            handleSelect(data, "mainSupplierId");
          }}
        />
        <InputField
          label=" Huvudleverantörens artikel nummer"
          name="suppliers_article_number"
          value={fields.suppliers_article_number}
          onChange={handleChange}
        />{" "}
        <Select
          required
          label="Aktiv"
          options={ReqOptions}
          value={ReqOptions.find((item) => item.value === fields.isActive)}
          onChange={(e, data) => {
            handleSelect(data, "isActive");
          }}
        />
      </div>
    </div>
  );
}

export default BasicInformationView;
