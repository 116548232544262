import React from "react";
import InputField from "../../../../components/Inputs/InputField";
import DateSelect from "../../../../components/Inputs/DateSelect";
import Button from "../../../../components/buttons/Button";

function ModuleViewForm({
  fields,
  setFields,
  handleChange,
  handleDateChange,
  handleSubmit,
  handleClose,
  setHasInputChanged,
}) {
  const handleClickOnChecked = (e) => {
    if (e.target.name === "is_checked") {
      if (fields.is_checked) setFields({ ...fields, is_checked: 0 });
      else setFields({ ...fields, is_checked: 1 });
    } else {
      if (fields.self_produced) setFields({ ...fields, self_produced: 0 });
      else setFields({ ...fields, self_produced: 1 });
    }

    setHasInputChanged(true);
  };
  if (fields) {
    return (
      <>
        <form onSubmit={handleSubmit} className="wrapper">
          <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
            <div
              className="form-grid-auto"
              style={{ flex: "2", height: "fit-content" }}
            >
              <InputField
                required
                label={"Väggbet"}
                name="wall_designation"
                onChange={handleChange}
                value={fields.wall_designation}
                disabled={fields.wall_designation ? true : false}
              />
              <InputField
                label="Modulnummer"
                name="category_name"
                value={fields.module_number}
                onChange={handleChange}
                disabled
              />

              <div>
                <InputField
                  required
                  label={"Vagga"}
                  name="module_stage"
                  onChange={handleChange}
                  value={fields.module_stage}
                  disabled={fields.module_stage ? true : false}
                />
              </div>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
                <div style={{ marginTop: "10px" }}>
                  <input
                    name="self_produced"
                    label={"Klarmarkerad"}
                    type="checkbox"
                    onChange={handleClickOnChecked}
                    checked={fields.self_produced === 1}
                    // value={fields}
                  ></input>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <p>Egentillverkad</p>
                </div>
              </div>
              <DateSelect
                required={fields.is_checked === 1}
                label={"Material uttag"}
                value={fields.material_withdrawal}
                onChange={(e) => handleDateChange("material_withdrawal", e)}
              />
              <InputField
                required={fields.is_checked === 1}
                name="work_time"
                label={"Faktisk spiktid"}
                onChange={handleChange}
                defaultValue={fields.work_time}
                type="number"
                step={"0.01"}
              />
              <DateSelect
                required={fields.is_checked === 1}
                label={"Datum spikad"}
                value={fields.date_nailed}
                onChange={(e) => handleDateChange("date_nailed", e)}
              />

              <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
                <div style={{ marginTop: "10px" }}>
                  <input
                    name="is_checked"
                    label={"Klarmarkerad"}
                    type="checkbox"
                    onChange={handleClickOnChecked}
                    checked={fields.is_checked === 1}
                    // value={fields}
                  ></input>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <p>Egenkontroll klar</p>
                </div>
              </div>
            </div>
            <div style={{ flex: "1" }}>
              <InputField
                label="Avikelser"
                multiline={true}
                name="deviations_notes"
                onChange={handleChange}
                defaultValue={fields.deviations_notes}
                maxLength={5000}
                minRows={4}
              />
            </div>
          </div>

          <div style={{ display: "flex", gap: "1em" }}>
            <Button color="success" type="submit">
              Spara ändringar
            </Button>
            <Button onClick={handleClose}>Stäng</Button>
          </div>
        </form>
      </>
    );
  }
}
export default ModuleViewForm;
