import React from "react";
import { Button, Modal } from "react-bootstrap";

const PictureModal = ({ showDialog, picture, message, onHide }) => {
  return (
    <Modal show={showDialog} onHide={onHide} style={{ top: "4%" }} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          src={picture}
          alt="Modal content"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PictureModal;
