import { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.css";

import Content from "../../components/Content";
import UserView from "./user/UserView";
import CreateOwnFieldValueView from "./createOwnFieldValues/CreateOwnFieldValueView";
import CreateMunicipalityView from "./createMunicipality/CreateMunicipalityView";
import StatusOverView from "./status/StatusOverView";
function AdminView() {
  let tab = JSON.parse(localStorage.getItem("adminview"))
    ? JSON.parse(localStorage.getItem("adminview"))
    : 1;
  const [lastTab, setLastTab] = useState(tab);

  useEffect(() => {
    let lastTab = JSON.parse(localStorage.getItem("adminview"));
    if (lastTab) {
      setLastTab(lastTab);
    }
    localStorage.clear();
  }, []);

  const tabs = [
    { label: "Skapa användare", id: 1 },
    { label: "Skapa egna statusar", id: 2 },
    { label: "Ladda in kommun", id: 3 },
    { label: "Statuskoll", id: 4 },
  ];
  return (
    <>
      <Content
        tabs={true}
        tabList={tabs}
        title={"Admin"}
        lastTab={lastTab}
        view="adminview"
      >
        <div value={1}>
          <UserView />
        </div>
        <div value={2}>
          <CreateOwnFieldValueView />
        </div>
        <div value={3}>
          <CreateMunicipalityView />
        </div>
        <div value={4}>
          <StatusOverView />
        </div>
      </Content>
    </>
  );
}

export default AdminView;
