import { QueryClientProvider, QueryClient } from "react-query";

import { useState, useEffect } from "react";
import { ProjectContext } from "./context/ProjectContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppRoutes from "./routes/AppRoutes";
import { useAuthContext } from "./hooks/useAuthContext";
import * as authAPI from "./api/auth/authAPI";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    // Primary: {
    //   main: "#9737a7",
    //   light: "#ca68d9",
    //   dark: "#660077",
    // },
    // Secondary: {
    //   main: "#2b2f3b",
    //   light: "#545865",
    //   dark: "#000515",
    // },
    // bg: {
    //   main: "#f2f4f3",
    // },
    // Error: {
    //   main: "#9739a7",
    //   light: "",
    //   dark: "",
    // },
    // Warning: {
    //   main: "#9739a7",
    //   light: "",
    //   dark: "",
    // },
    // Info: {
    //   main: "#9739a7",
    //   light: "",
    //   dark: "",
    // },
    // Success: {
    //   main: "#9739a7",
    //   light: "",
    //   dark: "",
    // },
  },
});

function handleClickViewChosenProjects(navigate) {
  const projects = JSON.parse(localStorage.getItem("projects"));

  localStorage.setItem("LocalProjectStrings", JSON.stringify(projects));

  let path = `/projektoversikt/${projects[0]}`;
  navigate(path);
}

function App() {
  const [projectList, setProjectList] = useState([]);
  const { auth, setAuth } = useAuthContext();
  const [wasIntiated, setWasIntiated] = useState(false);
  // const [sidebarOpen, setSidebarOpen] = useState(true);

  const [
    currentProjectToViewFromProjectList,
    setCurrentProjectToViewFromProjectList,
  ] = useState(0);

  useEffect(() => {
    authAPI
      .getAuthentication()
      .then((response) => {
        if (
          response.auth !== auth.auth &&
          response.user.email === auth.user.email
        )
          setAuth(response);
      })
      .catch((er) => {
        if (!auth) setAuth();
        console.log(er, "error received");
      });
  }, [auth, setAuth]);

  useEffect(() => {
    authAPI
      .getAuthentication()
      .then((response) => {
        setWasIntiated(true);

        setAuth(response);
      })
      .catch((er) => {
        setWasIntiated(true);
      });
  }, [setAuth]);

  if (wasIntiated)
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ProjectContext.Provider
            value={{
              projectList,
              setProjectList,
              currentProjectToViewFromProjectList,
              setCurrentProjectToViewFromProjectList,
              handleClickViewChosenProjects,
            }}
          >
            <AppRoutes wasIntiated={wasIntiated} />
            <ToastContainer />
          </ProjectContext.Provider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </ThemeProvider>
    );
}

export default App;
