import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import ColumnFilter from "../dataTableFilters/ColumnFilter";

// const { projectList, setProjectList } = useContext(ProjectContext);

export const SalesColumns = [
  {
    Header: "Projekt Nr",
    accessor: "proj_nr",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Project namn",
    accessor: "proj_name",
    disableFilters: true,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Project klart",
    accessor: "proj_klart",
    disableFilters: true,
  },
  {
    Header: "Kund",
    accessor: "kund",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kommun",
    accessor: "kommun",
    disableFilters: true,
  },
  {
    Header: "Ansvarig",
    accessor: "ansvarig",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Produktionssätt",
    accessor: "produktionssätt",
    disableFilters: true,
  },
  {
    Header: "Antal delar",
    accessor: "number_of_parts",
    disableFilters: true,
  },
  {
    Header: "Spikbudget",
    accessor: "spikbudget",
    disableFilters: true,
  },
  {
    Header: "Spikverkl",
    accessor: "spikverkl",
    disableFilters: true,
  },
  {
    Header: "Block Karlskoga",
    accessor: "block_karlskoga",
    disableFilters: true,
  },
  {
    Header: "Block Surahammar",
    accessor: "block_surahammar",
    disableFilters: true,
  },
  {
    Header: "Fabrik",
    accessor: "fabrik",
    disableFilters: true,
  },
  {
    Header: "Utlev",
    accessor: "utlev",
    disableFilters: true,
  },
  {
    Header: "Spikning",
    accessor: "spikning",
    disableFilters: true,
  },
  {
    Header: "Timmar_bokf",
    accessor: "timmar_bokf",
    disableFilters: true,
  },
  {
    Header: "Kaplista",
    accessor: "kaplista",
    disableFilters: true,
  },
  {
    Header: "Övrigt block mtrl",
    accessor: "ovrigt_block_mtrl",
    disableFilters: true,
  },
  {
    Header: "Block mtrl bokfört mån",
    accessor: "block_mtrl_bokfort_man",
    disableFilters: true,
  },
  {
    Header: "Storm  resning",
    accessor: "storm_resning",
    disableFilters: true,
  },
  {
    Header: "Fabrik Bulk",
    accessor: "fabrik_bulk",
    disableFilters: true,
  },
  {
    Header: "Fabrik Bulk Bokfort",
    accessor: "fabrik_bulk_bokfort",
    disableFilters: true,
  },
];
export const OverheadColumns = [
  {
    Header: "Projnr",
    accessor: "projektnr",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kundens namn",
    accessor: "kund1_kundnamn",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Säljare",
    accessor: "säljare",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "PL",
    accessor: "pl",
    disableFilters: true,
  },
  {
    Header: "Pi Inköp",
    accessor: "pl_inkop",
    disableFilters: true,
  },
  {
    Header: "A-rit",
    accessor: "a-rit",
    disableFilters: true,
  },
  {
    Header: "K-rit",
    accessor: "k-rit",
    disableFilters: true,
  },
  {
    Header: "Status",
    accessor: "Status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Fas",
    accessor: "fas",
    disableFilters: true,
  },
];
export const Different = [
  {
    Header: "Projnr",
    accessor: "projektnr",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kundens namn",
    accessor: "kund1_kundnamn",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Säljare",
    accessor: "säljare",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Status",
    accessor: "Status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];

export const AtaColumns = [
  {
    Header: "ÄTA Nr",
    accessor: "ata_ata_nr",
    disableFilters: true,
  },
  {
    Header: "Beställt",
    accessor: "ata_order_date",
    disableFilters: true,
  },
  {
    Header: "Skapad av",
    accessor: "registered_by",
    disableFilters: true,
  },
  {
    Header: "Aviserad av av",
    accessor: "supplier_name",
    disableFilters: true,
  },
  {
    Header: "Godkänd av",
    accessor: "confirmed_by",
    disableFilters: true,
  },
  {
    Header: "Godkänd datum",
    accessor: "ata_confirmed_date",
    disableFilters: true,
  },

  {
    Header: "Märke",
    accessor: "ata_label",
    disableFilters: true,
  },
  {
    Header: "Enhet",
    accessor: "ata_unit",
    disableFilters: true,
  },
  {
    Header: "Antal",
    accessor: "ata_quantity",
    disableFilters: true,
  },
  {
    Header: "Pris",
    accessor: "ata_price",
    disableFilters: true,
  },
  {
    Header: "Summa",
    accessor: "ata_sum",
    disableFilters: true,
  },
];
export const InkopColumns = [
  {
    Header: "Leverantör",
    accessor: "leverantor",
    disableFilters: true,
  },
  {
    Header: "IO nr",
    accessor: "io_nr",
    disableFilters: true,
  },
  {
    Header: "Beställt",
    accessor: "order_date",
    disableFilters: true,
  },
  {
    Header: "Önskat leveransdatum",
    accessor: "requested_delivery_date",
    disableFilters: true,
  },
  {
    Header: "Förväntat leveransdatum",
    accessor: "estimated_delivery_date",
    disableFilters: true,
  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
  },
  {
    Header: "Belopp",
    accessor: "belopp",
    disableFilters: true,
  },
  {
    Header: "Samlastning",
    accessor: "samlastning",
    disableFilters: true,
  },
  {
    Header: "Frakt",
    accessor: "frakt",
    disableFilters: true,
  },
];
export const InkopsItemsColumns = [
  {
    Header: "Litra",
    accessor: "Litra",
    disableFilters: true,
  },
  {
    Header: "Ant.(v)",
    accessor: "ant_v",
    disableFilters: true,
  },
  {
    Header: "Ant.(h)",
    accessor: "ant_h",
    disableFilters: true,
  },
  {
    Header: "Bredd/vinkelr",
    accessor: "bredd_vinkel",
    disableFilters: true,
  },
  {
    Header: "Höjd",
    accessor: "hojd",
    disableFilters: true,
  },
  {
    Header: "Ljudkrav",
    accessor: "ljudkrav",
    disableFilters: true,
  },
];

export const CommentColumns = [
  {
    Header: "Datum",
    accessor: "datum",
    disableFilters: true,
  },
  {
    Header: "Kommentar",
    accessor: "kommentar",
    disableFilters: true,
  },
  {
    Header: "Ärende",
    accessor: "arende",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Ansvarig",
    accessor: "ansvarig",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];

export const newProjColumns = [
  {
    Header: "Projektnummer",
    table: "project",
    accessor: "project_project_number",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Projektnamn",
    table: "project",
    accessor: "project_name",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kommun",
    table: "project",
    accessor: "projectMunicipality",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },

  {
    Header: "Projektledare",
    table: "project",
    accessor: "PLName",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Inköpare",
    table: "project",
    accessor: "Purchaser",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Säljare",
    table: "project",
    accessor: "Salesperson",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "K-rit",
    table: "project",
    accessor: "KRITName",
    Filter: SelectColumnFilter,
    filter: "includesStringString",
  },
  {
    Header: "A-rit",
    table: "project",
    accessor: "artName",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Projekttyp",
    table: "project",
    accessor: "projectType",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "FAS",
    table: "project",
    accessor: "projectPhase",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Leveranstyp",
    table: "project",
    accessor: "delivery_type",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kontraktdatum",
    accessor: "project_contract",
    disableFilters: true,
  },
  {
    Header: "Status",
    table: "project",
    accessor: "statusName",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kanal",
    table: "project",
    accessor: "leadChannelName",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];

// {
//   Header: "Välj",
//   Filter: ColumnFilter,
//   filter: "includesString",
//   Cell: (row, msg) => {
//     return (
//       <div style={{ "text-align": "center" }}>
//         <input
//           type="checkbox"
//           defaultChecked={row.value == "Yes" ? true : false}
//           onBlur={(event) =>
//             console.log(
//               parseInt(row.row.id),
//               row.column.id,
//               event.target.checked ? "Yes" : "No",
//               msg
//             )
//           }
//         />
//       </div>
//     );
//   },
// },

export const projectColums = [
  {
    Header: "Projnr",
    accessor: "projektnr",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kundens namn",
    accessor: "kund1_kundnamn",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Säljare",
    accessor: "säljare",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "PL",
    accessor: "pl",
    disableFilters: true,
  },
  {
    Header: "Pi Inköp",
    accessor: "pl_inkop",
    disableFilters: true,
  },
  {
    Header: "A-rit",
    accessor: "a-rit",
    disableFilters: true,
  },
  {
    Header: "K-rit",
    accessor: "k-rit",
    disableFilters: true,
  },
  {
    Header: "Status",
    accessor: "Status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Fas",
    accessor: "fas",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kommun",
    accessor: "Kommun",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Offert tkr",
    accessor: "offert_belopp",
    disableFilters: true,
  },

  {
    Header: "Kanal in",
    accessor: "Kanal",
    disableFilters: true,
  },
  {
    Header: "Inkommet lead datum",
    accessor: "lead_datum",
    disableFilters: true,
  },
  {
    Header: "LOI finns",
    accessor: "loi_finns",
    disableFilters: true,
  },
  {
    Header: "Typ",
    accessor: "typ",
    disableFilters: true,
  },
  {
    Header: "Offert datum",
    accessor: "offert_datum",
    disableFilters: true,
  },
  {
    Header: "Avtal datum",
    accessor: "avtal_datum",
    disableFilters: true,
  },
  {
    Header: "Anmälan datum",
    accessor: "anmalan_datum",
    disableFilters: true,
  },
  {
    Header: "Avrop datum",
    accessor: "avrop_datum",
    disableFilters: true,
  },
  {
    Header: "Lev fabrik",
    accessor: "lev_fab_datum",
    disableFilters: true,
  },
  {
    Header: "Överlämning kund",
    accessor: "lev_overlamning_kund",
    disableFilters: true,
  },
];

export const nyaInkopsColumns = [
  {
    Header: "Order",
    accessor: "ordernr",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Leverantör",
    accessor: "lev_namn",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Datum order",
    accessor: "order_datum",
    disableFilters: true,
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Vår referens",
    accessor: "ref",
    disableFilters: true,
  },
  {
    Header: "Lev adr nr",
    accessor: "lev_adress_nr",
    disableFilters: true,
  },
  {
    Header: "Leveransadress",
    accessor: "lev_adress",
    disableFilters: true,
  },
  {
    Header: "Önskat dat",
    accessor: "onskad_lev_datum",
    disableFilters: true,
  },
  {
    Header: "Bekr dag på plats",
    accessor: "bekraftat_lev_datum",
    disableFilters: true,
  },
  {
    Header: "Ankomst datum",
    accessor: "ankomst_datum",
    disableFilters: true,
  },
  {
    Header: "Kommentar",
    accessor: "kommentar",
    disableFilters: true,
  },
  {
    Header: "Konto",
    accessor: "konto",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Kalkyl",
    accessor: "kalkyl",
    disableFilters: true,
  },
];

export const inkopnyaitems = [
  {
    Header: "Art nr",
    accessor: "artikel_nr",
    disableFilters: true,
  },
  {
    Header: "Benämning",
    accessor: "benamning",
    disableFilters: true,
  },
  {
    Header: "Modul",
    accessor: "modul",
    disableFilters: true,
  },

  {
    Header: "Kommentar",
    accessor: "kommentar",
    disableFilters: true,
  },
  {
    Header: "Längd",
    accessor: "längd",
    disableFilters: true,
  },
  {
    Header: "Bredd",
    accessor: "bredd",
    disableFilters: true,
  },

  {
    Header: "Enhet",
    accessor: "enhet",
    disableFilters: true,
  },
  {
    Header: "Antal",
    accessor: "antal",
    disableFilters: true,
  },
  {
    Header: "Mängdenhet",
    accessor: "mangdenhet",
    disableFilters: true,
  },

  {
    Header: "Omräkning",
    accessor: "omrakning",
    disableFilters: true,
  },
  {
    Header: "Best enh",
    accessor: "bestenhet",
    disableFilters: true,
  },
  {
    Header: "Pris",
    accessor: "pris",
    disableFilters: true,
  },

  {
    Header: "Specialpris",
    accessor: "specialpris",
    disableFilters: true,
  },
  {
    Header: "Inköp rad",
    accessor: "inkop_rad",
    disableFilters: true,
  },
  {
    Header: "Vikt",
    accessor: "vikt",
    disableFilters: true,
  },

  {
    Header: "Saldo K",
    accessor: "saldo_k",
    disableFilters: true,
  },
  {
    Header: "Saldo S",
    accessor: "saldo_s",
    disableFilters: true,
  },
];
