import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Tooltip } from "@mui/material";
function handleClickOnNextProject(navigate, project) {
  const projects = JSON.parse(localStorage.getItem("LocalProjectStrings"));

  let index = projects.indexOf(project.id);
  if (index === projects.length - 1) {
  }
  let path = `/projektoversikt/${projects[index + 1]}`;
  navigate(path);

  // console.log(currentProjectToViewFromProjectList);
}

function handleClickOnPreviousProject(navigate, project) {
  const projects = JSON.parse(localStorage.getItem("LocalProjectStrings"));

  let index = projects.indexOf(project.id);
  if (index === projects.length - 1) {
  }
  let path = `/projektoversikt/${projects[index - 1]}`;
  navigate(path);

  // console.log(currentProjectToViewFromProjectList);
}

function ProjectHeader({ project, preventPageChange }) {
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  const [currentProjects, setCurrentProjects] = useState({
    totalt: 0,
    index: 0,
  });
  useEffect(() => {
    const projects = JSON.parse(localStorage.getItem("LocalProjectStrings"));

    if (projects) {
      let index = projects.indexOf(project.id);
      setCurrentProjects({ total: projects.length, index: index });
      index === 0
        ? setDisablePreviousButton(true)
        : setDisablePreviousButton(false);
      if (index === projects.length - 1) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    }
  }, [project]);
  const navigate = useNavigate();
  const projects = JSON.parse(localStorage.getItem("LocalProjectStrings"));

  return (
    <div
      style={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{project.project_project_number + " " + project.project_name}</div>
      <div
        style={{
          marginTop: "5px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {projects ? (
          <>
            {" "}
            <Tooltip
              title={
                <strong style={{ fontSize: "0.85rem" }}>
                  {preventPageChange
                    ? "Spara ändringar innan du går vidare"
                    : ""}
                </strong>
              }
            >
              <div>
                {" "}
                <Button
                  variant="text"
                  disabled={disablePreviousButton}
                  startIcon={<NavigateBeforeIcon />}
                  onClick={() =>
                    handleClickOnPreviousProject(navigate, project)
                  }
                >
                  Föregående projekt
                </Button>
              </div>
            </Tooltip>
            <div style={{ fontSize: "0.85rem" }}>
              Projekt {currentProjects.index + 1} / {currentProjects.total}
            </div>
            <Tooltip
              title={
                <strong style={{ fontSize: "0.85rem" }}>
                  {preventPageChange
                    ? "Spara ändringar innan du går vidare"
                    : ""}
                </strong>
              }
            >
              <div>
                {" "}
                <Button
                  variant="text"
                  disabled={disableNextButton}
                  endIcon={<NavigateNextIcon />}
                  onClick={() => handleClickOnNextProject(navigate, project)}
                >
                  Nästa projekt
                </Button>
              </div>
            </Tooltip>
          </>
        ) : null}
      </div>
    </div>

    // <div className="row mt-3">
    //   <div className="col-xl-3 ">
    //     <div className="card overflow-hidden ">
    //       <div className="card-content">
    //         <div className="card-body cleartfix">
    //           <div className="media align-items-stretch">
    //             <div className="align-self-center">
    //               <i className="icon-pencil primary font-large-2 mr-2"></i>
    //             </div>

    //             <div className="align-self-center">
    //               <h1>Projektnr : {project.projektnr}</h1>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="col-xl-6 ">
    //     <div className="card">
    //       <div className="card-content">
    //         <div className="card-body cleartfix">
    //           <div className="media align-items-stretch">
    //             <div className="align-self-center">
    //               <i className="icon-speech warning font-large-2 mr-2"></i>
    //             </div>

    //             <div className="align-self-center">
    //               <h1>Projektnamn : {project.projektnamn}</h1>
    //             </div>
    //           </div>

    //         </div>

    //       </div>

    //     </div>
    //     {(() => {
    //             if (projects) {
    //               return  <button
    //               style={{float: "right"}}

    //               className="btn btn-primary "
    //                 onClick={() =>
    //                   handleClickOnNextProject(navigate,project)
    //                 }
    //               >
    //                 Nästa projekt
    //               </button>;
    //             }
    //           })()}

    //   </div>

    // </div>
  );
}

export default ProjectHeader;
