import React from "react";
import { Modal } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function ErrandLogModal({
  errandLogToShow,
  showModal,
  hideModal,
  queryClient,
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(0);
  if (errandLogToShow)
    return (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            hideModal();
            queryClient.invalidateQueries();
          }}
          centered
        >
          <Modal.Header closeButton>Loggar</Modal.Header>

          <Modal.Body>
            {errandLogToShow.length > 0 ? (
              <>
                {errandLogToShow?.map((item, i) => (
                  <div
                    style={{
                      marginBottom: "1em",
                    }}
                    key={i}
                  >
                    <Accordion
                      expanded={open && selectedID === i}
                      onChange={() => {
                        setOpen(!open);
                        setSelectedID(i);
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>Loggades: {item.timestamp}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ marginBottom: "1em" }}>
                          <Typography>{item.previous_status}</Typography>
                        </div>

                        <Typography>{item.comment}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
              </>
            ) : (
              <>Finns ingen logg under detta ärende</>
            )}
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
}

export default ErrandLogModal;
