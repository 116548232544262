import { Button, Modal } from "react-bootstrap";

const DialogBox = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  message,
}) => {
  return (
    <Modal show={showDialog} style={{ top: "10%" }}>
      <Modal.Header>
        <Modal.Title>Varning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message.split("\n").map(function (item, idx) {
          return (
            <strong key={idx}>
              {item}
              <br />
            </strong>
          );
        })}
        <br />
        <br /> Är du säker att du vill byta sida?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={cancelNavigation}>
          Nej
        </Button>
        <Button variant="danger" onClick={confirmNavigation}>
          Ja
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogBox;
