import React from "react";
import InputField from "../../../../../components/Inputs/InputField";
function AccumulatedTimeView({ objectWithTimeProperties }) {
  return (
    <>
      <div className="form-grid-auto">
        <InputField
          multiline
          name="time_reg_time"
          label={"Antal delar"}
          value={objectWithTimeProperties.numberOfParts.toFixed(2)}
        />
        <InputField
          multiline
          name="time_reg_time"
          label={"Totalt antal moduler"}
          value={objectWithTimeProperties.numberOfModules}
        />

        <InputField
          multiline
          name="time_reg_time"
          label="Antal moduler klarmarkerade"
          value={objectWithTimeProperties.numberOfModulesChecked}
        />

        <InputField
          multiline
          label="Total ackumulerad tid"
          value={objectWithTimeProperties.accummulatedtime}
        />
      </div>
    </>
  );
}

export default AccumulatedTimeView;
