import React, { useEffect, useState } from "react";
import * as statusAPI from "../../../../api/status/statusAPI";
import Button from "../../../../components/buttons/Button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import "../../../../styles/status/Status.css";
import UpdateConfirmation from "../../../../components/modals/UpdateConfirmation";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineEditOff } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import StatusGroupView from "./statusComponents/StatusGroupView";
import WarningIcon from "@mui/icons-material/Warning";
import logo from "../../../../assets/gifs/loading.gif";
import timeline from "../../../../assets/Images/processexplanation.png";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
import PictureModal from "../../../../components/modals/PictureModal";

function StatusView({
  projectId,
  clientDeliveryDate,
  factoryDeliveryDate,
  preventPageChange,
  setPreventPageChange,
}) {
  const [statusGroups, setStatusGroups] = useState();
  const [implementRules, setImplementRules] = useState(false);
  const [editView, setEditView] = useState(false);
  const [showExplaination, setShowExplaination] = useState(false);

  const queryClient = useQueryClient();
  const { data: responseData } = useQuery(
    ["projectstatusgroups", projectId],
    () => statusAPI.getStatusGroupsForProject(projectId),
    {
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (typeof responseData === "object") setStatusGroups(responseData);

    if (clientDeliveryDate && factoryDeliveryDate) {
      setImplementRules(true);
    } else {
      setImplementRules(false);
    }
  }, [responseData, clientDeliveryDate, factoryDeliveryDate]);

  const [displayUpdateConfirmationModal, setDisplayUpdateConfirmationModal] =
    useState(false);

  const { mutate } = useMutation(statusAPI.updateStatusGroupsForProject, {
    onSettled: (e) => {
      queryClient.invalidateQueries();
      setPreventPageChange(false);

      toastSuccess("Status uppdaterades");
    },
    onError: (err) => {
      toastError("Gick inte att uppdatera");
    },
  });

  const handleSubmit = () => {
    console.log(statusGroups, "statusGroups");
    mutate(statusGroups);
    setDisplayUpdateConfirmationModal(false);
  };

  const handleChangeStatusRow = (groupIndex, statusIndex, key, value) => {
    setPreventPageChange(true);
    let tempGroups = statusGroups;
    tempGroups[groupIndex].statusRows[statusIndex][key] = value;
    setStatusGroups([...tempGroups]);
  };
  if (!statusGroups)
    return (
      <div style={{ position: "absolute", left: "40%", top: "400%" }}>
        <img
          src={logo}
          alt="loading..."
          style={{ height: "200px", width: "200px" }}
        />
      </div>
    );
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "start",
          marginBottom: "1em",
        }}
      >
        <Button
          onClick={() => setEditView(!editView)}
          endIcon={editView ? <MdOutlineEditOff /> : <FiEdit3 />}
          variant="text"
        >
          {editView ? "Stäng" : "Redigera"}
        </Button>
        <Button
          onClick={() => setShowExplaination(true)}
          endIcon={<RiQuestionnaireLine />}
          variant="text"
        >
          Processförklaring
        </Button>
        {!implementRules && (
          <>
            <div
              style={{
                textAlign: "center",
                color: "red",
                border: "thin solid red",
                padding: "0.5em",
                borderRadius: "5px",
                backgroundColor: "#f38f7733",
                display: "flex",
                gap: "0.5em",
              }}
            >
              <div>Samtliga datum är ej satta för att regler ska funka</div>

              <WarningIcon />
            </div>
          </>
        )}
      </div>

      <div
        className="status-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.5em",
        }}
      >
        {statusGroups ? (
          statusGroups.map((group, index) => {
            return group.statusRows.some((row) => row.is_shown) ? (
              <StatusGroupView
                key={index}
                group={group}
                groupIndex={index}
                implementRules={implementRules}
                editView={editView}
                setStatusGroups={setStatusGroups}
                handleChangeStatusRow={handleChangeStatusRow}
                clientDeliveryDate={clientDeliveryDate}
                factoryDeliveryDate={factoryDeliveryDate}
              />
            ) : (
              <>
                {editView ? (
                  <StatusGroupView
                    key={index}
                    group={group}
                    groupIndex={index}
                    implementRules={implementRules}
                    editView={editView}
                    setStatusGroups={setStatusGroups}
                    handleChangeStatusRow={handleChangeStatusRow}
                    clientDeliveryDate={clientDeliveryDate}
                    factoryDeliveryDate={factoryDeliveryDate}
                  />
                ) : (
                  <></>
                )}
              </>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <Button
        disabled={!preventPageChange}
        onClick={() => setDisplayUpdateConfirmationModal(true)}
        color="success"
      >
        Spara
      </Button>
      <PictureModal
        showDialog={showExplaination}
        onHide={() => setShowExplaination(false)}
        picture={timeline}
        title={""}
      />
      <UpdateConfirmation
        showModal={displayUpdateConfirmationModal}
        confirmModal={handleSubmit}
        hideModal={() => setDisplayUpdateConfirmationModal(false)}
      />
    </>
  );
}

export default StatusView;
