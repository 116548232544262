import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import UpdateConfirmation from "../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import * as articleAPI from "./../../../api/register/articleApi";
import * as supplierAPI from "./../../../api/register/supplierApi";
import * as pricelistAPI from "./../../../api/register/priceListApi";
import Select from "../../../components/Inputs/Select";
import InputField from "../../../components/Inputs/InputField";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function PriceListItemView({ showModal, hideModal, priceListItemToShow }) {
  let navigate = useNavigate();
  // let priceItem = useParams();
  const [supplierList, setSupplierList] = useState();
  const [articleList, setArticleList] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayUpdateConfirmationModal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [fields, setFields] = useState();

  useEffect(() => {
    if (priceListItemToShow)
      pricelistAPI
        .getPriceListItem(priceListItemToShow)
        .then((item) => setFields(item));
  }, [priceListItemToShow]);

  const queryClient = useQueryClient();

  const { mutate } = useMutation(pricelistAPI.updatePriceListItem, {
    onSuccess: () => {
      toastSuccess("Pris uppdaterades");
      hideModal();
      queryClient.invalidateQueries(["pricelist"]);
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "supplierId" || name === "articleId") {
      value = parseInt(value);
    }
    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
  };

  const handleSelectSupplier = (e, data) => {
    setFields({ ...fields, supplierId: data.value });
    setHasInputChanged(true);
  };
  const handleSelectArticle = (e, data) => {
    setFields({ ...fields, articleId: data.value });
    setHasInputChanged(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      setDisplayUpdateConfirmationModal(true);
    }
  };

  const confirmChange = () => {
    if (hasInputChanged) {
      mutate(fields);
      setDisplayUpdateConfirmationModal(false);
      setHasInputChanged(false);
    }
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const hideUpdateConfirmationModal = () => {
    setDisplayUpdateConfirmationModal(false);
  };

  useEffect(() => {
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSupplierList(suppliers));
  }, []);
  useEffect(() => {
    articleAPI
      .GetAllArticlesNames()
      .then((articles) => setArticleList(articles));
  }, []);

  const confirmClose = () => {
    navigate(`/register/`);
  };
  if (fields && supplierList && articleList)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Prisuppgift:</Modal.Header>

        <Modal.Body>
          <form>
            <div
              style={{
                display: "grid",
                gap: "1em",
                marginTop: "10px",
              }}
            >
              <div>
                <Select
                  required
                  label="Leverantör"
                  options={supplierList}
                  value={supplierList.find(
                    (item) => item.value === fields.supplierId
                  )}
                  onChange={handleSelectSupplier}
                />
              </div>
              <div>
                <Select
                  required
                  label="Artikel"
                  options={articleList}
                  value={articleList.find(
                    (item) => item.value === fields.articleId
                  )}
                  onChange={handleSelectArticle}
                />
              </div>
              <div>
                <InputField
                  required
                  label="hemtagingskost (ex frakt och emballage)"
                  name="retrieval_cost"
                  onChange={handleChange}
                  value={fields.retrieval_cost}
                />
              </div>
              <div>
                <InputField
                  required
                  label="Inköpspris"
                  name="purchase_price"
                  onChange={handleChange}
                  value={fields.purchase_price}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "1em", marginTop: "10px" }}>
              <Button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                color="success"
              >
                Spara
              </Button>
              <Button type="button" onClick={(e) => hideModal()}>
                Stäng
              </Button>
            </div>

            <UpdateConfirmation
              showModal={displayUpdateConfirmationModal}
              confirmModal={confirmChange}
              hideModal={hideUpdateConfirmationModal}
            />
            <CloseFonfirmation
              showModal={displayConfirmationModal}
              confirmModal={confirmClose}
              hideModal={hideConfirmationModal}
            />
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
}

export default PriceListItemView;
