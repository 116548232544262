import React from "react";
import Select from "../../../../components/Inputs/Select";
import InputField from "../../../../components/Inputs/InputField";
import { UnitOptions } from "../../../../components/customSelect/SelectOptions";

function UnitInformationView({ fields, handleSelect, handleChange }) {
  return (
    <div className="wrapper" style={{ flex: "1" }}>
      <div className="wrapper-title">Enhet</div>
      <div className="form-grid-auto">
        {" "}
        <Select
          required
          label="Enhet"
          options={UnitOptions}
          value={UnitOptions.find((item) => item.value === fields.unit)}
          onChange={(e, data) => {
            handleSelect(data, "unit");
          }}
        />
        <Select
          required
          label="Pris enhet"
          options={UnitOptions}
          value={UnitOptions.find((item) => item.value === fields.price_unit)}
          onChange={(e, data) => {
            handleSelect(data, "price_unit");
          }}
        />
        <Select
          required
          label="Beställningsenhet"
          options={UnitOptions}
          value={UnitOptions.find((item) => item.value === fields.order_unit)}
          onChange={(e, data) => {
            handleSelect(data, "order_unit");
          }}
        />
        <InputField
          type="number"
          required
          label="Omräkningsfaktor"
          step={"0.01"}
          name="recount_unit"
          minNumbValue={"0.01"}
          value={fields.recount_unit}
          onChange={handleChange}
        />
        <Select
          type="number"
          required
          label="Vikt enhet"
          minNumbValue="0"
          options={UnitOptions}
          value={UnitOptions.find((item) => item.value === fields.weight_unit)}
          onChange={(e, data) => {
            handleSelect(data, "weight_unit");
          }}
        />
        <InputField
          type="number"
          required
          label="Vikt (kg/x)"
          name="weight"
          step={"0.01"}
          minNumbValue="0"
          value={fields.weight}
          onChange={handleChange}
        />
        <InputField
          type="number"
          required
          label="volym"
          step={"0.01"}
          name="volym"
          minNumbValue="0"
          value={fields.volym}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default UnitInformationView;
