import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as articleAPI from "./../../../api/register/articleApi";
import * as supplierAPI from "./../../../api/register/supplierApi";
import * as pricelistAPI from "./../../../api/register/priceListApi";
import * as accountAPI from "./../../../api/register/accountApi";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
import Button from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function AddPriceListItemView({ showModal, hideModal }) {
  let navigate = useNavigate();
  const [supplierList, setSupplierList] = useState();
  const [articleList, setArticleList] = useState();
  const [accountList, setAccountList] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [supplierToDisplay, setSupplierToDisplay] = useState();
  const [articleToDisplay, setArticleToDisplay] = useState();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const queryClient = useQueryClient();

  const [fields, setFields] = useState({
    articleId: null,
    supplierId: null,
    retrieval_cost: "",
    purchase_price: "",
  });

  useEffect(() => {
    setFields({
      articleId: null,
      supplierId: null,
      retrieval_cost: "",
      purchase_price: "",
    });
    setArticleToDisplay();
    setSupplierToDisplay();
  }, [showModal]);

  useEffect(() => {
    if (isSubmit) {
      mutate(fields);
      setHasInputChanged(false);
    }
  }, [isSubmit, fields]);

  useEffect(() => {
    if (fields.supplierId)
      supplierAPI
        .getSupplier(fields.supplierId)
        .then((supplier) => setSupplierToDisplay(supplier));
  }, [fields.supplierId]);

  useEffect(() => {
    if (fields.articleId)
      articleAPI
        .getArticle(fields.articleId)
        .then((article) => setArticleToDisplay(article));
  }, [fields.articleId]);

  useEffect(() => {
    supplierAPI
      .getAllSupplierNames()
      .then((suppliers) => setSupplierList(suppliers));
    accountAPI
      .getAllAccountNames()
      .then((accounts) => setAccountList(accounts));
  }, []);

  useEffect(() => {
    articleAPI
      .getAllArticleNumbers()
      .then((articles) => setArticleList(articles));
  }, []);

  const { mutate } = useMutation(pricelistAPI.newPriceListItem, {
    onSuccess: () => {
      toastSuccess("Pris skapades");
      setIsSubmit(false);
      hideModal();
      queryClient.invalidateQueries(["pricelist"]);
    },
    onError: (e) => {
      if (e.response.status === 409) {
        toastError("Finns redan ett pris för artikel & leverantör!");
        setIsSubmit(false);
      }
      if (e.response.status === 500) {
        toastError("Gick inte att skapa pris");
        setIsSubmit(false);
      }
      console.log("error fron mutate");
    },
  });

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "supplierId" || name === "articleId") {
      value = parseInt(value);
    }
    setFields({ ...fields, [name]: value });
    setHasInputChanged(true);
  };

  const handleSelectSupplier = (e, data) => {
    setFields({ ...fields, supplierId: data.value });
    setHasInputChanged(true);
  };
  const handleSelectArticle = (e, data) => {
    setFields({ ...fields, articleId: data.value });
    setHasInputChanged(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmit(true);
  };

  const IsObjectEmpty = (obj) => {
    for (let key in obj) {
      if (obj[key] !== undefined) {
        return false;
      }
    }
    return true;
  };

  const handleClose = (e) => {
    e.preventDefault();
    const isMyObjectEmpty = IsObjectEmpty(fields);
    if (!isMyObjectEmpty) {
      if (hasInputChanged) {
        setDisplayConfirmationModal(true);
      } else {
        confirmClose();
      }
    } else if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      handleClose();
    }
  };

  const confirmClose = () => {
    navigate(`/register/`);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  if (supplierList && articleList && accountList)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Registrera ett nytt pris:</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "grid",
                gap: "1em",
                marginTop: "10px",
              }}
            >
              <div>
                <Select
                  required
                  label={"Artikel"}
                  options={articleList}
                  value={articleList.find(
                    (item) => item.value === fields.articleId
                  )}
                  onChange={handleSelectArticle}
                />
                {articleToDisplay ? (
                  <div className="info-container">
                    <p> Artikel : {articleToDisplay.article_name}</p>
                    <p> Artikelnr : {articleToDisplay.article_number}</p>
                  </div>
                ) : null}
              </div>
              <div>
                <Select
                  required
                  label={"Leverantör"}
                  options={supplierList}
                  value={supplierList.find(
                    (item) => item.value === fields.supplierId
                  )}
                  onChange={handleSelectSupplier}
                />
              </div>
              {supplierToDisplay ? (
                <div className="info-container">
                  <p>Referens : {supplierToDisplay.reference}.</p>
                  <p>Tel. nr : {supplierToDisplay.phonenumber}</p>
                </div>
              ) : null}
              <div>
                <InputField
                  type="number"
                  minNumbValue={0}
                  required
                  name="retrieval_cost"
                  label={"Hemtagingskost (ex frakt och emballage)"}
                  onChange={handleChange}
                  value={fields.retrieval_cost}
                />
              </div>
              <div>
                <InputField
                  type="number"
                  minNumbValue={0}
                  required
                  name="purchase_price"
                  label={"Inköpspris"}
                  onChange={handleChange}
                  value={fields.purchase_price}
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: "1em", marginTop: "10px" }}>
              <Button type="submit" color="success">
                Lägg till
              </Button>
              <Button type="button" onClick={(e) => hideModal()}>
                Stäng
              </Button>
            </div>
            <CloseFonfirmation
              showModal={displayConfirmationModal}
              confirmModal={handleClose}
              hideModal={hideConfirmationModal}
            />
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
}

export default AddPriceListItemView;
