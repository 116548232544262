import React from "react";

function DocumentOverView() {
  return (
    <div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://malarvillan.sharepoint.com/:f:/s/ProjektMlarvillan/Eu8xMPoE0yVCocwE4CE-6h0BeuNP0ozHWTnHty9Wv5madA?e=MsaojG"
      >
        Till Dokumentbank på teams
      </a>
    </div>
  );
}

export default DocumentOverView;
