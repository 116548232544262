import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputField from "../../components/Inputs/InputField";
import { useForm } from "react-hook-form";
import Button from "../../components/buttons/Button";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/login/login.css";
function LoginOTPView({
  hideModal,
  showModal,
  user,
  loginStep2,
  resetPw,
  setResetPw,
  forgottenPasswordStep2,
}) {
  const [userOTPEntry, setUserOTPEntry] = useState();
  const { handleSubmit } = useForm({});
  const { setAuth } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    setUserOTPEntry();
  }, [showModal]);
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          setUserOTPEntry(undefined);
        }}
        centered
        size="sm"
      >
        <Modal.Header closeButton>ANGE VERIFIKATIONSKOD</Modal.Header>

        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            {resetPw ? (
              <p>
                Finns användarnamn registrerat har verifikationkod via sms
                skickats
              </p>
            ) : (
              <p>Verifikationskod skickad</p>
            )}
          </div>
          <form
            className="form-grid-auto"
            onSubmit={handleSubmit(async () => {
              if (resetPw) {
                let recoverySent = await forgottenPasswordStep2(
                  userOTPEntry,
                  user
                );
                if (recoverySent) {
                  setResetPw(false);
                  hideModal();
                }
              } else {
                console.log("loggar in");
                setAuth(await loginStep2(userOTPEntry, user));
                navigate(from, { replace: true });
              }
            })}
          >
            <div>
              <div style={{ marginBottom: "1em" }}></div>
              <InputField
                sx={{ input: { textAlign: "center" } }}
                required
                label={"ANGE KOD"}
                name={"user_otp_entry"}
                value={userOTPEntry}
                onChange={(e) => setUserOTPEntry(e.target.value)}
              />{" "}
              <div style={{ marginTop: "1em", textAlign: "center" }}>
                <Button
                  type="submit"
                  color={"primary"}
                  sx={{ width: "250px", color: "white" }}
                  size="large"
                >
                  Logga in
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginOTPView;
