import React from "react";
import Button from "@mui/material/IconButton";

export default function IconButton(props) {
  return (
    <Button
      aria-label={props.ariaLabel}
      style={{
        background: props.background ? props.background : undefined,
        color: props.color ? props.color : undefined,
        fontSize: props.fontSize ? props.fontSize : undefined,
        padding: props.padding ? props.padding : undefined,
        boxShadow: props.boxShadow ? props.boxShadow : undefined,
      }}
    >
      {props.icon}
    </Button>
  );
}
