import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";
import ColumnFilter from "../dataTableFilters/ColumnFilter";
import SelectColumnFilterTrueFalse from "../dataTableFilters/SelectColumnFilterTrueFalse";

export const ArticleListColumns = [
  {
    Header: "Artikel nr",
    table: "article",
    accessor: "article_number",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Artikel",
    table: "article",
    accessor: "article_name",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Huvudleverantör",
    table: "article",
    accessor: "supplier_name",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Leverantörs artikel nr",
    table: "article",
    accessor: "suppliers_article_number",
    Filter: ColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Konto",
    table: "article",
    accessor: "Description",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Enhet",
    accessor: "unit",
    disableFilters: true,
  },
  {
    Header: "Längd krävs",
    table: "article",
    accessor: "length_required",
    Cell: (props) => {
      return <>{props?.row?.original?.length_required ? <>Ja</> : <> Nej</>}</>;
    },
    Filter: SelectColumnFilterTrueFalse,
    filter: "includesString",
  },
  {
    Header: "Bredd krävs",
    table: "article",
    accessor: "width_required",
    Cell: (props) => {
      return <>{props?.row?.original?.width_required ? <>Ja</> : <> Nej</>}</>;
    },
    Filter: SelectColumnFilterTrueFalse,
    filter: "includesString",
  },

  {
    Header: "Best enh",
    accessor: "order_unit",
    disableFilters: true,
  },
  {
    Header: "Pris enh",
    accessor: "price_unit",
    disableFilters: true,
  },
  {
    Header: "Omräkning",
    table: "article",
    accessor: "recount_unit",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
  {
    Header: "Vikt (kg/x)",
    accessor: "weight",
    disableFilters: true,
  },
  {
    Header: "Vikt enhet",
    accessor: "weight_unit",
    disableFilters: true,
  },
];
