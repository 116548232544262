import React from "react";
import "../../styles/navbar/Hamburger.css";
import { useTheme } from "@mui/material/styles";

export default function Hamburger(props) {
  const theme = useTheme();

  return (
    <div className="burger" onClick={props.handleChange}>
      <div
        style={{ background: theme.palette.primary.main }}
        className={
          props.active
            ? "burger-layer layer-top active-top"
            : "burger-layer layer-top"
        }
      ></div>
      <div
        style={{ background: theme.palette.primary.main }}
        className={
          props.active
            ? "burger-layer layer-mid active-mid"
            : "burger-layer layer-mid"
        }
      ></div>
      <div
        style={{ background: theme.palette.primary.main }}
        className={
          props.active
            ? "burger-layer layer-bot active-bot"
            : "burger-layer layer-bot"
        }
      ></div>
    </div>
  );
}
