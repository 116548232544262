import React from "react";
import DateSelect from "../../../../../components/Inputs/DateSelect";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import { Typography } from "@mui/material";
import { OrderStatusOptions } from "../../../../../components/customSelect/SelectOptions";
import Button from "../../../../../components/buttons/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
function OrderHead({
  fields,
  setFields,
  handleChange,
  handleSelectSupplier,
  handleSelectStatus,
  supplierList,
  handleSelectAccount,
  accountList,
  blockPurchaseEdit,
  supplierToDisplay,
  setHasInputChanged,
}) {
  const HandleSeeParentOrder = () => {
    window.location = `/projektoversikt/inkop/${fields.parent_order}`;
  };
  if (fields)
    return (
      <>
        {fields.order_number ? (
          <div style={{ marginBottom: "1.5rem" }}>
            <Typography variant="h5" component="h2">
              Ordernummer: {fields.order_number}{" "}
              {/* <Tooltip title={<h6>{toolTipText}</h6>}>
              <InfoIcon />
            </Tooltip> */}
            </Typography>
          </div>
        ) : (
          <></>
        )}
        <div className="wrapper">
          <div className="form-grid-auto">
            <div
              style={{
                gridColumn: "1 /4",
              }}
            >
              <Select
                required={true}
                label={"Leverantör"}
                onChange={handleSelectSupplier}
                options={supplierList}
                value={supplierList.find(
                  (item) => item.value === fields.supplierId
                )}
              />
            </div>
            <InputField
              readOnly={true}
              label={"Skapades"}
              value={fields.created_date}
            />
            <InputField
              readOnly={true}
              label={"Orderdatum"}
              value={fields.order_date ? fields.order_date : ""}
            />
            <DateSelect
              readOnly={blockPurchaseEdit}
              label={"Önskat leveransdatum"}
              emptyLabel={"Önskat leveransdatum"}
              value={fields.requested_delivery_date}
              onChange={(newValue) => {
                setFields({ ...fields, requested_delivery_date: newValue });
                setHasInputChanged(true);
              }}
            />
            <DateSelect
              label={"Bekräftat leveransdatum"}
              value={fields.confirmed_delivery_date}
              onChange={(newValue) => {
                setFields({ ...fields, confirmed_delivery_date: newValue });
                setHasInputChanged(true);
              }}
            />{" "}
            <DateSelect
              required={
                fields.status === "Levererad" ||
                fields.status === "Mottagningskontrollerat"
              }
              label={"Verklig leverans"}
              value={fields.real_delivery ? fields.real_delivery : null}
              onChange={(newValue) => {
                setFields({ ...fields, real_delivery: newValue });
                setHasInputChanged(true);
              }}
            />
            <DateSelect
              label={"Monterad"}
              value={fields.montage_date ? fields.montage_date : null}
              onChange={(newValue) => {
                setFields({ ...fields, montage_date: newValue });
                setHasInputChanged(true);
              }}
            />
            <Select
              required
              label={"Status"}
              onChange={handleSelectStatus}
              options={OrderStatusOptions.filter(
                (option) => option.label !== "Importerad"
              )}
              value={OrderStatusOptions.find(
                (item) => item.value === fields.status
              )}
              disabled={blockPurchaseEdit}
            />
            <Select
              required
              label={"Konto"}
              onChange={handleSelectAccount}
              options={accountList}
              value={accountList.find((item) => item.value === fields.account)}
              disabled={blockPurchaseEdit}
            />
            {fields.order_number ? (
              <>
                <InputField
                  required={
                    fields.status === "Fakturerat" ||
                    fields.status === "Internfakturerad"
                  }
                  label={"Fakturerat belopp"}
                  name="invoiced_amount"
                  value={fields.invoiced_amount}
                  onChange={handleChange}
                />
                <InputField
                  required={
                    fields.status === "Fakturerat" ||
                    fields.status === "Internfakturerad"
                  }
                  label={"Faktureranummer"}
                  name="invoice_number"
                  value={fields.invoice_number}
                  onChange={handleChange}
                />
              </>
            ) : (
              <></>
            )}{" "}
            {fields.order_number ? (
              <div>
                <InputField
                  label={"Ordermail"}
                  name={"order_mail"}
                  value={
                    supplierToDisplay?.order_mail
                      ? supplierToDisplay?.order_mail
                      : ""
                  }
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
            ) : (
              <></>
            )}{" "}
            {fields.parent_order ? (
              <div>
                <Button
                  startIcon={<UploadFileIcon />}
                  onClick={HandleSeeParentOrder}
                >
                  Se huvudorder
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div
            style={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "space-between",
              gap: "1em",
            }}
          >
            <InputField
              label={"Förtydligande till leverantör"}
              name="order_comment"
              value={fields.order_comment}
              onChange={handleChange}
              multiline
              minRows={4}
              maxLength={5000}
            />
            <InputField
              label={"Kommentar"}
              name="comment"
              value={fields.comment}
              onChange={handleChange}
              multiline
              minRows={4}
              maxLength={5000}
            />
          </div>
        </div>
      </>
    );
}

export default OrderHead;
