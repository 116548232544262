import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

const DraggableListItem = ({ item, index, onClick }) => {
  const classes = useStyles();
  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <ListItem
          sx={{ height: "50px", paddingLeft: "0" }}
          onDoubleClick={() => onClick(item)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ""}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(121 182 243 / 22%)",
              borderRadius: "3px",
              fontStyle: "bold",
              color: "#1976d2",
              padding: "5px",
              marginRight: "1rem",
              fontWeight: "500",
              height: "30px",
              aspectRatio: "1/1",
            }}
          >
            {index + 1}#
          </div>

          <ListItemText
            primary={item.name ? item.name : item.title}
            secondary={
              item.name
                ? item.lead_time_1_towards || item.lead_time_2_towards
                  ? "Regler: " +
                    item.lead_time_1_towards +
                    "," +
                    item.lead_time_2_towards
                  : "Inga regler satta"
                : undefined
            }
          />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
