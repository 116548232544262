import React, { useEffect, useState } from "react";
import * as api from "../../../api/register/supplierApi";
import * as accountAPI from "../../../api/register/accountApi";
import * as constructionpartAPI from "../../../api/register/constructionpartApi";
import * as materialAPI from "../../../api/register/materialApi";
import * as storagespaceAPI from "../../../api/register/storagespaceApi";
import * as climateAPI from "../../../api/register/climateApi";
import UpdateConfirmation from "../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import Button from "../../../components/buttons/Button";
import BasicInformationView from "./components/BasicInformationView";
import UnitInformationView from "./components/UnitInformationView";
import RequirementInformationView from "./components/RequirementInformationView";
import OtherInformationView from "./components/OtherInformationView";
function ArticleForm({
  fields,
  handleSubmit,
  handleChange,
  handleClose,
  handleSelect,
  confirmClose,
  hideConfirmationModal,
  hideUpdateConfirmationModal,
  confirmChange,
  displayConfirmationModal,
  displayUpdateConfirmationModal,
}) {
  const [supplierList, setSupplierList] = useState();
  const [accountList, setAccountList] = useState();
  const [partList, setPartList] = useState();
  const [materialList, setMaterialList] = useState();
  const [storagespaceList, setStoragespaceList] = useState();
  const [climateList, setClimateList] = useState();

  useEffect(() => {
    api.getAllSupplierNames().then((suppliers) => setSupplierList(suppliers));
    accountAPI
      .getAllAccountNames()
      .then((accounts) => setAccountList(accounts));
    constructionpartAPI
      .getConstructionpartNames()
      .then((parts) => setPartList(parts));
    materialAPI
      .getAllMaterial()
      .then((materials) => setMaterialList(materials));

    storagespaceAPI
      .getAllStoragespace()
      .then((storages) => setStoragespaceList(storages));

    climateAPI
      .getAllClimatenames()
      .then((climates) => setClimateList(climates));
  }, []);

  if (
    supplierList &&
    accountList &&
    partList &&
    materialList &&
    storagespaceList &&
    climateList
  )
    return (
      <>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <div
            style={{
              display: "grid",
              gap: "1em",
              width: "100%",
            }}
          >
            <BasicInformationView
              fields={fields}
              handleChange={handleChange}
              handleSelect={handleSelect}
              newArticleNumber={fields.article_number}
              supplierList={supplierList}
            />

            <UnitInformationView
              fields={fields}
              handleSelect={handleSelect}
              handleChange={handleChange}
            />

            <RequirementInformationView
              fields={fields}
              handleChange={handleChange}
              handleSelect={handleSelect}
            />

            <OtherInformationView
              fields={fields}
              handleChange={handleChange}
              handleSelect={handleSelect}
              accountList={accountList}
              partList={partList}
              materialList={materialList}
              climateList={climateList}
              storagespaceList={storagespaceList}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1em ",
              margin: "1em 0",
            }}
          >
            <Button type="submit" color="success">
              Spara
            </Button>
            <Button onClick={(e) => confirmClose(e)}>Stäng</Button>
          </div>
        </form>

        <UpdateConfirmation
          showModal={displayUpdateConfirmationModal}
          confirmModal={confirmChange}
          hideModal={hideUpdateConfirmationModal}
        />
        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={handleClose}
          hideModal={hideConfirmationModal}
        />
      </>
    );
}

export default ArticleForm;
