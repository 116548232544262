import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import * as contactPersonAPI from "../../../api/register/contactPersonApi";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
const SupplierContactPersonView = ({
  showModal,
  hideModal,
  queryClient,
  contactPersonId,
}) => {
  const [contactPerson, setContactPerson] = useState({});

  useEffect(() => {
    if (contactPersonId)
      contactPersonAPI
        .getContactPerson(contactPersonId)
        .then((contactPerson) => setContactPerson(contactPerson));
  }, [contactPersonId]);

  const { mutate } = useMutation(contactPersonAPI.updateContactPerson, {
    onSettled: () => {
      setTimeout(function () {
        hideModal();
        queryClient.invalidateQueries();
      }, 1000);
      toastSuccess("Kontaktperson uppdaterades");
    },
    onError: (e) => {
      toastError("Gick inte att uppdatera");
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
        queryClient.invalidateQueries();
      }}
      size="lg"
      centered
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div>
          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Namn"}
              name="3;name"
              value={contactPerson.name}
              onChange={(e) =>
                setContactPerson({ ...contactPerson, name: e.target.value })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Roll"}
              name="3;role"
              value={contactPerson.role}
              onChange={(e) =>
                setContactPerson({ ...contactPerson, role: e.target.value })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <InputField
              label={"Telefonnummer"}
              name="3;phone"
              value={contactPerson.phone}
              onChange={(e) =>
                setContactPerson({
                  ...contactPerson,
                  phone: e.target.value,
                })
              }
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            {" "}
            <InputField
              label={"Mail"}
              name="3;email"
              value={contactPerson.email}
              onChange={(e) => {
                setContactPerson({
                  ...contactPerson,
                  email: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={() => {
            mutate(contactPerson);
          }}
        >
          Spara
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SupplierContactPersonView;
