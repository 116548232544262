import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../../components/buttons/Button";
import InputField from "../../../../../components/Inputs/InputField";
const AddLogView = ({
  showModal,
  hideModal,
  userId,
  projectId,
  currentStatus,
  setNewStatus,
  newStatus,
  setFields,
  fields,
  setNewLog,
}) => {
  const [comment, setComment] = useState(undefined);

  useEffect(() => {
    setComment(undefined);
  }, [newStatus]);
  const addNewLog = () => {
    let projectLog = {
      registered_by: userId,
      project: projectId,
      previous_status: currentStatus.label + " ---> " + newStatus?.label,
      new_status: newStatus?.value,
      comment: comment,
    };
    setNewLog(projectLog);
    hideModal();
  };

  const handleChange = (e) => {
    let { value } = e.target;
    setComment(value);
  };
  if (currentStatus)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          setFields({
            ...fields,
            project_status: currentStatus.value,
          });
          setNewStatus();
          setComment();
          hideModal();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till logginlägg</Modal.Header>

        <Modal.Body>
          <div>
            <div style={{ marginBottom: "1em" }}>
              <InputField
                name="previous_status"
                label={"Statusändring"}
                value={currentStatus.label + " ---> " + newStatus?.label}
              />
            </div>
            <InputField
              multiline={true}
              minRows={4}
              required
              name="comment"
              label={"Kommentar"}
              value={comment}
              onChange={handleChange}
              maxLength={500}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={!comment} onClick={addNewLog}>
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default AddLogView;
