import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import * as api from "../../../../api/ata/ataApi";
import AtaForm from "./AtaForm";
import logo from "../../../../assets/gifs/loading.gif";
import * as supplierAPI from "../../../../api/register/supplierApi";
import * as userAPI from "../../../../api/user/userAPI";
import * as ataStatusAPI from "../../../../api/ata/ataStatusAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useCallbackPrompt } from "../../../../hooks/useCallBackPrompt";
import DialogBox from "../../../../components/modals/DialogBox";
import { toast } from "react-toastify";
function AtaView() {
  const [users, setUsers] = useState();
  const [suppliers, setSuppliers] = useState();
  const [ataStatusList, setAtaStatusList] = useState();

  const ataParam = useParams();
  const pur = ataParam.id;
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayUpdateConfirmationModal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [fields, setFields] = useState();
  const [hasInputChanged, setHasInputChanged] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(hasInputChanged);

  useEffect(() => {
    if (pur) {
      api.getAta(pur).then((ata) => setFields(ata));
      userAPI.userList().then((staffs) => setUsers(staffs));
      supplierAPI
        .getAllSupplierNames()
        .then((suppliers) => setSuppliers(suppliers));
      ataStatusAPI
        .getStatusList()
        .then((statusList) => setAtaStatusList(statusList));
    }
  }, [pur]);

  const { mutate } = useMutation(api.updateAta, {
    onSuccess: (e) => {
      toast.success("ÄTA uppdaterades", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setHasInputChanged(false);
    },
    onError: () => {
      toast.error("Gick inte att uppdatera", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("error fron mutate");
    },
  });
  const navigate = useNavigate();
  const handleChange = (event) => {
    setHasInputChanged(true);

    const { name, value, type } = event.target;
    if (type === "checkbox") {
      if (name === "ata_bill_towards_supplier") {
        if (fields.ata_bill_towards_supplier === 0) {
          setFields({ ...fields, ata_bill_towards_supplier: 1 });
        } else {
          setFields({ ...fields, ata_bill_towards_supplier: 0 });
        }
      } else {
        if (fields.ata_bill_towards_client === 0) {
          setFields({ ...fields, ata_bill_towards_client: 1 });
        } else {
          setFields({ ...fields, ata_bill_towards_client: 0 });
        }
      }
    } else setFields({ ...fields, [name]: value });
  };

  const handleChangeDate = (value) => {
    setHasInputChanged(true);
    setFields({ ...fields, order_date: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      setDisplayUpdateConfirmationModal(true);
    } else {
      setHasInputChanged(false);

      mutate(fields);
    }
  };

  const confirmClose = () => {
    navigate(`/projektoversikt/${fields.ata_project}`);
  };
  /////////////////////////

  const confirmChange = () => {
    if (hasInputChanged) {
      mutate(fields);
      setDisplayUpdateConfirmationModal(false);
      setHasInputChanged(false);
    }
  };

  const hideUpdateConfirmationModal = () => {
    setDisplayUpdateConfirmationModal(false);
  };

  const handleClose = (e) => {
    if (hasInputChanged) {
      setDisplayConfirmationModal(true);
    } else {
      confirmClose();
    }
  };
  if (fields && users && suppliers && ataStatusList) {
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <DialogBox
          message={`Du har osparad data på ÄTAn`}
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <AtaForm
          setFields={setFields}
          fields={fields}
          users={users}
          suppliers={suppliers}
          ataStatusList={ataStatusList}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleChangeDate={handleChangeDate}
          hideUpdateConfirmationModal={hideUpdateConfirmationModal}
          setDisplayConfirmationModal={setDisplayConfirmationModal}
          displayConfirmationModal={displayConfirmationModal}
          displayUpdateConfirmationModal={displayUpdateConfirmationModal}
          confirmClose={confirmClose}
          confirmChange={confirmChange}
          setHasInputChanged={setHasInputChanged}
          hasInputChanged={hasInputChanged}
        />
      </div>
    );
  }
  return (
    <div style={{ position: "absolute", left: "45%", top: "40%" }}>
      <img
        src={logo}
        alt="loading..."
        style={{ height: "200px", width: "200px" }}
      />
    </div>
  );
}

export default AtaView;
