import React, { useState, useEffect } from "react";
import ModuleViewForm from "./ModuleViewForm";
import { useQueryClient, useMutation, useQuery } from "react-query";
import * as API from "../../../../api/project/moduleApi";
import UpdateConfirmation from "../../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../../components/modals/CloseConfirmation";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "../../../../components/newDataTable/DataTable";
import { orderRowColumns } from "../../../../components/dataTable/columns/OrderRowColumns";
import Content from "../../../../components/Content";
import { useCallbackPrompt } from "../../../../hooks/useCallBackPrompt";
import DialogBox from "../../../../components/modals/DialogBox";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function Moduleview() {
  const module = useParams();
  const moduleId = module.id;
  const [displayUpdateConfirmationmodal, setDisplayUpdateConfirmationModal] =
    useState(false);
  const [displayCloseConfirmationModal, setDisplayCloseConfirmationModal] =
    useState(false);

  const [hasInputChanged, setHasInputChanged] = useState(false);
  let [fields, setFields] = useState();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(hasInputChanged);
  const { data: moduleData } = useQuery(["moduleorderrows"], () =>
    API.getAllOrderRowsForModule(moduleId)
  );

  let navigate = useNavigate();
  useEffect(() => {
    API.getModule(moduleId).then((module) => {
      setFields(module);
    });
  }, [moduleId]);

  const queryClient = useQueryClient();

  const { mutate } = useMutation(API.updateModule, {
    onSuccess: () => {
      setHasInputChanged(false);
      setDisplayUpdateConfirmationModal(false);
      toastSuccess("Modulen uppdaterades");
      queryClient.invalidateQueries(["module"]);
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });

  const handleChange = (event) => {
    setHasInputChanged(true);
    const { name, value } = event.target;
    setFields({ ...fields, [name]: value });
  };
  const handleDateChange = (key, value) => {
    setHasInputChanged(true);

    setFields({ ...fields, [key]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasInputChanged) {
      setDisplayUpdateConfirmationModal(true);
    }
  };

  const confirmSubmit = () => {
    mutate(fields);
  };

  const handleClose = () => {
    if (hasInputChanged) {
      setDisplayCloseConfirmationModal(true);
    } else {
      confirmClose();
    }
  };
  const confirmClose = () => {
    if (module.id2 === "1") {
      navigate(`/moduleoverview/`);
    } else navigate(`/projektoversikt/${fields.projectId}`);
  };
  if (fields && moduleData) {
    return (
      <Content title="Modul">
        <DialogBox
          message={`Du har osparad data på modulen`}
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <ModuleViewForm
          setFields={setFields}
          fields={fields}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          setHasInputChanged={setHasInputChanged}
        />
        <div style={{ marginTop: "30px" }}>
          <h4>Material</h4>
          <DataTable
            columns={orderRowColumns}
            data={moduleData}
            typeOfTable="orderrowsformodule"
          />
        </div>
        <UpdateConfirmation
          showModal={displayUpdateConfirmationmodal}
          confirmModal={confirmSubmit}
          hideModal={() => setDisplayUpdateConfirmationModal(false)}
        />
        <CloseFonfirmation
          showModal={displayCloseConfirmationModal}
          confirmModal={confirmClose}
          hideModal={() => setDisplayCloseConfirmationModal(false)}
        />
      </Content>
    );
  }
}

export default Moduleview;
