import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputField from "../../../../components/Inputs/InputField";
import Button from "../../../../components/buttons/Button";
import { useForm } from "react-hook-form";
import * as errandAPI from "../../../../api/project/errandApi";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useMutation } from "react-query";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function AddErrandLogView({
  showModal,
  hideModal,
  errandId,
  queryClient,
  uploadedFiles,
  setUploadedFiles,
  userId,
}) {
  const { register, handleSubmit, reset } = useForm({});
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const { mutate } = useMutation(errandAPI.newErrandLog, {
    onSettled: (e) => {
      console.log("ON SETTLED");
      queryClient.invalidateQueries();
      reset();
      hideModal();
      toastSuccess("Ärendelogg skapades!");
    },
    onError: () => {
      toastError("Gick inte att skapa ärendelogg!");
    },
  });

  const fileInput = React.useRef(null);
  const handleClickOnUpload = (event) => {
    fileInput.current.click();
  };

  const changeHandler = (files) => {
    let updatedFileList = [...uploadedFiles];

    updatedFileList.push(files[0]);
    setUploadedFiles(updatedFileList);
    setSelectedFile(files[0]);
    setIsSelected(true);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
        setUploadedFiles([]);
      }}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>Lägg till logg</Modal.Header>

      <Modal.Body>
        <form
          className="form-grid-auto"
          onSubmit={handleSubmit((data) => {
            data.errand = errandId;
            data.registered_by = userId;
            // queryClient.invalidateQueries(["errands"]);
            // data.date = new Date().toLocaleDateString("sv-SE");
            // data.project = projectId;
            mutate(data);
          })}
        >
          <InputField
            required
            register={register("comment")}
            label={"Notering"}
            name={"comment"}
            multiline={true}
            minRows={8}
            maxLength={500}
          />
          <div>
            {uploadedFiles.length > 0 ? (
              uploadedFiles.map((file) => {
                return (
                  <div
                    style={{
                      fontWeight: "500",
                      color: "rgba(0, 0, 0, 0.596)",
                      margin: "1em 0",
                      padding: "8.5px 14px",
                      border: "1px solid rgba(0, 0, 0, 0.175)",
                      borderRadius: "4px",
                    }}
                  >
                    <AttachFileIcon />
                    {file.name}
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          <Button startIcon={<UploadFileIcon />} onClick={handleClickOnUpload}>
            Ladda upp bilaga
          </Button>
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => changeHandler(e.target.files)}
            style={{ display: "none" }}
            value=""
            multiple
          />
          <Button type="submit">Lägg till</Button>
        </form>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default AddErrandLogView;
