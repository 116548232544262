import React, { useEffect, useState } from "react";
import * as api from "../../../api/register/supplierCategoryApi";
import UpdateConfirmation from "../../../components/modals/UpdateConfirmation";
import CloseFonfirmation from "../../../components/modals/CloseConfirmation";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
import Button from "../../../components/buttons/Button";
function SupplierForm({
  fields,
  handleSubmit,
  handleChange,
  handleClose,
  handleSelectCategory,
  handleSelectActive,
  ReqOptions,
  confirmClose,
  hideConfirmationModal,
  hideUpdateConfirmationModal,
  confirmChange,
  displayConfirmationModal,
  displayUpdateConfirmationModal,
  handleSelectContract,
  contractList,
}) {
  const [categoryList, setCategoryList] = useState();

  useEffect(() => {
    api
      .getAllCategoriesNames()
      .then((categories) => setCategoryList(categories));
    // setContactPersons(JSON.parse(fields.contactpersons))
  }, []);

  if (categoryList)
    return (
      <>
        <>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2em",
                marginBottom: "1em",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1em",
                }}
              >
                <div className="newSupplier-form-left">
                  <div className="newSupplier-form-left--div1">
                    <InputField
                      label={"Referens"}
                      name="reference"
                      value={fields.reference}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div2">
                    <InputField
                      label={"Telefon"}
                      name="phonenumber"
                      value={fields.phonenumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div3">
                    <InputField
                      label={"Mail"}
                      name="email"
                      value={fields.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div4">
                    <InputField
                      label={"Ordermail"}
                      name="order_mail"
                      value={fields.order_mail}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div5">
                    <InputField
                      label={" Org. nummer"}
                      name="org_number"
                      value={fields.org_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div6">
                    <Select
                      label={"Huvudkategori"}
                      options={categoryList}
                      value={categoryList.find(
                        (item) =>
                          item.value ===
                          fields.supplierandcategory_supplier_category
                      )}
                      onChange={handleSelectCategory}
                    />
                  </div>
                  <div className="newSupplier-form-left--div7">
                    <InputField
                      label={"Adress"}
                      name="adress"
                      value={fields.adress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div8">
                    <InputField
                      label={"Postnumber"}
                      name="postnumber"
                      value={fields.postnumber}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-left--div9">
                    <InputField
                      label={"Postadress"}
                      name="postadress"
                      value={fields.postadress}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="newSupplier-form-right">
                  <div className="newSupplier-form-right--div1">
                    <InputField
                      label={"Ledtid"}
                      name="lead_time"
                      value={fields.lead_time}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div2">
                    <InputField
                      label={"Kredit tid"}
                      name="credit_time"
                      value={fields.credit_time}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div3">
                    <InputField
                      label={"BG"}
                      name="bg"
                      value={fields.bg}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="newSupplier-form-right--div4">
                    <Select
                      label={"Aktiv"}
                      value={ReqOptions.find(
                        (item) => item.value === fields.isActive
                      )}
                      options={ReqOptions}
                      onChange={handleSelectActive}
                    />
                  </div>
                  <div className="newSupplier-form-right--div5">
                    <Select
                      label={"Kontrakt"}
                      value={contractList.find(
                        (item) => item.value === fields.contract
                      )}
                      options={contractList}
                      onChange={handleSelectContract}
                    />
                  </div>
                  <div
                    className="newSupplier-form-right--div7"
                    style={{ gridArea: "5/1/8/5" }}
                  >
                    <InputField
                      type={"text"}
                      multiline
                      minRows={6}
                      label={"Beställningsmallar/rutiner"}
                      name="order_template"
                      value={fields.order_template}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", gap: "1em" }}>
              <Button type="submit" color="success">
                Spara
              </Button>
              <Button onClick={(e) => confirmClose(e)}>Stäng</Button>
            </div>
          </form>
        </>

        <UpdateConfirmation
          showModal={displayUpdateConfirmationModal}
          confirmModal={confirmChange}
          hideModal={hideUpdateConfirmationModal}
        />
        <CloseFonfirmation
          showModal={displayConfirmationModal}
          confirmModal={handleClose}
          hideModal={hideConfirmationModal}
        />
      </>
    );
}

export default SupplierForm;
