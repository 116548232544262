import React, { useState, useEffect } from "react";
import { useQueryClient, useMutation } from "react-query";
import * as statusAPI from "../../../api/status/statusAPI";
import Button from "../../../components/buttons/Button";
import { Modal } from "react-bootstrap";
import DraggableList from "../../../components/dragableList/DraggableList";
import { reorder } from "../../../components/dragableList/utils/helpers";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function SetStatusGroupOrderView({ showModal, hideModal, groups }) {
  const [items, setItems] = useState();
  const queryClient = useQueryClient();

  useEffect(() => {
    setItems(groups);
  }, [groups]);

  const { mutate } = useMutation(statusAPI.updatePositions, {
    onSuccess: () => {
      toastSuccess("Positioner uppdaterades");
      queryClient.invalidateQueries(["statusgroup"]);
    },
    onError: () => {
      toastError("Gick inte att uppdatera");
    },
  });
  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
        queryClient.invalidateQueries();
      }}
      size="sm"
      centered
    >
      <Modal.Header closeButton>Statusgrupp placeringar</Modal.Header>

      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mutate(items);
          }}
        >
          <div>
            <DraggableList items={items} onDragEnd={onDragEnd} />
          </div>
          <div style={{ marginTop: "1em" }}>
            <Button type="submit" color="success" sx={{ marginRight: "1em" }}>
              Spara
            </Button>
            <Button type="button" onClick={(e) => hideModal()}>
              Stäng
            </Button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default SetStatusGroupOrderView;
