import React, { useState, useEffect } from "react";
import Content from "../../components/Content";
import DataTable from "../../components/newDataTable/DataTable";
import { Paper } from "@mui/material";
import { AllModuleColumns } from "../../components/dataTable/columns/ModuleColumns";
import * as moduleAPI from "../../api/project/moduleApi";
import { useQuery } from "react-query";
function ModuleOverView() {
  const [lastTab, setLastTab] = useState(1);
  const [moduls, setModuls] = useState([]);
  useEffect(() => {
    localStorage.clear();
  });

  const tabs = [{ label: "Moduler", id: 1 }];

  const { data: moduleData } = useQuery(["module"], () =>
    moduleAPI.getAllModules()
  );

  useEffect(() => {
    if (typeof moduleData === "object") {
      setModuls(moduleData);
    }
  }, [moduleData]);
  return (
    <>
      <Content tabs={true} tabList={tabs} lastTab={lastTab}>
        <div value={1}>
          <DataTable
            columns={AllModuleColumns}
            data={moduls}
            typeOfTable="moduleoverview"
          />
        </div>
        <div value={2}>
          <Paper></Paper>
        </div>
      </Content>
    </>
  );
}

export default ModuleOverView;
