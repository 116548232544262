import React from "react";
import InputField from "../../../../components/Inputs/InputField";
import Select from "../../../../components/Inputs/Select";
export default function OtherInformationView({
  fields,
  handleChange,
  handleSelect,
  accountList,
  partList,
  materialList,
  climateList,
  storagespaceList,
}) {
  return (
    <div className="wrapper" style={{ flex: "1" }}>
      <div className="wrapper-title">Övrigt</div>
      <div className="form-grid-auto">
        <InputField
          type="number"
          label="Orderpoint_karlskoga"
          name="orderpoint_karlskoga"
          value={fields.orderpoint_karlskoga}
          onChange={handleChange}
        />
        <InputField
          type="number"
          label="Orderpoint_surahammar"
          name="orderpoint_surahammar"
          value={fields.orderpoint_surahammar}
          onChange={handleChange}
        />

        <Select
          required
          label="Konto"
          options={accountList}
          value={accountList.find((item) => item.value === fields.account)}
          onChange={(e, data) => {
            handleSelect(data, "account");
          }}
        />
        <Select
          required
          label="Byggdel"
          options={partList}
          value={partList.find(
            (item) => item.value === fields.construction_part
          )}
          onChange={(e, data) => {
            handleSelect(data, "construction_part");
          }}
        />
        <Select
          required
          label="Material"
          options={materialList}
          value={materialList.find((item) => item.value === fields.material)}
          onChange={(e, data) => {
            handleSelect(data, "material");
          }}
        />

        <Select
          label="Klimat"
          options={climateList}
          value={climateList.find((item) => item.value === fields.climateid)}
          onChange={(e, data) => {
            handleSelect(data, "climateid");
          }}
        />
        <Select
          label="Lagerplats"
          name="storage_space"
          options={storagespaceList}
          value={storagespaceList.find(
            (item) => item.value === fields.storage_space
          )}
          onChange={(e, data) => {
            handleSelect(data, "storage_space");
          }}
        />
      </div>
      <div style={{ marginTop: "1em " }}>
        <InputField
          multiline
          minRows={4}
          label="Kommentar"
          name="comment"
          value={fields.comment}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
