import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import DataTable from "../../../components/newDataTable/DataTable";
import { MaterialColumns } from "../../../components/dataTable/columns/MaterialColumns";
import * as materialAPI from "../../../api/register/materialApi";
import MaterialView from "./MaterialView";
import AddMaterialView from "./AddMaterialView";
import { useQuery } from "react-query";
function MaterialOverview() {
  const [displayAddMaterialView, setDisplayAddMaterialView] = useState(false);
  const [displayMaterialView, setDisplayMaterialView] = useState(false);
  const { data: materialResponse } = useQuery(["material"], () =>
    materialAPI.getAllMaterial()
  );
  return (
    <>
      <Button onClick={() => setDisplayAddMaterialView(true)}>
        Lägg till Material
      </Button>
      <DataTable
        columns={MaterialColumns}
        data={materialResponse?.length > 0 ? materialResponse : []}
        typeOfTable="material"
        handleShowObject={setDisplayMaterialView}
      />

      <MaterialView
        showModal={displayMaterialView}
        hideModal={() => setDisplayMaterialView(false)}
        materialId={displayMaterialView}
      />

      <AddMaterialView
        showModal={displayAddMaterialView}
        hideModal={() => setDisplayAddMaterialView(false)}
      />
    </>
  );
}

export default MaterialOverview;
