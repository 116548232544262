import React from "react";
import Button from "../../../components/buttons/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListIcon from "@mui/icons-material/List";
import DataTable from "../../../components/newDataTable/DataTable";
import { useState, useEffect } from "react";
import AddStatusGroupView from "./AddStatusGroupView";
import * as statusAPI from "../../../api/status/statusAPI";
import { useQueryClient, useQuery } from "react-query";
import SetStatusGroupOrderView from "./SetStatusGroupOrderView";
function StatusOverView() {
  const [statusGroups, setStatusGroups] = useState();
  const [displayAddStatusGroupView, setDisplayAddStatusGroupView] =
    useState(false);
  const [displayStatusGroupOrderView, setDisplayStatusGroupOrderView] =
    useState(false);
  const queryClient = useQueryClient();

  const { data: responseData } = useQuery(["statusgroups"], () =>
    statusAPI.getStatusGroups()
  );

  useEffect(() => {
    if (typeof responseData === "object") setStatusGroups(responseData);
  }, [responseData]);

  const statusGroupColumns = [
    {
      Header: "Namn",
      accessor: "title",
      disableFilters: true,
    },
    {
      Header: "Datum titel 1",
      accessor: "datetitle_1",
      disableFilters: true,
    },
    {
      Header: "Datum titel 2",
      accessor: "datetitle_2",
      disableFilters: true,
    },
  ];
  return (
    <>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setDisplayAddStatusGroupView(true)}
      >
        Lägg till statusgrupp
      </Button>
      <Button
        sx={{ marginLeft: "1em" }}
        startIcon={<ListIcon />}
        onClick={() => setDisplayStatusGroupOrderView(true)}
      >
        Positioner
      </Button>
      {statusGroups ? (
        <DataTable
          columns={statusGroupColumns}
          data={statusGroups.length > 0 ? statusGroups : []}
          typeOfTable="statusgroup"
          // handleShowObject={console.log("first")}
        />
      ) : (
        <></>
      )}

      <AddStatusGroupView
        statusAPI={statusAPI}
        queryClient={queryClient}
        showModal={displayAddStatusGroupView}
        hideModal={() => setDisplayAddStatusGroupView(false)}
      />

      <SetStatusGroupOrderView
        queryClient={queryClient}
        groups={statusGroups}
        showModal={displayStatusGroupOrderView}
        hideModal={() => setDisplayStatusGroupOrderView(null)}
      />
    </>
  );
}

export default StatusOverView;
