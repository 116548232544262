import React, { useEffect, useState } from "react";
import ProjectForm from "./ProjectForm";
import * as projectStatusAPI from "../../../api/ownFields/projectStatusApi";
import * as projectTypeAPI from "../../../api/ownFields/projectTypeApi";
import * as projectPhaseAPi from "../../../api/ownFields/projectPhaseApi";
import * as leadChannelAPI from "../../../api/ownFields/leadChannelApi";
import * as projectDeliveryTypeAPI from "../../../api/ownFields/projectDeliveryTypeAPI";
import * as projectIndexTypeAPI from "../../../api/ownFields/projectIndexTypeAPI";
import * as projectAPI from "../../../api/project/projectApi";
import * as municipalityAPI from "../../../api/project/municipalityApi";
import * as userAPI from "../../../api/user/userAPI";
import { useMutation } from "react-query";
import ShowNewProjectNumber from "../../../components/modals/ShowNewProjectNumber";
import { useNavigate } from "react-router-dom";
import {
  toastError,
  toastSuccess,
} from "../../../components/toast/toastFunctions";
function CreateProjectView() {
  let navigate = useNavigate();
  const [staffList, setStaffList] = useState();
  const [projectTypeList, setProjectTypeList] = useState();
  const [projectPhaseList, setProjectPhaseList] = useState();
  const [leadChannelList, setLeadChannelList] = useState();
  const [projectStatusList, setProjectStatusList] = useState();
  const [newProjectNumber, setNewProjectNumber] = useState();
  const [municipalityList, setMunicipalityList] = useState();
  const [projectIndexTypeList, setProjectIndexTypeList] = useState();
  const [projectDeliveryTypeList, setProjectDeliveryTypeList] = useState();

  const [showNewProjectNumberModal, setShowNewProjectNumberModal] =
    useState(false);
  const [newProjectId, setNewProjectId] = useState();

  useEffect(() => {
    projectStatusAPI.getStatusList().then((list) => setProjectStatusList(list));
    projectPhaseAPi.getPhaseList().then((list) => setProjectPhaseList(list));
    projectTypeAPI
      .getProjectTypeList()
      .then((list) => setProjectTypeList(list));
    leadChannelAPI
      .getLeadChannelList()
      .then((list) => setLeadChannelList(list));
    userAPI.userList().then((list) => setStaffList(list));
    municipalityAPI
      .getMunicipalityList()
      .then((list) => setMunicipalityList(list));
    projectDeliveryTypeAPI
      .getDeliveryTypeList()
      .then((list) => setProjectDeliveryTypeList(list));
    projectIndexTypeAPI
      .getIndexTypeList()
      .then((list) => setProjectIndexTypeList(list));
  }, []);

  const createProject = useMutation((data) => projectAPI.newProject(data));

  const handleCreateProject = (data) => {
    try {
      createProject.mutate(data, {
        onSuccess: (e) => {
          setNewProjectNumber(e.data.project_project_number);
          setNewProjectId(e.data.id);
          setShowNewProjectNumberModal(true);
          toastSuccess("Projekt skapades");
        },
        onError: () => {
          toastError("Gick inte att skapa projekt");
        },
      });
    } catch {
      console.log("error från click on send");
    }
  };

  if (
    staffList &&
    projectTypeList &&
    projectPhaseList &&
    leadChannelList &&
    projectStatusList &&
    municipalityList &&
    projectIndexTypeList &&
    projectDeliveryTypeList
  ) {
    return (
      <>
        <ProjectForm
          handleCreateProject={handleCreateProject}
          newProjectNumber={newProjectNumber}
          staffList={staffList}
          projectTypeList={projectTypeList}
          projectPhaseList={projectPhaseList}
          leadChannelList={leadChannelList}
          projectStatusList={projectStatusList}
          municipalityList={municipalityList}
          projectIndexTypeList={projectIndexTypeList}
          projectDeliveryTypeList={projectDeliveryTypeList}
        />
        <ShowNewProjectNumber
          newProjectNumber={newProjectNumber}
          showModal={showNewProjectNumberModal}
          hideModal={() => navigate(`/projektoversikt/${newProjectId}`)}
        />
      </>
    );
  }
}

export default CreateProjectView;
