import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../components/buttons/Button";
import InputField from "../../../../components/Inputs/InputField";
import { useMutation } from "react-query";
import Switch from "@mui/material/Switch";
import RuleView from "./RuleView";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";

function StatusRowView({
  showModal,
  hideModal,
  statusAPI,
  rowToShow,
  queryClient,
  statusGroup,
}) {
  const [statusRow, setStatusRow] = useState();
  const [rules, setRules] = useState({
    rule_1_active: false,
    rule_2_active: false,
  });
  useEffect(() => {
    setRules({
      rule_1_active: false,
      rule_2_active: false,
    });
    if (rowToShow) {
      if (
        rowToShow.lead_time_1_yellow ||
        rowToShow.lead_time_1_red ||
        rowToShow.lead_time_2_yellow ||
        rowToShow.lead_time_2_red
      )
        setRules({
          ...rules,
          rule_1_active:
            rowToShow.lead_time_1_yellow || rowToShow.lead_time_1_red
              ? true
              : false,
          rule_2_active:
            rowToShow.lead_time_2_yellow || rowToShow.lead_time_2_red
              ? true
              : false,
        });

      setStatusRow(rowToShow);
    }
  }, [rowToShow]);
  console.log(rowToShow);
  const { mutate } = useMutation(statusAPI.updateStatusRow, {
    onSuccess: (e) => {
      queryClient.invalidateQueries("statusgroup");
      toastSuccess("Statusrad uppdaterades");
      queryClient.invalidateQueries();
      setTimeout(function () {
        hideModal();
        queryClient.invalidateQueries();
      }, 1000);
    },
    onError: (e) => {
      toastError("Gick inte att uppdatera");
    },
  });
  if (statusRow)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          // queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div style={{ minHeight: "15em" }}>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Namn"}
                value={statusRow.name}
                onChange={(e) =>
                  setStatusRow({ ...statusRow, name: e.target.value })
                }
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Tooltip"}
                multiline={true}
                minRows={4}
                value={statusRow.tooltip_text}
                onChange={(e) =>
                  setStatusRow({
                    ...statusRow,
                    tooltip_text: e.target.value,
                  })
                }
              />
            </div>
            <div style={{ marginBottom: "10px", display: "flex" }}>
              {" "}
              <div>
                {" "}
                Regel för {statusGroup.datetitle_1}
                <Switch
                  checked={rules.rule_1_active}
                  onChange={() =>
                    setRules({
                      ...rules,
                      rule_1_active: !rules.rule_1_active,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />{" "}
              </div>
              <div>
                {" "}
                Regel för {statusGroup.datetitle_2}
                <Switch
                  checked={rules.rule_2_active}
                  onChange={() =>
                    setRules({
                      ...rules,
                      rule_2_active: !rules.rule_2_active,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <div style={{ marginLeft: "25em" }}>
                Aktiv
                <Switch
                  checked={statusRow.is_active}
                  onChange={() =>
                    setStatusRow({
                      ...statusRow,
                      is_active: !statusRow.is_active,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            {rules.rule_1_active ? (
              <div style={{ marginBottom: "30px" }}>
                <h5>Regel för {statusGroup.datetitle_1}</h5>
                <RuleView
                  rule_required={rules.rule_1_active}
                  statusRow={statusRow}
                  setStatusRow={setStatusRow}
                  ruleTowards={1}
                  ruleTitle={statusGroup.datetitle_1}
                />
              </div>
            ) : (
              <></>
            )}
            {rules.rule_2_active ? (
              <div style={{ marginBottom: "10px" }}>
                <h5>Regel för {statusGroup.datetitle_2}</h5>

                <RuleView
                  rule_required={rules.rule_2_active}
                  statusRow={statusRow}
                  setStatusRow={setStatusRow}
                  ruleTowards={2}
                  ruleTitle={statusGroup.datetitle_2}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              console.log(statusRow);
              mutate(statusRow);
            }}
          >
            Spara
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

export default StatusRowView;
