import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../../components/buttons/Button";
import AddStatusRowView from "./AddStatusRowView";
import StatusGroupStatusRowView from "./StatusGroupStatusRowView";
import { useMutation, useQueryClient, useQuery } from "react-query";
import * as statusAPI from "../../../../api/status/statusAPI";
import StatusRowView from "./StatusRowView";
import InputField from "../../../../components/Inputs/InputField";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function StatusGroupView() {
  let statusGroupid = useParams();
  const [statusGroup, setStatusGroup] = useState();
  const [displayAddStatusRowView, setDisplayAddStatusRowView] = useState();
  const [displayStatusRowView, setDisplayStatusRowView] = useState();
  const [rowToShow, setRowToShow] = useState();
  const queryClient = useQueryClient();

  const { data: responseData } = useQuery(["statusgroups"], () =>
    statusAPI.getStatusGroup(statusGroupid.id)
  );

  useEffect(() => {
    if (typeof responseData === "object") setStatusGroup(responseData);
  }, [responseData]);

  useEffect(() => {
    if (rowToShow) setDisplayStatusRowView(true);
  }, [rowToShow]);

  const { mutate } = useMutation(statusAPI.updateStatusGroup, {
    onSettled: (e) => {
      console.log(e, "Statusrad");
      setTimeout(function () {
        queryClient.invalidateQueries("statusgroup");
      }, 1000);

      console.log("ON SETTLED");
    },
    onSuccess: (e) => {
      console.log(e, "Statusrad");
      setTimeout(function () {
        queryClient.invalidateQueries("statusgroup");
      }, 1000);
      toastSuccess("Statusgrupp uppdaterades");

      console.log("ON SETTLED");
    },
    onError: (e) => {
      console.log(e, "Statusrad");
      toastError("Gick inte att uppdatera");
    },
  });
  console.log(statusGroup, "this the group yo");
  if (statusGroup)
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <div style={{ marginBottom: "1.5em" }}>
          {/* <StatusGroupHeader title={statusGroup.title} /> */}

          <div style={{ display: "grid", gap: "1em" }}>
            <div
              style={{
                display: "grid",
                gap: "1em",
                height: "fit-content",
              }}
            >
              <InputField
                name="quantity"
                label={"Namn"}
                value={statusGroup.title}
                onChange={(e) =>
                  setStatusGroup({ ...statusGroup, title: e.target.value })
                }
              />
              <div style={{ display: "flex", gap: "1em" }}>
                {" "}
                <InputField
                  name="quantity"
                  label={"Datumtitel 1"}
                  value={statusGroup.datetitle_1}
                  onChange={(e) =>
                    setStatusGroup({
                      ...statusGroup,
                      datetitle_1: e.target.value,
                    })
                  }
                />{" "}
                <InputField
                  name="quantity"
                  label={"Datumtitel 2"}
                  value={statusGroup.datetitle_2}
                  onChange={(e) =>
                    setStatusGroup({
                      ...statusGroup,
                      datetitle_2: e.target.value,
                    })
                  }
                />{" "}
              </div>
            </div>{" "}
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <Button onClick={() => setDisplayAddStatusRowView(true)}>
                Lägg till statusrad
              </Button>
            </div>
            <div>
              <StatusGroupStatusRowView
                rows={statusGroup.statusrows}
                setRowToShow={setRowToShow}
              />
            </div>
          </div>
        </div>
        <AddStatusRowView
          statusGroup={statusGroup}
          statusAPI={statusAPI}
          queryClient={queryClient}
          showModal={displayAddStatusRowView}
          hideModal={() => setDisplayAddStatusRowView(false)}
        />
        <StatusRowView
          statusGroup={statusGroup}
          rowToShow={rowToShow}
          statusAPI={statusAPI}
          queryClient={queryClient}
          showModal={displayStatusRowView}
          hideModal={() => {
            setDisplayStatusRowView(false);
            setRowToShow();
          }}
        />
        <Button onClick={() => mutate(statusGroup)}>Spara</Button>
      </div>
    );
}

export default StatusGroupView;
