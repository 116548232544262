import React, { useEffect, useState } from "react";
import * as errandAPI from "../../../../api/project/errandApi";
import ErrandTable from "./ErrandTable";
import { errandColumns } from "../../../../components/dataTable/columns/ErrandColumns";
import ErrandView from "./ErrandView";
import { useQuery, useQueryClient } from "react-query";
function ErrandOverview({ client_mail, projectId }) {
  const [errandToShow, setErrandToShow] = useState();
  const [errandsData, setErrandsData] = useState();
  let queryClient = useQueryClient();

  const { data: apiResponse } = useQuery(["errands", projectId], () =>
    errandAPI.getErrandsForProject(projectId)
  );

  useEffect(() => {
    if (apiResponse) {
      setErrandsData(apiResponse);
    }
  }, [apiResponse]);

  if (errandsData)
    return (
      <>
        <ErrandTable
          errandColumns={errandColumns}
          errandsData={errandsData}
          setErrandLogId={setErrandToShow}
        />

        <ErrandView
          queryClient={queryClient}
          errandId={errandToShow}
          showModal={errandToShow}
          hideModal={() => {
            setErrandToShow(null);
          }}
          client_mail={client_mail}
        />
      </>
    );
}

export default ErrandOverview;
