import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import * as errandAPI from "../../../../api/project/errandApi";
import Button from "../../../../components/buttons/Button";
import InputField from "../../../../components/Inputs/InputField";
import Select from "../../../../components/Inputs/Select";
import { useForm, Controller } from "react-hook-form";
import * as errandStatusAPI from "../../../../api/ownFields/errandStatusApi";
import * as errandTypeAPI from "../../../../api/ownFields/errandTypeApi";
import * as userAPI from "../../../../api/user/userAPI";
import { Modal } from "react-bootstrap";
import { Switch } from "@mui/material";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";
function AddErrandView({
  showModal,
  hideModal,
  projectId,
  queryClient,
  userId,
}) {
  const [errandStatusList, setErrandStatusList] = useState();
  const [errandTypeList, setErrandTypeList] = useState();
  const [staffList, setStaffList] = useState();
  const [controlToClient, setControlToClient] = useState(false);
  const [selects, setSelects] = useState({
    status: undefined,
    type: undefined,
    responsible_person: undefined,
  });
  useEffect(() => {
    errandStatusAPI.getStatusList().then((list) => setErrandStatusList(list));
    errandTypeAPI.getTypeList().then((list) => setErrandTypeList(list));
    userAPI.userList().then((list) => setStaffList(list));
  }, []);

  const { register, handleSubmit, control, reset } = useForm({});

  const { mutate } = useMutation(errandAPI.newComment, {
    onSettled: (e) => {
      console.log("ON SETTLED");
      queryClient.invalidateQueries("errands");
      setTimeout(function () {
        hideModal();
        reset();
      }, 500);
      setSelects({
        status: undefined,
        type: undefined,
        responsible_person: undefined,
      });
      setControlToClient(false);
      toastSuccess("Ärende skapades!");
    },
    onError: () => {
      toastError("Gick inte att skapa ärende!");
    },
  });

  if (errandTypeList && errandStatusList && staffList)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          queryClient.invalidateQueries();
        }}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>Skapa nytt ärende</Modal.Header>

        <Modal.Body>
          <form
            onSubmit={handleSubmit((data) => {
              queryClient.invalidateQueries(["errands"]);
              data.control_to_client = controlToClient;
              data.registered_by = userId;
              data.date = new Date().toLocaleDateString("sv-SE");
              data.project = projectId;
              mutate(data);
            })}
          >
            <div style={{ marginBottom: "1em" }}>
              {" "}
              <Controller
                control={control}
                name={"status"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    control={control}
                    register={register("status")}
                    options={errandStatusList}
                    value={errandStatusList.find(
                      (item) => item.value === selects.status
                    )}
                    label={"Status"}
                    onChange={(event, item) => {
                      setSelects({
                        ...selects,
                        status: item.value,
                      });
                      onChange(item.value);
                    }}
                  />
                )}
              />
            </div>

            <div style={{ marginBottom: "1em" }}>
              {" "}
              <Controller
                control={control}
                name={"type"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    control={control}
                    register={register("type")}
                    options={errandTypeList}
                    value={errandTypeList.find(
                      (item) => item.value === selects.type
                    )}
                    label={"Kategori"}
                    onChange={(event, item) => {
                      setSelects({
                        ...selects,
                        type: item.value,
                      });
                      onChange(item.value);
                    }}
                  />
                )}
              />
            </div>

            <div style={{ marginBottom: "1em" }}>
              {" "}
              <Controller
                control={control}
                name={"responsible_person"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    control={control}
                    register={register("responsible_person")}
                    options={staffList}
                    value={staffList.find(
                      (item) => item.value === selects.responsible_person
                    )}
                    label={"Ansvarig"}
                    onChange={(event, item) => {
                      setSelects({
                        ...selects,
                        responsible_person: item.value,
                      });
                      onChange(item.value);
                    }}
                  />
                )}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              Egenkontroll till kund
              <Switch
                checked={controlToClient}
                onChange={() => setControlToClient(!controlToClient)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <InputField
                required
                register={register("comment")}
                label={"Notering"}
                name={"comment"}
                multiline={true}
                maxLength={9999}
                minRows={8}
              />
            </div>
            <Button type="submit">Lägg till</Button>
          </form>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    );
}

export default AddErrandView;
