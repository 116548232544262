import React from "react";
import Select from "../../../../../components/Inputs/Select";
import InputField from "../../../../../components/Inputs/InputField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function ErrandForm({
  Controller,
  control,
  register,
  errandStatusList,
  fields,
  setFields,
  setHasInputChanged,
  setStatusWasChanged,
  errandTypeList,
  staffList,
  setResponsiblePersonWasChanged,
  errandLogToShow,
  selectedID,
  setOpen,
  setSelectedID,
  open,
}) {
  return (
    <div style={{ display: "flex", gap: "1em" }}>
      <div style={{ flex: "1" }}>
        <div style={{ marginBottom: "1em" }}>
          <Controller
            control={control}
            name={"status"}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                control={control}
                register={register("status")}
                options={errandStatusList}
                label={"Status"}
                value={
                  fields?.errand_status
                    ? errandStatusList.find(
                        (item) => item.value === fields?.errand_status
                      )
                    : ""
                }
                onChange={(event, item) => {
                  setHasInputChanged(true);
                  setStatusWasChanged(item.label);
                  onChange(item.value);
                  setFields({
                    ...fields,
                    errand_status: item.value,
                  });
                }}
              />
            )}
          />
        </div>
        <div style={{ marginBottom: "1em" }}>
          <Controller
            control={control}
            name={"type"}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                control={control}
                register={register("type")}
                options={errandTypeList}
                label={"Kategori"}
                value={
                  fields?.errand_type
                    ? errandTypeList.find(
                        (item) => item.value === fields?.errand_type
                      )
                    : ""
                }
                onChange={(event, item) => {
                  setFields({
                    ...fields,
                    errand_type: item.value,
                  });

                  onChange(item.value);
                  setHasInputChanged(true);
                }}
              />
            )}
          />
        </div>
        <div style={{ marginBottom: "1em" }}>
          <Controller
            control={control}
            name={"responsible_person"}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                control={control}
                register={register("responsible_person")}
                options={staffList}
                label={"Ansvarig"}
                value={
                  fields?.errand_responsible_person
                    ? staffList.find(
                        (item) =>
                          item.value === fields?.errand_responsible_person
                      )
                    : ""
                }
                onChange={(event, item) => {
                  setResponsiblePersonWasChanged(item.label);
                  onChange(item?.value);
                  setFields({
                    ...fields,
                    errand_responsible_person: item.value,
                  });
                  setHasInputChanged(true);
                }}
              />
            )}
          />
        </div>

        <InputField
          required
          register={register("comment")}
          label={"Notering"}
          name={"comment"}
          value={fields.errand_comment}
          multiline={true}
          minRows={10}
          onChange={(e) => {
            setFields({
              ...fields,
              errand_comment: e.target.value,
            });
            setHasInputChanged(true);
          }}
        />
      </div>
      <div style={{ flex: "1", maxHeight: "350px", overflow: "auto" }}>
        {errandLogToShow.length > 0 ? (
          <>
            <div style={{ marginLeft: "1em" }}>
              {" "}
              Antal loggar : {errandLogToShow.length}
              <hr></hr>
            </div>

            {errandLogToShow?.map((item, i) => (
              <div
                style={{
                  marginBottom: "1em",
                }}
                key={i}
              >
                <Accordion
                  expanded={open && selectedID === i}
                  onChange={() => {
                    setOpen(!open);
                    setSelectedID(i);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Datum: {item.timestamp}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item.previous_status}</Typography>
                    <h6>Kommentar</h6>

                    <Typography>{item.comment}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </>
        ) : (
          <>Finns ingen logg under detta ärende</>
        )}
      </div>
    </div>
  );
}

export default ErrandForm;
