import * as authAPI from "../api/auth/authAPI";

export const useLogin = () => {
  const loginStep1 = async (email, password, setFailedAttempt) => {
    const loginAttempt = {
      email: email,
      password: password,
    };
    const response = await authAPI.loginStep1(loginAttempt);
    if (!response) {
    }
    if (response) {
      if (response.credentialsFailed) {
        setFailedAttempt(true);
      } else {
        return response;
      }
    }
  };

  const loginStep2 = async (otp, user) => {
    const loginAttempt = {
      userid: user.uid,
      email: user.email,
      otp: otp,
    };
    const response = await authAPI.loginStep2(loginAttempt);
    if (!response) {
    }
    if (response) {
      return response;
    }
  };

  const forgottenPasswordStep1 = async (email) => {
    const resetAttempt = {
      email: email,
    };
    const response = await authAPI.forgottenPasswordStep1(resetAttempt);
    if (response.credentialsFailed) {
      return { attemt: 1 };
    }
    if (response) {
      return response;
    }
  };

  const forgottenPasswordStep2 = async (otp, user) => {
    const resetAttempt = {
      userid: user.uid,
      email: user.email,
      otp: otp,
    };
    const response = await authAPI.forgottenPasswordStep2(resetAttempt);
    if (!response) {
    }
    if (response) {
      if (response.revoceryMail) {
        return true;
      } else return false;
    }
  };

  return {
    loginStep1,
    loginStep2,
    forgottenPasswordStep1,
    forgottenPasswordStep2,
  };
};
