import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getAllSuppliers = () =>
  api.get("/supplier/").then((res) => res.data);

export const getAllInactiveSuppliers = () =>
  api.get("/inactivesupplier/").then((res) => res.data);

export const getSupplier = (id) =>
  api.get(`/supplier/${id}`).then((res) => res.data);

export const updateSupplier = ({ id, ...updatedSupplier }) => {
  return api.put(`/supplier/${id}`, updatedSupplier);
};

export const newSupplier = (supplier) => api.post(`/supplier`, supplier);

export const deleteSupplier = (id) => api.delete(`/supplier/` + id);

export const getAllSupplierNames = () =>
  api.get("/suppliernames/").then((res) => res.data);
