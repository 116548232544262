import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/navbar/Navbar.css";
import { RiAdminLine } from "react-icons/ri";
import IconButton from "../buttons/IconButton";
import { IoIosArrowBack } from "react-icons/io";
import { useTheme } from "@mui/material/styles";
import Hamburger from "./Hamburger";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ViewListIcon from "@mui/icons-material/ViewList";
import { BsClipboardData } from "react-icons/bs";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { useAuthContext } from "../../hooks/useAuthContext";

const navlinks = [
  { name: "Projekt", href: "./", icon: <BsClipboardData /> },
  // { name: "Dokumentation ", href: "./test2", icon: <BiErrorAlt /> },
  { name: "Register", href: "./register", icon: <LibraryBooksIcon /> },
  // { name: "Användare", href: "./personal", icon: <IoIosPeople /> },
  { name: "Ärenden", href: "./errand", icon: <FormatListBulletedIcon /> },
  { name: "Moduler", href: "./moduleoverview", icon: <WidgetsIcon /> },
  { name: "Inköpsordrar", href: "./purchaseoverview", icon: <ViewListIcon /> },
];

const bottomNavLinks = [
  { name: "Admin ", href: "./admin", icon: <RiAdminLine /> },
];

export default function Navbar(props) {
  const [open, setOpen] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const { auth } = useAuthContext();

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    if (window.innerWidth <= 1200) {
      setIsDesktop(false);
    } else if (window.innerWidth > 1200) {
      setIsDesktop(true);
    }
  };

  const theme = useTheme();

  function handleBurgerChange() {
    setOpen(!open);
  }
  if (auth.auth)
    return (
      <div className={!isDesktop && open ? "overlay" : null}>
        {!isDesktop ? (
          <Hamburger active={open} handleChange={handleBurgerChange} />
        ) : null}
        <div
          className={
            !props.sidebarOpen && isDesktop
              ? "sidebar sidebar_small"
              : !isDesktop && open
              ? "sidebar sidebar_open"
              : !isDesktop && !open
              ? "sidebar sidebar_hidden"
              : "sidebar"
          }
        >
          <div
            style={{ display: isDesktop ? "flex" : "none" }}
            id={
              props.sidebarOpen
                ? "navbar-resize-button"
                : "navbar-resize-button-open"
            }
            onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
          >
            <IconButton
              icon={<IoIosArrowBack />}
              background={theme.palette.primary.main}
              color="#f2f4f3"
              fontSize="15px"
              boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            />
          </div>
          <div className="sidebar--navigation">
            <div>
              {navlinks.map((link) => {
                return (
                  <div key={link.name} className="nav-element">
                    <NavLink
                      className={
                        !props.sidebarOpen ? "nav-link active-link" : "nav-link"
                      }
                      to={link.href}
                      style={({ isActive }) => ({
                        color: isActive ? "#f2f4f3" : "#3E5060",
                        background: isActive
                          ? theme.palette.primary.main
                          : undefined,
                        boxShadow: isActive
                          ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
                          : undefined,
                      })}
                    >
                      <div className="nav-icon">{link.icon}</div>
                      <div
                        className={
                          !props.sidebarOpen && isDesktop
                            ? "nav-name-mini"
                            : "nav-name"
                        }
                      >
                        {link.name}
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
          {auth?.user?.role === 2 ? (
            <div className="sidebar--navigation-bottom">
              {bottomNavLinks.map((link) => {
                return (
                  <div key={link.name} className="nav-element">
                    <NavLink
                      className={
                        !props.sidebarOpen ? "nav-link active-link" : "nav-link"
                      }
                      to={link.href}
                      style={({ isActive }) => ({
                        color: isActive ? "#f2f4f3" : "#3E5060",
                        background: isActive
                          ? theme.palette.primary.main
                          : undefined,
                        boxShadow: isActive
                          ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
                          : undefined,
                      })}
                    >
                      <div className="nav-icon">{link.icon}</div>
                      <div
                        className={
                          !props.sidebarOpen && isDesktop
                            ? "nav-name-mini"
                            : "nav-name"
                        }
                      >
                        {link.name}
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}
