import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../../components/buttons/Button";
import InputField from "../../../../components/Inputs/InputField";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import Switch from "@mui/material/Switch";
import RuleView from "./RuleView";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";

function AddStatusRowView({
  showModal,
  hideModal,
  statusAPI,
  queryClient,
  statusGroup,
}) {
  const [statusRow, setStatusRow] = useState();
  const [rules, setRules] = useState({
    rule_1_active: false,
    rule_2_active: false,
  });
  useEffect(() => {
    setRules({
      rule_1_active: false,
      rule_2_active: false,
    });
    setStatusRow({
      name: "",
      statusgroup: statusGroup.id,
      lead_time_1_yellow: undefined,
      lead_time_1_red: undefined,
      lead_time_2_yellow: undefined,
      lead_time_2_red: undefined,
      is_active: 1,
      tooltip_text: "",
      lead_time_1_towards: "",
      lead_time_2_towards: "",
    });
  }, [showModal, statusGroup.id]);
  const navigate = useNavigate();
  const { mutate } = useMutation(statusAPI.newStatusRow, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      setTimeout(function () {
        hideModal();
        queryClient.invalidateQueries("statusgroup");
      }, 1000);
      toastSuccess("Statusrad lades till");
      setTimeout(function () {
        navigate(`/admin/statusgroup/${e.data.raw.insertId}`);
      }, 1000);
    },
    onError: (e) => {
      toastError("Gick inte att lägga till Statusrad");
    },
  });
  if (statusRow)
    return (
      <Modal
        show={showModal}
        onHide={() => {
          hideModal();
          // queryClient.invalidateQueries();
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>Lägg till statusrad</Modal.Header>

        <Modal.Body>
          <div style={{ minHeight: "15em" }}>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Namn"}
                value={statusRow.name}
                onChange={(e) =>
                  setStatusRow({ ...statusRow, name: e.target.value })
                }
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              <InputField
                label={"Tooltip"}
                multiline={true}
                minRows={4}
                value={statusRow.tooltip_text}
                onChange={(e) =>
                  setStatusRow({
                    ...statusRow,
                    tooltip_text: e.target.value,
                  })
                }
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              {" "}
              Regel för {statusGroup.datetitle_1}
              <Switch
                checked={rules.rule_1_active}
                onChange={() =>
                  setRules({
                    ...rules,
                    rule_1_active: !rules.rule_1_active,
                  })
                }
                inputProps={{ "aria-label": "controlled" }}
              />{" "}
              Regel för {statusGroup.datetitle_2}
              <Switch
                checked={rules.rule_2_active}
                onChange={() =>
                  setRules({
                    ...rules,
                    rule_2_active: !rules.rule_2_active,
                  })
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            {rules.rule_1_active ? (
              <div style={{ marginBottom: "30px" }}>
                <h5>Regel för {statusGroup.datetitle_1}</h5>

                <RuleView
                  rule_required={rules.rule_1_active}
                  statusRow={statusRow}
                  setStatusRow={setStatusRow}
                  ruleTowards={1}
                  ruleTitle={statusGroup.datetitle_1}
                />
              </div>
            ) : (
              <></>
            )}
            {rules.rule_2_active ? (
              <div style={{ marginBottom: "10px" }}>
                <h5>Regel för {statusGroup.datetitle_2}</h5>

                <RuleView
                  rule_required={rules.rule_2_active}
                  statusRow={statusRow}
                  setStatusRow={setStatusRow}
                  ruleTowards={2}
                  ruleTitle={statusGroup.datetitle_2}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              mutate(statusRow);
            }}
          >
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    );
}

export default AddStatusRowView;
