import React from "react";
import Select from "react-select";
function CustomSelect({
  handleChange,
  optionList,
  styles,
  placeholder,
  defaultValue,
  name,
  isDisabled,
}) {
  let defaultObj;
  if (optionList) {
    if (defaultValue || defaultValue === 0) {
      defaultObj = optionList.find((element) => element.value === defaultValue);
    }
  }

  return (
    <Select
      placeholder={placeholder}
      onChange={handleChange}
      isSearchable={true}
      options={optionList}
      style={styles}
      value={defaultObj}
      name={name}
      isDisabled={isDisabled}
    />
  );
}

export default CustomSelect;
