import React from "react";
import { useForm, Controller } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
function CreateOwnFieldValueForm({
  createNewOwnField,
  setTableToShow,
  tableToShow,
  setShowProgressBar,
}) {
  const options = [
    { label: "Projektstatus", value: "Projektstatus" },
    { label: "Indextyp", value: "Indextyp" },
    { label: "Leveranstyp", value: "Leveranstyp" },
    // { label: "Projekttyp", value: "Projekttyp" }, vill inte låta dem skapa projekttyper då projektyp styr projektnummer som genereras
    { label: "Projektfas", value: "Projektfas" },
    { label: "Ärendekategori", value: "Ärendekategori" },
    { label: "Ärendestatus", value: "Ärendestatus" },
    { label: "Leadskanal", value: "Leadskanal" },
    { label: "Tidstyp", value: "Tidstyp" },
    { label: "Tidstyp", value: "Tidstyp" },
    { label: "Atastatus", value: "Atastatus" },
  ];
  const { register, handleSubmit, control } = useForm({});
  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          createNewOwnField(data);
        })}
      >
        <h5>Skapa nytt fält</h5>
        <div
          style={{
            display: "grid",
            gap: "1em",
            margin: "1em 0",
            marginTop: "25px",
          }}
        >
          <Controller
            control={control}
            name={"typeofField"}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                control={control}
                register={register("typeofField")}
                options={options}
                value={options.find((item) => item.value === tableToShow)}
                label={"Typ av fält"}
                onChange={(event, item) => {
                  onChange(item.value);
                  setTableToShow(item.label);
                }}
              />
            )}
          />

          <InputField
            required
            name="name"
            label="Namn på nya fältet"
            register={register("name")}
          />
        </div>
        <hr></hr>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            {" "}
            <Button type="submit" color="success">
              Skapa
            </Button>
          </div>

          {tableToShow === "Projektstatus" ? (
            <div>
              {" "}
              <Button onClick={() => setShowProgressBar(true)}>
                Ändra progressbar
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
    </>
  );
}

export default CreateOwnFieldValueForm;
