import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Button from "../../../components/buttons/Button";
import InputField from "../../../components/Inputs/InputField";
import Select from "../../../components/Inputs/Select";
function CreateStaffForm({
  staffRoles,
  createNewStaff,
  register,
  handleSubmit,
  control,
  errors,
}) {
  const [roleId, setRoleId] = useState();

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          let user = {
            first_name: data.first_name,
            last_name: data.last_name,
            staff_role: roleId,
            email: data.email,
            phone: data.phone,
          };
          createNewStaff(user);
        })}
      >
        <h5>Skapa ny användare</h5>
        <div
          style={{
            display: "grid",
            gap: "1em",
            margin: "1em 0",
            marginTop: "25px",
          }}
        >
          <InputField
            required
            name="first_name"
            label="Förnamn"
            register={register("first_name")}
          />
          <InputField
            required
            name="last_name"
            label="Efternamn"
            register={register("last_name")}
          />

          <Controller
            control={control}
            name={"staff_role"}
            render={({ field: { onChange, value } }) => (
              <Select
                required
                value={staffRoles.find((item) => item.value === roleId)}
                label={"Roll"}
                control={control}
                register={register("staff_role")}
                options={staffRoles}
                onChange={(event, item) => {
                  onChange(item.value);
                  setRoleId(item.value);
                }}
              />
            )}
          />

          <InputField
            required
            type="email"
            name="email"
            label="Email"
            register={register("email")}
          />

          <InputField
            required
            name="phone"
            label="Telefon"
            defaultValue={"+46"}
            register={register("phone", {
              pattern: { value: /^([+]46)\s*(7[0236])\s*(\d{4})\s*(\d{3})$/ },
              message: "Telefonummer måste börja med +46",
            })}
          />
          <ErrorMessage errors={errors} name="phone" />
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => (
              <p style={{ color: "#FF0000" }}>
                {"Telefonummer måste vara i format +46 7xxxxxxxx"}
              </p>
            )}
          />
        </div>
        <hr></hr>
        <Button type="submit">Skapa</Button>
      </form>
    </>
  );
}

export default CreateStaffForm;
