import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    console.log(status, "ERROR api status");
    if (status === 401) {
      console.log("401 error från api");
      // window.location.reload();
      window.location =
        window.location.protocol + "//" + window.location.host + "/login";
    } else {
      // window.location =
      //   window.location.protocol + "//" + window.location.host + "/login";
      console.log(error, "APIERROR");
      console.log("APIERROR");

      return Promise.reject(error); // Delegate error to calling side
    }
  }
);

export const getContactPerson = (id) =>
  api.get(`/suppliercontactperson/${id}`).then((res) => res.data);

export const getAllContactPersonForSupplier = (id) =>
  api.get(`/suppliercontactpersonbysupplier/${id}`).then((res) => res.data);

export const getAllContactPersonForProject = (id) =>
  api.get(`/projectcontactpersonforproject/${id}`).then((res) => res.data);

export const getAllContactPerson = () =>
  api.get("/suppliercontactperson/").then((res) => res.data);

export const updateContactPerson = ({ id, ...updatedContactPerson }) => {
  return api.put(`/suppliercontactperson/${id}`, updatedContactPerson);
};

export const addContactPersonToproject = (contactpersonAndProjectId) => {
  api.post(`/projectcontactperson/`, contactpersonAndProjectId);
};

export const createContactPerson = (contactPerson) => {
  api.post(`/suppliercontactperson/`, contactPerson);
};

export const getProjectContactPerson = (id) =>
  api.get(`/projectcontactperson/${id}`).then((res) => res.data);

export const updateProjectContactPerson = ({ id, ...updatedContactPerson }) =>
  api.put(`/projectcontactperson/${id}`, updatedContactPerson);

export const deleteProjectContactPerson = (id) =>
  api.delete(`/projectcontactperson/` + id);

export const newContactPerson = (contactperson) =>
  api.post(`/suppliercontactperson/`, contactperson);

export const deleteContactPerson = (id) =>
  api.delete(`/suppliercontactperson/` + id);
