import React from "react";
import DataTable from "../../../components/newDataTable/DataTable";
import { ClimateColumns } from "../../../components/dataTable/columns/ClimateColumns";
import * as climateAPI from "../../../api/register/climateApi";
import { useQuery } from "react-query";
function ClimateOverview() {
  const { data: climateResponse } = useQuery(["climate"], () =>
    climateAPI.getAllClimate()
  );
  return (
    <>
      {" "}
      <DataTable
        columns={ClimateColumns}
        data={climateResponse?.length > 0 ? climateResponse : []}
        typeOfTable="climate"
      />
    </>
  );
}

export default ClimateOverview;
