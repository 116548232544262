import { useState, useEffect } from "react";
import Content from "../../components/Content";
import PriceListOverview from "./pricelist/PriceListOverview";
import ArticleOverview from "./article/ArticleOverview";
import SupplierOverview from "./supplier/SupplierOverview";
import SupplierCategoryOverview from "./supplierCategory/SupplierCategoryOverview";
import ConstructionPartOverview from "./constructionpart/ConstructionPartOverview";
import ClimateOverview from "./climate/ClimateOverview";
import AccountOverview from "./account/AccountOverview";
import MaterialOverview from "./material/MaterialOverview";
function RegisterOverView() {
  let tab = JSON.parse(localStorage.getItem("registeroverview"))
    ? JSON.parse(localStorage.getItem("registeroverview"))
    : 1;

  const [lastTab, setLastTab] = useState(tab);

  useEffect(() => {
    let lastTab = JSON.parse(localStorage.getItem("registeroverview"));
    if (lastTab) {
      setLastTab(lastTab);
    }
  }, [lastTab]);
  const tabs = [
    { label: "Prislista", id: 1 },
    { label: "Leverantörer", id: 2 },
    { label: "Inaktiva Leverantörer", id: 3 },
    { label: "Leverantörskategorier", id: 4 },
    { label: "Artiklar", id: 5 },
    { label: "Inaktiva artiklar", id: 6 },
    { label: "Byggdelar", id: 7 },
    { label: "Klimatdatabasen", id: 8 },
    { label: "Konton", id: 9 },
    { label: "Material", id: 10 },
  ];

  return (
    <>
      <Content
        tabs={true}
        tabList={tabs}
        lastTab={lastTab}
        view="registeroverview"
      >
        <div value={1}>
          <PriceListOverview />
        </div>
        <div value={2}>
          <SupplierOverview activeSuppliers={true} />
        </div>
        <div value={3}>
          <SupplierOverview activeSuppliers={false} />
        </div>
        <div value={4}>
          <SupplierCategoryOverview />
        </div>
        <div value={5}>
          <ArticleOverview activeArticles={true} />
        </div>
        <div value={6}>
          <ArticleOverview activeArticles={false} />
        </div>
        <div value={7}>
          <ConstructionPartOverview />
        </div>
        <div value={8}>
          <ClimateOverview />
        </div>
        <div value={9}>
          <AccountOverview />
        </div>
        <div value={10}>
          <MaterialOverview />
        </div>
      </Content>
    </>
  );
}

export default RegisterOverView;
