import React, { useState } from "react";
import { useMutation } from "react-query";
import * as api from "../../../../api/project/moduleApi";
import Button from "../../../../components/buttons/Button";
import InputField from "../../../../components/Inputs/InputField";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import {
  toastError,
  toastSuccess,
} from "../../../../components/toast/toastFunctions";

function AddModuleView({
  showModal,
  hideModal,
  projectId,
  queryClient,
  projektnr,
}) {
  const { handleSubmit, reset } = useForm({});

  const [fields, setFields] = useState({
    wall_designation: "",
    module_stage: "",
    material_withdrawal: null,
    module_number: projektnr,
    project: Number(projectId),
    self_produced: 0,
  });

  const { mutate } = useMutation(api.newModule, {
    onSettled: (e) => {
      console.log("ON SETTLED");
      queryClient.invalidateQueries("module");
    },
    onSuccess: () => {
      setTimeout(function () {
        hideModal();
        reset();
      }, 500);
      toastSuccess("Modul skapades!");
      setFields({
        wall_designation: "",
        module_stage: "",
        material_withdrawal: null,
        module_number: projektnr,
        project: Number(projectId),
        self_produced: 0,
      });
    },
    onError: (e) => {
      if (e.response.status === 409)
        toastError("Finns redan en modul med den beteckningen!");
      if (e.response.status === 500) toastError("Gick inte att skapa modul!");
      console.log("error fron mutate");
    },
  });

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      if (name === "self_produced") {
        if (fields.self_produced === 0) {
          setFields({ ...fields, self_produced: 1 });
        } else {
          setFields({ ...fields, self_produced: 0 });
        }
      }
    } else setFields({ ...fields, [name]: value });
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        hideModal();
        queryClient.invalidateQueries();
      }}
      centered
      backdrop="static"
      size="sm"
    >
      <Modal.Header closeButton>Skapa ny modul</Modal.Header>

      <Modal.Body>
        <form
          onSubmit={handleSubmit((data) => {
            queryClient.invalidateQueries(["module"]);
            mutate(fields);
          })}
        >
          <div
            style={{
              display: "grid",
              gap: "1em",
              marginBottom: "1em",
              maxWidth: "500px",
            }}
          >
            <InputField
              required
              label={"Väggbet"}
              name="wall_designation"
              onChange={handleChange}
              value={fields.wall_designation}
              maxLength={255}
            />

            <div>
              <InputField
                required
                label={"Vagga"}
                name="module_stage"
                onChange={handleChange}
                value={fields.module_stage}
                maxLength={45}
              />
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", marginTop: "8px" }}>
                <p>Egentillverkad</p>
              </div>
              <Checkbox
                name="self_produced"
                label={"self_produced"}
                type="checkbox"
                onChange={handleChange}
                checked={fields.self_produced === 1}
                // value={fields}
              >
                Egentillverkad
              </Checkbox>
            </div>
          </div>

          <Button type="submit">Lägg till</Button>
        </form>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default AddModuleView;
