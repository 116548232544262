import React, { useState, useEffect } from "react";
import logo from "../../../../assets/gifs/loading.gif";
import * as purchaseAPI from "../../../../api/project/newPurchaseApi";
import * as projectAPI from "../../../../api/project/projectApi";
import PurchaseForm from "./PurchaseForm";
import { useParams } from "react-router-dom";
function PurchaseView({ handleClose, setKeepDataInForm }) {
  const purchaseParam = useParams();
  const pur = purchaseParam.id;
  const [purchase, setPurchase] = useState();
  const [orderRows, setOrderRows] = useState([]);
  const [projectadress, setProjectAdress] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [project, setProject] = useState();
  const [alteredRows, setAlteredRows] = useState([]);
  const [wasSaved, setWasSaved] = useState(false);

  useEffect(() => {
    purchaseAPI.GetOrder(pur).then((purchase) => {
      setPurchase(purchase);
    });
    purchaseAPI.getPageNumber(pur).then((response) => {
      setNumberOfPages(response.numberOfPages);
    });
  }, [pur, wasSaved]);

  useEffect(() => {
    purchaseAPI
      .GetOrderRows(pur, pageNumber)
      .then((rows) => setOrderRows(rows));
    setWasSaved(false);
  }, [pageNumber, pur, wasSaved]);

  useEffect(() => {
    if (purchase) {
      projectAPI
        .getProject(purchase.project)
        .then((project) => setProject(project));
    }
  }, [purchase]);

  useEffect(() => {
    if (project) {
      setProjectAdress(
        project.property_designation +
          "," +
          project.property_adresss +
          "," +
          project.property_post_adress
      );
    }
  }, [project]);
  if ((purchase && orderRows, project)) {
    return (
      <div style={{ marginTop: "5em", padding: "1em" }}>
        <PurchaseForm
          purchase={purchase}
          projectid={project.id}
          handleClose={handleClose}
          setKeepDataInForm={setKeepDataInForm}
          rows={orderRows}
          projectadress={projectadress}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numberOfPages={numberOfPages}
          alteredRows={alteredRows}
          setAlteredRows={setAlteredRows}
          setWasSaved={setWasSaved}
        />
      </div>
    );
  } else {
    return (
      <div style={{ position: "absolute", left: "40%", top: "400%" }}>
        <img
          src={logo}
          alt="loading..."
          style={{ height: "200px", width: "200px" }}
        />
      </div>
    );
  }
}

export default PurchaseView;
