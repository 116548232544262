import SelectColumnFilter from "../dataTableFilters/SelectColumnFilter";

export const MaterialColumns = [
  {
    Header: "Material",
    table: "material",
    accessor: "label",
    Filter: SelectColumnFilter,
    filter: "includesString",
  },
];
