import React, { useState, useEffect } from "react";
import Button from "../../components/buttons/Button";
import InputField from "../../components/Inputs/InputField";
import "../../styles/login/login.css";
import { useLogin } from "../../hooks/useLogin";
import LoginOTPView from "./LoginOTPView";
export default function Login() {
  const [showOTPVeryView, setShowOTPVeryView] = useState(false);
  const [usderAttemptingToLogin, setUserAttemptingLogin] = useState();

  const [resetPw, setResetPw] = React.useState(false);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [failedAttempt, setFailedAttempt] = useState(false);
  const [loginButtonTimeOut, setLoginButtonTimeout] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const {
    loginStep1,
    loginStep2,
    forgottenPasswordStep1,
    forgottenPasswordStep2,
    error,
  } = useLogin();

  useEffect(() => {
    if (failedAttempt) {
      setShowLoginError(true);
      setFailedAttempt(false);
    }
    //
  }, [failedAttempt]);

  useEffect(() => {
    if (showLoginError) {
      let timer = setInterval(() => {
        setShowLoginError(false);
        clearInterval(timer);
      }, 5000);
    }
  }, [showLoginError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (resetPw) {
      console.log("loggar in");
      let response = await forgottenPasswordStep1(email);

      if (response.OTPVerificationCreated && response.validUser) {
        setUserAttemptingLogin({ uid: response.userid, email: response.email });
        setShowOTPVeryView(true);
      } else {
        // Felmeddelande till användaren.
        setShowOTPVeryView(true);
      }
    } else {
      console.log("loggar in");
      let response = await loginStep1(email, password, setFailedAttempt);
      if (response.OTPVerificationCreated && response.validUser) {
        setUserAttemptingLogin({ uid: response.userid, email: response.email });
        setShowOTPVeryView(true);
      } else {
        // Felmeddelande till användaren.
      }
      setPassword("");
      setEmail("");
      // navigate(from, { replace: true })
    }
  };

  const timeOutButton = (buttonToTimeOut) => {
    buttonToTimeOut(true);
    let timer = setInterval(() => {
      buttonToTimeOut(false);
      clearInterval(timer);
    }, 1500);
  };
  return (
    <div className="login-container">
      <form
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {showLoginError ? (
          <p style={{ color: "#FF0000" }}>Felaktiga uppgifter</p>
        ) : (
          <></>
        )}
        <span style={{ margin: "1em 0" }}>
          <h1 style={{ fontWeight: "600" }}>Välkommen</h1>
          <h6 style={{ fontWeight: "600", opacity: "0.5" }}>
            Ange dina uppgifter för att komma vidare
          </h6>
        </span>

        <InputField
          label="Användarnamn"
          onChange={(e) => setEmail(e.target.value)}
          color="primary"
          required
        />
        {!resetPw && (
          <InputField
            label="Lösenord"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            color="primary"
            required
          />
        )}

        <Button
          disabled={
            resetPw
              ? loginButtonTimeOut
              : !email || !password || loginButtonTimeOut
          }
          onClick={(e) => {
            handleSubmit(e);
            timeOutButton(setLoginButtonTimeout);
          }}
          color={"primary"}
          sx={{ color: "white" }}
          size="large"
        >
          {resetPw ? "Återställ lösenord" : "Logga in"}
        </Button>
        {error && <div>{error} </div>}
        {resetPw && (
          <Button
            onClick={() => setResetPw(false)}
            color={"primary"}
            variant="outlined"
            size="large"
          >
            Avbryt
          </Button>
        )}
      </form>
      {!resetPw && (
        <div
          style={{
            display: "flex",
            margin: "1em 0",
            alignItems: "center",
          }}
        >
          Glömt lösenord?
          <Button
            sx={{ textTransform: "none" }}
            variant="text"
            onClick={() => setResetPw(true)}
          >
            Återställ lösenord
          </Button>
        </div>
      )}
      <LoginOTPView
        resetPw={resetPw}
        setResetPw={setResetPw}
        loginStep2={loginStep2}
        forgottenPasswordStep2={forgottenPasswordStep2}
        user={usderAttemptingToLogin}
        showModal={showOTPVeryView}
        hideModal={() => setShowOTPVeryView(false)}
      />
    </div>
  );
}
