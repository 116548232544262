import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DataTable from "../../../components/newDataTable/DataTable";
import { useQuery } from "react-query";
import { downloadContacts } from "../utils/ExportFunctions";
import AddContactPersonView from "../ProjectDetailTabViews/AdressAndContacts/components/AddContactPersonView";
import { ProjectContactPersonColumns } from "../../../components/dataTable/columns/ProjectContactPersonColumns";
import ContactPersonView from "../ProjectDetailTabViews/AdressAndContacts/components/ContactPersonsView";
import * as contactPersonAPI from "../../../api/register/contactPersonApi";
import { useQueryClient } from "react-query";
function ProjectContactPersonOverview({ project }) {
  const [displayAddContactPersonView, setDisplayAddContactPersonView] =
    useState(false);

  const [contactPersonToView, setContactPersonToView] = useState();
  const { data: projectContactPersonData } = useQuery(
    ["projectcontactperson", project.id],
    () => contactPersonAPI.getAllContactPersonForProject(project.id)
  );

  const queryClient = useQueryClient();

  return (
    <>
      <div className="card-body">
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={() => downloadContacts(projectContactPersonData, project)}
        >
          Exporta kontakter
        </Button>{" "}
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setDisplayAddContactPersonView(true)}
        >
          Lägg till kontaktperson
        </Button>
        <DataTable
          columns={ProjectContactPersonColumns}
          data={
            projectContactPersonData?.length > 0 ? projectContactPersonData : []
          }
          typeOfTable="projectcontactpersonforproject"
          handleShowObject={setContactPersonToView}
        />
      </div>
      <AddContactPersonView
        queryClient={queryClient}
        projectId={project.id}
        showModal={displayAddContactPersonView}
        confirmModal={() => console.log("first")}
        hideModal={() => setDisplayAddContactPersonView(false)}
      />
      <ContactPersonView
        queryClient={queryClient}
        projectId={project.id}
        showModal={contactPersonToView}
        confirmModal={() => console.log("first")}
        hideModal={() => setContactPersonToView(null)}
      />
    </>
  );
}

export default ProjectContactPersonOverview;
