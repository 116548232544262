import React from "react";
import "../styles/contentcontainer/Content.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "../styles/projectdetails/projectdetails.css";
export default function Content(props) {
  /*
   * tabs: bool
   * tabList: list/obj -- [{label: string, id:numb  }]
   * title: string
   * children
   */
  const [value, setValue] = React.useState(props.lastTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(props.view, JSON.stringify(newValue));
  };

  return (
    <>
      <div className="content-container">
        {props.title ? (
          <Typography variant="h5" component="h1">
            {props.title}
          </Typography>
        ) : (
          <div></div>
        )}

        <div className="content-container--body">
          {props.tabs ? (
            <div className="tabs-container" id={props.tabsCointainerId}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  {props.tabList.map((tab, index) => {
                    return (
                      <Tab
                        key={index}
                        value={tab.id}
                        label={tab.label}
                        wrapped
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </div>
          ) : null}
          <div className="content-area">
            {props.tabs
              ? props.children.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: item.props.value === value ? "unset" : "none",
                      }}
                    >
                      {item}
                    </div>
                  );
                })
              : props.children}

            {/* {props.children} */}

            {/* {props.tabs ? tabpanel : props.children} */}
          </div>
        </div>
      </div>
    </>
  );
}
