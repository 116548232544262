import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_DBBASE,
  withCredentials: true,
});

export const loginStep1 = (user) =>
  api.post(`/userloginstep1/`, user).then((res) => res.data);

export const loginStep2 = (data) =>
  api.post(`/userloginstep2/`, data).then((res) => {
    return res.data;
  });

export const forgottenPasswordStep1 = (user) =>
  api.post(`/forgottenpasswordstep1/`, user).then((res) => res.data);

export const forgottenPasswordStep2 = (data) =>
  api.post(`/forgottenpasswordstep2/`, data).then((res) => {
    return res.data;
  });

export const getStatus = (id) =>
  api.get(`/errandstatus/${id}`).then((res) => res.data);

export const getAuthentication = () =>
  api.get(`/getauth/`).then((res) => res.data);

export const logout = () => api.post(`/logout/`).then((res) => res.data);
