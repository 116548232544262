import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQueryClient } from "react-query";
import ErrandOverview from "../ProjectDetailTabViews/errand/ErrandOverview";
import AddErrandView from "../ProjectDetailTabViews/errand/AddErrandView";
function ProjectErrandOverView({ project, user }) {
  const [displayAddErrandView, setDisplayAddErrandView] = useState();

  const queryClient = useQueryClient();
  return (
    <>
      <div className="card-body">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setDisplayAddErrandView(true)}
        >
          Skapa nytt ärende
        </Button>
        <ErrandOverview
          projectId={project.id}
          client_mail={project.client_email}
        />
      </div>
      <AddErrandView
        userId={user.id}
        queryClient={queryClient}
        projectId={project.id}
        showModal={displayAddErrandView}
        confirmModal={() => console.log("first")}
        hideModal={() => setDisplayAddErrandView(null)}
      />
    </>
  );
}

export default ProjectErrandOverView;
